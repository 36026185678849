import Swal from 'sweetalert2';
import {
    IPlanning,
    IPlanningEvent,
    iPlanningUnavailableObject,
    IPlanningEventData,
    IPlanningIssuer,
    IPlanningDetails,
    IPlanningIVL,
} from '../helpers/interfaces/planning';
import planningService from './api/planning.service';
import ObjectToArray from '../helpers/ObjectToArray';
import authService from './auth.service';
import moment from "moment";
import 'moment/locale/it';

class PlanningService {
    async getPeopleBetweenTwoDates(
        apiService: (startDate: string, endDate: string) => Promise<IPlanning>,
        startDate: moment.Moment,
        endDate: moment.Moment
    ): Promise<IPlanningEventData[] | null> {
        return await apiService(
            startDate.format('YYYY-MM-DD'),
            endDate.format('YYYY-MM-DD')
        ).then(
            async (data: IPlanning | null) => {
                return data ? await this.createPeopleData(data) : null;
            },
            (error: any) => {
                Promise.reject(error);
                return null;
            }
        ).catch((error: any) => {
            Swal.fire({
                title: 'Errore imprevisto',
                text: 'A causa di un errore imprevisto non è stato possibile caricare i dati.',
                icon: 'error',
            });

            return null;
        });
    }

    async getIVLBetweenTwoDates(
        startDate: moment.Moment,
        endDate: moment.Moment
    ): Promise<IPlanningIVL | null> {
        return await planningService.getIVLDates(
            startDate.format('YYYY-MM-DD'),
            endDate.format('YYYY-MM-DD')
        ).catch(() => {
            Swal.fire({
                title: 'Errore imprevisto',
                text: 'A causa di un errore imprevisto non è stato possibile caricare i dati.',
                icon: 'error',
            });

            return null;
        });
    }

    async getDayDipendente(
        startDate: moment.Moment,
    ): Promise<IPlanningEventData[] | null> {
        const currentUser = await authService.getCurrentUser();
        if (!currentUser) { return null; }

        return await planningService.getDayDipendente(
            startDate.format('YYYY-MM-DD')
        ).then(
            async (result: IPlanning | null) => {
                let data: IPlanning | null = result;
                if (data && !data.events[0]) {
                    data.events.push({
                        resource: {
                            id: currentUser.id,
                            fullname: currentUser.name + '' + currentUser.lastname
                        },
                        plannings: []
                    })
                }

                return data ? await this.createPeopleData(data) : null;
            },
        ).catch((error: any) => {
            Swal.fire({
                title: 'Errore imprevisto',
                text: 'A causa di un errore imprevisto non è stato possibile caricare le informazioni.',
                icon: 'error',
            });

            return null;
        });
    }

    async createPeopleData(
        data: IPlanning
    ): Promise<IPlanningEventData[]> {
        let plannings: IPlanningEventData[] = [];
        data.events.forEach((event: IPlanningEvent & { issuer?: IPlanningIssuer }, index: number) => {
            if (!plannings[event.resource.id]) {
                plannings[event.resource.id] = {
                    resource: { ...event.resource, type: "people" },
                    plannings: []
                }
            }

            event.plannings.length && event.plannings.forEach((planningEvent: IPlanningDetails) => {
                plannings[event.resource.id].plannings.push({ ...planningEvent, type: 'P' });
            })
        });

        const unavailableData = ObjectToArray<iPlanningUnavailableObject[][]>(data.unavailable);

        unavailableData.forEach((unavailables: iPlanningUnavailableObject[]) => {
            unavailables.forEach((unavailable) => {
                if (!plannings[unavailable.resource.id]) {
                    plannings[unavailable.resource.id] = {
                        resource: { ...unavailable.resource, type: "people" },
                        plannings: []
                    }
                }

                plannings[unavailable.resource.id].plannings.push({ ...unavailable, type: 'U' })
            })
        });

        return plannings;
    }
}

export default new PlanningService()