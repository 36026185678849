import axios, { AxiosResponse } from "axios";
import EventBus from "../common/EventBus";
import { history } from "../helpers/history";
import { IAuthChangePassword, IChangePassword, LoginResponse } from "../helpers/interfaces/user";
import IUser from "../types/user.type";
const API_URL = process.env.REACT_APP_API_URL;

class AuthService {

  async login(email: string, password: string): Promise<LoginResponse> {
    return await axios.post(API_URL + "login", {
      email,
      password
    })
      .then((response: AxiosResponse<any>) => {
        if (response.data.token) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  logout(): void {
    localStorage.removeItem("user");
    EventBus.dispatch("hideLoader");
    history.push("/login");
  }

  async getCurrentUser(): Promise<IUser | null> {
    const userStr = localStorage.getItem("user");
    if (userStr) return JSON.parse(userStr);
    return null;
  }

  async getIsAdmin() : Promise<boolean> {
    const userString = localStorage.getItem("user");

    if (!userString) {
      return false;
    }

    const user = JSON.parse(userString);
    return user.roles.find((role: any) => role.code === "admin" || role.code === "amministrazione")
  }

  changePassword(body: IChangePassword) {
    return axios.post<IChangePassword>(API_URL + "changepassword", body)
      .then((response: AxiosResponse<any>) => { return response.data; })
      .catch((error) => { return Promise.reject(); });
  }

  recoverPassword(body: { registrationemail: string }) {
    return axios.post<{ registrationemail: string }>(API_URL + "passwordrecover", body)
      .then((response: AxiosResponse<any>) => { return response.data; })
      .catch((error) => { return Promise.reject(); });
  }

  resetPassword(body: IAuthChangePassword) {
    return axios.post<IAuthChangePassword>(API_URL + "passwordreset", body)
      .then(
        (response: AxiosResponse<any>) => { return response.data; })
      .catch((error) => { return Promise.reject(); });
  }
}

export default new AuthService();
