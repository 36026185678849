import React, { Component } from 'react'
import EventBus from '../../../common/EventBus'
import Table from '../../../common/TableComponent'
import getTipologiaStrumentiColumnDefs, { listTipologiaStrumentiColumns } from '../../../config/tableColumns/magazzino/magazzino-tipologia-strumenti'
import { IMagazzinoTipologiaStrumento } from '../../../helpers/interfaces/magazzino/magazzino-tipologia-strumenti'
import tipologiastrumentiService from '../../../services/api/magazzino/magazzino-tipologia-strumenti.service'

type State = {
    tipologie: IMagazzinoTipologiaStrumento[]
}

export class MagazzinoTipologiaStrumenti extends Component<{}, State> {

    constructor(props: {}) {
        super(props)

        this.state = {
            tipologie: []
        }
    }

    async componentDidMount() {
        // loading the contracts
        EventBus.dispatch("showLoader", { text: 'Caricamento delle tipologie in corso...' });

        await tipologiastrumentiService.getAll().then(
            (tipologie) => this.setState({ tipologie })
        );

        EventBus.dispatch("hideLoader");
    }

    render() {
        const { tipologie } = this.state;

        const buttons = ['excel', 'pdf', 'print']
        return (
            <React.Fragment>
                <div className='custom-container'>
                    <div className="card">
                        <div className="card-body">
                            <div className="form-group mb-3 d-flex justify-content-between align-items-center">
                                <h2 className="card-title">Tipologia strumenti</h2>
                                <a href="/magazzino/tipologia-strumenti/nuovo" id="add_btn" className="btn btn-outline-primary">
                                    <span>Aggiungi tipologia</span>
                                </a>
                            </div>
                            {
                                tipologie.length > 0 &&
                                <Table id={'table_magazzino_contratti'} columns={listTipologiaStrumentiColumns} columnDefs={getTipologiaStrumentiColumnDefs()} datas={tipologie} buttons={buttons} />
                            }
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default MagazzinoTipologiaStrumenti