import React, { Component, MouseEvent } from 'react'
import EventBus from '../../common/EventBus';
import Table from '../../common/TableComponent';
import getListOreSettimanaliColumnDefs, {
    listOreSettimanaliColumns,
} from '../../config/tableColumns/organico/ore-settimanali'
import { IOreSettimanaliResponse, IOreSettimanaliFormField } from '../../helpers/interfaces/ore-settimanali';
import OreSettimanaliService from '../../services/api/ore-settimanali.service';
import ModalForm from '../../common/ModalForm';
import { iFormField, iFormFieldOption } from '../../helpers/interfaces/generic';
import { getOreSettimanaliFormFields } from '../../config/formFields/ore-settimanali';
import $ from 'jquery';
import Swal from 'sweetalert2';
import personService from '../../services/api/person.service';
import { IDipendente } from '../../helpers/interfaces/magazzino/magazzino-home';

type State = {
    OreSettimanali: IOreSettimanaliResponse[],
    OreSettimanaliOptions: iFormFieldOption[],
    showForm: boolean,
    modalType: string,
    modalTitle: string,
    formFields: iFormField[],
    formInitialValues: IOreSettimanaliFormField| {},
    loading: boolean,
}

export default class OreSettimanali extends Component<{}, State>
{
    apiSubmit: any;

    constructor(props: {}) {
        super(props)

        this.state = {
            OreSettimanali: [],
            OreSettimanaliOptions: [],
            showForm: false,
            modalType: '',
            modalTitle: '',
            formFields: [],
            formInitialValues: {},
            loading: false,
        }

        this.apiSubmit = null;
    }

    async componentDidMount() {
        EventBus.dispatch("showLoader", { text: 'Caricamento dei profili settimanali in corso...' });

        await OreSettimanaliService.getAll().then(
            async (OreSettimanali: IOreSettimanaliResponse[]) => {
                this.setState({OreSettimanali})
                return OreSettimanali;
            }
        ).catch(
            (error: any) => {
                this.setState({OreSettimanali: []})
            }
        );

        EventBus.dispatch("hideLoader");


        $('#table_ore_settimanali.table').on('click', '.edit_btn', async (e: JQuery.ClickEvent) => {
            e.preventDefault();

            EventBus.dispatch("showLoader", { text: 'Caricamento dei dati in corso...' });

            const idSettimanali = $(e.currentTarget).data('id');

            await OreSettimanaliService.get(idSettimanali).then(
                async (OreSettimanali: IOreSettimanaliResponse | null) => {

                    if (!OreSettimanali) {
                        Swal.fire(
                            'Errore di lettura',
                            'Si è verificato un errore imprevisto durante la lettura dei dati',
                            'error'
                        )
                        return null;
                    } else {
                        this.apiSubmit = OreSettimanaliService.edit;

                        this.setState({
                            formFields: getOreSettimanaliFormFields([{
                                label: `${OreSettimanali.user.name} ${OreSettimanali.user.lastname}`,
                                value: OreSettimanali.user.id
                            }], 'edit'),
                            formInitialValues: {
                                id: OreSettimanali.id,
                                domenica: OreSettimanali.domenica,
                                lunedi: OreSettimanali.lunedi,
                                martedi: OreSettimanali.martedi,
                                mercoledi: OreSettimanali.mercoledi,
                                giovedi: OreSettimanali.giovedi,
                                venerdi: OreSettimanali.venerdi,
                                sabato: OreSettimanali.sabato,
                                person: OreSettimanali.user.id
                            },
                            modalType: 'edit',
                            modalTitle: `Modifica ore settimanali di ${OreSettimanali.user.name} ${OreSettimanali.user.lastname}`
                        }, async() => {
                            this.setState({showForm: true});
                        });
                    }
                },
                (error: any) => {
                    Swal.fire(
                        'Errore di lettura',
                        'Si è verificato un errore imprevisto durante la lettura dei dati',
                        'error'
                    );

                    return null
                }
            ).finally(() => {
                EventBus.dispatch("hideLoader");
            });
        });
    }

    async addHandler() {
        EventBus.dispatch("showLoader", { text: 'Caricamento dei dati in corso...' });

        this.apiSubmit = OreSettimanaliService.add;

        let employeesOptions: iFormFieldOption[] = [];
        await personService.getAllDipendenti().then(employees => {
            employeesOptions = employees.length ? employees.map(
                (employee: IDipendente) => {
                    return {
                        value: employee.id,
                        label: `${employee.name} ${employee.lastname}`
                    }
                }
            ) : [];
            
            return employeesOptions;
        }).catch(() => {
            return []
        });

        this.setState({
            formFields: getOreSettimanaliFormFields(employeesOptions, 'add'),
            formInitialValues: {
                domenica: 0,
                lunedi: 0,
                martedi: 0,
                mercoledi: 0,
                giovedi: 0,
                venerdi: 0,
                sabato: 0,
            },
            modalType: 'add',
            modalTitle: 'Crea nuovo profilo settimanale'
        }, async() => {
            this.setState({showForm: true});
        });

        EventBus.dispatch("hideLoader");
    }

    closeModalForm() {
      this.setState({ showForm: false });
    }
  
    handleSuccess() {
      window.location.hash = "#oreSettimanali";
      window.location.reload();
    }

    render() {
        const { OreSettimanali, showForm, modalType, modalTitle, formFields, formInitialValues } = this.state;

        const buttons = ['excel', 'pdf', 'print'];

        return <React.Fragment>
            <ModalForm
                showModal={showForm}
                title={modalTitle}
                modalType={modalType}
                formFields={formFields}
                initialValues={formInitialValues}
                apiSubmit={this.apiSubmit}
                closeCallback={this.closeModalForm.bind(this)}
                successAction={this.handleSuccess.bind(this)}
            />
            <div className='custom-container'>
                <div className="card">
                    <div className="card-body">
                        <div className="form-group mb-3 d-flex justify-content-between align-items-center">
                            <h2 className="card-title">Ore settimanali</h2>
                            <a
                                id="add_btn"
                                href="#add"
                                className="btn btn-outline-primary"
                                onClick={(e: MouseEvent) => {
                                    e.preventDefault();
                                    this.addHandler();
                                }}
                            >
                                <span>Aggiungi settimanali</span>
                            </a>
                        </div>
                        {
                            OreSettimanali.length > 0 &&
                            <Table
                                id={'table_ore_settimanali'}
                                columns={listOreSettimanaliColumns}
                                columnDefs={getListOreSettimanaliColumnDefs()}
                                datas={OreSettimanali}
                                buttons={buttons}
                            />
                        }
                    </div>
                </div>
            </div>
        </React.Fragment>
    }
}