import { AxiosResponse } from 'axios';
import axios from '../api';
import { IDipendenteRatei } from '../../helpers/interfaces/user';

const API_URL = process.env.REACT_APP_API_URL;

class GestioneRateiService {
    
    getAllDipendente(): Promise<IDipendenteRatei[]> {
        return axios.get<IDipendenteRatei[]>(API_URL + 'dipendente/ratei')
            .then((response: AxiosResponse<IDipendenteRatei[]>) => { return response.data })
            .catch((error) => { return Promise.reject(error); });
    }

    getAll(): Promise<IDipendenteRatei[]> {
        return axios.get<IDipendenteRatei[]>(API_URL + 'v2/administration/ratei')
            .then((response: AxiosResponse<IDipendenteRatei[]>) => { return response.data })
            .catch((error) => { return Promise.reject(error); });
    }
   
    getOne(id: string): Promise<IDipendenteRatei> {
        return axios.get<IDipendenteRatei>(API_URL + 'v2/administration/ratei/' + id)
            .then((response: AxiosResponse<IDipendenteRatei>) => { return response.data })
            .catch((error) => { return Promise.reject(); });
    }

    update(body: FormData): Promise<any> {
        return axios.post<FormData, AxiosResponse<any>>(API_URL + 'v2/administration/ratei/fileupdate', body, { headers: { "Content-Type": "multipart/form-data" } })
            .then((response: AxiosResponse<any>) => { return response.data; })
            .catch((error) => { return Promise.reject(error); });
    }

}

export default new GestioneRateiService();