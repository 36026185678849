import { AxiosResponse } from 'axios';
import axios from '../../api';
import { IMagazzinoStrumento } from '../../../helpers/interfaces/magazzino/magazzino-strumenti';

const API_URL = process.env.REACT_APP_API_URL;

class MagazzinoStrumentiService {

    //* se ci sono chiamate che restituiscono 200 ma non hanno dati, metti controllo su error

    async getAll(): Promise<IMagazzinoStrumento[]> {
        try {
            const response = await axios.get<IMagazzinoStrumento[]>(API_URL + 'magazzino/strumenti');
            return Array.isArray(response.data) ? response.data : [];
        } catch (error) {
            return await Promise.reject();
        }
    }

    async getOne(id: string): Promise<IMagazzinoStrumento> {
        try {
            const response = await axios.get<IMagazzinoStrumento>(API_URL + 'magazzino/strumenti/' + id);
            return response.data;
        } catch (error) {
            return await Promise.reject();
        }
    }

    async add(body: FormData): Promise<IMagazzinoStrumento> {
        try {
            const response = await axios.post<FormData, AxiosResponse<IMagazzinoStrumento>>(
                API_URL + 'magazzino/strumenti',
                body,
                { headers: { "Content-Type": "multipart/form-data" } }
            );

            if (response.data?.error) {
                return await Promise.reject(response.data.error);
            }

            return response.data;
        } catch (error) {
            return await Promise.reject();
        }
    }

    async edit(id: string, body: FormData): Promise<IMagazzinoStrumento> {
        try {
            const response = await axios.put<FormData, AxiosResponse<IMagazzinoStrumento>>(
                API_URL + 'magazzino/strumenti/' + id,
                body,
                { headers: { "Content-Type": "multipart/form-data" } }
            );

            if (response.data?.error) {
                return await Promise.reject(response.data.error);
            }

            return response.data;
        } catch (error) {
            return await Promise.reject();
        }
    }

}

export default new MagazzinoStrumentiService();