import { ConfigColumns } from "datatables.net";

export const listGroupColumns: ConfigColumns[] = [
    { title: "Nome", data: "nome" },
    {
        title: "Azioni", data: "id", render: (data: any, type: any, row: any): string => {
            return `<div class="d-flex">
                <a href="." class="subscribers_btn nav-link" data-id="${data}" title="Visualizza l'elenco degli iscritti al gruppo">Lista iscritti</a>
                <a href="." class="add_btn nav-link" data-id="${data}" title="Aggiungi personale al gruppo">Aggiungi</a>
                <a href="." class="edit_btn nav-link" data-id="${data}" title="Modifica gruppo">Modifica</a>
                <a href="." class="delete_btn nav-link" data-id="${data}" title="Elimina gruppo">Elimina</a>
            </div>`;
        },
        searchable: false,
    },
];

export const listSubscribersGroupColumns: ConfigColumns[] = [
    { title: "Nominativo", data: "nominativo" },
    { 
        title: "Team Leader", data: "id", render: (data: any, type: any, row: any): string => {
            return row.isTL ?
                // `<a class="edit_btn fa fa-check-square" data-id="${data}" style="font-size: 20px; color: #206bc4; display: flex; justify-content: center" aria-hidden="true"></a>` :
                // `<a class="edit_btn fa fa-check-square" data-id="${data}" style="font-size: 20px; color: lightgrey; display: flex; justify-content: center" aria-hidden="true"></a>`

                `<a class="editTL_btn fa fa-check-square" data-id="${data}" style="font-size: 20px; color: #206bc4;" aria-hidden="true"></a>` :
                `<a class="editTL_btn fa fa-check-square" data-id="${data}" style="font-size: 20px; color: lightgrey;" aria-hidden="true"></a>`
        }
    },
    { 
        title: "Admin HR", data: "id", render: (data: any, type: any, row: any): string => {
            return row.isAdminHR ?
                `<a class="editHR_btn fa fa-check-square" data-id="${data}" style="font-size: 20px; color: #206bc4;" aria-hidden="true"></a>` :
                `<a class="editHR_btn fa fa-check-square" data-id="${data}" style="font-size: 20px; color: lightgrey;" aria-hidden="true"></a>`
        }
    },
    {
        title: "Azioni", data: "id", render: (data: any, type: any, row: any): string => {
            return `<div class="d-flex">
                <a class="nav-link" href="/organico/dettaglio-personale/${data}" title="Visualizza i dettagli dell'iscritto">Dettagli</a>
                <a class="delete_subscriber_btn nav-link" href="." data-id="${data}" title="Elimina dal gruppo">Elimina</a>
            </div>`
        },
        searchable: false,
    },
];

export const listNotSubscribersGroupColumns: ConfigColumns[] = [
    { title: "Nominativo", data: "nominativo" },
    {
        title: "Azioni", data: "id", render: (data: any, type: any, row: any): string => {
            return `<div><a href="." class="add_subscriber_btn nav-link" data-id="${data}" title="Aggiungi questa persona al gruppo">Aggiungi</a></div>`;
        },
        searchable: false,
    },
];