import { ConfigColumnDefs, ConfigColumns } from "datatables.net";

export const listOreSettimanaliColumns: ConfigColumns[] = [
    { "title": "Azioni", "data": "id" },
    { "title": "Persona", "data": "user" },
    { "title": "Dom", "data": "domenica" },
    { "title": "Lun", "data": "lunedi" },
    { "title": "Mar", "data": "martedi" },
    { "title": "Mer", "data": "mercoledi" },
    { "title": "Gio", "data": "giovedi" },
    { "title": "Ven", "data": "venerdi" },
    { "title": "Sab", "data": "sabato" },
];

export default function getListOreSettimanaliColumnDefs(): ConfigColumnDefs[] {
    return [
        {
            target: 0,
            render: function (data: any, type: any, row: any) {
                return `<a class="edit_btn custom-icon btn btn-outline-primary rounded-circle" data-id="${data}">
                    <i style="font-size: 18px" class="fa fa-pencil" aria-hidden="true"></i>
                </a>`;
            },
        },{
            target: 1,
            render: function (data: any, type: any, row: any) {
                return `${data.name} ${data.lastname}`;
            },
        },
    ];
}
