import * as Yup from "yup";
import { iFormField } from "../../helpers/interfaces/generic";

export function getEditAttivitaFormFields(): Array<iFormField> {
  return [
    {
      'label': 'Tipo',
      'name': 'type',
      'type': 'select',
      'values': getRitTypeFieldValues(),
      'class': 'form-control',
      'validation': Yup.string().notRequired()
    },
    {
      'label': 'Codice',
      'name': 'code',
      'type': 'text',
      'class': 'form-control',
      'validation': Yup.string().notRequired()
    },
    {
      'label': 'Descrizione',
      'name': 'descrizione',
      'type': 'text',
      'class': 'form-control',
      'validation': Yup.string().notRequired()
    }
  ];
}

export function getAddAttivitaFormFields(): Array<iFormField> {
  return [
    {
      'label': 'Tipo',
      'name': 'type',
      'type': 'select',
      'values': getRitTypeFieldValues(),
      'class': 'form-control',
      'validation': Yup.string().notRequired()
    },
    {
      'label': 'Codice',
      'name': 'code',
      'type': 'text',
      'class': 'form-control',
      'validation': Yup.string().notRequired()
    },
    {
      'label': 'Descrizione',
      'name': 'descrizione',
      'type': 'text',
      'class': 'form-control',
      'validation': Yup.string().notRequired()
    }
  ];
}

function getRitTypeFieldValues(): ({
  key: string,
  value: string
})[] {
  return [
    { key: 'O', value: 'A CONSUNTIVO' },
    { key: 'C', value: 'A CORPO' },
    { key: 'G', value: 'A GIORNATA'},
  ]
}