import React, { ReactNode } from 'react';
import { Table } from 'react-bootstrap';

export type TableBSColumn<T> = {
    id: string,
    name: string,
    data?: keyof T | keyof T[],
    default?: string,
    render?: (data: any, index: number, row: T) => ReactNode,
    className?: string,
    columnClassName?: string,
    headerColspan?: number,
    colspan?: number,
    headerRowspan?: number,
    rowspan?: number,
}

type Props<T> = {
    id: string,
    inputText?: string;
    columnRows: TableBSColumn<T>[][],
    data: T[],
    emptyDataText?: string,
    className?: string,
};

export default function TableBootstrap<T = any>(props: Props<T>) {

    const {id, inputText, columnRows, data, emptyDataText, className} = props;

    function createColumnHead(
        columns: TableBSColumn<T>[]
    ) : ReactNode {
        return <tr>
            {columns.map((column: TableBSColumn<T>, index: number) => {
                return <th
                    id={`${id}_${column.id}`}
                    key={index}
                    className={column.columnClassName}
                    rowSpan={column.headerRowspan}
                    colSpan={column.headerColspan}
                >
                    {column.name}
                </th>
            })}
        </tr>
    }

    function createRowBody(data: any[]) : ReactNode {

        const filteredData = data.filter((el) => {
            if (inputText === '' || inputText === null || inputText === undefined) {
                return el;
            }
            else {
                return el.resource.fullname.toLowerCase().includes(inputText)
            }
        })

        return filteredData.map((element: {[key: string]: any}, index: number) => {
            return <tr key={index}>
                {columnRows[columnRows.length - 1].map((column: TableBSColumn<any>, index2: number) => {
                    const columnId = `${id}_${column.id}_${index2}`;
                    const defaultValue = column.default !== undefined ? column.default : "";
                    const columnValue = typeof column.data !== 'undefined' 
                        ? (element[column.data.toString()]
                            ? element[column.data.toString()]
                            : defaultValue)
                        : defaultValue;

                    const row = typeof column.render !== 'undefined' ? column.render(columnValue, index2, element) : String(columnValue);

                    return <td
                        id={columnId}
                        key={index2}
                        className={column.className}
                        rowSpan={column.rowspan}
                        colSpan={column.colspan}
                    >
                        {row}
                    </td>;
                })}
            </tr>
        })
    }

    return columnRows[columnRows.length - 1] ? (
        <Table id={id} className={className} striped bordered hover>
            <thead>
                {columnRows.length === 1
                    ? createColumnHead(columnRows[0])
                    : columnRows.map((columns: TableBSColumn<any>[], index: number) => {
                        return <React.Fragment key={index}>
                            {createColumnHead(columns)}
                        </React.Fragment>
                    })
                }
            </thead>
            <tbody>
                {data.length ? createRowBody(data) : <tr>
                    <td colSpan={columnRows[columnRows.length - 1]
                            ? columnRows[columnRows.length - 1].length
                            : 1
                        } className="text-center">
                        {emptyDataText ? emptyDataText : 'Nessuna informazione da mostrare in questa tabella'}
                    </td>
                </tr>}
            </tbody>
        </Table>
    ) : <></>
}
