import { ConfigColumnDefs, ConfigColumns } from "datatables.net";

export const listBusinessunitColumns: ConfigColumns[] = [
    { "title": "Azioni", "data": "id" },
    { "title": "Logo", "data": "logourl" },
    { "title": "Nome", "data": "nome" },
    { "title": "Dominio", "data": "domain" },
    { "title": "Footer per email", "data": "mailfooter" },
    { "title": "Footer per documenti", "data": "documentfooter" }
];

export function getListBusinessunitColumnDefs(): ConfigColumnDefs[] {
    return [
        {
            target: 0,
            render: (data: any, type: any, row: any) => {
                const edit = `<a class="edit_btn custom-icon btn btn-outline-primary rounded-circle" data-id="${data}"><i style="font-size: 18px" class="fa fa-pencil" aria-hidden="true"></i></a>`;
                return '<div class="d-flex">' + edit + '</div>';
            },
        },
        {
            target: 1,
            render: (data: any, type: any, row: any) => {
                const logo = `<img class="img-fluid" src="${data}" />`;
                return '<div class="d-flex">' + logo + '</div>';
            },
        },
        {
            target: 4,
            render: (data: any, type: any, row: any) => {
                const details = `<a class="info_btn btn btn-outline-primary" data-id="${data}">Mostra</a>`;
                return '<div class="d-flex">' + details + '</div>';
            },
        },
        {
            target: 5,
            render: (data: any, type: any, row: any) => {
                const details = `<a class="info_btn btn btn-outline-primary" data-id="${data}">Mostra</a>`;

                return '<div class="d-flex">' + details + '</div>';
            },
        }
    ];
}