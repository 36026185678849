import { ConfigColumnDefs, ConfigColumns } from "datatables.net";

export const listComuniColumns: ConfigColumns[] = [
    { "title": "Azioni", "data": "id" },
    { "title": "Comune", "data": "nome" },
    { "title": "Data Patrono", "data": "dataPatrono" }
];

export function getListComuniColumnDefs(): ConfigColumnDefs[] {
    return [
        {
            target: 0,
            //data: null,
            render: function (data: any, type: any, row: any) {
                /*const trash = `<div class="delete_btn action-icon col m-1" data-id="${data}"><i class="fa fa-trash" aria-hidden="true"></i></div>`;*/

                const edit = `<a class="edit_btn custom-icon btn btn-outline-primary rounded-circle" data-id="${data}">
                    <i style="font-size: 18px" class="fa fa-pencil" aria-hidden="true"></i>
                </a>`;

                return '<div class="d-flex">' + edit + '</div>';
            },
        },
    ];
}