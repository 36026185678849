import { ConfigColumnDefs, ConfigColumns } from "datatables.net";

export const docsListColumns: ConfigColumns[] = [
    { "title": "Azioni", "data": "id" },
    { "title": "Tipo", "data": "type" },
    { "title": "Nome", "data": "nome" },
    {
        "title": "File", "data": null, render: (data: any, type: any, row: any) => {
            return row.path;
        }
    },
    { "title": "Note", "data": "note" },
    { "title": "Data scadenza", "data": "expire_date" }
];

export default function getDocsListColumnDefs(): ConfigColumnDefs[] {
    return [
        {
            target: 0,
            render: function (data: any, type: any, row: any) {

                const download = `<a class="download_btn action-icon custom-icon btn btn-outline-secondary rounded-circle me-1"  data-id="${data}">
                    <i class="fa fa-download" aria-hidden="true"></i>
                </a>`;

                return '<div class="d-flex">' + download + '</div>';
            }
        }
    ];
}