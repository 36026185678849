import React, { Component } from "react";
import EventBus from "../../common/EventBus";
import ModalForm from '../../common/ModalForm';

import ComuniService from "../../services/api/database/comuni.service";
import { listComuniColumns, getListComuniColumnDefs } from "../../config/tableColumns/comuni";
import { getEditComuneFormFields, getAddComuneFormFields } from "../../config/formFields/comuni";
import Table from '../../common/TableComponent';
import { iFormField } from "../../helpers/interfaces/generic";

const $ = require('jquery');

type Props = {
    history: any
};
type State = {
    formFields: Array<iFormField>,
    loading: boolean,
    message: string,
    showModal: boolean,
    modalType: string,
    comuni: any,
    formInitialValues: { [key: string]: any }
}
export default class Comuni extends Component<Props, State> {
    innerRefs: any;
    apiSubmit: any;
    buttons: string[] | null;

    constructor(props: Props) {
        super(props);
        this.state = {
            formFields: [],
            comuni: [],
            loading: false,
            message: "",
            showModal: false,
            modalType: 'add',
            formInitialValues: {},
        }
        this.innerRefs = [];
        this.apiSubmit = null;// LicenzeService.personAdd;
        this.buttons = null;
    }

    async componentDidMount() {
        EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });
        const comuni = await ComuniService.getAll();
        if (comuni.length > 0) {
            this.setState({ comuni });
        }
        EventBus.dispatch("hideLoader");

        $('#table_comuni.table').on('click', '.edit_btn', async (e: any) => {
            e.preventDefault();
            EventBus.dispatch("showLoader", { text: 'Caricamento Comune in corso...' });
            const idComune = $(e.currentTarget).data('id');
            const comune = await ComuniService.get(idComune);
            if (comune) {
                this.setState({ formFields: getEditComuneFormFields(), formInitialValues: comune }, () => {
                    this.apiSubmit = ComuniService.put;
                    this.setState({ showModal: true, modalType: 'edit' });
                });
            }
            EventBus.dispatch("hideLoader");
        });
    }

    closeModal() {
        this.setState({ showModal: false });
    }

    openAddModale() {
        this.apiSubmit = ComuniService.add;
        this.setState({ formFields: getAddComuneFormFields(), formInitialValues: {}, showModal: true, modalType: 'add' });
    }

    render() {
        const { formFields, showModal, modalType, comuni, formInitialValues } = this.state;
        return <React.Fragment>
            <div className="custom-container">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center">
                            <h3>Comuni</h3>
                            <button id="add_btn" type="button" className="btn btn-outline-primary" onClick={() => this.openAddModale()}>
                                <span>Nuovo Comune</span>
                            </button>
                        </div>
                        <ModalForm showModal={showModal} title={'Comune'} modalType={modalType} formFields={formFields} initialValues={formInitialValues} apiSubmit={this.apiSubmit} closeCallback={this.closeModal.bind(this)} />
                        {comuni.length > 0 && <Table id="table_comuni" columns={listComuniColumns} columnDefs={getListComuniColumnDefs()} datas={comuni} buttons={this.buttons} />}
                    </div>
                </div>
            </div>
        </React.Fragment>
    }
}