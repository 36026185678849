import moment from "moment";
import 'moment/locale/it';

const nomi_mesi = [
    'Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'
];

const mesi_abbreviati = [
    'Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'
];

const giorniSettimana = [
    'Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'
]

const giorni_abbreviati = [
    'D', 'L', 'M', 'M', 'G', 'V', 'S'
]

const feste = [
    { month: 1, day: 1, name: 'Capodanno' },
    { month: 1, day: 6, name: 'Epifania' },
    { month: 4, day: 25, name: 'Festa della Liberazione' },
    { month: 5, day: 1, name: 'Festa dei Lavoratori' },
    { month: 6, day: 2, name: 'Festa della Repubblica' },
    { month: 8, day: 15, name: 'Ferragosto' },
    { month: 11, day: 1, name: 'Tutti Santi' },
    { month: 12, day: 8, name: 'Immacolata Concezione' },
    { month: 12, day: 25, name: 'Natale' },
    { month: 12, day: 26, name: 'Santo Stefano' },
];

const getNomeMese = (index: number) => {
    return nomi_mesi[index];
}

const getGiorniSettimana = (): string[] => {
    return giorniSettimana;
}

const getMesi = () => {
    return nomi_mesi
}

const getMesiAbbreviati = () => {
    return mesi_abbreviati
}

const checkFestivo = (year: number, month: number, day: number): boolean => {
    const data = moment(year + '-' + month + '-' + day);
    const giorno_settimana = data.day();
    const giorno_mese = data.date();
    const mese = data.month() + 1;
    let listaFeste = [...feste];

    let easter = getEaster(year, month, day);
    if (easter)
        listaFeste = getFestivitiesWithEasterMonday(easter, year, listaFeste);

    let festa = false;
    listaFeste.forEach(value => {
        if (value.day === giorno_mese && value.month === mese) {
            festa = true;
        }
    });

    return (festa || [0, 6].includes(giorno_settimana));
}

// TODO: Update after 2499 d.C.
const getEaster = (year: number, month: number, day: number): { day: number, month: number } | null => {
    const date = moment(year + '-' + month + '-' + day);
    const dateDay = date.date();
    const dateMonth = date.month() + 1;

    let M: number = 0;
    let N: number = 0;

    if (year < 2099) {
        M = 24;
        N = 5;
    } else if (year < 2199) {
        M = 24;
        N = 6;
    } else if (year < 2299) {
        M = 25;
        N = 0;
    } else if (year < 2399) {
        M = 26;
        N = 1;
    } else if (year < 2499) {
        M = 25;
        N = 1;
    } else {
        alert('Error: Could not load the Easter day');
        return null;
    }

    let a = year % 19;
    let b = year % 4;
    let c = year % 7;
    let d = ((19 * a) + M) % 30;
    let e = ((2 * b) + (4 * c) + (6 * d) + N) % 7;

    let easterDay;
    let easterMonth;

    if (d + e < 10) {
        easterDay = d + e + 22;
        easterMonth = 3;
    } else {
        easterDay = d + e - 9;
        easterMonth = 4;
    }

    if (dateDay === 26 && dateMonth === 4) {
        easterDay = 19;
        easterMonth = 4;
    }

    if (dateDay === 25 && dateMonth === 4 && d === 28 && e === 6 && a > 10) {
        easterDay = 18;
        easterMonth = 4;
    }

    return {
        day: easterDay,
        month: easterMonth,
    };
}

const getFestivitiesWithEasterMonday = (easter: { day: number, month: number }, year: number, festivities: { month: number, day: number, name: string }[]) => {
    const easterMonthDays = moment(year + '-' + easter.month + '-' + easter.day).daysInMonth();
    let easterMondayDay = easter.day;
    let easterMondayMonth = easter.month;
    if (easterMondayDay === easterMonthDays) {
        easterMondayMonth += 1;
        easterMondayDay = 1;
    } else {
        easterMondayDay += 1;
    }

    festivities.push({
        month: easterMondayMonth,
        day: easterMondayDay,
        name: "Pasquetta"
    });
    return festivities;
}

const getGiornoSettimana = (year: number, month: number, day: number): string => {
    const data = moment(year + '-' + fixDate(month) + '-' + fixDate(day));
    const giorno_settimana = data.day();
    return giorniSettimana[giorno_settimana];
}

const getGiornoSettimanaAbbreviato = (year: number, month: number, day: number): string => {
    const data = moment(year + '-' + fixDate(month) + '-' + fixDate(day));
    const giorno_settimana = data.day();
    return giorni_abbreviati[giorno_settimana];
}

const getDaysMonth = (year: number, month: number, firstDay: number, lastDay: number) => {
    const allDaysInMonth = moment(year + '-' + month, "YYYY-MM").daysInMonth()
    if (firstDay !== undefined && lastDay !== undefined) {
        return firstDay === 0 ? (lastDay === 0 ? allDaysInMonth : lastDay) : (lastDay === 0 ? allDaysInMonth - firstDay + 1 : lastDay - firstDay + 1)
    } else {
        return allDaysInMonth
    }
}

const giorniLavorativi = (year: number, month: number, firstDay: number, lastDay: number) => {
    let calcDays: number = getDaysMonth(year, month, firstDay, lastDay);
    let startDate: number = firstDay === 0 ? 1 : firstDay;
    let endDate: number = lastDay === 0 ? moment(year + '-' + month, "YYYY-MM").daysInMonth() : lastDay;

    for (let d = startDate; d <= endDate; d++) {
        if (checkFestivo(year, month, d)) {
            calcDays -= 1;
        }
    }

    return calcDays;
}

const fixDate = (month: number) => {
    return month < 10 ? String('0' + month) : String(month);
}

export {
    getNomeMese,
    getGiorniSettimana,
    getMesiAbbreviati,
    checkFestivo,
    getGiornoSettimana,
    getGiornoSettimanaAbbreviato,
    giorniLavorativi,
    fixDate,
    getMesi
};