import React, { Component } from 'react'
import * as Yup from 'yup'
import { Formik, Form, Field, ErrorMessage, FormikProps } from 'formik';
import Swal from 'sweetalert2';
import EventBus from '../../../common/EventBus';
import { iFormField, iFormFieldValue } from '../../../helpers/interfaces/generic';
import { base64toBlob } from '../../../common/Base64';
import { buttonsStyle, iconsStyle } from '../../../helpers/settings/buttons-icons-styles';
import NoteSpeseService from '../../../services/api/note-spese.service';
import { INoteSpese, INoteSpeseForm, INoteSpeseBody, ITypeNote } from '../../../helpers/interfaces/note-spese';
import personService from "../../../services/api/person.service";
import { IPersonForSearch } from '../../../helpers/interfaces/person';
import UserService from "../../../services/api/user.service";
import { JoborderObject } from '../../../helpers/interfaces/user';
import { notaSpesaFormFields } from '../../../config/formFields/note-spese-amministrazione';

type Props = {
    history: {
        push(url: string): void;
    }
    match: {
        params: {
            id: string
        };
    }
};

type State = {
    notaSpese: INoteSpese,
    personale: IPersonForSearch[],
    tipologieNota: ITypeNote[],
    commesse: JoborderObject[],
    formFields: iFormField[],
    loading: boolean,
    disabledForm: boolean,
    formInitialValues: INoteSpeseForm,
    attachmentsToUpload: File[]
};

export default class NoteSpeseDettaglio extends Component<Props, State> {
    
    innerRefs: { [key: string]: string };

    constructor(props: Props) {
        super(props)

        this.state = {
            notaSpese: {} as INoteSpese,
            personale: [],
            tipologieNota: [],
            commesse: [],
            disabledForm: true,
            formFields: [],
            loading: false,
            formInitialValues: {} as INoteSpeseForm,
            attachmentsToUpload: []
        }

        this.innerRefs = {};

        this.setValidations = this.setValidations.bind(this)
        this.handleUpdateNotaSpese = this.handleUpdateNotaSpese.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this)
    }

    initFormValues(): INoteSpeseForm {
        let commessa = null;
        if (this.state.notaSpese && this.state.notaSpese.joborder) {
            commessa = this.state.commesse.find((item: JoborderObject) => item.joborderid === this.state.notaSpese.joborder.id);
        }
        let tipologia = null;
        if (this.state.notaSpese && this.state.notaSpese.tipologia) {
            tipologia = this.state.tipologieNota.find((item: ITypeNote) => item.id === this.state.notaSpese.tipologia.id);
        }
        let dipendente = null;
        if (this.state.notaSpese && this.state.notaSpese.user) {
            dipendente = this.state.personale.find((item: IPersonForSearch) => item.id === this.state.notaSpese.user.id);
        }

        return {
            amount: this.state.notaSpese.amount ?? 0,
            credit: this.state.notaSpese.creditcard ? 1 : 0,
            date: this.state.notaSpese.date ?? "",
            id: this.state.notaSpese.id ?? 0,
            joborder: commessa ? commessa.joborderid : undefined,
            km: this.state.notaSpese.km ?? "",
            tipologia: tipologia ? tipologia.id : undefined,
            tragitto: this.state.notaSpese.tragitto ?? "",
            person: dipendente ? dipendente.id : undefined
        }
    }

    async componentDidMount() {
        EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });

        const nataSpesePromise = NoteSpeseService.getOneNote(this.props.match.params.id);
        const personalePromise = personService.getAllForSearch();
        const tipologieNotaPromise = NoteSpeseService.getType();
        const commessePromise = UserService.getProfile();

        await Promise.all([nataSpesePromise, personalePromise, tipologieNotaPromise, commessePromise])
            .then(
                values => {
                    this.setState(
                        {
                            notaSpese: values[0],
                            personale: values[1].data,
                            tipologieNota: values[2],
                            commesse: values[3].personjoborders.active
                        },
                        () => {
                            const _personale = this.state.personale.filter((item: IPersonForSearch) => item.status.toLowerCase() === 'e');
                            const personale = _personale.map((person: IPersonForSearch) => {return { key: person.id, value: person.name + ' ' + person.lastname } });

                            const tipologieNota = this.state.tipologieNota.map((item: ITypeNote) => { return { key: item.id, value: item.nome } });

                            const commesse = this.state.commesse.map((item: JoborderObject) => { return { key: item.joborderid, value: item.code + ' - ' + item.name } });

                            this.setState({
                                formFields: notaSpesaFormFields(personale, tipologieNota, commesse),
                                formInitialValues: this.initFormValues(),
                                attachmentsToUpload: []
                            }, () => EventBus.dispatch("hideLoader"))
                        }
                    )
                },
                error => {
                    EventBus.dispatch("hideLoader");
                }
            );
    }

    async handleUpdateNotaSpese(values: INoteSpeseForm) {
        EventBus.dispatch("showLoader", { text: 'Salvataggio in corso...' });

        this.setState({
            loading: true
        });

        let formData = new FormData();
        const { attachmentsToUpload } = this.state;
        if (attachmentsToUpload) {
            let files = Array.from(attachmentsToUpload)
            files.forEach((file, i) => formData.append("attachment_" + i, file))
        }

        const notaSpeseBody: INoteSpeseBody = {
            amount: values.amount,
            credit: values.credit,
            date: values.date,
            id: values.id,
            joborder: values.joborder,
            km: values.km,
            tipologia: values.tipologia,
            tragitto: values.tragitto,
            person: values.dipendente
        }

        // for (const key of Object.keys(notaSpeseBody) as (keyof INoteSpeseBody)[]) {
        //     formData.append(key, notaSpeseBody[key]?.toString() ?? '');
        // }

        for (const key of Object.keys(notaSpeseBody)) {
            formData.append(key, notaSpeseBody[key]?.toString() ?? '');
        }

        await NoteSpeseService.editNote(this.props.match.params.id, formData)
        .then(    
            response => {
                EventBus.dispatch("hideLoader");
                Swal.fire(
                    'Operazione eseguita.',
                    '',
                    'success'
                ).then(
                    () => this.setState(
                        {
                            loading: false
                        },
                        () => this.props.history.push("/amministrazione/note-spese")
                    )
                );
            },
            error => {
                EventBus.dispatch("hideLoader");

                Swal.fire(
                    'Errore.',
                    '',
                    'error'
                );

                this.setState({
                    loading: false
                });
            }
        )
    }

    setValidations() {
        let validations: any = {};
        this.state.formFields.forEach(value => (validations[value.name] = value.validation));
        return Yup.object().shape(validations);
    }

    handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
        const { attachmentsToUpload } = this.state
        let files = event.currentTarget.files ? Array.from(event.currentTarget.files) : []
        if (attachmentsToUpload.length === 0) {
            this.setState({ attachmentsToUpload: files }, () => { event.target.value = "" })
        } else {
            let concatArray = files.concat(attachmentsToUpload)
            // removing duplicates after merging new and old files
            let attachments = concatArray.filter((value, index, self) =>
                index === self.findIndex((t) => (
                    t.name === value.name &&
                    t.size === value.size &&
                    t.type === value.type &&
                    t.lastModified === value.lastModified
                ))
            )
            this.setState({ attachmentsToUpload: attachments }, () => { event.target.value = "" })
        }
    }

    async downloadAttachment(id: number) {
        EventBus.dispatch("showLoader", { text: 'Download in corso...' });

        this.setState({
            loading: true
        })

        await NoteSpeseService.downloadAttachmentNote(id).then(
            documento => {
                EventBus.dispatch("hideLoader");
                this.setState({
                    loading: false
                })

                let fileBlob = base64toBlob(documento.body, documento.mimetype)
                let url = window.URL.createObjectURL(fileBlob);
                let a = document.createElement('a');
                a.href = url;
                a.download = documento.filename;
                document.body.appendChild(a);
                a.click();
                a.remove();
            },
            () => {
                EventBus.dispatch("hideLoader");

                Swal.fire(
                    'Errore',
                    '',
                    'error'
                );

                this.setState({
                    loading: false
                });
            }
        )
    }

    // async deleteAttachment(id: number) {
    //     EventBus.dispatch("showLoader", { text: 'Eliminazione in corso...' });

    //     this.setState({
    //         loading: true
    //     });

    //     await documentiService.delete(id).then(
    //         () => this.setState(
    //             (prevState) => {
    //                 return {
    //                     ...prevState,
    //                     notaSpese: {
    //                         ...prevState.notaSpese,
    //                         attachments: prevState.notaSpese.attachments.filter(a => a.id !== id)
    //                     }
    //                 }
    //             },
    //             () => {
    //                 EventBus.dispatch("hideLoader");
    //                 Swal.fire(
    //                     'Operazione eseguita.',
    //                     '',
    //                     'success'
    //                 );
    //                 this.setState({
    //                     loading: false
    //                 });
    //             }
    //         ),
    //         error => {
    //             EventBus.dispatch("hideLoader");

    //             Swal.fire(
    //                 'Errore',
    //                 '',
    //                 'error'
    //             );

    //             this.setState({
    //                 loading: false
    //             });
    //         }
    //     )
    // }

    renderFormFields(item: iFormField, key: number, formik: FormikProps<any>) {
        const value = !isNaN(Number(formik.values[item.showFromFieldName ?? ''])) ? Number(formik.values[item.showFromFieldName ?? '']) : formik.values[item.showFromFieldName ?? ''];
        const valueIsPresent: string = (item.showFromFieldName && formik.values[item.showFromFieldName] && formik.values[item.showFromFieldName] > 0) ? 'true' : 'false';

        const displayItem = ((item.showFromFieldName && item.showFromFieldValue?.includes(value)) ||
            (item.showFromFieldName && item.showFromFieldType?.toString().includes(valueIsPresent)) ||
            typeof item.showFromFieldName == 'undefined');
        formik.initialValues[item.name] = formik.initialValues[item.name] ?? item.value;

        if (!displayItem || item.hidden) {
            return null;
        }

        switch (item.type) {
            case 'select':
                return <React.Fragment>
                    <label className={"form-label col-form-label" + (item.labelClass ? ' ' + item.labelClass : '')}>{item.label}</label>
                    <div className="col">
                        <Field
                            innerRef={(el: any) => this.innerRefs[item.name] = el}
                            as={item.type}
                            name={item.name}
                            className={item.class} 
                            value={formik.values[item.name]}
                            disabled={this.state.disabledForm || item.disabled}
                            onChange={async (event: any) => {
                                formik.setFieldValue(item.name, event.target.value)
                            }}
                        >
                            <option key={''} value={''}>{item.value ?? '---'}</option>
                            {item.values?.map((item: iFormFieldValue) => {
                                return <option key={item.key} value={item.key} defaultValue={item.defaultValue} disabled={item.disabled}>{item.value}</option>
                            })}
                        </Field>
                    </div>
                </React.Fragment>    
            case 'file':
                const { attachmentsToUpload, notaSpese } = this.state
                return (
                    <React.Fragment>
                        <label className={"form-label col-form-label" + (item.labelClass ? ' ' + item.labelClass : '')}>{item.label}</label>
                        <Field
                            id={item.name}
                            name={item.name}
                            multiple={true}
                            className={item.class}
                            type={item.type}
                            onChange={this.handleFileChange}
                            disabled={this.state.disabledForm}
                        />
                        <div className='d-flex'>
                            <div className='col-6 mt-3'>
                                <h4>File caricati</h4>
                                {
                                    notaSpese.attachments.length !== 0 ? notaSpese.attachments.map((file: any, index: number) => (
                                        <div className='d-flex align-items-center mb-1' key={index + '_' + Date.now()}>
                                            {/* <button style={buttonsStyle} className='btn btn-outline-danger rounded-circle me-1' type='button' disabled={this.state.disabledForm} onClick={() => this.deleteAttachment(file.id)}>
                                                <i style={iconsStyle} className="fa fa-trash-o" aria-hidden="true"></i>
                                            </button> */}
                                            <button style={buttonsStyle} className='btn btn-outline-primary rounded-circle me-2' type='button' onClick={() => this.downloadAttachment(file.id)} >
                                                <i style={iconsStyle} className="fa fa-download" aria-hidden="true"></i>
                                            </button>
                                            <span>
                                                {file.path}
                                            </span>
                                        </div>
                                    )) : <p className='mt-3'> Nessun file da visualizzare </p>
                                }
                            </div>

                            {
                                attachmentsToUpload.length !== 0 &&
                                <div className='col-6 mt-3'>
                                    <h4>File da caricare</h4>
                                    {attachmentsToUpload.map((file: any, index: number) => (
                                        <div className='d-flex align-items-center mb-1'>
                                            <button style={buttonsStyle} className='btn btn-outline-danger rounded-circle me-2' type='button' disabled={this.state.disabledForm} onClick={() => {
                                                let files = attachmentsToUpload
                                                files.splice(files.indexOf(file), 1)
                                                this.setState({ attachmentsToUpload: files })
                                            }}>
                                                <i style={iconsStyle} className="fa fa-trash-o" aria-hidden="true"></i>
                                            </button>
                                            <span key={index + '_' + Date.now()}>
                                                {file.name}
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            }
                        </div>
                    </React.Fragment>
                )
            default:
                return <React.Fragment>
                    <label className={"form-label col-form-label" + (item.labelClass ? ' ' + item.labelClass : '')}>{item.label}</label>
                    <div className="col">
                        <Field
                            innerRef={(el: any) => this.innerRefs[item.name] = el}
                            name={item.name}
                            type={item.type}
                            className={item.class}
                            value={formik.values[item.name] ?? ''}
                            disabled={this.state.disabledForm || item.disabled}
                            onChange={async (event: any) => {
                                formik.setFieldValue(item.name, event.target.value)
                            }}
                        />
                    </div>
                </React.Fragment>
        }
    }

    render() {
        const { loading, disabledForm, formFields, formInitialValues } = this.state;
        const id = "id";
        // formFields.map(field => field.value = formInitialValues[field.name as keyof INoteSpeseForm])

        return <div className="container py-3">
            {
                formFields.length > 0 && <React.Fragment>
                    <Formik
                        initialValues={formInitialValues}
                        validationSchema={this.setValidations.bind(this)}
                        onSubmit={this.handleUpdateNotaSpese.bind(this)}
                    >
                        {(formik: FormikProps<{[key: string]: any;}>) => {
                            return <Form>
                                <div className="card">
                                    <div className="card-body">
                                        <div className='col-12 pb-2 d-flex justify-content-between align-items-center'>
                                            <h3>Dettaglio nota spese</h3>
                                            <button style={buttonsStyle} className='btn rounded-circle' type='button' onClick={() => this.setState({ disabledForm: !disabledForm })} >
                                                {
                                                    disabledForm ?
                                                        <i style={iconsStyle} className="fa fa-lock text-danger" aria-hidden="true"></i> :
                                                        <i style={iconsStyle} className="fa fa-unlock-alt text-success" aria-hidden="true"></i>
                                                }
                                            </button>
                                        </div>
                                        {
                                            formFields && formFields.map((item: iFormField, key: number) => {
                                                return <div key={key} id={`${id}-${item.name}`} className="form-group my-2">

                                                    {
                                                        this.renderFormFields(item, key, formik)
                                                    }

                                                    <ErrorMessage
                                                        name={item.name}
                                                        component="div"
                                                        className="alert alert-danger"
                                                    />
                                                </div>
                                            })
                                        }
                                        <div className="col-12 d-flex justify-content-end">
                                            <button type="submit" className="btn btn-primary" disabled={loading || disabledForm}>
                                                {
                                                    loading && <span className="spinner-border spinner-border-sm mr-1"></span>
                                                }
                                                <span>Salva</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        }}
                    </Formik>
                </React.Fragment>
            }
        </div>
    }
}