export const listCommesseColumns = [
    {
        title: 'Azioni',
        data: 'id'
    },
    {
        title: 'Nome',
        width: 'auto',
        data: 'name'
    },
    {
        title: 'Codice',
        width: 'auto',
        data: 'code'
    },
    {
        title: 'Data Inizio',
        width: 'auto',
        data: 'startdate'
    },
    {
        title: 'Data Fine',
        width: 'auto',
        data: 'enddate'
    }
];

export function getListCommesseColumnDefs() {
    return [
        {
            target: 0,
            //data: null,
            render: function (data: any, type: any, row: any) {
                const trash = `<a class="delete_btn action-icon custom-icon btn btn-outline-danger rounded-circle me-1" data-id="${data}">
                    <i class="fa fa-trash" aria-hidden="true"></i>
                </a>`;

                const edit = `<a class="edit_btn action-icon custom-icon btn btn-outline-primary rounded-circle" data-id="${data}">
                    <i style="font-size: 18px" class="fa fa-pencil" aria-hidden="true"></i>
                </a>`;

                return '<div class="d-flex">' + trash + edit + '</div>';
            },
        },
    ];
}