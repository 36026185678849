import React, { Component } from 'react';
import Swal from 'sweetalert2';
import EventBus from '../../../common/EventBus';
import { tipologiaDispositiviFormFields } from '../../../config/formFields/magazzino/magazzino-tipologia-dispositivi';
import { iFormField } from '../../../helpers/interfaces/generic';
import { IMagazzinoTipologiaDispositivo } from '../../../helpers/interfaces/magazzino/magazzino-tipologia-dispositivi';
import tipologiadispositiviService from '../../../services/api/magazzino/magazzino-tipologia-dispositivi.service';
import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';

type State = {
    tipologia: IMagazzinoTipologiaDispositivo,
    formFields: iFormField[],
    loading: boolean,
    formInitialValues: { nome: string }
}

export default class MagazzinoNuovaTipologia extends Component<{}, State> {

    constructor(props: {}) {
        super(props)

        this.state = {
            tipologia: {} as IMagazzinoTipologiaDispositivo,
            formFields: [],
            loading: false,
            formInitialValues: { nome: "" }
        }

        this.setValidations = this.setValidations.bind(this)
        this.handleAddTipologia = this.handleAddTipologia.bind(this)
    }

    // method: initializing the form values
    initFormValues(): { nome: string } {
        return {
            nome: "",
        }
    }

    componentDidMount(): void {
        this.setState(
            {
                formFields: tipologiaDispositiviFormFields(),
                formInitialValues: this.initFormValues()
            },
            () => {
                EventBus.dispatch("hideLoader");
            }
        );
    }

    // method: handling add type actions
    async handleAddTipologia(body: { nome: string }) {
        EventBus.dispatch("showLoader", { text: 'Salvataggio in corso...' });

        this.setState({
            loading: true
        });

        // handle add call
        await tipologiadispositiviService.add(body).then(
            response => {
                this.setState(
                    {
                        formInitialValues: this.initFormValues()
                    },
                    () => {
                        EventBus.dispatch("hideLoader");
                        Swal.fire(
                            'Operazione eseguita.',
                            '',
                            'success'
                        );

                        this.setState({
                            loading: false
                        });
                    }
                )
            },
            error => {
                EventBus.dispatch("hideLoader");

                Swal.fire(
                    'Errore.',
                    '',
                    'error'
                );

                this.setState({
                    loading: false
                });
            }
        )
    }

    // method: setting the form validations using Yup
    setValidations() {
        let validations: any = {};
        this.state.formFields.forEach(value => (validations[value.name] = value.validation));
        return Yup.object().shape(validations);
    }

    render() {
        const { loading, formFields, formInitialValues } = this.state;

        // assigning values to the form fields
        formFields.map(field => field.value = formInitialValues[field.name as keyof { nome: string }])

        return <div className="container py-3">
            {
                formFields.length > 0 &&
                <React.Fragment>
                    <Formik
                        initialValues={formInitialValues}
                        validationSchema={this.setValidations}
                        onSubmit={this.handleAddTipologia}
                    >
                        <Form className="card">
                            <div className="card-body">
                                <div className='col-12 pb-2'>
                                    <h3>Nuova tipologia dispositivo</h3>
                                </div>

                                {formFields.map(
                                    (field: iFormField, key) => {
                                        return <div className="mb-3" key={key}>
                                            <div className='col-12'>
                                                <label className="form-label">{field.label}</label>
                                                <Field name={field.name} type={field.type} className={field.class} />
                                                <ErrorMessage
                                                    name={field.name}
                                                    component="div"
                                                    className="alert alert-danger"
                                                />
                                            </div>
                                        </div>
                                    }
                                )}

                                <div className="col-12 d-flex justify-content-end">
                                    <button type="submit" className="btn btn-primary" disabled={loading}>
                                        {
                                            loading && <span className="spinner-border spinner-border-sm me-1"></span>
                                        }
                                        <span>Salva</span>
                                    </button>
                                </div>
                            </div>
                        </Form>
                    </Formik>
                </React.Fragment>
            }
        </div>

    }
}
