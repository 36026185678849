import { ConfigColumnDefs, ConfigColumns } from 'datatables.net';

export const listJobordersColumns: ConfigColumns[] = [
    { title: "Azioni", "data": "id" },
    { title: "Nome", data: "name" },
    { title: "Tipologia", data: "rittype" },
    { title: "Data Inizio", data: "startdate" },
    { title: "Data fine", data: "enddate" }
];

export default function getlistJobordersColumnsDefs(): ConfigColumnDefs[] {
    return [
        {
            target: 0,
            render: function (data: any) {
                const add = `<a class="addrit_btn custom-icon btn btn-outline-primary rounded-circle me-1" data-id="${data}"><i style="font-size: 18px" class="fa fa-file-text" aria-hidden="true"></i></a>`;

                return '<div class="d-flex">' + add + '</div>';
            },
        },
    ];
}

export const listDevicesColumns: ConfigColumns[] = [
    { title: "Tipologia", data: "tipologia_nome" },
    { title: "Marca e modello", data: "marca_modello" },
    { title: "Data consegna", data: "data_consegna" },
    { title: "Data restituzione", data: "data_restituzione" },
];

export const listLicencesColumns: ConfigColumns[] = [
    { title: "Nome", data: "prodotto_nome" },
    { title: "Identificativo", data: "identificativo" },
    { title: "Data consegna", data: "data_consegna" },
    { title: "Data restituzione", data: "data_restituzione" }
];

export const listServicesColumns: ConfigColumns[] = [
    { title: "Nome", data: "nome" }
];

export const listPermissionsColumns: ConfigColumns[] = [
    { title: "Nome", data: "causale" },
    { title: "Data Inizio", data: "inizio" },
    { title: "Data fine", data: "fine" },
];