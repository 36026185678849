import * as Yup from "yup";
import { iFormField } from "../../../../../helpers/interfaces/generic";

export function getEditDocumentoFormFields(): Array<iFormField> {
  return [
    {
      'label': 'Nome',
      'name': 'nome',
      'type': 'text',
      'class': 'form-control',
      'validation': Yup.string().required("Campo obbligatorio")
    },
    {
      'label': 'Note',
      'name': 'note',
      'type': 'text',
      'class': 'form-control',
      'validation': Yup.string().notRequired()
    },
    // {
    //   'label': 'Tipo',
    //   'name': 'type',
    //   'type': 'text',
    //   'class': 'form-control',
    //   'validation': Yup.string().notRequired()
    // },
    {
      'label': 'Tipo Documento',
      'name': 'type',
      'type': 'select',
      'value': '',
      'values': [{ key: 'ALTRO', value: 'Altro' }, { key: 'DISPOSITIVO-C', value: 'Lettera consegna dispositivo' }, { key: 'DISPOSITIVO-R', value: 'Lettera riconsegna dispositivo' }],
      'class': 'form-select',
      'validation': Yup.string()
    },
    {
      'label': 'Scadenza',
      'name': 'expire_date',
      'type': 'date',
      'class': 'form-control',
      'validation': Yup.date().notRequired()
    }
  ];
}