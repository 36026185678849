import { timeToMins, addTimes, subTimes } from './time';
import Swal from 'sweetalert2';
import Events from './events';
import RendicontazioniService from "../../../../services/api/rendicontazioni.service";
import { IPermessiStudioEsami } from '../../../../helpers/interfaces/rendicontazione';
import moment from "moment";
import 'moment/locale/it';
import { base64Encode } from '../../../../common/Base64';
import { FormikProps } from 'formik';
import { isEmptyObject } from 'jquery';

export interface iRendicontazione {
    commessa: {
        text: string | null,
        id: number | null,
        code: string
    },
    causale: {
        text: string | null,
        id: number | null,
    },
    dispositivo: {
        text: string | null,
        id: number | null,
    },
    operatore: {
        text: string | null,
        id: number | null,
    },
    trasferta: {
        text: string | null,
        id: number | null,
    },
    ora: {
        inizio: string,
        fine: string,
        diff: {
            minuti: number,
            tempo: string
        }
    },
    pausa: {
        text: string | null,
        id: string | null,
    },
    viaggio: {
        tempo: string | null,
    },
    note: string,
    note_cliente: string,
    parent: {
        id: number
        nome: string
        code: string
    } | null,
    inTheRit: boolean,
    ritSend: boolean,
    measuresSent: boolean,
}

interface iArrayCommesse {
    [key: string]: string
}

interface iArrayGiorni {
    [key: number]: iRendicontazione[]
}

interface iStatoObject {
    code: string,
    name: string,
}

export interface iReportingFormData {
    "all-days": boolean,
    causale: string | undefined,
    commessa: string | undefined,
    strumento: string | undefined,
    note: string | undefined,
    note_cliente: string | undefined,
    operatore: string | undefined,
    // ora_fine: string | undefined,
    ora_fine_hh: string,
    ora_fine_mm: string,
    // ora_inizio: string | undefined,
    ora_inizio_hh: string,
    ora_inizio_mm: string,
    // tempo_viaggio: string | undefined
    tempo_viaggio_hh: string | undefined,
    tempo_viaggio_mm: string | undefined,
    parent: string | undefined,
    pausa: string | undefined,
    tipologia: 'causale' | 'commessa',
    trasferta: string | undefined,
    rit_send: boolean,
    measures_sent: boolean,
}

export interface iReportingInitialFormData {
    "all-days"?: boolean,
    causale?: string | undefined,
    commessa?: string | undefined,
    parent?: string | undefined,
    strumento?: string | undefined,
    note?: string | undefined,
    note_cliente?: string | undefined,
    operatore?: string | undefined,
    // ora_fine?: string | undefined,
    ora_fine_hh: string,
    ora_fine_mm: '00' | '30',
    // ora_inizio?: string | undefined,
    ora_inizio_hh: string,
    ora_inizio_mm: '00' | '30',
    // tempo_viaggio?: string | undefined
    tempo_viaggio_hh: string | undefined,
    tempo_viaggio_mm: string | undefined,
    pausa?: string | undefined,
    tipologia?: 'causale' | 'commessa',
    trasferta?: string | undefined,
    rit_send?: boolean,
    measures_sent?: boolean,
}

export interface iReportingImportFormData {
    tipologia: 'planning' | 'causale',
    planning?: number,
    causale?: number,
    year: number,
    month: number,
    day: number,
}

const $ = require('jquery');
class Reportings extends Events {

    protected giorni: iArrayGiorni;
    protected totaleOre: string;
    protected edited: boolean;
    protected person: any;
    protected lastday: number;
    protected firstday: number;
    protected stato: iStatoObject;
    protected attachments: Array<any>;
    private parent: any;
    selectedDay: { year: number; month: number; day: number; };
    selectedIndex: number;
    currentDate: { year: string; month: string; };
    rendicontazione_id: string;
    private pageView: 'amministrazione' | 'dipendente';
    private infoBuoniPasto: Array<any> = [];
    private totBuoniPasto: number = 0;

    constructor() {
        super();
        this.giorni = [];
        this.totaleOre = '00:00';
        this.edited = false;
        this.rendicontazione_id = '';
        this.person = {
            name: '',
            id: ''
        };
        this.stato = {
            code: 'O',
            name: 'Aperto'
        }
        this.attachments = [];
        this.parent = null;
        this.currentDate = {
            year: '',
            month: ''
        }
        this.selectedDay = {
            year: 0,
            month: 0,
            day: 0
        }
        this.selectedIndex = 0;
        this.lastday = 0
        this.firstday = 0

        this.clickSubmitRendicontazione(this.updateRendicontazione);
        this.changeMonth(this.getStatoModificaRendicontazione);
        this.pageView = 'amministrazione';
    }

    setParent = (parent: any): void => { this.parent = parent; }
    getParent = () => { return this.parent; }

    setRendicontazioneId = (id: string): void => { this.rendicontazione_id = id; }
    getRendicontazioneId = (): string => { return this.rendicontazione_id; }

    setCurrentDate = (year: string, month: string): void => {
        this.currentDate = {
            year,
            month
        }
    }
    getCurrentDate = (): { year: string, month: string } => { return this.currentDate; }
    setSelectedDay = (year: number, month: number, day: number): void => {
        this.selectedDay = {
            year,
            month,
            day
        };
    }
    getSelectedDay = () => { return this.selectDay; }

    setSelectedIndex = (index: number): void => {
        this.selectedIndex = index;
    }
    getSelectedIndex = () => { return this.selectedIndex; }

    setEdited = (edited: boolean): void => { this.edited = edited; }
    getEdited = (): boolean => { return this.edited; }
    clearJoborderName = (commessa: any) => {
        if (commessa && commessa.includes('|')) {
            const expParentName = commessa.split('|');
            commessa = expParentName[1];
        }
        return commessa;
    };

    setPerson = (id: number, name: string, personalEmail: string = '', companyEmail: string = '', phone: string = '', personStatus: string = 'E', permessiStudioEsami: IPermessiStudioEsami = {} as IPermessiStudioEsami): void => { this.person = { id, name, personalEmail, companyEmail, phone, personStatus, permessiStudioEsami }; }

    getPersonName = (): string => { return this.person.name; }
    getPersonId = (): string => { return this.person.id; }
    getPersonPersonalEmail = (): string => { return this.person.personalEmail }
    getPersonCompanyEmail = (): string => { return this.person.companyEmail }
    getPersonPhone = (): string => { return this.person.phone }
    getPersonStatus = (): string => { return this.person.personStatus }
    getPersonRole = (): string => {
        const userStr: any = localStorage.getItem("user");
        const user = JSON.parse(userStr);
        return user.roles[0].code;
    }
    getPersonPermessiStudioEsami = (): IPermessiStudioEsami => { return this.person.permessiStudioEsami }

    setFirstDay = (firstDay: number) => { this.firstday = firstDay }
    getFirstDay = () => { return this.firstday }
    setLastDay = (lastDay: number) => { this.lastday = lastDay }
    getLastDay = () => { return this.lastday }

    setStato = (stato: iStatoObject): void => {
        this.stato = stato
            ? { ...stato }
            : {
                code: 'O',
                name: 'Aperto'
            };
    }
    getStatoCode = (): string => { return this.stato.code; }

    setAttachements = (attachements: Array<any>) => { this.attachments = attachements; }
    getAttachements = (): Array<any> => { return this.attachments; }

    getPageView = () => { return this.pageView }
    setPageView = (value: 'amministrazione' | 'dipendente') => { this.pageView = value }

    render = (day: number, data: iRendicontazione, status?: string, index?: number): string => {
        const reportings = typeof this.giorni[day] !== 'undefined' ? Object.keys(this.giorni[day]).length - 1 : 0;
        if (typeof index === 'undefined') { index = reportings; }
        if (!status) status = this.getStatoCode();

        const inTheRit = typeof data.inTheRit !== 'undefined' && data.inTheRit === true ? 'intherit' : 'bg-info';

        var base = `<div class="rendicontazione" day="${day}" index="${index}"> \
                        <div class="event d-block p-1 pl-2 pr-2 mb-1 rounded small ${inTheRit} text-white">`;
        if (this.pageView !== 'dipendente') {
            if (['C', 'A'].includes(status)) {
                base += `<a class="editParent custom-icon me-1" data-edit="${base64Encode(JSON.stringify(data))}"><i style="font-size: 18px" class="fa fa-pencil" aria-hidden="true"></i></a>`;
                base += `<a class="deleteParent custom-icon me-1" data-id="${data}"><i style="font-size: 18px" class="fa fa-trash" aria-hidden="true"></i></a>`;
            }
        } else {
            if (['O', 'R'].includes(status) && inTheRit !== 'intherit') {
                base += `<a class="editParent custom-icon me-1" data-edit="${base64Encode(JSON.stringify(data))}"><i style="font-size: 18px" class="fa fa-pencil" aria-hidden="true"></i></a>`;
                base += `<a class="deleteParent custom-icon me-1" data-id="${data}"><i style="font-size: 18px" class="fa fa-trash" aria-hidden="true"></i></a>`;
            }
        }

        if (data.parent) {
            base += '<p class="nome-rendicontazione-padre"><span class="nome-commessa-padre">Commessa Padre:</span> <span class="valore-commessa-padre">' + data.parent.code + ' | ' + data.parent.nome + '</span></p>';
        }

        if (data.commessa.text) {
            base += '<span class="nome-rendicontazione"><span class="nome-commessa">Commessa:</span> <span class="valore-commessa">' + data.commessa.text.trim() + '</span></span><br />';
        }

        if (data.dispositivo.text) {
            base += '<span class="nome-dispositivo">Dispositivo: <span class="dispositivo-rendicontazione">' + data.dispositivo.text.trim() + '</span></span><br />';
        }

        if (data.operatore.text) {
            base += '<span class="nome-operatore">operatore: <span class="operatore-rendicontazione">' + data.operatore.text.trim() + '</span></span><br />';
        }

        if (data.trasferta.text) {
            base += '<span class="nome-trasferta">trasferta: <span class="trasferta-rendicontazione">' + data.trasferta.text.trim() + '</span></span><br />';
        }

        if (data.causale.text) {
            base += '<span class="nome-rendicontazione"><span class="nome-causale">Causale:</span> <span class="valore-causale">' + data.causale.text.trim() + '</span></span><br/>';
        }

        base += '<span class="ora-rendicontazione">';
        base += '<span class="ora-inizio">Inizio: <span class="ora-inizio-rendicontazione">' + data.ora.inizio.trim() + '</span></span><br />';
        base += '<span class="ora-fine">Fine: <span class="ora-fine-rendicontazione">' + data.ora.fine.trim() + '</span></span><br />';
        base += '</span>';

        if (data.pausa.text) {
            base += '<span class="pausa">Pausa: <span class="pausa-rendicontazione">' + data.pausa.text.trim() + '</span></span><br />';
        }

        if (data.viaggio.tempo) {
            base += '<span class="tempo-viaggio">Viaggio: <span class="viaggio-rendicontazione">' + data.viaggio.tempo.trim() + '</span></span><br />';
        }

        if (typeof data.note !== 'undefined' && data.note.trim()) {
            base += '<span class="note">Nota: <span class="note-rendicontazione">' + data.note.trim() + '</span></span><br />';
        }

        if (typeof data.note_cliente !== 'undefined' && data.note_cliente.trim()) {
            base += '<span class="note">Nota cliente: <span class="note-rendicontazione">' + data.note_cliente.trim() + '</span></span><br />';
        }

        base += '<span class="dettaglio-rendicontazione hide">' + JSON.stringify(data) + '</span>';
        base += `</div> \
                    </div>`;
        return base;
    }

    clear = (): void => {
        this.giorni = [];
        $('.rendicontazione').remove();
    }

    load = (data: any, parent: any): void => {
        this.clear();
        //var data = JSON.parse(reportings);
        for (var d = 1; d <= 31; d++) {
            if ($('#giorno-' + d).length > 0) {
                if (typeof data['d' + d] !== 'undefined' && data['d' + d] !== 'null' && data['d' + d]) {
                    const rendicontazione = JSON.parse(data['d' + d]);
                    this.giorni[d] = [];
                    for (var key in rendicontazione) {
                        this.giorni[d].push(rendicontazione[key]);
                        $('#giorno-' + d).append(this.render(d, rendicontazione[key], data.stato.code, Number(key)));
                    };

                }
            }
        }
        if (Object.keys(data).length !== 0) {
            this.setParent(parent);
            this.setRendicontazioneId(data.id);
            this.setPerson(data.idperson, data.person, data.personal_email, data.company_email, data.phone, data.personStatus, data.permessiStudioEsami);
            this.setStato(data.stato);
            this.setFirstDay(data.firstday);
            this.setLastDay(data.lastday);
            this.setAttachements(data.attachments);
            this.setCurrentDate(data.annonumber, data.mesenumber);
            this.deleteParent(this.removeDay, this.setStatoModificaRendicontazione);
            this.updateTotaleRendicontazione();
        }
    }

    /*observeOpenRendicontazione = () => {
        this.openRendicontazione(this.modalRendicontazione);
    }*/

    removeDay = (day: number, reporting: number): void => {
        delete this.giorni[day][reporting];
        this.giorni[day].splice(reporting, 1);
        this.regenerateDayIndex(day);

        this.updateTotaleRendicontazione();
    }

    regenerateDayIndex = (day: number): void => {
        const elements = $('#d' + day + ' .rendicontazione');
        if (elements.length > 0) {
            elements.each(function (index: number, element: any) {
                $(element).attr('index', index);
            });
        }
    }

    updateTotaleRendicontazione = (): void => {
        let array_commesse: iArrayCommesse = {};
        let totale = '00:00';
        this.infoBuoniPasto = [];
        this.totBuoniPasto = 0;

        for (var d = 1; d <= 31; d++) {
            var totale_giorno = '00:00';
            let minutiGiornalieriLavorati: number = 0;

            if (typeof this.giorni[d] !== 'undefined' && this.giorni[d].length > 0) {
                const rendicontazioni = this.giorni[d];
                for (let r = 0; r < rendicontazioni.length; r++) {
                    const nomeCommessa = rendicontazioni[r].commessa.text?.trim() ?? '';
                    const nomeCausale = rendicontazioni[r].causale.text?.trim() ?? '';
                    let tempo = rendicontazioni[r].ora.diff.tempo;

                    const valore_pausa = rendicontazioni[r].pausa.id;
                    if (valore_pausa) {
                        if (typeof array_commesse['PAUSA'] == 'undefined') { array_commesse['PAUSA'] = '00:00'; }
                        array_commesse['PAUSA'] = addTimes(array_commesse['PAUSA'], valore_pausa);
                        tempo = subTimes(tempo, valore_pausa);
                    }

                    totale = addTimes(totale, tempo);
                    totale_giorno = addTimes(totale_giorno, tempo);

                    if (nomeCommessa && !rendicontazioni[r].trasferta.id) {
                        const totGiornoArray = (tempo.split(":"));
                        const minutiParziali = (Number(totGiornoArray[0])* 60 + Number(totGiornoArray[1]));
                        minutiGiornalieriLavorati = minutiGiornalieriLavorati + minutiParziali;
                    }

                    let nome_rendicontazione = nomeCommessa + (nomeCommessa && nomeCausale ? '-' : '') + nomeCausale;

                    if (typeof rendicontazioni[r].parent !== 'undefined' && rendicontazioni[r].parent) {
                        nome_rendicontazione = '(' + rendicontazioni[r].parent?.nome + ') ' + nome_rendicontazione;
                    }

                    if (typeof array_commesse[nome_rendicontazione] == 'undefined') { array_commesse[nome_rendicontazione] = '00:00'; }
                    array_commesse[nome_rendicontazione] = addTimes(array_commesse[nome_rendicontazione], tempo);

                    const valore_viaggio = rendicontazioni[r].viaggio.tempo;
                    if (valore_viaggio) {
                        if (typeof array_commesse['VIAGGIO'] == 'undefined') { array_commesse['VIAGGIO'] = '00:00'; }
                        array_commesse['VIAGGIO'] = addTimes(array_commesse['VIAGGIO'], valore_viaggio);
                    }

                    $('#totale-giorno-' + d).text(totale_giorno);
                }

                const element = { giorno: d, minuti: minutiGiornalieriLavorati };
                this.infoBuoniPasto.push(element);
            } else {
                $('#totale-giorno-' + d).text('');
            }
        }
        this.totaleOre = totale;

        // totale ore per commessa
        $('#rendicontazione-totale-ore .rendicontazione').remove();

        if (array_commesse) {
            for (var key in array_commesse) {
                $('#rendicontazione-totale-ore tbody').prepend(`<tr class="rendicontazione">
                    <th>${key}</th>
                    <td>${array_commesse[key]}</td>
                </tr>`);
            }
        }

        $('#totale-ore').text(totale);
        let checkTotaleOre = this.checkTotaleOreMaggioreOreMese();

        switch (checkTotaleOre) {
            case -1:
                $("#totale-ore").attr('class', 'text-warning');
                break;
            default:
                $("#totale-ore").attr('class', 'text-success');
        }

        // operazioni per il calcolo dei buoni pasto
        let minuti: Array<number> = [];
        
        for( let i = 1; i<= 31; i++ ) {
            let empty = true;
            this.infoBuoniPasto.map((item: {giorno: number, minuti: number}) => {
                if (item.giorno === i) {
                    minuti.push(item.minuti);
                    empty = false;
                }
                return null
            });
            if (empty) minuti.push(0);
        }
        
        this.totBuoniPasto = 0;
        minuti.map((el: number, index: number) => {
            if (el >= 360) {
                $("#buono-pasto-" + (index + 1)).attr('hidden', false);
                this.totBuoniPasto++;
            } else {
                $("#buono-pasto-" + (index + 1)).attr('hidden', true);
            }
            return null
        });

        $('#totale-buoni-pasto').text(this.totBuoniPasto);
    }

    // isMealVoucherAssignable(): Array<boolean> {
    //     let minuti: Array<number> = [];
        
    //     for( let i = 1; i<= 31; i++ ) {
    //         let empty = true;
    //         this.infoBuoniPasto.map((item: {giorno: number, minuti: number}) => {
    //             if (item.giorno === i) {
    //                 minuti.push(item.minuti);
    //                 empty = false;
    //             }
    //             return null
    //         });
    //         if (empty) minuti.push(0);
    //     }
        
    //     let erogazioneBuonoPasto: Array<boolean> = [];
    //     this.totBuoniPasto = 0;
    //     minuti.map((el: number) => {
    //         (el >= 360) ? erogazioneBuonoPasto.push(true) : erogazioneBuonoPasto.push(false);
    //         if (el >= 360) this.totBuoniPasto++;
    //         return null
    //     });

    //     return erogazioneBuonoPasto
    // }

    // totaleBuoniPasto(): number {
    //     return this.totBuoniPasto;
    // }

    checkTotaleOreStraordinario() {
        let totaleOre = this.totaleOre;
        let oreMese = $('.oreMese').text().trim() + ':00';

        let minutiTotale = timeToMins(totaleOre);
        let minutiOre = timeToMins(oreMese);

        return minutiTotale - minutiOre !== 0 ? (minutiTotale - minutiOre) / 60 : 0
    }

    checkTotaleOreMaggioreOreMese = (): number => {
        let totaleOre = this.totaleOre;
        let oreMese = $('.oreMese').text().trim() + ':00';

        let minutiTotale = timeToMins(totaleOre);
        let minutiOre = timeToMins(oreMese);

        return minutiTotale === minutiOre ? 0 : (minutiTotale > minutiOre ? 1 : -1);
    }

    getStatoModificaRendicontazione = (): number => {
        return $('input[name="edited"]').val().trim();
    }

    setStatoModificaRendicontazione = (stato: number): void => {
        $('input[name="edited"]').val(stato);
    }

    isFreeTime = (giorno: number, currentTime: any[]): boolean => {
        var exist = true;

        if ($('#giorno-' + giorno).length > 0) {
            var rendicontazioni = $('#giorno-' + giorno + ' .rendicontazione');
            if (rendicontazioni.length > 0) {
                rendicontazioni.each(function (index: number) {
                    var valore_inizio = $(rendicontazioni[index]).find('.valore-inizio').text().trim();
                    var valore_fine = $(rendicontazioni[index]).find('.valore-fine').text().trim();
                    var currentDate = moment().format("YYYY-MM-DD");

                    currentTime.forEach(function (time) {
                        if (exist) {
                            exist = !moment(currentDate + ' ' + time, "YYYY-MM-DD h:mm").isBetween(currentDate + ' ' + valore_inizio, currentDate + ' ' + valore_fine);
                        }
                    });

                    // [valore_inizio, valore_fine].forEach(function (time) {
                    //     if (exist) {
                    //         exist = !moment(currentDate + ' ' + time, "YYYY-MM-DD h:mm").isBetween(currentDate + ' ' + currentTime[0], currentDate + ' ' + currentTime[1]);
                    //     }
                    // });
                });
            }
        }

        return exist;
    }

    checkSubmitRendicontazione = (): void => {
        Swal.fire({
            title: 'Sei sicuro di voler confermare la rendicontazione ?',
            //text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Conferma',
            cancelButtonText: 'Annulla'
        }).then((result: any) => {
            if (result.isConfirmed) {
                //this.submitRendicontazione();
            }
        });
    }

    updateRendicontazione = async (status: string) => {
        var data = new FormData();
        let commesse = [];
        let causali = [];

        for (var d = 1; d <= 31; d++) {
            if (typeof this.giorni[d] !== 'undefined') {
                const rendicontazioni = this.giorni[d];
                let dett: string[] = [];

                for (let r = 0; r < rendicontazioni.length; r++) {
                    if (rendicontazioni[r].commessa.id) {
                        commesse.push(rendicontazioni[r].commessa.id);
                    } else if (rendicontazioni[r].causale.id) {
                        causali.push(rendicontazioni[r].causale.id);
                    }

                    dett.push(JSON.stringify(rendicontazioni[r]));
                }
                data.append('d' + d, '[' + dett + ']');
            }
        }
        data.append('status', status);
        const join_commesse = commesse.filter((v, i, a) => a.indexOf(v) === i).join(',');
        const join_causali = causali.filter((v, i, a) => a.indexOf(v) === i).join(',');
        data.append('commesse', join_commesse);
        data.append('causali', join_causali);

        const currentDate: any = this.getCurrentDate();

        data.append('anno', currentDate.year);
        data.append('mese', currentDate.month);

        if ($('.attachment').length > 0) {
            $('.attachment').each(function (index: any, element: any) {
                if ($(element)[0].files.length > 0) {
                    data.append('attachment[]', $(element)[0].files[0]);
                }
            });
        }

        //var url = '/dipendente/rendicontazione';
        if (status === 'M') {
            var rendicontazione_id = this.getRendicontazioneId();
            data.append('rendicontazione_id', rendicontazione_id);
            return await RendicontazioniService.amministrazioneEditRendicontazione(rendicontazione_id, data);
        }

        return await RendicontazioniService.dipendenteEditRendicontazione(currentDate.year, currentDate.month, data);
    }

    existTrasferta = (
        giorno: number,
        index: number | undefined = undefined
    ): boolean => {
        let result = false;

        if (typeof this.giorni[giorno] !== 'undefined') {
            const rendicontazioni = this.giorni[giorno];

            for (let r = 0; r < rendicontazioni.length; r++) {
                if (
                    typeof rendicontazioni[r].trasferta !== 'undefined' &&
                    rendicontazioni[r].trasferta.id && (
                        typeof index === 'undefined' || (
                            typeof index !== 'undefined' &&
                            index >= 0 &&
                            index !== r
                        )
                    )
                ) {
                    result = true;
                }
            }
        }

        return result;
    }

    getRendicontazioneData = (
        formData: iReportingFormData,
        day: number
    ): iRendicontazione | false => {
        const tipologia: iReportingFormData['tipologia'] = formData.tipologia === 'causale' ? 'causale' : 'commessa';

        let commessa: iRendicontazione['commessa'] = {
            text: null,
            id: null,
            code: ''
        }

        let parent: iRendicontazione['parent'] | null = null;

        let causale: iRendicontazione['causale'] = {
            text: null,
            id: null
        }

        let dispositivo: iRendicontazione['dispositivo'] = {
            text: null,
            id: null
        }

        let operatore: iRendicontazione['operatore'] = {
            text: null,
            id: null
        }

        let trasferta: iRendicontazione['trasferta'] = {
            text: null,
            id: null
        }

        let pausa: iRendicontazione['pausa'] = {
            text: null,
            id: null
        }

        if (tipologia === 'commessa') {
            const commessaSplit = formData.commessa?.split('||') ?? [];

            commessa = {
                text: typeof commessaSplit[1] === 'string' ? commessaSplit[1].trim() : null,
                id: typeof commessaSplit[0] === 'string' ? Number(commessaSplit[0]) : null,
                code: typeof commessaSplit[2] === 'string' ? commessaSplit[2].trim() : ''
            }
        } else if (tipologia === 'causale') {
            const causaleSplit = formData.causale?.split('||') ?? [];

            causale = {
                text: typeof causaleSplit[1] === 'string' ? causaleSplit[1].trim() : null,
                id: typeof causaleSplit[0] === 'string' ? Number(causaleSplit[0]) : null,
            }
        }

        if (formData.parent) {
            const parentSplit = formData.parent?.split('||') ?? [];

            parent = {
                id: typeof parentSplit[0] === 'string' ? Number(parentSplit[0]) : 0,
                nome: typeof parentSplit[1] === 'string' ? parentSplit[1].trim() : '',
                code: typeof parentSplit[1] === 'string' ? parentSplit[2].trim() : '',
            }
        }

        if (formData.strumento && formData.strumento !== '') {
            const dispositivoSplit = formData.strumento?.split('||') ?? [];

            dispositivo = {
                text: typeof dispositivoSplit[1] === 'string' ? dispositivoSplit[1].trim() : null,
                id: typeof dispositivoSplit[0] === 'string' ? Number(dispositivoSplit[0]) : null
            }
        }

        if (formData.operatore && formData.operatore !== '') {
            const operatoreSplit = formData.operatore?.split('||') ?? [];

            operatore = {
                text: typeof operatoreSplit[1] === 'string' ? operatoreSplit[1].trim() : null,
                id: typeof operatoreSplit[0] === 'string' ? Number(operatoreSplit[0]) : null
            }
        }

        if (formData.trasferta && formData.trasferta !== '') {
            const trasfertaSplit = formData.trasferta?.split('||') ?? [];

            trasferta = {
                text: typeof trasfertaSplit[1] === 'string' ? trasfertaSplit[1].trim() : null,
                id: typeof trasfertaSplit[0] === 'string' ? Number(trasfertaSplit[0]) : null
            }
        }

        // let pausaOra = 0;
        // let pausaMinuto = 0;

        if (formData.pausa && formData.pausa !== '') {
            const pausaSplit = formData.pausa?.split('||') ?? [];

            pausa = {
                text: typeof pausaSplit[1] === 'string' ? pausaSplit[1].trim() : null,
                id: typeof pausaSplit[0] === 'string' ? pausaSplit[0].trim() : null
            }

            // const pausaIdSplit = pausaSplit[0].split(':');
            // pausaOra = Number(pausaIdSplit[0] ?? '0');
            // pausaMinuto = Number(pausaIdSplit[1] ?? '0');
        }

        // const ore = parseInt(formData.ore.toString().trim()) || '00';
        // const minuti = formData.minuti.toString().trim().padStart(2, '0') || '00';

        const oraInizio: number = Number(formData.ora_inizio_hh ?? "0");
        const minutoInizio: number = Number(formData.ora_inizio_mm ?? "0");
        const oraFine: number = Number(formData.ora_fine_hh ?? "0");
        const minutoFine: number = Number(formData.ora_fine_mm ?? "0");

        const DataOraInizio = moment(new Date(
            moment().year(),
            moment().month(),
            moment().day(),
            oraInizio,
            minutoInizio,
            0
        ));

        const DataOraFine = moment(new Date(
            moment().year(),
            moment().month(),
            moment().day(),
            oraFine,
            minutoFine,
            0
        ));

        const DiffInizioFine = DataOraFine.diff(DataOraInizio, 'minutes');
        const oraDiff = Math.floor(Number(DiffInizioFine / 60));
        const minutoDiff = DiffInizioFine % 60;

        const oraDiffTempo = moment(new Date(
            moment().year(),
            moment().month(),
            moment().day(),
            oraDiff,
            minutoDiff,
            0
        ));

        let tempoViaggio: null | moment.Moment = null;
        const oraViaggio: number = Number(formData.tempo_viaggio_hh ?? "0");
        const minutoViaggio: number = Number(formData.tempo_viaggio_mm ?? "0");

        if (oraViaggio !== 0 || minutoViaggio !== 0) {
            tempoViaggio = moment(new Date(
                moment().year(),
                moment().month(),
                moment().day(),
                oraViaggio,
                minutoViaggio,
                0
            ));
        }

        const note = formData.note ? formData.note.trim().replace(/(<([^>]+)>)/gi, "") : '';

        const note_cliente = formData.note_cliente ? formData.note_cliente.trim().replace(/(<([^>]+)>)/gi, "") : '';

        const ritSend = formData.rit_send;
        const measuresSent = formData.measures_sent;

        //const parent_id = $('#modal-action select[name="commessa"] option:selected').attr('parent-id');
        //const parent_value = $('#modal-action select[name="commessa"] option:selected').attr('parent-value');

        // const dataValue = typeof commessa !== 'undefined' ? commessa.trim() : causale.trim();
        // const splitDataValue = dataValue.split('||');

        let data: iRendicontazione = {
            commessa,
            causale,
            dispositivo,
            operatore,
            trasferta,
            ora: {
                inizio: DataOraInizio.format('HH:mm'),
                fine: DataOraFine.format('HH:mm'),
                diff: {
                    minuti: DiffInizioFine,
                    tempo: oraDiffTempo.format('HH:mm')
                }
            },
            pausa,
            viaggio: {
                tempo: tempoViaggio?.format('HH:mm') ?? null
            },
            note,
            note_cliente,
            parent,
            inTheRit: false,
            ritSend,
            measuresSent /*,
            'establishment': {
                'name': establishment.attr('name'),
                'distance': establishment.attr('distance')
            }*/
        };

        /*if (parent_id && parent_value) {
            data.parent = {
                id: parent_id,
                nome: parent_value
            };
        }*/

        // if ((oraInizio === 0 && minutoInizio === 0) || oraInizio > 23 || minutoInizio > 59) {
        //     Swal.fire('Rendicontazione non aggiunta, controllare il tempo.', undefined, 'warning');
        // } else if ((oraFine === 0 && minutoFine === 0) || oraFine > 23 || minutoFine > 59) {
        //     Swal.fire('Rendicontazione non aggiunta, controllare il tempo.', undefined, 'warning');
        if (oraInizio > 23 || minutoInizio > 59) {
            Swal.fire('Rendicontazione non aggiunta, controllare il tempo.', undefined, 'warning');
        } else if (oraFine > 23 || minutoFine > 59) {
            Swal.fire('Rendicontazione non aggiunta, controllare il tempo.', undefined, 'warning');
        } else if (!data.commessa.id && !data.causale.id) {
            Swal.fire('Rendicontazione non aggiunta, selezionare una commessa oppure una causale.', undefined, 'warning');
        } else if (data.commessa && (typeof data.commessa.code == 'undefined' || data.commessa.code === 'undefined')) {
            Swal.fire('Rendicontazione non aggiunta, non è stato possibile recuperare il codice SRV, riprovare.', undefined, 'warning');
        } else if (!this.isFreeTime(day, [DataOraInizio.format('HH:mm'), DataOraFine.format('HH:mm')])) {
            Swal.fire('Rendicontazione non aggiunta, l\'orario fa parte di un\'altra fascia oraria.', undefined, 'warning');
        } else {
            return data;
        }

        return false;
    }

    SubmitModalValidation = (
        formData: iReportingFormData,
        formik: FormikProps<iReportingFormData>
    ): boolean => {
        // const ora_inizio = Number(formData.ora_inizio_hh ?? '0');
        // const minuto_inizio = Number(formData.ora_inizio_mm ?? '0');
        // const ora_fine = Number(formData.ora_fine_hh ?? '0');
        // const minuto_fine = Number(formData.ora_fine_mm ?? '0');
        const { tipologia } = formData;

        let errors: { [key: string]: string } = {};

        if (typeof formData[tipologia] == 'undefined') {
            errors[tipologia] = `Questo campo è obbligatorio`;
        }

        // if (ora_inizio === 0 && minuto_inizio === 0) {
        //     errors['ora_inizio'] = "Questo campo è obbligatorio";
        // }

        // if (ora_fine === 0 && minuto_fine === 0) {
        //     errors['ora_fine'] = "Questo campo è obbligatorio";
        // }

        if (!isEmptyObject(errors)) {
            formik.setErrors(errors);
            return false;
        } else {
            return true;
        }
    }

    addSubmitModal = (
        formData: iReportingFormData,
        formik: FormikProps<iReportingFormData>
    ): boolean => {
        const modalIsValid = this.SubmitModalValidation(formData, formik);

        if (modalIsValid) {
            const newRendicontazione = this.newRendicontazione(formData);

            if (newRendicontazione) {
                this.updateTotaleRendicontazione();
                formik.resetForm();
            }

            return newRendicontazione;
        }

        return false;
    }

    editSubmitModal = (
        formData: iReportingFormData,
        formik: FormikProps<iReportingFormData>
    ): boolean => {
        const modalIsValid = this.SubmitModalValidation(formData, formik);

        if (modalIsValid) {
            const editRendicontazione = this.editRendicontazione(formData);

            if (editRendicontazione) {
                this.updateTotaleRendicontazione();
                formik.resetForm();
            }

            return editRendicontazione;
        }

        return false;
    }

    newRendicontazione = (
        formData: iReportingFormData
    ): boolean => {
        let day = this.selectedDay.day;

        if (formData['all-days'] && $('#rendicontazione .day.selected').length > 0) {
            const self = this
            const giorni_selezionati = $('#rendicontazione .day.selected');

            giorni_selezionati.map(async function (index: number) {
                const day: number = Number($(giorni_selezionati[index]).attr('id')?.replace('d', ''));
                if (day) {
                    const add = self.getRendicontazioneData(formData, day);
                    if (add) {
                        if (add.trasferta && add.trasferta.text && self.existTrasferta(day)) {
                            Swal.fire('Rendicontazione non aggiunta, trasferta già rendicontata.', undefined, 'warning');
                            return false;
                        } else {
                            const render = self.render(day, add, undefined, (typeof self.giorni[day] !== 'undefined'
                                ? Object.keys(self.giorni[day]).length : 0
                            ));
                            $('#giorno-' + day).append(render);
                            if (typeof self.giorni[day] == 'undefined') { self.giorni[day] = []; }
                            self.giorni[day].push(add);
                            $('#modal-action .message').text('Rendicontazione aggiunta.').removeClass('text-danger').addClass('text-success');
                        }
                    }

                    self.regenerateDayIndex(day);

                    return add !== false;
                } else {
                    return false;
                }
            });

            $('#rendicontazione .day.selected').each(function (this: any) {
                $(this).removeClass('selected');
            })
        } else {
            const add = this.getRendicontazioneData(formData, day);

            if (add) {
                if (add.trasferta && add.trasferta.text && this.existTrasferta(day)) {
                    Swal.fire('Rendicontazione non aggiunta, trasferta già rendicontata.', undefined, 'warning');
                    return false;
                } else {
                    const render = this.render(day, add, undefined, (typeof this.giorni[day] !== 'undefined'
                        ? Object.keys(this.giorni[day]).length : 0
                    ));
                    $('#giorno-' + day).append(render);
                    if (typeof this.giorni[day] == 'undefined') { this.giorni[day] = []; }
                    this.giorni[day].push(add);
                    $('#modal-action .message').text('Rendicontazione aggiunta.').removeClass('text-danger').addClass('text-success');
                }
            }

            this.regenerateDayIndex(day);

            return add !== false;
        }

        this.deleteParent(this.removeDay, this.setStatoModificaRendicontazione);
        this.updateTotaleRendicontazione();
        this.setStatoModificaRendicontazione(1);

        return true;
    }

    editRendicontazione = (
        formData: iReportingFormData
    ): boolean => {
        let day = this.selectedDay.day;

        const index = this.getSelectedIndex();
        const edit = this.getRendicontazioneData(formData, day);

        if (edit) {
            if (edit.trasferta && edit.trasferta.text && this.existTrasferta(day, index)) {
                Swal.fire('Rendicontazione non aggiornata, trasferta già rendicontata.', undefined, 'warning');
                return false;
            } else {
                const render = this.render(day, edit, undefined, index);
                $('#giorno-' + day + ' div[index=' + index + ']').replaceWith(render);
                if (typeof this.giorni[day] == 'undefined') { this.giorni[day] = []; }
                this.giorni[day][index] = edit;
                $('#modal-action .message').text('Rendicontazione aggiornata.').removeClass('text-danger').addClass('text-success');
            }

            return true;
        }

        this.regenerateDayIndex(day);

        return false;
    }
}

export default new Reportings();