import moment from "moment";
import 'moment/locale/it';
import { ConfigColumnDefs, ConfigColumns } from "datatables.net";
import { ITimetable } from "../../helpers/interfaces/timetable";
import { statusColors } from "../../helpers/settings/status-settings";

export const listTimetableColumns: ConfigColumns[] = [
    { title: "Azioni", data: "id" },
    { title: "Utente", data: "person" },
    { title: "Scadenza", data: "deadline" },
    { title: "Note", data: "note" },
    { title: "Tipologia", data: "type" },
    { title: "Gruppo", data: "group" },
    { title: "Stato", data: "managed" },
];

export const listReadOnlyTimetableColumns: ConfigColumns[] = [
    { title: "Utente", data: "person" },
    { title: "Scadenza", data: "deadline" },
    { title: "Note", data: "note" },
    { title: "Tipologia", data: "type" },
    { title: "Gruppo", data: "group" },
    { title: "Stato", data: "managed" },
];

export function getListReadOnlyTimetableColumnDefs(): ConfigColumnDefs[] {
    return [
        {
            target: 1,
            render: (id: number, type: string, row: ITimetable) => {
                return moment(row.deadline).format('DD/MM/YYYY');
            }
        },
        {
            target: listReadOnlyTimetableColumns.length - 1,
            render: (id: number, type: string, row: ITimetable) => {

                // status giallo
                if (!row.managed && !row.expired) {
                    return `<div style="width: 16px; height: 16px; background-color: ${statusColors[1].color}" class="rounded-circle"></div>`;
                }

                // status verde
                if (row.managed) {
                    return `<div style="width: 16px; height: 16px; background-color: ${statusColors[3].color}" class="rounded-circle"></div>`;
                }

                // status rosso - default
                return `<div style="width: 16px; height: 16px; background-color: ${statusColors[2].color}" class="rounded-circle"></div>`;

            }
        }
    ];
}

export function getListTimetableColumnDefs(): ConfigColumnDefs[] {
    return [
        {
            target: 0,
            //data: null,
            render: (data: any, type: any, row: ITimetable) => {
                const edit = `<a class="edit_btn custom-icon btn btn-outline-primary rounded-circle" data-id="${data}" title="Modifica"><i style="font-size: 18px" class="fa fa-pencil" aria-hidden="true"></i></span>`;

                const managed = !row.managed ? `<a class="managed_btn custom-icon btn btn-outline-success rounded-circle ms-1" data-id="${data}" title="Segna come gestita"><i style="font-size: 18px" class="fa fa-check" aria-hidden="true"></i></span>` : '';

                const unmanaged = row.managed ? `<a class="unmanaged_btn custom-icon btn btn-outline-success rounded-circle ms-1" data-id="${data}" title="Segna come non gestita"><i style="font-size: 18px" class="fa fa-refresh" aria-hidden="true"></i></span>` : '';

                return '<div class="d-flex">' + edit + managed + unmanaged + '</div>';
            },
        },
        {
            target: 2,
            render: (id: number, type: string, row: ITimetable) => {
                return moment(row.deadline).format('DD/MM/YYYY');
            }
        },
        {
            target: listTimetableColumns.length - 1,
            render: (id: number, type: string, row: ITimetable) => {

                // status giallo
                if (!row.managed && !row.expired) {
                    return `<div style="width: 16px; height: 16px; background-color: ${statusColors[1].color}" class="rounded-circle"></div>`;
                }

                // status verde
                if (row.managed) {
                    return `<div style="width: 16px; height: 16px; background-color: ${statusColors[3].color}" class="rounded-circle"></div>`;
                }

                // status rosso - default
                return `<div style="width: 16px; height: 16px; background-color: ${statusColors[2].color}" class="rounded-circle"></div>`;

            }
        }
    ];
}