import React, { Component } from 'react';
import Swal from 'sweetalert2';

import Table from '../../../common/TableComponent';
import ModalForm from '../../../common/ModalForm';
import EventBus from "../../../common/EventBus";

import ContrattiService from "../../../services/api/contratti.service";
import TipologiaContrattiService from "../../../services/api/database/tipologiacontratti.service";
import TutorsService from "../../../services/api/tutors.service";
import FineContrattiService from "../../../services/api/database/finecontratti.service";
import variabilicontrattoService from '../../../services/api/variabilicontratto.service';
import { listContrattiColumns, getListContrattiColumnDefs } from "./config/tableColumns/listContratti";
import { listVariabiliContrattiColumns, getListVariabiliContrattiColumnDefs } from "./config/tableColumns/listVariabiliContratti";
import comuniService from '../../../services/api/database/comuni.service';
import { iFormField } from '../../../helpers/interfaces/generic';
import { getContrattoFormFields } from './config/formFields/addContratto';
import { getEditVariabileContrattoFormFields } from './config/formFields/variabileContratto';
import { Tab, TabProp } from '../../../common/TabComponent';
import InfoVariabileComponent from './InfoVariabileComponent';
import InfoContrattoComponent from './InfoContrattoComponent';
import { history } from '../../../helpers/history';
import { useHistory } from 'react-router-dom';
import contrattiService from '../../../services/api/contratti.service';

type Props = {
  contratti: any,
  contrattoAttivo: any,
  personId: number,
  history: {
    push(url: string): void;
  }
};

type State = {
  data: any[],
  variabiliAttivo: any[],
  variabili: any[],
  showModal: boolean,
  modalType: string,
  modalTitle: string,
  formFields: Array<iFormField>,
  formInitialValues: { [key: string]: any },
  currentJobrder: string
}

const $ = require('jquery');

class ContrattiComponent extends Component<Props, State> {

  contrattoButtons: (string | { text: string; className: string; action: (e: any, dt: any, node: any, config: any) => void; })[];
  formFields: any;
  apiSubmit: any;
  variabiliAttivoButtons: (string | { text: string; className: string; action: (e: any, dt: any, node: any, config: any) => Promise<void>; })[];
  navigate = useHistory

  // State array variable to save and show data
  constructor(props: Props) {
    super(props);

    let variabili: any[] = []
    props.contratti.forEach(
      (contratto: any) => {
        variabili = variabili.concat(contratto.variabili)
      }
    )

    this.state = {
      data: props.contratti || [],
      variabiliAttivo: props.contrattoAttivo?.variabili || [],
      variabili,
      showModal: false,
      modalType: 'add',
      modalTitle: 'Nuovo',
      formFields: [],
      formInitialValues: [],
      currentJobrder: 'attivo'
      //apiSubmit: null
    };

    this.apiSubmit = null;

    this.contrattoButtons = [
      //"copy",
      //"csv",
      "excel",
      "pdf",
      "print",
      //"colvis"
    ];

    this.variabiliAttivoButtons = [
      //"copy",
      //"csv",
      "excel",
      "pdf",
      "print",
      //"colvis"
    ];
  }

  async componentDidMount() {
    $('#contratti.table').on('click', '.view_btn', async (e: any) => {
      e.preventDefault();
      EventBus.dispatch("showLoader", { text: 'Caricamento contratto in corso...' });
      const idContratto = $(e.currentTarget).data('id');
      const dettaglioContratto: any = await ContrattiService.get(idContratto);
      if (dettaglioContratto) {
        const tipologiacontratti_all = await TipologiaContrattiService.getAllDatore();
        let tipologiacontratti: any = tipologiacontratti_all.map((item: any) => { return { key: item.id, value: item.nome } });

        const tutors_all = await TutorsService.getAll();
        let tutors: any = tutors_all.map((item: any) => { return { key: item.id, value: item.tutor } });

        const finecontratti_all = await FineContrattiService.getAllDatore();
        let finecontratti: any = finecontratti_all.map((item: any) => { return { key: item.id, value: item.nome } });

        let initialValues = this.initFormValues(dettaglioContratto, null)

        this.apiSubmit = ContrattiService.put;
        this.setState({
          formFields: getContrattoFormFields(tipologiacontratti, tutors, finecontratti),
          formInitialValues: { ...initialValues },
          showModal: true,
          modalType: 'edit',
          modalTitle: 'Modifica contratto'
        });
      }
      EventBus.dispatch("hideLoader");
    });

    $('#variabili-contratto.table').on('click', '.edit_btn', async (e: any) => {
      e.preventDefault();
      EventBus.dispatch("showLoader", { text: 'Caricamento variabile contratto in corso...' });
      const idVariabileContratto = $(e.currentTarget).data('id');
      const dettaglioVariabile: any = await variabilicontrattoService.personGet(idVariabileContratto);
      if (dettaglioVariabile) {
        const comuni_all = await comuniService.getAllPatrono();
        const comuni: any = comuni_all.map((item: any) => { return { key: item.id, value: item.nome } });

        dettaglioVariabile['variabili_stipendio_lordo'] = dettaglioVariabile['ral'] / 14;
        this.apiSubmit = variabilicontrattoService.personPut;
        this.setState({
          formFields: getEditVariabileContrattoFormFields(comuni),
          formInitialValues: dettaglioVariabile,
          showModal: true,
          modalType: 'edit',
          modalTitle: 'Modifica variabile contratto'
        });
      }
      EventBus.dispatch("hideLoader");
    });

    $('#variabili-contratto.table').on('click', '.delete_btn', async (e: any) => {
      e.preventDefault();

      Swal.fire({
        title: "Vuoi confermare l'eliminazione?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Conferma',
        cancelButtonText: 'Annulla'
      }).then(async (result) => {
        if (result.isConfirmed) {
          const idVariabileContratto = $(e.currentTarget).data('id');
          const variabilecontratto: any = await variabilicontrattoService.personDelete(idVariabileContratto);

          if (variabilecontratto && typeof variabilecontratto.error !== 'undefined') {
            Swal.fire(
              variabilecontratto.error,
              '',
              'error'
            );
          } else {
            this.handleSuccess()
          }
        }
      });

    });
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  initFormValues(contratto: any, variabile: any) {
    let initialValues = { variabile: null }
    if (contratto)
      Object.keys(contratto).forEach(
        value => {
          Object.assign(initialValues, {
            ...initialValues,
            variabile: variabile ? { ...variabile } : null,
            [value]: typeof contratto[value] === 'boolean' ? Number(contratto[value]) : contratto[value]
          })
        }
      )
    return initialValues
  }

  componentWillUnmount() {
    TipologiaContrattiService.abort();
    TutorsService.abort();
    FineContrattiService.abort();
  }

  async openAddContratto(variabileId: number) {
    EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });
    this.apiSubmit = ContrattiService.add;

    let variabileAttiva = {}

    if (variabileId !== undefined) {
      const dettaglioVariabile: any = await variabilicontrattoService.personGet(variabileId);
      dettaglioVariabile['variabili_stipendio_lordo'] = dettaglioVariabile['ral'] / 14;
      variabileAttiva = dettaglioVariabile
    }

    const tipologiacontratti_all = await TipologiaContrattiService.getAllDatore();
    let tipologiacontratti: any = tipologiacontratti_all.map((item: any) => { return { key: item.id, value: item.nome } });

    const tutors_all = await TutorsService.getAll();
    let tutors: any = tutors_all.map((item: any) => { return { key: item.id, value: item.tutor } });

    const finecontratti_all = await FineContrattiService.getAllDatore();
    let finecontratti: any = finecontratti_all.map((item: any) => { return { key: item.id, value: item.nome } });

    if (Object.keys(tipologiacontratti).length > 0 && Object.keys(tutors).length > 0 && Object.keys(finecontratti).length > 0) {

      const dettaglioContratto = this.props.contrattoAttivo
      let initialValues = this.initFormValues(dettaglioContratto, variabileAttiva)

      this.setState(
        {
          formFields: getContrattoFormFields(tipologiacontratti, tutors, finecontratti),
          formInitialValues: { ...initialValues, person_id: this.props.personId },
          showModal: true,
          modalType: 'add',
          modalTitle: 'Aggiungi contratto'
        }
      );
    }
    EventBus.dispatch("hideLoader");
  }

  async openAddVariabileContratto(id: number) {
    EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });

    const comuni_all = await comuniService.getAllPatrono();
    const comuni: any = comuni_all.map((item: any) => { return { key: item.id, value: item.nome } });
    let dettaglio = {}

    if (id !== undefined) {
      const dettaglioVariabile: any = await variabilicontrattoService.personGet(id);
      dettaglioVariabile['variabili_stipendio_lordo'] = dettaglioVariabile['ral'] / 14;
      dettaglio = dettaglioVariabile
    }

    this.apiSubmit = variabilicontrattoService.personAdd;

    this.setState(
      {
        formFields: getEditVariabileContrattoFormFields(comuni),
        formInitialValues: {
          ...dettaglio,
          person_id: this.props.personId,
          contratto_id: this.props.contrattoAttivo.id
        },
        showModal: true,
        modalType: 'add',
        modalTitle: 'Aggiungi variabile contratto'
      })
    EventBus.dispatch("hideLoader");
  }

  handleResponse() {
    EventBus.dispatch("hideLoader");
    Swal.fire({
      title: "Operazione completata con successo",
      icon: 'success',
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'OK',
    }).then(
      async result => {
        this.handleSuccess();
      }
    );
  }

  handleError() {
    EventBus.dispatch("hideLoader");
    Swal.fire(
      'Operazione fallita',
      'error'
    )
  }

  async submitCallback(data: any, formik: any) {
    EventBus.dispatch("showLoader", { text: 'Operazione in corso...' });
    const { contrattoAttivo } = this.props
    const { modalType } = this.state

    const formData = data

    if (modalType === 'edit') {
      if (formData.variabile === null) {
        // edit contratto
        delete formData.variabile
        let contratto = formData
        await contrattiService.put(contratto.id, contratto).then(
          () => this.handleResponse(),
          () => this.handleError()
        )
      } else {
        // edit variabile
        await variabilicontrattoService.personPut(formData.id, formData).then(
          () => this.handleResponse(),
          () => this.handleError()
        )
      }
    }

    if (modalType === 'add') {
      // add contratto
      if (formData.variabile && contrattoAttivo) {
        let variabile = formData.variabile
        delete formData.variabile
        let contratto = formData

        await contrattiService.add(contratto).then(
          async response => {
            if (response) {
              let variabileData = { ...variabile, contratto_id: response.id }
              await variabilicontrattoService.personAdd(variabileData).then(
                () => this.handleResponse(),
                () => this.handleError()
              )
            } else {
              this.handleError()
            }
          },
          () => this.handleError()
        )
      } else {
        if (contrattoAttivo) {
          // add variabile
          await variabilicontrattoService.personAdd(formData).then(
            () => this.handleResponse(),
            () => this.handleError()
          )
        } else {
          // add contratto
          let contratto = formData
          await contrattiService.add(contratto).then(
            () => this.handleResponse(),
            () => this.handleError()
          )
        }

      }
    }
  }

  handleSuccess() {
    window.location.hash = "#contratti";
    window.location.reload();
  }

  render() {
    const { contratti, contrattoAttivo, personId } = this.props
    const { showModal, formFields, formInitialValues, modalType, modalTitle, data, variabiliAttivo, variabili } = this.state;

    // set tabs config
    let contrattiTabs: TabProp[] = []

    if (contrattoAttivo && contrattoAttivo.length !== 0)
      contrattiTabs.push(
        {
          id: 'tab_contratto_attivo',
          label: `Contratto attivo`,
          content: <div className='d-flex flex-column flex-lg-row'>
            <div className="col-12 col-lg-6 pe-lg-2">
              <InfoContrattoComponent contrattoAttivo={contrattoAttivo} history={history} />
            </div>
            <div className="col-12 col-lg-6 ps-lg-2">
              <InfoVariabileComponent contrattoAttivo={contrattoAttivo} showEdit={true} personId={personId} />
            </div>
          </div>,
          isActive: true
        }
      )
    if (contratti.length !== 0)
      contrattiTabs.push(
        {
          id: 'tab_storico_contratti',
          label: "Tutti i contratti",
          content: data.length > 0 ? <React.Fragment>
            <div className='mt-4 mb-3'>
              <h3 className="m-0">Tutti i contratti</h3>
              <Table id="contratti" columns={listContrattiColumns} columnDefs={getListContrattiColumnDefs()} datas={data} buttons={this.contrattoButtons} />
            </div>
            <hr />
            <h3 className='m-0'>Tutte le variabili</h3>
            <Table id="variabili-contratto" columns={listVariabiliContrattiColumns} columnDefs={getListVariabiliContrattiColumnDefs()} datas={variabili} buttons={this.variabiliAttivoButtons} />
          </React.Fragment> : <p>Non sono presenti contratti</p>,
          isActive: false
        }
      )

    return <div className='row p-2'>
      <ModalForm
        showModal={showModal}
        title={modalTitle}
        modalType={modalType}
        formFields={formFields}
        initialValues={formInitialValues}
        apiSubmit={this.apiSubmit}
        closeCallback={this.closeModal.bind(this)}
        submitCallback={this.submitCallback.bind(this)}
        successAction={this.handleSuccess.bind(this)}
      />

      <div className='d-flex flex-column flex-lg-row justify-content-between align-items-lg-center align-items-lg-stretch mb-2'>
        <h3>Contratti</h3>
        <div className="d-flex flex-column flex-sm-row">
          <button id="add_btn" type="button" className="btn btn-outline-primary mb-2 mb-sm-0 me-sm-2" onClick={() => this.openAddContratto(variabiliAttivo[0]?.id)}>
            <span>Aggiungi contratto</span>
          </button>
          {
            contrattoAttivo && <button id="add_btn" type="button" className="btn btn-outline-primary" onClick={() => this.openAddVariabileContratto(variabiliAttivo[0]?.id)}>
              <span>Aggiungi variabile</span>
            </button>
          }
        </div>
      </div>
      {
        contrattiTabs.length > 0 ? <Tab tabs={contrattiTabs} /> : <p>Non sono presenti contratti</p>
      }
    </div>
  }
}
export default ContrattiComponent;