
export default function ObjectToArray<T = any>(
    objectArray: {[key: string | number]: {[key: string | number]: any}}
) : T {
    let result: any = [];

    Object.keys(objectArray).forEach((value: string | number, index: number) => {
        result[index] = objectArray[value];
    })

    return result;
}