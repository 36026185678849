import * as Yup from "yup";
import { iFormField } from "../../helpers/interfaces/generic";

export function addRuoliFormFields(): Array<iFormField> {
  return [
    {
      'label': 'Ruolo',
      'name': 'role',
      'type': 'text',
      'class': 'form-control',
      'validation': Yup.string().required("Campo obbligatorio")
    },
    {
      'label': 'Codice',
      'name': 'code',
      'type': 'text',
      'class': 'form-control',
      'validation': Yup.string().required("Campo obbligatorio")
    }
  ];
}

export function editRuoliFormFields(): Array<iFormField> {
    return [
      {
        'label': 'Ruolo',
        'name': 'role',
        'type': 'text',
        'class': 'form-control',
        'validation': Yup.string().required("Campo obbligatorio")
      },
      {
        'label': 'Codice',
        'name': 'code',
        'type': 'text',
        'class': 'form-control',
        'validation': Yup.string().required("Campo obbligatorio")
      }
    ];
  }