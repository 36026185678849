import React, { Component } from "react";
import { FormikProps } from "formik";
import * as Yup from "yup";
import eventBus from "../../common/EventBus";
import NoteSpeseService from '../../services/api/note-spese.service';
import { INoteSpese } from '../../helpers/interfaces/note-spese';
import getNoteSpeseColumnDefs, { listNoteSpeseColumns } from '../../config/tableColumns/note-spese-amministratore';
import NoteSpeseAmministrazioneSelect from './note-spese-select';
import { iFormField, iFormFieldValue } from "../../helpers/interfaces/generic";
import Table from '../../common/TableComponent';
import ModalForm from '../../common/ModalForm';
import "moment/locale/it";
import Swal from "sweetalert2";
import { base64toBlob } from '../../common/Base64';

const $ = require('jquery');

type Props = {
    renderField: (item: iFormField, key: number, formik: FormikProps<any>) => false | JSX.Element,
    // parentCallback2: any,
    persons: iFormFieldValue[],
    tipologieNota: iFormFieldValue[],
    commesse: iFormFieldValue[]
};

type State = {
    loading: boolean,
    formFields: Array<iFormField>,
    formInitialValues: { [key: string]: any },
    disabledForm: boolean,
    showModal: boolean,
    modalType: string,
    modalTitle: string,
    expenses: INoteSpese[]
};

export default class NoteSpeseDipendente extends Component<Props, State> {
    
    apiSubmit: any;

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: false,
            formFields: [],
            formInitialValues: {},
            disabledForm: true,
            showModal: false,
            modalType: '',
            modalTitle: '',
            expenses: []
        }

        this.showNoteDetail = this.showNoteDetail.bind(this);
        // this.getExpensesFromChild = this.getExpensesFromChild.bind(this);
    }

    async componentDidMount() {
    }

    getExpensesFromChild(data: INoteSpese[]) {
        this.setState({ expenses: data });
        this.showNoteDetail();
    }

    showNoteDetail() {
        // $('#table_note-spese.table').on('click', '.edit_btn', async (e: any) => {
        //     e.preventDefault();
        //     eventBus.dispatch("showLoader", { text: 'Caricamento nota spese in corso...' });

        //     const idNota = $(e.currentTarget).data('id');
        //     await NoteSpeseService.getOneNote(idNota)
        //     .then((response: INoteSpese) => {
        //         eventBus.dispatch("hideLoader");

        //         if (!response || (response.status && response.status.toLowerCase() === 'error')) {
        //             Swal.fire({
        //                 title: 'Errore',
        //                 text: 'Si è verificato un errore nel caricamento dei dati.',
        //                 icon: 'error',
        //                 })
        //         } else {
        //             this.props.parentCallback2(
        //                 response
        //             );
        //         }
        //     })
        //     .catch((error: string) => {
        //         eventBus.dispatch("hideLoader");

        //         Swal.fire({
        //             title: 'Errore',
        //             text: error,
        //             icon: 'error',
        //             });
        //     })
        // });

        $('#table_note-spese.table').on('click', '.delete_btn', async (e: any) => {
            e.preventDefault();
            Swal.fire({
                title: 'Vuoi confermare la cancellazione?',
                //text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Conferma',
                cancelButtonText: 'Annulla'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const idNota = $(e.currentTarget).data('id');
                    const notaSpese: any = await NoteSpeseService.deleteNote(idNota);
                    if (notaSpese && typeof notaSpese.error !== 'undefined') {
                        Swal.fire(
                            notaSpese.error,
                            '',
                            'error'
                        );
                    } else {
                        window.location.reload();
                    }
                }
            });
        });

        $('#table_note-spese.table').on('click', '.download_link', async (event: any) => {
            event.preventDefault();
            
            eventBus.dispatch("showLoader", { text: 'Download in corso...' });

            const id = event.currentTarget.dataset.id;
    
            this.setState({
                loading: true
            })
    
            await NoteSpeseService.downloadAttachmentNote(id).then(
                documento => {
                    eventBus.dispatch("hideLoader");
                    this.setState({
                        loading: false
                    })
    
                    let fileBlob = base64toBlob(documento.body, documento.mimetype)
                    let url = window.URL.createObjectURL(fileBlob);
                    let a = document.createElement('a');
                    a.href = url;
                    a.download = documento.filename;
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                },
                () => {
                    eventBus.dispatch("hideLoader");
    
                    Swal.fire(
                        'Errore',
                        '',
                        'error'
                    );
    
                    this.setState({
                        loading: false
                    });
                }
            )
        });
    }

    closeModal() {
        this.setState({ showModal: false });
    }

    validationSchema() {
        let validations: any = {};
        this.state.formFields.forEach((value: any, key: any) => (validations[value.name] = value.validation));

        return Yup.object().shape(validations);
    }

    render() {
        const { formFields, formInitialValues, showModal, modalTitle, modalType } = this.state;
        const buttons = ['excel', 'pdf', 'print'];
 
        return (
            <React.Fragment>
                <div className='custom-container'>
                    <div className="card">
                        <div className="card-body">
                            <NoteSpeseAmministrazioneSelect
                                renderField={this.props.renderField}
                                profiloId={0}
                                persons={this.props.persons}
                                parentCallback={this.getExpensesFromChild.bind(this)}
                            />
                            <div className="mt-5"></div>
                                
                            {
                                this.state.expenses.length > 0 &&
                                <Table id={'table_note-spese'} columns={listNoteSpeseColumns} columnDefs={getNoteSpeseColumnDefs()} datas={this.state.expenses} buttons={buttons} />
                            }

                            <ModalForm
                                showModal={showModal}
                                title={modalTitle}
                                modalType={modalType}
                                formFields={formFields}
                                initialValues={formInitialValues}
                                apiSubmit={this.apiSubmit}
                                closeCallback={this.closeModal.bind(this)}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}