import React, { Component } from "react";
import EventBus from "../../../common/EventBus";
import ModalForm from '../../../common/ModalForm';

import StatoRITService from "../../../services/api/database/statorit.service";
import { listStatoRITColumns, getListStatoRITColumnDefs } from "../../../config/tableColumns/stato-rit";
import { getEditStatoRITFormFields, getAddStatoRITFormFields } from "../../../config/formFields/stato-rit";
import Table from '../../../common/TableComponent';
import { iFormField } from "../../../helpers/interfaces/generic";

const $ = require('jquery');

type Props = {
    history: any
};
type State = {
    formFields: Array<iFormField>,
    loading: boolean,
    message: string,
    showModal: boolean,
    modalType: string,
    statorit: any,
    formInitialValues: { [key: string]: any }
}
export default class StatoRIT extends Component<Props, State> {
    innerRefs: any;
    apiSubmit: any;
    buttons: string[] | null;

    constructor(props: Props) {
        super(props);
        this.state = {
            formFields: [],
            statorit: [],
            loading: false,
            message: "",
            showModal: false,
            modalType: 'add',
            formInitialValues: {},
        }
        this.innerRefs = [];
        this.apiSubmit = null;// LicenzeService.personAdd;
        this.buttons = null;
    }

    async componentDidMount() {
        EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });
        const statorit = await StatoRITService.getAll();
        if (statorit.length > 0) {
            this.setState({ statorit });
        }
        EventBus.dispatch("hideLoader");

        $('#table_statorit.table').on('click', '.edit_btn', async (e: any) => {
            e.preventDefault();
            EventBus.dispatch("showLoader", { text: 'Caricamento stato rit in corso...' });
            const idTipologia = $(e.currentTarget).data('id');
            const tipologia = await StatoRITService.get(idTipologia);
            if (tipologia) {
                this.setState({ formFields: getEditStatoRITFormFields(), formInitialValues: tipologia }, () => {
                    this.apiSubmit = StatoRITService.put;
                    this.setState({ showModal: true, modalType: 'edit' });
                });
            }
            EventBus.dispatch("hideLoader");
        });
    }

    closeModal() {
        this.setState({ showModal: false });
    }

    openAddModale() {
        this.apiSubmit = StatoRITService.add;
        this.setState({ formFields: getAddStatoRITFormFields(), formInitialValues: {}, showModal: true, modalType: 'add' });
    }

    render() {
        const { formFields, showModal, modalType, statorit, formInitialValues } = this.state;
        return <React.Fragment>
            <div className="custom-container">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <h2 className="card-title">Stato rit</h2>
                            <button id="add_btn" type="button" className="btn btn-outline-primary" onClick={() => this.openAddModale()}>
                                <span>Aggiungi stato rit</span>
                            </button>
                        </div>
                        <ModalForm showModal={showModal} title={'Stato rit'} modalType={modalType} formFields={formFields} initialValues={formInitialValues} apiSubmit={this.apiSubmit} closeCallback={this.closeModal.bind(this)} />
                        {statorit.length > 0 && <Table id="table_statorit" columns={listStatoRITColumns} columnDefs={getListStatoRITColumnDefs()} datas={statorit} buttons={this.buttons} />}
                    </div>
                </div>
            </div>
        </React.Fragment>
    }
}