import * as Yup from "yup";
import { iFormField, iFormFieldValue } from "../../helpers/interfaces/generic";

export function getNoteSpeseAmministratoreFormFields(anni: iFormFieldValue[], mesi: iFormFieldValue[] ): Array<iFormField> {
    return [
      {
        'label': 'Anno',
        'name': 'year',
        'type': 'select',
        'value': '- Seleziona un anno -',
        'values': anni,
        'class': 'form-select',
        'validation': Yup.string().required("Campo obbligatorio")
      },
      {
        'label': 'Mese',
        'name': 'month',
        'type': 'select',
        'value': '- Seleziona un mese -',
        'values': mesi,
        'class': 'form-select',
        'validation': Yup.string().required("Campo obbligatorio")
      }
    ]
  }

  export function getNoteSpeseDipendentiFormFields(dipendenti: iFormFieldValue[], anni: iFormFieldValue[], mesi: iFormFieldValue[] ): Array<iFormField> {
    return [
      {
        'label': 'Dipendente',
        'name': 'persons',
        'type': 'select',
        'values': dipendenti,
        'class': 'form-select',
        // 'hidden': dipendenti.length === 0
        // 'validation': Yup.string().required("Campo obbligatorio")
      },
      {
        'label': 'Anno',
        'name': 'year',
        'type': 'select',
        'value': '- Seleziona un anno -',
        'values': anni,
        'class': 'form-select',
        'validation': Yup.string().required("Campo obbligatorio")
      },
      {
        'label': 'Mese',
        'name': 'month',
        'type': 'select',
        'value': '- Seleziona un mese -',
        'values': mesi,
        'class': 'form-select',
        'validation': Yup.string().required("Campo obbligatorio")
      }
    ]
  }

  export function notaSpesaFormFields(dipendenti: iFormFieldValue[], tipologia: iFormFieldValue[], commesse: iFormFieldValue[]): Array<iFormField> | Array<iFormField> {
    const tipologiaIdArray: any = [];
    for (let i=1; i <= tipologia.length; i++) {
      if (i !== 5) tipologiaIdArray.push(i);
    }
    tipologiaIdArray.push(undefined);

    return [
      {
        'label': 'Data',
        'name': 'date',
        'type': 'date',
        'class': 'form-control',
        'validation': Yup.date().required("Campo obbligatorio")
      },
      {
        'label': 'Tipologia',
        'name': 'tipologia',
        'type': 'select',
        'values': tipologia,
        'class': 'form-select',
        'validation': Yup.string().required("Campo obbligatorio")
      },
      {
        'label': 'Ammontare',
        'name': 'amount',
        'type': 'text',
        'class': 'form-control',
        // se tipologia='rimborso km' no amount
        'validation': Yup.string().when("tipologia", {
          is: (value: any) => { return value !== '5' },
          then: Yup.string().required("Campo obbligatorio")
        }),
        showFromFieldName: 'tipologia',
        showFromFieldValue: tipologiaIdArray
      },
      {
        'label': 'Tragitto',
        'name': 'tragitto',
        'type': 'text',
        'class': 'form-control',
        // se tipologia='rimborso km' si tragitto
        // 'validation': Yup.string().when("tipologia", {
        //   is: (value: any) => { return value === '5' },
        //   then: Yup.string().required("Campo obbligatorio")
        // }),
        showFromFieldName: 'tipologia',
        showFromFieldValue: [ undefined, 0, 5 ]
      },
      {
        'label': 'km',
        'name': 'km',
        'type': 'text',
        'class': 'form-control',
        // se tipologia='rimborso km' si km
        'validation': Yup.string().when("tipologia", {
          is: (value: any) => { return value === '5' },
          then: Yup.string().required("Campo obbligatorio")
        }),
        showFromFieldName: 'tipologia',
        showFromFieldValue: [ undefined, 0, 5 ]
      },
      {
        'label': 'Persona',
        'name': 'person',
        'type': 'select',
        'values': dipendenti,
        'class': 'form-select',
        // 'validation': Yup.string().required("Campo obbligatorio"),
        'disabled': true
      },
      {
        'label': 'Carta di credito Aziendale',
        'name': 'credit',
        'type': 'select',
        'values': [{ key: 0, value: 'No', defaultValue: 'No' }, { key: 1, value: 'Si' }],
        'class': 'form-select',
        'validation': Yup.string().when("tipologia", {
          is: (value: any) => { return value !== '5' },
          then: Yup.string().required("Campo obbligatorio")
        }),
        showFromFieldName: 'tipologia',
        showFromFieldValue: tipologiaIdArray
      },
      {
        'label': 'Commessa',
        'name': 'joborder',
        'type': 'select',
        'values': commesse,
        'class': 'form-select',
        // 'validation': Yup.string().required("Campo obbligatorio")
      },
      {
        'label': 'Allegati',
        'name': 'attachments',
        'type': 'file',
        'class': 'form-control'
        // 'validation': Yup.string().required("Campo obbligatorio")
      }
    ];
  }