import React from "react";
import Swal from "sweetalert2";
import planningService from '../services/api/planning.service';

type Props = {
    history: {
        push(url: string): void;
    };
    match: {
        params: {
            id: string,
            token: string
        };
    }
};

export default class PlanningEventConfirmPage extends React.Component<Props>  {
    id: string = "";
    token: string = "";

    async componentDidMount() {

        this.id = this.props.match.params.id;
        this.token = this.props.match.params.token;
        const url = "planning/confirmbytoken/" + this.id + '/' + this.token;

        await planningService.confirmByToken(url)
        .then(() => {
            Swal.fire({
                title: 'Operazione eseguita',
                text: "La pianificazione dell'evento è stata confermata.",
                icon: 'success',
            })
            .then(async () => {
                this.props.history.push('/');
            })
        })
        .catch(() => {
            Swal.fire({
                title: 'Operazione non riuscita',
                text: "Si è verificato un errore. Non è stato possibile confermare l'evento.",
                icon: 'error',
            })
            .then(async () => {
                this.props.history.push('/');
            })
        });
    }

    render(): JSX.Element {
        return (<div></div>)
    }
}