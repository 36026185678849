import React, { Component } from 'react';
import Swal from 'sweetalert2';
import EventBus from '../../../common/EventBus';
import ModalForm from '../../../common/ModalForm';
import { iFormField } from '../../../helpers/interfaces/generic';
import { iconsStyle } from '../../../helpers/settings/buttons-icons-styles';
import comuniService from '../../../services/api/database/comuni.service';
import variabilicontrattoService from '../../../services/api/variabilicontratto.service';
import { getEditVariabileContrattoFormFields } from './config/formFields/variabileContratto';

type Props = {
    personId?: number,
    contrattoAttivo: any,
    showEdit?: boolean
};

type State = {
    showModal: boolean,
    modalType: string,
    modalTitle: string,
    formFields: Array<iFormField>,
    formInitialValues: { [key: string]: any },
}

export class InfoVariabileComponent extends Component<Props, State> {

    apiSubmit: typeof variabilicontrattoService.personPut = variabilicontrattoService.personPut

    constructor(props: Props) {
        super(props)

        this.state = {
            showModal: false,
            modalType: 'edit',
            modalTitle: 'Modifica',
            formFields: [],
            formInitialValues: {}
        }
    }

    async openEditModal(id: number) {
        EventBus.dispatch("showLoader", { text: 'Caricamento variabile in corso...' });
        const dettaglioVariabile: any = await variabilicontrattoService.personGet(id);
        if (dettaglioVariabile) {
            const comuni_all = await comuniService.getAllPatrono();
            const comuni: any = comuni_all.map((item: any) => { return { key: item.id, value: item.nome } });

            this.setState(
                {
                    formFields: getEditVariabileContrattoFormFields(comuni),
                    formInitialValues: dettaglioVariabile,
                    showModal: true,
                    modalTitle: 'Modifica variabile contratto'
                }
            );
        }
        EventBus.dispatch("hideLoader");
    }

    closeModal() {
        this.setState({ showModal: false })
    }

    handleSuccess() {
        window.location.hash = "#contratti";
        window.location.reload();
    }

    handleResponse() {
        EventBus.dispatch("hideLoader");
        Swal.fire({
            title: "Operazione completata con successo",
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK',
        }).then(
            async result => {
                this.handleSuccess();
            }
        );
    }

    handleError() {
        EventBus.dispatch("hideLoader");
        Swal.fire(
            'Operazione fallita',
            'error'
        )
    }

    async submitCallback(data: any, formik: any) {
        EventBus.dispatch("showLoader", { text: 'Operazione in corso...' });

        const formData = data

        // edit variabile
        await variabilicontrattoService.personPut(formData.id, formData).then(
            () => this.handleResponse(),
            () => this.handleError()
        )
    }

    render() {
        const { contrattoAttivo, showEdit } = this.props
        const { showModal, modalTitle, modalType, formFields, formInitialValues } = this.state

        const variabileAttiva = contrattoAttivo?.variabili[0]
        return (
            variabileAttiva ? <div className="card mt-2">
                <ModalForm
                    showModal={showModal}
                    title={modalTitle}
                    modalType={modalType}
                    formFields={formFields}
                    initialValues={formInitialValues}
                    apiSubmit={this.apiSubmit}
                    closeCallback={this.closeModal.bind(this)}
                    successAction={this.handleSuccess.bind(this)}
                    submitCallback={this.submitCallback.bind(this)}
                />

                <div className="card-body">
                    <div className='mb-3 d-flex align-items-center justify-content-between'>
                        <h3 className='mb-0'>
                            Variabile contratto attiva
                        </h3>
                        {
                            showEdit && <button className="action-icon custom-icon btn btn-outline-primary rounded-circle" onClick={() => this.openEditModal(variabileAttiva.id)}>
                                <i style={iconsStyle} className="fa fa-pencil" aria-hidden="true"></i>
                            </button>
                        }
                    </div>
                    {
                        variabileAttiva.livello && <div className="mb-2 d-flex justify-content-between">
                            <label><strong>Livello</strong></label>
                            <span>{variabileAttiva.livello}</span>
                        </div>
                    }
                    {
                        variabileAttiva.ral && <div className="mb-2 d-flex justify-content-between">
                            <label><strong>Ral</strong></label>
                            <span>{variabileAttiva.ral} €</span>
                        </div>
                    }
                    {
                        variabileAttiva.indennita && <div className="mb-2 d-flex justify-content-between">
                            <label><strong>Indennità</strong></label>
                            <span>{variabileAttiva.indennita} € ({variabileAttiva.tipo_indennita})</span>
                        </div>
                    }
                    {
                        variabileAttiva.premio && <div className="mb-2 d-flex justify-content-between">
                            <label><strong>Premio</strong></label>
                            <span>{variabileAttiva.premio} €</span>
                        </div>
                    }
                    {
                        variabileAttiva.nota && <div className="mb-2 d-flex justify-content-between">
                            <label><strong>Nota premio</strong></label>
                            <span>{variabileAttiva.nota}</span>
                        </div>
                    }
                    {
                        variabileAttiva.data_aggiornamento && <div className="mb-2 d-flex justify-content-between">
                            <label><strong>Data agg.</strong></label>
                            <span>{variabileAttiva.data_aggiornamento}</span>
                        </div>
                    }
                    {
                        variabileAttiva.comune_patrono && <div className="mb-2 d-flex justify-content-between">
                            <label><strong>Comune patrono</strong></label>
                            <span>{variabileAttiva.comune_patrono}</span>
                        </div>
                    }
                </div>
            </div> : null
        )
    }
}

export default InfoVariabileComponent