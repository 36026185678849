import React, { Component } from "react";
import eventBus from "../../../common/EventBus";
import Table from "../../../common/TableComponent";
import { listGroupColumns } from "../../../config/tableColumns/groups";
import { groupAddPersonResponse, groupDeletePersonRequest, GroupAll, GroupDetails, Subscribers } from "../../../helpers/interfaces/group";
import Swal from "sweetalert2";
import groupsService from "../../../services/api/database/groups.service";
import ModalForm from "../../../common/ModalForm";
import { getNewGroupFormFields } from "../../../config/formFields/groups";
import Modal from "../../../common/Modal";
import { listNotSubscribersGroupColumns, listSubscribersGroupColumns } from "../../../config/tableColumns/groups";
import personService from "../../../services/api/person.service";
import { IPersonForSearch } from "../../../helpers/interfaces/person";
import { iFormField } from "../../../helpers/interfaces/generic";

const $ = require('jquery');

type Props = {};

type State = {
  group: Array<GroupAll | never>;
  subscribers: Array<Subscribers | never>;
  subscribersModal: boolean;
  groupID: number | null;
  notSubscribers: Array<IPersonForSearch>;
  subscribersAdded: Array<number>;
  addModal: boolean;
  editModal: boolean;
  formFields: Array<iFormField>;
  formInitialValues: { [key: string]: any };
}

export default class ElencoGruppi extends Component<Props, State> {

  idGroup: number = 0;

  constructor(props: Props) {
    super(props);

    this.state = {
      group: [],
      subscribers: [],
      subscribersModal: false,
      groupID: null,
      notSubscribers: [],
      subscribersAdded: [],
      addModal: false,
      editModal: false,
      formFields: [],
      formInitialValues: {},
    }
  }

  async componentDidMount() {
    eventBus.dispatch("showLoader", { text: 'Caricamento elenco gruppi in corso...' });

    await this.updateHandler();

    const formFields = getNewGroupFormFields();

    if (formFields) {
      this.setState({ formFields });
    }

    $('#table_group.table').on('click', '.subscribers_btn', async (e: any) => {
      e.preventDefault();
      this.idGroup = $(e.currentTarget).data('id');

      this.loadSubscribers(this.idGroup);

      // const groupID: number = $(e.currentTarget).data('id');
      // const group: GroupDetails = await groupsService.get(groupID);

      // if (group && typeof group.error === 'undefined') {
      //   this.setState({ subscribers: group.subscribers, subscribersModal: true });
      // } else {
      //   Swal.fire({
      //     title: 'Errore',
      //     text: typeof group.error !== 'undefined'
      //       ? group.error
      //       : 'Si è verificato un errore imprevisto e non è stato possibile caricare la lista dei iscritti',
      //     icon: 'error',
      //   })
      // }
    });

    $('#table_group.table').on('click', '.add_btn', async (e: any) => {
      e.preventDefault();

      eventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });

      const persons = await personService.getAllForSearch();
      const groupID: number = $(e.currentTarget).data('id');
      const group: GroupDetails = await groupsService.get(groupID);

      if (persons && persons.data && group && typeof group.error === 'undefined') {
        const notSubscribers: Array<IPersonForSearch | never> = [];
        for (let index = 0; index < persons.data.length; index++) {
          let isSubscribed = false;
          const element = persons.data[index];

          for (let index = 0; index < group.subscribers.length; index++) {
            if (element.id === group.subscribers[index].id) {
              isSubscribed = true;
            }
          }

          if (!isSubscribed) {
            notSubscribers.push(element);
          }
        };

        this.setState({ notSubscribers, groupID, addModal: true });

        eventBus.dispatch("hideLoader");
      } else {
        eventBus.dispatch("hideLoader");

        Swal.fire({
          title: 'Errore',
          text: 'Si è verificato un errore imprevisto e non è stato possibile caricare la lista del personale',
          icon: 'error',
        })
      }
    });

    $('#table_group.table').on('click', '.edit_btn', async (e: any) => {
      e.preventDefault();

      eventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });

      const groupID = $(e.currentTarget).data('id');
      const group: any = await groupsService.get(groupID);

      this.setState({ editModal: true, formInitialValues: group })

      eventBus.dispatch("hideLoader");
    });

    $('#table_group.table').on('click', '.delete_btn', async (e: any) => {
      e.preventDefault();

      Swal.fire({
        title: 'Sei sicuro di voler eliminare questo gruppo?',
        //text: "You won't be able to revert this!",
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#85d630',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Elimina',
        cancelButtonText: 'Annulla'
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            eventBus.dispatch("showLoader", { text: 'Eliminazione del gruppo in corso...' });

            const groupID = $(e.currentTarget).data('id');
            const group: any = await groupsService.delete(groupID);

            eventBus.dispatch("hideLoader");

            if (group && typeof group.error !== 'undefined') {
              Swal.fire(
                group.error,
                '',
                'error'
              );
            } else {
              await Swal.fire({
                title: "Gruppo eliminato con successo",
                icon: "success",
                timer: 2500,
                showConfirmButton: false,
              });

              await this.updateHandler();
            }
          }
        });
    });

    eventBus.dispatch("hideLoader");
  }

  async loadSubscribers(id: number): Promise<void> {
    eventBus.dispatch("showLoader", { text: 'Caricamento elenco iscritti in corso...' });

    const group: GroupDetails = await groupsService.get(id);

    if (group && typeof group.error === 'undefined') {
      eventBus.dispatch("hideLoader");

      this.setState({ subscribers: group.subscribers, subscribersModal: true });
    } else {
      eventBus.dispatch("hideLoader");

      Swal.fire({
        title: 'Errore',
        text: typeof group.error !== 'undefined'
          ? group.error
          : 'Si è verificato un errore imprevisto e non è stato possibile caricare la lista dei iscritti',
        icon: 'error',
      })
    }
  }

  async updateHandler(): Promise<void> {
    eventBus.dispatch("showLoader", { text: 'Caricamento elenco gruppi in corso...' });

    await groupsService.getAll()
      .then((value: Array<GroupAll | never>) => {
        if (value) {
          this.setState({ group: value });
        }
      })
      .catch((error: string) => {
        Swal.fire({
          title: 'Errore',
          text: 'A causa di un errore imprevisto non è stato possibile caricare la lista dei gruppi.',
          icon: 'error',
        })
      });

      eventBus.dispatch("hideLoader");
  }

  subscribers() {
    $('#table_subscribers.table').on('click', '.editTL_btn', async (e: any) => {
      e.preventDefault();
      
      const idEmployee = $(e.currentTarget).data('id');

      const body: any = {
        personid: idEmployee
      }

      await groupsService.setTL(this.idGroup, body)
      .then(async () => {
        this.closeSubscribersModal();
        $('.modal-backdrop').remove();
        this.loadSubscribers(this.idGroup);
      })
      .catch(() => {
        Swal.fire({
          title: 'Errore',
          text: 'Si è verificato un errore imprevisto.',
          icon: 'error',
        })
      });
    });

    $('#table_subscribers.table').on('click', '.editHR_btn', async (e: any) => { // TODO
      e.preventDefault();
      const idEmployee = $(e.currentTarget).data('id');
      
      const body: any = {
        personid: idEmployee
      }

      await groupsService.setHR(this.idGroup, body)
      .then(async () => {
        this.closeSubscribersModal();
        $('.modal-backdrop').remove();
        this.loadSubscribers(this.idGroup);
      })
      .catch(() => {
        Swal.fire({
          title: 'Errore',
          text: 'Si è verificato un errore imprevisto.',
          icon: 'error',
        })
      });
    });

    $('#table_subscribers.table').on('click', '.delete_subscriber_btn', async (e: any) => {
      e.preventDefault();
      Swal.fire({
        title: 'Sei sicuro di voler eliminare questo iscritto dal gruppo?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#85d630',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Elimina',
        cancelButtonText: 'Annulla'
      })
        .then(async (result) => {
          if (result.isConfirmed) {

            const body: groupDeletePersonRequest = {
              group: this.idGroup,
              person: $(e.currentTarget).data('id')
            }
            const group: any = await groupsService.deletePerson(body);

            if (group && typeof group.error !== 'undefined') {
              Swal.fire(
                group.error,
                'Si è verificato un errore.',
                'error'
              );
            } else {
              await Swal.fire({
                title: "Iscritto eliminato con successo",
                icon: "success",
                timer: 2500,
                showConfirmButton: false,
              });

              this.loadSubscribers(this.idGroup);
            }
          }
        });
      });
  }

  addSubscribers() {
    $('#table_addSubscribers.table').on('click', '.add_subscriber_btn', async (e: any) => {
      e.preventDefault();
      let { groupID, subscribersAdded } = this.state;
      const personID: number = $(e.currentTarget).data('id');
      if (groupID && personID && !subscribersAdded.includes(personID)) {
        const addPerson: groupAddPersonResponse & { error: string } = await groupsService.addPerson({ groupID, personID });

        if (addPerson && typeof addPerson.error === 'undefined') {
          subscribersAdded.push(personID);
          // $(e.currentTarget).removeAttribute('href');
          // $(e.currentTarget).style.color = '#888';
          this.setState({ subscribersAdded });
        } else {
          Swal.fire({
            title: 'Errore',
            text: typeof addPerson.error !== 'undefined'
              ? addPerson.error
              : 'Si è verificato un errore imprevisto e non è stato possibile aggiungere questa persona alla lista dei iscritti',
            icon: 'error',
          })
        }
      } else {
        Swal.fire({
          title: 'Errore',
          text: 'Questa persona è già iscritta a questo gruppo',
          icon: 'error',
        })
      }
    });
  }

  closeSubscribersModal() {
    this.setState({ subscribersModal: false });
  }

  closeAddModal() {
    this.setState({ addModal: false, subscribersAdded: [] });
  }

  closeEditModal() {
    this.setState({ editModal: false });
  }

  render(): JSX.Element {
    const {
      group, subscribers, subscribersModal, addModal, notSubscribers, editModal, formFields, formInitialValues
    } = this.state;

    return <React.Fragment>
      <div className="custom-container">
        <div className="card">
          <div className="card-body">
            <h2 className="card-title">Elenco gruppi</h2>
            <Modal
              id="subscribers"
              showModal={subscribersModal}
              title={'Elenco iscritti'}
              closeCallback={this.closeSubscribersModal.bind(this)}
              type={''}
              closeButton
              content={subscribers.length
              ? (
                <Table
                  id="table_subscribers"
                  columns={listSubscribersGroupColumns}
                  columnDefs={null} 
                  datas={subscribers}
                  buttons={null}
                  lengthChange={false}
                  lengthMenu={[10]}
                  didMount={this.subscribers.bind(this)}
                />)
              : (
                <span>Nessun iscritto a questo gruppo</span>
              )
            } />
            <Modal
              id="add_subscribers"
              showModal={addModal}
              title={'Aggiungi iscritti'}
              closeCallback={this.closeAddModal.bind(this)}
              type={''}
              closeButton
              content={
                <>
                  <Table
                    id="table_addSubscribers"
                    columns={listNotSubscribersGroupColumns}
                    columnDefs={null}
                    datas={notSubscribers}
                    buttons={null}
                    lengthChange={false}
                    lengthMenu={[10]}
                    didMount={this.addSubscribers.bind(this)}
                  />
                  {!notSubscribers.length &&
                    <span>Nessuna persona da iscrivere a questo gruppo</span>
                  }
                </>
              }
            />
            <ModalForm
              showModal={editModal}
              title={'Modifica gruppo'}
              modalType={'edit'}
              formFields={formFields}
              initialValues={formInitialValues}
              apiSubmit={groupsService.edit}
              closeCallback={this.closeEditModal.bind(this)}
              successAction={async() => this.updateHandler.bind(this)}
            />
            <Table
              id="table_group"
              columns={listGroupColumns}
              columnDefs={null}
              datas={group}
              buttons={null}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  }
}