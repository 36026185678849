import { AxiosResponse } from 'axios';
import { IPlanningNotifica } from '../../helpers/interfaces/planning-notifica';
import axios from '../api';
const API_URL = process.env.REACT_APP_API_URL;

class PlanningNotificheService {

    getAll(): Promise<IPlanningNotifica[]> {
        return axios.get<IPlanningNotifica[]>(API_URL + 'datore/planning/event', {
        })
          .then(response => { return Array.isArray(response.data) ? response.data : []; })
          .catch((error) => { return Promise.reject(); });
      }

    getOne(id: string): Promise<IPlanningNotifica> {
    return axios.get<IPlanningNotifica>(API_URL + 'datore/planning/event/' + id)
        .then((response: AxiosResponse<IPlanningNotifica>) => { return response.data; })
        .catch((error) => { return Promise.reject(); });
    }

    add(body: { nome: string }): Promise<IPlanningNotifica> {
        return axios.post<{ nome: string }, AxiosResponse<IPlanningNotifica>>(API_URL + 'datore/planning/event', body)
          .then((response: AxiosResponse<IPlanningNotifica>) => { return response.data; })
          .catch((error) => { return Promise.reject(); });
      }

    edit(id: string, body: { nome: string }): Promise<IPlanningNotifica> {
      return axios.put<{ nome: string }, AxiosResponse<IPlanningNotifica>>(API_URL + 'datore/planning/event/' + id, body)
        .then((response: AxiosResponse<IPlanningNotifica>) => { return response.data; })
        .catch((error) => { return Promise.reject(); });
    }

    delete(id: string): Promise<IPlanningNotifica> {
      return axios.delete<{ nome: string }, AxiosResponse<IPlanningNotifica>>(API_URL + 'datore/planning/event/' + id)
        .then((response: AxiosResponse<IPlanningNotifica>) => { return response.data; })
        .catch((error) => { return Promise.reject(); });
    }

    getEventType(): Promise<string[]> {
      return axios.get<string[]>(API_URL + '/datore/planning/eventtype', {
      })
        .then(response => { return Array.isArray(response.data) ? response.data : []; })
        .catch((error) => { return Promise.reject(); });
    }
}

export default new PlanningNotificheService();