import React, { Component } from "react";
import eventBus from "../../../common/EventBus";
import Table from "../../../common/TableComponent";
import { listTimetableColumns, getListTimetableColumnDefs } from "../../../config/tableColumns/timetable";
import Swal from 'sweetalert2';
import { ITimetable } from "../../../helpers/interfaces/timetable";
import timetableService from "../../../services/api/timetable.service";
import { getNewTimetableFormFields } from "../../../config/formFields/timetable";
import { iFormField } from "../../../helpers/interfaces/generic";
import { GroupAll, groupList } from "../../../helpers/interfaces/group";
import authService from "../../../services/auth.service";
import groupsService from "../../../services/api/database/groups.service";
import * as Yup from "yup";
import ModalForm from "../../../common/ModalForm";
import { orderTimetableByDate } from "../../../helpers/settings/date-settings";

const $ = require('jquery');

type Props = {};

type State = {
  scadenzeInScadenza: ITimetable[];
  formFields: Array<iFormField>;
  formInitialValues: {
    deadline: string,
    note: string,
    type: string,
    group?: string
  },
  showModal: boolean,
  modalType: string,
  loading: boolean;
  routes: Array<string | null>;
  groups: Array<groupList>;
}

export default class InScadenza extends Component<Props, State> {

  apiSubmit: typeof timetableService.put = timetableService.put

  constructor(props: Props) {
    super(props);

    this.state = {
      scadenzeInScadenza: [],
      formFields: [],
      loading: false,
      formInitialValues: {
        deadline: '',
        note: '',
        type: ''
      },
      showModal: false,
      modalType: 'edit',
      routes: [],
      groups: []
    }
  }

  async componentDidMount() {
    eventBus.dispatch("showLoader", { text: 'Caricamento elenco in scadenza in corso...' });

    await timetableService.getExpiring()
      .then(value => {
        if (value) {
          this.setState({ scadenzeInScadenza: orderTimetableByDate(value) });
        }
      },
        (error) => {
          Swal.fire({
            title: 'Errore',
            text: error ?? 'A causa di un errore non è stato possibile caricare l\'elenco delle scadenze non gestite.',
            icon: 'error',
          });
        }
      ).catch(() => {
        Swal.fire({
          title: 'Errore imprevisto',
          text: 'A causa di un errore imprevisto non è stato possibile caricare l\'elenco delle scadenze non gestite.',
          icon: 'error',
        });
      });

    $('#table_timetable_expiring.table').on('click', '.managed_btn', async (e: any) => {
      e.preventDefault();
      Swal.fire({
        title: 'Sei sicuro di voler segnalare la scadenza come gestita?',
        //text: "You won't be able to revert this!",
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Conferma',
        cancelButtonText: 'Annulla'
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            const timetableId = $(e.currentTarget).data('id');
            const timetable: any = await timetableService.managed(timetableId);

            if (timetable && typeof timetable.error !== 'undefined') {
              Swal.fire(
                timetable.error,
                '',
                'error'
              );
            } else {
              await Swal.fire({
                title: "Scadenza registrata come gestita",
                icon: "success",
                showConfirmButton: true,
              }).then(
                res => window.location.reload()
              )
            }
          }
        });
    });

    $('#table_timetable_expiring.table').on('click', '.edit_btn', async (e: any) => {
      e.preventDefault();
      eventBus.dispatch("showLoader", { text: 'Caricamento scadenza in corso...' });
      const timetableId = $(e.currentTarget).data('id');
      const timetable = await timetableService.get(timetableId);

      if (timetable) {
        const currentUser = await authService.getCurrentUser();

        if (currentUser) {
          this.setState(
            {
              formInitialValues: timetable,
              routes: currentUser.routes,
              formFields: getNewTimetableFormFields()
            },
            async () => {
              let formFields = this.state.formFields;
              let formInitialValues = this.state.formInitialValues
              if (!currentUser.routes.includes('amministrazione-groups-list')) {
                formFields = formFields.map(field => {
                  if (field.name === 'type') {
                    formInitialValues['type'] = "Private"
                    return { ...field, type: "unset", validation: undefined }
                  } else {
                    return field
                  }
                });
              } else {
                eventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });
                const getGroups: Array<GroupAll> = await groupsService.getAllScadenziario();

                if (getGroups) {
                  let groups = getGroups.map((group: GroupAll) => { return { key: group.id, value: group.nome } });
                  formFields.push(
                    {
                      label: 'Gruppo',
                      name: 'group',
                      type: 'select',
                      values: groups,
                      class: 'form-select',
                      validation: Yup.string().when('type', {
                        is: (value: string) => value === "Group",
                        then: Yup.string().required('Campo obbligatorio')
                      }),
                      showFromFieldName: 'type',
                      showFromFieldValue: ['Group']
                    })

                  let findGroup = groups.find(group => group.value === timetable.group)
                  formInitialValues['group'] = findGroup ? findGroup.key.toString() : ''
                  this.setState({ groups }, () => eventBus.dispatch("hideLoader"));
                }
              }
              this.setState(
                {
                  formFields,
                  formInitialValues,
                  showModal: true
                }
              );
              eventBus.dispatch("hideLoader");
            }
          )
        }
      }
    });

    eventBus.dispatch("hideLoader");
  }

  closeModal() {
    this.setState({ showModal: false })
  }

  render(): JSX.Element {
    const { scadenzeInScadenza, showModal, modalType, formFields, formInitialValues } = this.state;
    return <>
      <ModalForm showModal={showModal} title={'Modifica scadenza'} modalType={modalType} formFields={formFields} initialValues={formInitialValues} apiSubmit={this.apiSubmit} closeCallback={this.closeModal.bind(this)} />
      <Table id="table_timetable_expiring" columns={listTimetableColumns} columnDefs={getListTimetableColumnDefs()} datas={scadenzeInScadenza} buttons={null} />
    </>
  }
}