import React, { Component } from "react";
import Swal from 'sweetalert2';
import EventBus from "../../../common/EventBus";
import ModalForm from '../../../common/ModalForm';
import PermessiFerieService from "../../../services/api/permessiferie.service";
import { listPermessiFerieColumns, getListPermessiFerieColumnDefs } from "../../../config/tableColumns/permessi-ferie";
import { getRefusePermessiFerieFormFields } from "../../../config/formFields/permessiferie";
import Table from '../../../common/TableComponent';
import { iFormField } from "../../../helpers/interfaces/generic";
import { getListPermessiStudioEsamiColumnDefs, listPermessiStudioEsamiColumns } from "../../../config/tableColumns/permessi-ferie";

const $ = require('jquery');

type Props = {};

type State = {
    formFields: Array<iFormField>,
    loading: boolean,
    message: string,
    showModal: boolean,
    modalType: string,
    richieste: any,
    formInitialValues: { [key: string]: any }
}
export default class Valutare extends Component<Props, State> {
    apiSubmit: any;
    buttons: string[];

    constructor(props: Props) {
        super(props);
        this.state = {
            formFields: [],
            richieste: [],
            loading: false,
            message: "",
            showModal: false,
            modalType: 'add',
            formInitialValues: {},
        }
        this.apiSubmit = null;
        this.buttons = [
            //"copy",
            //"csv",
            "excel",
            "pdf",
            "print",
            //"colvis"
        ];
    }

    async componentDidMount() {
        EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });
        const results: any = await PermessiFerieService.getPermessiFerie(1);
        if (results.length > 0) {
            let richieste: any = [];
            results.forEach((value: any, key: any) => {
                if (typeof richieste[value.causale.nome] == 'undefined') { richieste[value.causale.nome] = []; }
                richieste[value.causale.nome].push(value);
            });
            this.setState({ richieste });
        }
        EventBus.dispatch("hideLoader");

        $('.table').on('click', '.delete_btn', async (e: any) => {
            e.preventDefault();
            const idRichiesta = $(e.currentTarget).data('id');
            this.apiSubmit = PermessiFerieService.refuse;
            this.setState({ formFields: getRefusePermessiFerieFormFields(), formInitialValues: { id: idRichiesta, note: '' }, showModal: true, modalType: 'edit' });
        });

        $('.table').on('click', '.confirm_btn', async (e: any) => {
            e.preventDefault();
            Swal.fire({
                title: 'Vuoi confermare la richiesta?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Conferma',
                cancelButtonText: 'Annulla'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    EventBus.dispatch("showLoader", { text: 'Approvazione in corso...' });
                    const idRichiesta = $(e.currentTarget).data('id');

                    await PermessiFerieService.confirm(idRichiesta)
                        .then(
                            result => {
                                EventBus.dispatch("hideLoader");
                                window.location.reload()
                            },
                            () => {
                                EventBus.dispatch("hideLoader");
                                window.location.reload()
                            }
                        );

                    EventBus.dispatch("hideLoader");
                }
            });
        });
    }

    closeModal() {
        this.setState({ showModal: false });
    }

    render() {
        const { formFields, showModal, modalType, richieste, formInitialValues } = this.state;
        return <React.Fragment>
            <div className="row mt-3">
                <ModalForm showModal={showModal} title={'Rifiutata'} modalType={modalType} formFields={formFields} initialValues={formInitialValues} apiSubmit={this.apiSubmit} closeCallback={this.closeModal.bind(this)} />
                {
                    Object.keys(richieste).length > 0 ? Object.keys(richieste).map((type: string, index) => {
                        return <div key={type.replace(/ /g, '_').replace(/\//g, '_')}>
                            <h3 className="card-title">{type.charAt(0).toUpperCase() + type.slice(1).toLowerCase()}</h3>
                            {
                                type.toLowerCase() !== 'permesso studio' && type.toLowerCase() !== 'permesso esami' ?
                                    <Table id={"table_" + type.replace(/ /g, '_').replace(/\//g, '_')} columns={listPermessiFerieColumns} columnDefs={getListPermessiFerieColumnDefs()} datas={richieste[type]} buttons={this.buttons} />
                                    : <Table id={"table_" + type.replace(/ /g, '_').replace(/\//g, '_')} columns={listPermessiStudioEsamiColumns} columnDefs={getListPermessiStudioEsamiColumnDefs()} datas={richieste[type]} buttons={this.buttons} />
                            }
                            {index !== richieste.length - 1 && <hr />}
                        </div>
                    }) : <p>Non sono presenti richieste</p>
                }
            </div>
        </React.Fragment>
    }
}