import React, { Component } from "react";
import EventBus from "../../common/EventBus";
import GestioneRateiService from "../../services/api/gestione-ratei.service";
import { IDipendenteRatei } from '../../helpers/interfaces/user';
import { aggiornaFileRateiFormFields } from '../../config/formFields/gestione-ratei';
import listEmployeesColumnDefs, { listEmployeesColumns } from '../../config/tableColumns/gestione-ratei';
import { Formik, Form, ErrorMessage, FormikProps, Field } from "formik";
import { iFormField } from "../../helpers/interfaces/generic";
import * as Yup from "yup";
import Swal from "sweetalert2";
import Table from '../../common/TableComponent';

interface person { id: number, fullname: string };

type Props = {
};

type State = {
    formFields: Array<iFormField>,
    formInitialValues: { [key: string]: any },
    loading: boolean,
    rateiList: IDipendenteRatei[],
    personale: person[],
    noDataMsg: string | null
}

export default class GestioneRateiComponent extends Component<Props, State>  {
    
    innerRefs: { [key: string]: string } = {};
    
    constructor(props: Props) {
        super(props);
        this.state = {
            formFields: [],
            formInitialValues: {},
            loading: false,
            rateiList: [],
            personale: [],
            noDataMsg: null
        }
    }

    async componentDidMount() {
        EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });

        this.setState({ formFields: aggiornaFileRateiFormFields(), formInitialValues: {} });

        this.updateEmployeesTable();
    }

    updateEmployeesTable() {
        GestioneRateiService.getAll().then(
            (response: any) => {
                EventBus.dispatch("hideLoader");
                if (!response || response.error) {
                    // Swal.fire(
                    //     'Errore.',
                    //     response.error ?? "Non è stato possibile recuperare i dati",
                    //     'error'
                    // );

                    this.setState({noDataMsg: "Non ci sono dati disponibili."})
                } else {
                    this.setState({ rateiList: response });

                    const personsList: person[] = [];

                    response.map((item: IDipendenteRatei) => {
                        let present: boolean = false;
                        personsList.map((elem: person) => {
                            if (item.person.id === elem.id) present = true;
                            return present;
                        });
                        if (!present) personsList.push(item.person);
                        return personsList;
                    });

                    this.setState({ personale: personsList });
                }
            }
        ).catch(
            () => {
                EventBus.dispatch("hideLoader");
                Swal.fire(
                    'Errore.',
                    'Non è stato possibile recuperare i dati a causa di un errore imprevisto',
                    'error'
                );
            }
        );
    }

    handleUpdate(formValue: any, formik: any) {
        EventBus.dispatch("showLoader", { text: 'Salvataggio in corso...' });

        const getFormData = (formValue: any) => Object.keys(formValue).reduce((formData, key) => {
            formData.append(key, formValue[key]);
            formData.append('formato', 'AXIST');
            formData.append('fileType', 'CSV');
            return formData;
        }, new FormData());

        const formData = getFormData(formValue);

        GestioneRateiService.update(formData)
        .then(
            (response: any) => {
                EventBus.dispatch("hideLoader");
                if (!response || response.error) {
                    Swal.fire(
                        'Salvataggio non effettuato.',
                        response.error ?? "",
                        'error'
                    );
                } else {
                    Swal.fire({
                        title: 'Operazione eseguita.',
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'OK',
                    }).then(async () => {
                        EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });
                        this.updateEmployeesTable();
                    });
                }
            }
        ).catch(() => {
            EventBus.dispatch("hideLoader");
            Swal.fire(
                'Salvataggio non effettuato.',
                'Non è stato possibile effettuare il salvataggio a causa di un errore imprevisto',
                'error'
            );
        });
    }

    validationSchema() {
        let validations: any = {};
        this.state.formFields.forEach((value: any, key: any) => (validations[value.name] = value.validation));

        return Yup.object().shape(validations);
    }

    renderField(item: iFormField, key: number, formik: FormikProps<any>) {
        switch (item.type) {
            case 'file':
                return <React.Fragment>
                    <label className={"form-label col-form-label" + (item.labelClass ? ' ' + item.labelClass : '')}>{item.label}</label>
                    <div className="col">
                        <input
                            ref={(el: any) => this.innerRefs[item.name] = el}
                            name={item.name}
                            type={item.type}
                            className={item.class}
                            onChange={(event: any) => {
                                formik.setFieldValue(item.name, event.currentTarget.files[0]);
                            }}
                            title='Select a file'
                            disabled={item.disabled}
                        />
                    </div>
                </React.Fragment>
            default:
                return <React.Fragment>
                    <label className="form-label col-3 col-form-label">{item.label}</label>
                    <div className="col">
                        <Field name={item.name} type={item.type} className={item.class} />
                    </div>
                </React.Fragment>
        }
    }

    render() {
        const { loading, formFields, personale, noDataMsg } = this.state;
        const buttons = ['excel', 'pdf', 'print'];
        let initialValues: any = {};

        return <React.Fragment>
            <Formik
                initialValues={initialValues}
                validationSchema={this.validationSchema.bind(this)}
                onSubmit={this.handleUpdate.bind(this)}
            >
                    {(formik: FormikProps<any>) => {
                        return <Form className="px-3 pt-3">
                            <div className="custom-container">
                                <div className="card">
                                    <div className="card-body">
                                        <h2 className="card-title">Gestione ratei</h2>

                                        {formFields && formFields.map((item: iFormField, key: number) => {
                                            return <div className="form-group mt-5 mb-3 row" key={key}>

                                                {this.renderField(item, key, formik)}

                                                <ErrorMessage
                                                    name={item.name}
                                                    component="div"
                                                    className="alert alert-danger"
                                                />
                                            </div>
                                        })}
                                        <div className="d-flex justify-content-end">
                                            <button type="submit" className="btn btn-primary" disabled={loading}>
                                                {loading && (
                                                    <span className="spinner-border spinner-border-sm me-1"></span>
                                                )}
                                                <span>Carica file</span>
                                            </button>
                                        </div>

                                        <div className="mt-5">
                                            {personale.length > 0 && 
                                                <Table id="table_elenco-dipendenti" columns={listEmployeesColumns} columnDefs={listEmployeesColumnDefs()} datas={personale} buttons={buttons} />}
                                        </div>

                                        {noDataMsg && <div className="d-flex align-items-center justify-content-center bg-danger p-2"><h3 className="m-0 me-2">Attenzione!</h3> {noDataMsg}</div>}

                                    </div>
                                </div>
                            </div>
                        </Form>
                    }}
            </Formik>
        </React.Fragment>
    }
}

