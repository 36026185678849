import { Component, ReactNode } from "react";
//import "bootstrap/dist/css/bootstrap.min.css";

import moment from "moment";
import 'moment/locale/it';
import Swal from "sweetalert2";
import { history } from '../helpers/history';
import { IRoute } from "../helpers/interfaces/generic";
import { IStatusNotifica } from "../helpers/interfaces/notifica";
import { IPersonForSearch } from "../helpers/interfaces/person";
import { ITimetable } from "../helpers/interfaces/timetable";
import { iconsStyle, resetButtonsStyle } from "../helpers/settings/buttons-icons-styles";
import { orderTimetableByDate } from "../helpers/settings/date-settings";
import Routes from '../routes/routes.json';
import notificheService from "../services/api/notifiche.service";
import personService from "../services/api/person.service";
import timetableService from "../services/api/timetable.service";
import AuthService from "../services/auth.service";
import IUser from '../types/user.type';

interface Props {
  navBar: Array<NavBarList>;
};

interface State {
  currentUser: IUser | undefined;
  isAdmin: boolean;
  people: IPersonForSearch[];
  filteredBySearch: IPersonForSearch[];
  bellButton: { count: number };
  notifications: IStatusNotifica[];
  scadenze: ITimetable[];
  navBar: Array<NavBarList>;
}

interface NavBarList {
  id: string;
  name: string;
  label?: string;
  position: number;
  path: string;
  exact: boolean;
  title?: string;
  to?: string;
  alias?: string | Array<string>;
  hidden?: boolean;
  elements?: Array<NavBarElement>;
  dropdown?: boolean;
  link?: string;
}

interface NavBarElement {
  id?: string;
  name: string;
  label?: string;
  position: number;
  path: string;
  exact: boolean;
  to?: string;
  alias?: string;
  hidden?: boolean;
  topDivider?: boolean;
  bottomDivider?: boolean;
  isDropend?: boolean;
  dropend?: Array<NavBarElement>;
}

class NavigationBar extends Component<Props, State> {

  dropdownRef: (HTMLButtonElement | null)[] = []
  dropdownMenuRef: (HTMLElement | null)[] = []

  constructor(props: Props) {
    super(props);

    this.state = {
      isAdmin: false,
      currentUser: undefined,
      people: [],
      filteredBySearch: [],
      bellButton: { count: 0 },
      notifications: [],
      scadenze: [],
      navBar: []
    };
  }

  async componentDidMount() {
    let { navBar } = this.props

    const currentUser = await AuthService.getCurrentUser();
    const isAdmin = await AuthService.getIsAdmin();

    if (currentUser) {
      this.setState(
        {
          currentUser,
          isAdmin
        },
        async () => {
          const { isAdmin } = this.state

          const notifichePromise = notificheService.getAllForMenu()
          const scadenzePromise = timetableService.getExpiring()

          // setting notifications & deadlines
          await Promise.all([notifichePromise, scadenzePromise])
            .then(
              response => this.setState(
                {
                  bellButton: response[0],
                  scadenze: response[1].length > 0 ? orderTimetableByDate(response[1]) : []
                },
                () => {
                  if (this.state.bellButton.count !== 0) {
                    let notifications: IStatusNotifica[] = []
                    Object.values(response[0].badge).forEach(n =>
                      notifications = notifications.concat(
                        n.map(
                          el => { return { ...el, message: JSON.parse(el.message).data[0] } }
                        )
                      )
                    )
                    this.setState({ notifications: notifications.slice(0, 5) })
                  }
                }
              )
            )

          if (isAdmin !== undefined) {
            // setting the profiles' search bar
            await personService.getAllForSearchDatore()
              .then(
                response => {
                  this.setState({
                    people: response.data,
                    filteredBySearch: response.data
                  })
                }
              )
          }
        }
      );
    }

    navBar = this.mergeRoute(navBar);

    this.setState({ navBar })
  }

  logOut() {
    AuthService.logout();
    this && this.setState({ currentUser: undefined })
  }

  mergeRoute(navBar: Array<NavBarList>) {
    navBar && navBar.forEach((value, index) => {
      Routes && Routes.forEach((route: IRoute, key) => {
        if (route.navbar && route.navbar === value.id) {
          if (!navBar[index].elements) navBar[index].elements = [];
          navBar[index].elements && navBar[index].elements?.push({
            path: route.path,
            exact: route.exact,
            name: route.name,
            label: route.label ? route.label : route.name ?? '',
            position: route.navbarPosition ?? key,
            alias: route.alias,
            // topDivider: route.topDivider ?? false,
            bottomDivider: route.bottomDivider ?? false,
            hidden: route.hidden ?? false,
          });
        }

        value.elements && value.elements.forEach((element, key) => {
          if (element.isDropend) {
            if (route.navbar && element.id && route.navbar === element.id) {
              if (!element.dropend) element.dropend = [];
              element.dropend?.push({
                path: route.path,
                exact: route.exact,
                name: route.name,
                label: route.label,
                position: route.navbarPosition ?? key,
                alias: route.alias,
                // topDivider: route.topDivider ?? false,
                bottomDivider: route.bottomDivider ?? false,
                hidden: route.hidden ?? false,
              });
            }
          }
        });
      });

      value.elements && value.elements.forEach((element, k) => {
        if (element.isDropend) {
          if (!element.dropend || (element.dropend && element.dropend?.length === 0)) {
            navBar[index].elements = navBar[index].elements?.filter(item => { return (item.id !== element.id) });
          }

          if (element.dropend && element.dropend?.length) {
            element.dropend?.sort((a, b) => a.position > b.position ? 1 : -1);
          }
        }
      })
    });

    Routes && Routes.forEach((route: IRoute, key: number) => {
      if (!route.navbar && route.navbarRoot === true) {
        navBar.push({
          id: route.name.toLowerCase(),
          name: route.name,
          label: route.label,
          position: route.navbarPosition ? route.navbarPosition : key,
          path: route.path,
          exact: route.exact,
          alias: route.alias,
          link: route.path,
          dropdown: route.dropdown,
          hidden: route.hidden ?? false,
        })
      }
    });

    return navBar;
  }

  getNavList(navBarList: Array<NavBarList>) {
    const { currentUser } = this.state;

    let navBarItems: NavBarList[] = [];
    navBarList && navBarList.forEach((value: NavBarList, index: number) => {
      let aliasAllowed = false;

      value.alias instanceof Array && value.alias.forEach(alias => {
        if (currentUser?.routes.includes(alias)) aliasAllowed = true;
      });

      if (typeof value.alias === 'string' && currentUser?.routes.includes(value.alias)) aliasAllowed = true;

      if (!value.hidden && (!value.alias || aliasAllowed)) {
        navBarItems.push(value);
      }
    });

    let result: Array<NavBarList> = [];
    navBarItems && navBarItems.forEach((value: NavBarList, index: number) => {
      if (value.elements?.length) {
        value.elements?.forEach((element: NavBarElement, key: number) => {
          if (!element.hidden && (element.alias === undefined || (currentUser?.routes && currentUser.routes.includes(element.alias)))) {
            if (!result[index]) {
              result[index] = { ...value, elements: [] };
            }

            let elementDropend: Array<NavBarElement> = [];
            element.dropend && element.dropend.forEach(dropValue => {
              if (dropValue.alias === undefined || (currentUser?.routes && currentUser.routes.includes(dropValue.alias))) {
                elementDropend?.push(dropValue);
              }
            });

            elementDropend.length && elementDropend.sort((a, b) => a.position > b.position ? 1 : -1);
            if (elementDropend.length) {
              element.dropend = elementDropend
            } else {
              delete element.dropend;
            }

            if (element.isDropend) {
              if (!element.dropend || (element.dropend && element.dropend?.length === 0)) {
                delete result[index];
              }

              if (element.dropend && element.dropend?.length) {
                element.dropend?.sort((a, b) => a.position > b.position ? 1 : -1);
              }
            }

            (element && result[index]) && result[index].elements?.push(element);
          }
        })
      } else {
        if (!result[index]) {
          result[index] = { ...value };
        }
      }

      result[index] && result[index]?.elements?.sort((a, b) => a.position > b.position ? 1 : -1);
    });

    result = result.filter((value) => typeof value !== 'undefined');

    if (result.length === 1) {
      result = result[0].elements ? result[0].elements.map(
        (element: NavBarElement, index: number) => {
          return {
            id: element.id ?? element.alias ?? `${result[0].id}_${index.toString()}`,
            name: element.name,
            position: element.position,
            path: element.path,
            exact: element.exact,
            label: element.label,
            alias: element.alias,
            to: element.to ?? element.path,
            elements: [],
            link: element.path,
            dropdown: element.dropend?.length ? element.dropend.length > 0 : false,
            hidden: element.hidden ?? false,
          }
        }
      ) : [];
    }

    return result.sort((a, b) => a.position > b.position ? 1 : -1);
  }

  getDropend(dropend: Array<NavBarElement>, index: number) {
    return dropend?.length && dropend.map((element, key) => {
      return <span key={key}>
        {element.topDivider === true && <div className="dropdown-divider"></div>}
        {element.dropend && element.dropend?.length ? (
          <div className="dropend">
            <button className="btn-link dropdown-item dropdown-toggle" data-bs-toggle="dropdown" data-bs-auto-close="inside" onClick={e => this.handleHistory(element.path ? element.path : "./#")}>
              {element.name}
            </button>
            <div className="dropdown-menu" data-bs-popper="none">
              {this.getDropend(element.dropend, index)}
            </div>
          </div>
        ) : (
          <button className="btn-link dropdown-item" onClick={e => this.handleHistory(element.path, index)}>{element.label}</button>
        )}
        {element.bottomDivider === true && <div className="dropdown-divider"></div>}
      </span>
    })
  }

  handleSearchClick(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.value !== "") {
      const filtered = this.state.people.filter((person) => {
        const value = event.target.value.replaceAll(/\s/g, "").toLowerCase()
        const name = (person.name.replaceAll(/\s/g, "").toLowerCase() + person.lastname.replaceAll(/\s/g, "").toLowerCase()).toLowerCase();
        const nameAlternative = (person.lastname.replaceAll(/\s/g, "").toLowerCase() + person.name.replaceAll(/\s/g, "").toLowerCase()).toLowerCase();

        return value && (name.includes(value) || nameAlternative.includes(value))
      })

      this.setState({
        filteredBySearch: filtered
      })
    } else {
      this.setState({
        filteredBySearch: this.state.people
      })
    }
  }

  async handleReadNotifica(id: number) {
    const { notifications, bellButton } = this.state
    await notificheService.edit({ readed: [id] }).then(
      response => {
        if (window.location.pathname.includes("notifiche"))
          window.location.reload()
        else {
          let findNotifica = notifications.find(n => n.id === id)
          if (findNotifica !== undefined) {
            notifications.splice(notifications.indexOf(findNotifica), 1)
            this.setState({
              notifications,
              bellButton: { count: bellButton.count - 1 }
            })
          }
        }
      }
    )
  }

  async handleManageDeadline(id: number) {
    const { scadenze } = this.state
    Swal.fire({
      title: 'Sei sicuro di voler segnalare la scadenza come gestita?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Conferma',
      cancelButtonText: 'Annulla'
    }).then(async (result) => {
      if (result.isConfirmed) {
        await timetableService.managed(id)
          .then(
            response => {
              if (window.location.pathname.includes("scadenzario"))
                window.location.reload()
              else {
                let findScadenza = scadenze.find(n => n.id === id)
                if (findScadenza !== undefined) {
                  scadenze.splice(scadenze.indexOf(findScadenza), 1)
                  this.setState({ scadenze })
                }
              }
            }
          )
      }
    });
  }

  hasElements(value: NavBarList): boolean {
    let count = 0;
    if (value.elements?.length) {
      value.elements.forEach(element => {
        if (element.dropend) {
          count += this.hasDropdown(element.dropend);
        } else {
          count++;
        }
      });
    }

    if (value.link) {
      count++;
    }

    return count > 0;
  }

  hasDropdown(value: NavBarElement[]): number {
    let count = 0;
    value.forEach(element => {
      if (element.dropend) {
        count += this.hasDropdown(element.dropend);
      } else {
        count++;
      }
    });

    return count;
  }

  renderScadenze() {
    const { scadenze } = this.state
    return <div className="nav-item dropdown d-flex me-2">
      <button style={resetButtonsStyle} className="nav-link px-0" data-bs-toggle="dropdown" aria-label="Show notifications" aria-expanded="false">
        <i style={iconsStyle} className="fa fa-calendar-check-o" aria-hidden="true"></i>
        {
          Array.isArray(scadenze) && scadenze.length !== 0 ? <span className="badge bg-red rounded-circle">{scadenze.length}</span> : null
        }
      </button>
      <div className="dropdown-custom dropdown-menu dropdown-menu-end dropdown-menu-arrow dropdown-menu-card">
        {
          Array.isArray(scadenze) && scadenze.length !== 0 ?
            <div className="list-group">
              {
                Array.isArray(scadenze) && scadenze.slice(0, 5).map(s => <li key={s.id} className="col-12 list-group-item d-flex align-items-center active">
                  <span className="col-10"><span className="badge bg-primary">{moment(s.deadline).format('DD/MM/YY')}</span> {s.note}</span>
                  <div className="d-flex justify-content-end col-2">
                    <button className="custom-icon btn btn-outline-success rounded-circle" onClick={() => this.handleManageDeadline(s.id)}>
                      <i style={{ fontSize: '18px' }} className="fa fa fa-check" aria-hidden="true"></i>
                    </button>
                  </div>
                </li>)
              }
            </div>
            : <span className="list-group-item text-center">Nessuna scadenza imminente</span>
        }
        <li className="nav-item">
          <button className="btn-link card-btn" onClick={e => this.handleHistory('/scadenzario')}>
            {
              scadenze.length > 5 ? 'Visualizza lo scadenzario (+' + (scadenze.length - 5) + ')' : 'Visualizza lo scadenzario'
            }
          </button>
        </li>
      </div>
    </div>
  }

  renderSearch() {
    const { filteredBySearch } = this.state
    let inputRef: HTMLInputElement | null = null

    return <div className="nav-item dropdown me-2">
      <button style={resetButtonsStyle} onClick={() => inputRef?.focus()} className="nav-link px-0" data-bs-toggle="dropdown" aria-label="Select profile">
        <i style={iconsStyle} className="fa fa-search me-1" aria-hidden="true"></i>
      </button>
      <div className="dropdown-custom dropdown-menu dropdown-menu-end dropdown-menu-arrow dropdown-menu-card">
        <div className='p-2'>
          <input ref={(input) => inputRef = input} type="text" className="form-control-light w-100" placeholder="Cerca un profilo" onChange={event => this.handleSearchClick(event)} />
        </div>
        <div className="search-list">
          {
            filteredBySearch.length > 0 && filteredBySearch.map(
              person => <button className="btn-link dropdown-item" key={person.id} onClick={e => this.handleHistory("/organico/dettaglio-personale", undefined, person.id.toString())}>
                <div className="d-flex align-items-center w-100">
                  <i style={{ fontSize: '16px' }} className={'fa fa-circle me-2 ' + (person.status === 'E' || person.status === 'N' ? 'text-success rounded' : (person.status === 'S' ? 'text-warning rounded' : 'text-danger rounded'))}></i>
                  <span className="text-truncate">{person.lastname} {person.name}</span>
                </div>
              </button>
            )
          }
          {
            filteredBySearch.length === 0 && <span className="dropdown-item">Nessun risultato</span>
          }
        </div>
      </div>
    </div>
  }

  renderNotifications() {
    const { notifications, bellButton } = this.state
    return <div className="nav-item dropdown d-flex me-2">
      <button style={resetButtonsStyle} className="nav-link px-0" data-bs-toggle="dropdown" aria-label="Show notifications">
        <i style={iconsStyle} className="fa fa-bell-o" aria-hidden="true"></i>
        {
          bellButton.count !== 0 ? <span className="badge bg-red rounded-circle">{bellButton.count}</span> : null
        }
      </button>
      <div className="dropdown-custom dropdown-menu dropdown-menu-end dropdown-menu-arrow dropdown-menu-card">
        {
          notifications.length !== 0 ?
            <div className="list-group">
              {
                notifications.map(n => <li key={n.id} className="col-12 list-group-item d-flex align-items-center active">
                  <span className="col-10">{n.message}</span>
                  <div className="d-flex justify-content-end col-2">
                    <button className="custom-icon btn btn-outline-success rounded-circle" onClick={() => this.handleReadNotifica(n.id)}>
                      <i style={{ fontSize: '18px' }} className="fa fa fa-check" aria-hidden="true"></i>
                    </button>
                  </div>
                </li>)
              }
            </div>
            : <span className="list-group-item text-center">Nessuna nuova notifica</span>
        }
        <li className="nav-item">
          <button className="btn-link card-btn" onClick={e => this.handleHistory("/notifiche")}>
            {
              notifications.length > 5 ? 'Visualizza tutte le notifiche (+' + (notifications.length - 5) + ')' : 'Visualizza tutte le notifiche'
            }
          </button>
        </li>
      </div>
    </div>
  }

  handleHistory(path: string = '', index?: number, id?: string) {
    if (index !== undefined) {
      this.dropdownRef[index]?.classList.remove("show")
      this.dropdownMenuRef[index]?.classList.remove("show")
    }
    history.push(`${path}/${id !== undefined ? id : ''}`);
    if (id !== undefined)
      window.location.reload();
  }

  render() {
    const { currentUser, isAdmin, navBar } = this.state;
    const navBarList: Array<NavBarList> = currentUser ? this.getNavList(navBar) : [];

    return (
      currentUser ? (
        <header className="navbar navbar-expand-xl navbar-dark bg-primary d-print-none">
          <div style={{ padding: "0 24px" }} className="custom-container d-xl-flex align-items-xl-center">
            <button className="btn-link d-xl-block d-none align-items-center p-0" onClick={e => this.handleHistory(isAdmin ? "/amministrazione" : "/dipendente/home")}>
              <img className="logo" width="96px" src="/assets/ritz_logo_small.png" alt="ritz-nav-logo" />
            </button>
            <div className="navbar-nav d-none d-xl-flex flex-row order-xl-last justify-content-between">
              {
                isAdmin && this.renderSearch()
              }
              {
                this.renderNotifications()
              }
              {
                this.renderScadenze()
              }
              <div className="nav-item dropdown">
                <button style={resetButtonsStyle} className="nav-link opacity-90 bg-light d-flex lh-1 px-2 py-1" title={currentUser.name + ' ' + currentUser.lastname} data-bs-toggle="dropdown" aria-label="Open user menu">
                  <img className="logo" width="96px" src={currentUser.businessunit.logourl} alt="businessunit-nav-logo" />
                  <i style={iconsStyle} className="fa fa-user-circle-o text-blue fs-1" aria-hidden="true"></i>
                </button>
                <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                  <button className="btn-link dropdown-item" onClick={e => this.handleHistory("/profile")}>Profilo</button>
                  <div className="dropdown-divider"></div>
                  <button style={resetButtonsStyle} className="dropdown-item" onClick={this.logOut}>Logout</button>
                </div>
              </div>
            </div>
            <div className="w-100 d-flex d-xl-none justify-content-between align-items-center">
              <div className="d-flex user-select-none">
                <button className="navbar-toggler me-2" data-bs-toggle="collapse" data-bs-target="#navbar-menu">
                  <span className="navbar-toggler-icon"></span>
                </button>
                <button className="btn-link d-flex d-xl-none align-items-center" onClick={e => this.handleHistory(isAdmin ? "/amministrazione" : "/dipendente/home")}>
                  <img className="logo" width="96px" src="/assets/ritz_logo_small.png" alt="ritz-nav-logo" />
                </button>
              </div>
              <div className="navbar-nav flex-row order-xl-last justify-content-between">
                {
                  isAdmin && this.renderSearch()
                }
                {
                  this.renderNotifications()
                }
                {
                  this.renderScadenze()
                }
                <div className="nav-item dropdown">
                  <button style={resetButtonsStyle} className="nav-link rounded d-flex lh-1 p-0" data-bs-toggle="dropdown" aria-label="Open user menu">
                    <i style={iconsStyle} className="fa fa-user-circle-o" aria-hidden="true"></i>
                  </button>
                  <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                    <div className="d-flex justify-content-center">
                      <img className="logo" width="96px" src={currentUser.businessunit.logourl} alt="businessunit-nav-logo" />
                    </div>
                    <div className="dropdown-divider"></div>
                    <button className="btn-link dropdown-item" onClick={e => this.handleHistory("/profile")}>Profilo</button>
                    <div className="dropdown-divider"></div>
                    <button style={resetButtonsStyle} className="dropdown-item" onClick={this.logOut}>Logout</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="collapse navbar-collapse" id="navbar-menu">
              <div className="d-flex flex-column flex-xl-row flex-fill align-items-stretch align-items-xl-center">
                <ul className="navbar-nav">
                  {navBarList && navBarList.map((value, index): ReactNode => {
                    return this.hasElements(value) && (
                      <li key={index} className="nav-item dropdown">
                        {value.dropdown === false ? (
                          <button className="btn-link nav-link border-0" onClick={e => this.handleHistory(value.link)}>
                            <span className="nav-link-title">
                              {value.label}
                            </span>
                          </button>
                        ) : (
                          <>
                            <button ref={ref => this.dropdownRef[index] = ref} className="btn-link nav-link border-0 dropdown-toggle" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                              <span className="nav-link-title">
                                {value.name}
                              </span>
                            </button>
                            <div ref={ref => this.dropdownMenuRef[index] = ref} className="dropdown-menu">
                              {value.elements && value.elements.map((element: NavBarElement, i: number): ReactNode => {
                                return (
                                  <span key={i}>
                                    {element.topDivider === true && <div className="dropdown-divider"></div>}
                                    {element.dropend && element.dropend?.length ? (
                                      <div key={index} className="dropend">
                                        <button className="btn-link dropdown-item dropdown-toggle" data-bs-toggle="dropdown" data-bs-auto-close="true" aria-expanded="false">
                                          {element.name}
                                        </button>
                                        <div className="dropdown-menu" data-bs-popper="none">
                                          {this.getDropend(element.dropend, index)}
                                        </div>
                                      </div>
                                    ) : (
                                      <button className="btn-link dropdown-item" onClick={e => this.handleHistory(element.path, index)}>{element.label}</button>
                                    )}
                                    {element.bottomDivider === true && <div className="dropdown-divider"></div>}
                                  </span>
                                )
                              }
                              )}
                            </div>
                          </>
                        )}
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </div>
        </header>
      ) : ('')
    );
  }
}
export default NavigationBar;