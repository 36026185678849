import { iFormField, iFormFieldValue } from "../../../helpers/interfaces/generic";
import * as Yup from 'yup';

export interface IStatoStrumentiForm {
    nome: string;
    censimento: string;
}

const genericOptions: iFormFieldValue[] = [
    {
        key: 0,
        value: "No"
    },
    {
        key: 1,
        value: "Sì"
    }
]

const requiredMessage = 'Campo obbligatorio'

// function: setting form fields for devices' add/edit page
export function statoStrumentiFormFields(): iFormField[] {
    return [
        {
            label: 'Nome',
            name: 'nome',
            type: 'text',
            class: 'form-control',
            validation: Yup.string().required(requiredMessage)
        },
        {
            label: 'Censimento',
            name: 'censimento',
            type: 'select',
            class: 'form-control',
            values: genericOptions
        }
    ];
}