import { iFormField } from "../../../helpers/interfaces/generic"
import * as Yup from 'yup';

export interface IProdottoForm {
    produttore: string,
    nome: string
}

const requiredMessage = 'Campo obbligatorio'

// function: setting form fields for devices' add/edit page
export function prodottiFormFields(): iFormField[] {
    return [
        {
            label: 'Produttore',
            name: 'produttore',
            type: 'text',
            class: 'form-control',
            validation: Yup.string().required(requiredMessage)
        },
        {
            label: 'Nome',
            name: 'nome',
            type: 'text',
            class: 'form-control',
            validation: Yup.string().required(requiredMessage)
        }
    ]
}