import { ConfigColumnDefs, ConfigColumns } from "datatables.net";
import { generatePath } from "react-router-dom";
import { AttachmentType } from '../../helpers/interfaces/note-spese';

export const listNoteSpeseColumns: ConfigColumns[] = [
    { title: "Azioni", data: "id" },
    {
        "title": "Tipologia", "data": null, render: (data: any, type: any, row: any) => {
            return row.tipologia.nome
        }
    },
    {
        "title": "Ammontare", "data": null, render: (data: any, type: any, row: any) => {
            return Number(row.amount).toFixed(2)
        }
    },
    { title: "Data", data: "date" },
    {
        "title": "Credito", "data": null, render: (data: any, type: any, row: any) => {
            return row.creditcard ? "Si" : "No"
        }
    },
    {
        "title": "km", "data": null, render: (data: any, type: any, row: any) => {
            return Number(row.km).toFixed(2)
        }
    },
    { title: "Tragitto", data: "tragitto" },
    {
        "title": "Commessa", "data": null, render: (data: any, type: any, row: any) => {
            const name = row?.joborder?.name ? row.joborder.name : '';
            const code = row?.joborder?.code ? ' - ' + row.joborder.code : '';
            return name + code
        }
    },
    {
        "title": "File", "data": null, render: (data: any, type: any, row: any) => {
            let attachments: any = [];
            // row.attachments.map((item: any) => attachments.push(item.path));
            row.attachments.map((item: AttachmentType) => {
                attachments.push('<a href="#" class="download_link" data-id="' + item.id + '"> ' + item.path + '</a>')
                return attachments
            });
            return attachments
        }
    }
];

export default function getNoteSpeseColumnDefs(): ConfigColumnDefs[] {
    return [
        {
            target: 0,
            render: function (id: any, data: any, type: any, row: any) {
                const url = generatePath("/dipendente/note-spese/note-spese-dettaglio/:id", { id })

                const edit = `<a class="custom-icon btn btn-outline-primary rounded-circle me-1" href="${url}" data-id="'+${id}+'"> 
                    <i style="font-size: 18px" class="fa fa-pencil" aria-hidden="true"></i>
                </a>`;

                // const edit = `<a class="edit_btn custom-icon btn btn-outline-primary rounded-circle me-1" data-id="${data}"><i style="font-size: 18px" class="fa fa-pencil" aria-hidden="true"></i></a>`;

                const trash = `<a class="delete_btn custom-icon btn btn-outline-danger rounded-circle me-1" data-id="${id}">
                    <i style="font-size: 18px" class="fa fa-trash" aria-hidden="true"></i>
                </a>`;

                return '<div class="d-flex">' + edit + trash + '</div>';
            },
        }
    ];
}
