import { ConfigColumnDefs, ConfigColumns } from "datatables.net";

export const listStatoRITColumns: ConfigColumns[] = [
    { "title": "Azioni", "data": "id" },
    { "title": "Nome", "data": "name" },
    { "title": "Code", "data": "code" }
];

export function getListStatoRITColumnDefs(): ConfigColumnDefs[] {
    return [
        {
            target: 0,
            //data: null,
            render: function (data: any, type: any, row: any) {
                return `<a class="edit_btn custom-icon btn btn-outline-primary rounded-circle" data-id="${data}"><i style="font-size: 18px" class="fa fa-pencil" aria-hidden="true"></i></a>`;;
            },
        },
    ];
}