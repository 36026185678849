import { ConfigColumnDefs, ConfigColumns } from "datatables.net";
import { NomineIssuer, NomineList, NominePerson, NomineSollecitiList } from "../../helpers/interfaces/nomine";

export const listNomineColumns: ConfigColumns[] = [
  { title: "", data: "id" },
  { title: "Inviata da", data: "person"},
  { title: "Emittente", data: "issuer"},
  { title: "Inviato il", data: "sentdate"},
  { title: "Ricevuto il", data: "receivedate"},
  { title: "Stato", data: "status"},
];

export function getNomineColumnDefs(): ConfigColumnDefs[] {
  return [
    {
      target: 0,
      render: (data: number, type: string, row: NomineList) => {
        return '<div class="d-flex"><input type="checkbox" name="ids[]" value="' + data + '" /></div>';
      },
    },
    {
      target: 1,
      render: (data: NominePerson, type: string, row: NomineList) => {
        return '<div class="d-flex">' + data.name + ' ' + data.lastname + '</div>';
      },
    },
    {
      target: 2,
      render: (data: NomineIssuer, type: string, row: NomineList) => {
        return '<div class="d-flex">' + data.name + ' ' + data.lastname + '</div>';
      },
    },
  ]
}

export const listSollecitiNomineColumns: ConfigColumns[] = [
  { title: "Inviata da", data: "person"},
  { title: "Emittente", data: "issuer"},
  { title: "Inviato il", data: "sentdate"},
  { title: "Ricevuto il", data: "receivedate", defaultContent: "-"},
  { title: "Stato", data: "status"},
  { title: "Tag", data: "tag"}
];

export function getSollecitiNomineColumnDefs(): ConfigColumnDefs[] {
  return [
    {
      target: 0,
      render: (data: NominePerson, type: string, row: NomineSollecitiList) => {
        return '<div class="d-flex">' + data.name + ' ' + data.lastname + '</div>';
      },
    },
    {
      target: 1,
      render: (data: NomineIssuer, type: string, row: NomineSollecitiList) => {
        return '<div class="d-flex">' + data.name + ' ' + data.lastname + '</div>';
      },
    },
  ]
}
