import React from 'react';
import { Tab, TabProp } from '../../common/TabComponent';
import eventBus from '../../common/EventBus';
import adminService from '../../services/api/admin.service';
import { AdminAuthorization, AdminRoute } from '../../helpers/interfaces/admin';
import utentiService from '../../services/api/utenti.service';
import { IRuoloUtente } from '../../helpers/interfaces/utente';
import Table from '../../common/TableComponent';
import { getAdminAuthorizationsColumnDefs, listAdminAuthorizationsColumns } from '../../config/tableColumns/admin/admin-authorizations';
import $ from 'jquery';
import Swal from 'sweetalert2';

type Props = {};

type State = {
  personRoles: IRuoloUtente[]
  adminAuthorizations: AdminAuthorization;
  adminRoutes: AdminRoute[];
  tabs: TabProp[];
  currentTab: number;
  role: { id: number, code: string };
  data: { [key: string]: number[] };
  changes: number[];
};

export default class ElencoAutorizzazioni extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      personRoles: [],
      adminAuthorizations: {},
      adminRoutes: [],
      tabs: [],
      currentTab: 0,
      role: { id: -1, code: '' },
      data: {},
      changes: [],
    }
  }

  async componentDidMount(): Promise<void> {
    await this.updateHandler().then(() => {
      if (this.state.personRoles[0]) {
        this.setState({
          role: {
            id: this.state.personRoles[0].id,
            code: this.state.personRoles[0].code
          }
        });
      }
    });

    $('div.authorization_container').on('change', 'input[type=checkbox]', async (e) => {
      e.preventDefault();
      let { data, changes } = this.state;
      const id = $(e.currentTarget).data('id');;
      const role = $(e.currentTarget).data('role');;
      const checked = $(e.currentTarget).is(':checked');

      if (id && role && data[role]) {
        const index = data[role].findIndex(elem => {
          return elem === id;
        })

        if (checked && !data[role][index]) {
          data[role].push(id);
        } else if (!checked && data[role][index]) {
          data[role] = data[role].filter((value: number, key: number) => {
            return key !== index
          });
        }

        const changeIndex = changes.findIndex(value => {
          return value === id
        });

        if (changeIndex === -1) {
          changes.push(id);
        } else {
          changes = changes.filter((value: number, key: number) => {
            return key !== changeIndex
          });
        }
      }

      this.setState({ data, changes });
    });

    $('div.authorization_container').on('click', '.save_btn', async (e: any) => {
      e.preventDefault();

      const { data, role } = this.state;
      const id = role?.id;
      const code = role?.code;

      if (!id || !code || (code && typeof data[code] === 'undefined')) {
        Swal.fire('Errore', 'Codice ruolo non trovato oppure non valido', 'error');
      } else {
        const putData = data[code];
        await adminService.putAuthorizations(id, { routes: putData }).then(async (response) => {
          if (typeof response.error === 'undefined') {
            this.setState({changes: []}, async () => await this.updateHandler());
          } else {
            Swal.fire('Errore', 'Si è verificato un errore durante il salvataggio dei dati', 'error');
          }
        },
          error => Swal.fire('Errore', error ? error : 'Si è verificato un errore durante il salvataggio dei dati', 'error'))
          .catch(error => Swal.fire('Errore', error ? error : 'Si è verificato un errore imprevisto durante il salvataggio dei dati', 'error'));
      }
    });
  }

  async updateHandler() {
    eventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });

    await Promise.all([
      utentiService.getRoles(),
      adminService.getAuthorizations(),
      adminService.getRoutes(),
    ]).then(async (data: [
        IRuoloUtente[],
        AdminAuthorization,
        AdminRoute[]
    ]) => {
      await this.updateTabHandler(data[0], data[1], data[2]);
  
      this.setState({
        personRoles: data[0],
        adminAuthorizations: data[1],
        adminRoutes: data[2],
      });
    }).catch((reason: any) => {
      Swal.fire('Si è verificato un errore imprevisto durante il caricamento delle informazioni', '', 'error');
    })

    eventBus.dispatch("hideLoader");
  }

  async updateTabHandler(personRoles: IRuoloUtente[], adminAuthorizations: AdminAuthorization, adminRoutes: AdminRoute[]) {
    let tabs: TabProp[] = [];
    let data: { [key: string]: number[] } = {};
    personRoles.forEach(role => {
      let tabData: Array<AdminRoute & { isChecked: boolean }> = [];
      data[role.code] = [];

      adminRoutes.forEach((route) => {
        let result: any = { ...route };
        let isChecked = adminAuthorizations[role.id] ? adminAuthorizations[role.id].includes(route.id) : false;
        Object.assign(result, { isChecked });
        tabData.push(result);
        if (isChecked) {
          data[role.code].push(route.id);
        }
      });

      tabs.push({
        id: `${role.code}_${role.id}`,
        label: role.role,
        args: { id: role.id, code: role.code },
        content: <div id={`admin_authorizations_${role.code}_${role.id}`} className="authorization_container"><Table
          id={`table_admin_authorizations_${role.code}_${role.id}`}
          columns={listAdminAuthorizationsColumns}
          columnDefs={getAdminAuthorizationsColumnDefs(role.code)}
          datas={tabData}
          buttons={[]}
        />
        <button className='save_btn btn btn-info'>Save</button>
        </div>
      });
    });

    this.setState({ tabs, data });
  }

  render() {
    const { tabs, changes, adminRoutes, adminAuthorizations, personRoles } = this.state;

    return <div className="custom-container">
      <div className='card'>
        <div className='card-body'>
          <h2 className='card-title'>Autorizzazioni</h2>
          <Tab canChangeTab={changes.length === 0} tabs={tabs} tabClickHandler={async (currentTab, args) => {
            this.setState({
              currentTab,
              changes: [],
              role: args ? { id: args.id, code: args.code } : this.state.role
            }, async () => {
              eventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });

              await this.updateTabHandler(personRoles, adminAuthorizations, adminRoutes);

              eventBus.dispatch("hideLoader");
            });
          }} />
        </div>
      </div>
    </div>
  }
}