import * as Yup from "yup";
import { iFormField } from "../../../../../helpers/interfaces/generic";

export function getEditCommesseFormFields(): Array<iFormField> {
  return [
    {
      'label': 'Data Inizio',
      'name': 'startdate',
      'type': 'date',
      'class': 'form-control',
      'validation': Yup.date().notRequired()
    },
    {
      'label': 'Data Fine',
      'name': 'enddate',
      'type': 'date',
      'class': 'form-control',
      'validation': Yup.date().notRequired()
    },
    {
      'label': '',
      'name': 'id',
      'type': 'hidden',
      'class': 'form-control',
      'validation': Yup.number().positive().integer().required("Campo obbligatorio")
    },
    {
      'label': '',
      'name': 'personid',
      'type': 'hidden',
      'class': 'form-control',
      'validation': Yup.number().positive().integer().required("Campo obbligatorio")
    },
    {
      'label': '',
      'name': 'joborderid',
      'type': 'hidden',
      'class': 'form-control',
      'validation': Yup.number().positive().integer().required("Campo obbligatorio")
    },
  ];
}