import React, { Component } from "react";
import Swal from 'sweetalert2';
import EventBus from "../../common/EventBus";
import {
    checkFestivo, getNomeMese, getMesiAbbreviati, getGiornoSettimana, giorniLavorativi
} from './../../helpers/calendar';
import RendicontazioniService from "../../services/api/rendicontazioni.service";
import PersonService from "../../services/api/person.service";
import CausaliService from "../../services/api/database/causali.service";
import reportings from "../organico/rendicontazione/helper/reportings";
import DocumentiService from "../../services/api/documenti.service";
import { base64Decode, openBase64NewTab } from '../../common/Base64';
import ModalForm from '../../common/ModalForm';
import {
    getModalImportReportingFields, getModalRendicontazioneFields,
    joborderModalType
} from "../../config/formFields/rendicontazione";
import authService from "../../services/auth.service";
import { iFormField, iFormFieldOption } from "../../helpers/interfaces/generic";
import { FormikProps } from "formik";
import {
    iRendicontazione, iReportingImportFormData, iReportingInitialFormData
} from "../organico/rendicontazione/helper/reportings";
import {
    iCausale, iOperatore, IPermessiStudioEsami, iStrumento, iTrasferta
} from "../../helpers/interfaces/rendicontazione";
import { jobOrderChild, JoborderObject, Profilo } from "../../helpers/interfaces/user";
import planningService from "../../services/planning.service";
import ApiPlanningService from "../../services/api/planning.service";
import {
    IPlanningDetails,
    IPlanningDevice, IPlanningEventData, IPlanningJobOrder, IPlanningJobOrderChildren
} from "../../helpers/interfaces/planning";
import { IDocumento } from "../../helpers/interfaces/documento";
import attivitaService from "../../services/api/database/attivita.service";
import { Activity } from "../../helpers/interfaces/joborders";
import moment from "moment";
import 'moment/locale/it';
import 'font-awesome/css/font-awesome.min.css';
import '../organico/rendicontazione/style.css';

const $ = require('jquery');

type Props = {
    match: any,
};

type State = {
    currentUser: any,
    year: number,
    month: number,
    giorniSettimana: any,
    giorniMese: any,
    giorniVuoti: any,
    oreMese: number,
    showModal: boolean,
    modalId: string,
    modalTitle: string,
    modalType: string,
    modalSave: string,
    formFields: Array<iFormField>,
    formInitialValues: iReportingInitialFormData,
    formSubmit: (
        formData: any,
        formik: FormikProps<any>
    ) => any,
    dropdownYears: boolean,
    activities: Activity[],
    instruments: iFormFieldOption[],
    jobOrder: iFormFieldOption[],
    causal: iFormFieldOption[],
    instrumentsList: iStrumento[],
    filterInstruments: iFormFieldOption[],
    operators: iFormFieldOption[],
    transfer: iFormFieldOption[],
}

type iCommesse = {
    id: number,
    value: string,
    code: string,
    parent?: Array<any> | null,
}

export default class RendicontazioneDipendente extends Component<Props, State> {
    giorniSettimana: string[];
    nowYear: number;
    nowMonth: number;
    nowDay: number;
    initialValues: iReportingInitialFormData;
    profile: Profilo | null;
    isMealVoucherVisible: boolean = false;

    constructor(props: Props) {
        super(props);

        this.giorniSettimana = [
            'Dom',
            'Lun',
            'Mar',
            'Mer',
            'Gio',
            'Ven',
            'Sab'
        ];

        this.initialValues = {
            ora_fine_hh: '0',
            ora_fine_mm: '00',
            ora_inizio_hh: '0',
            ora_inizio_mm: '00',
            tempo_viaggio_hh: '0',
            tempo_viaggio_mm: '0',
            note: '',
            note_cliente: ''
        }

        this.state = {
            currentUser: null,
            year: 0,
            month: 0,
            giorniVuoti: [],
            giorniSettimana: [],
            giorniMese: [],
            oreMese: 0,
            showModal: false,
            modalId: 'modal-form',
            modalTitle: 'Rendicontazione',
            modalType: 'create',
            modalSave: 'Salva',
            formFields: [],
            formInitialValues: this.initialValues,
            formSubmit: reportings.addSubmitModal,
            dropdownYears: false,
            activities: [],
            instruments: [],
            jobOrder: [],
            causal: [],
            instrumentsList: [],
            filterInstruments: [],
            operators: [],
            transfer: [],
        }

        const date: Date = new Date();
        this.nowYear = date.getFullYear();
        this.nowMonth = date.getMonth() + 1;
        this.nowDay = date.getDate();
        this.profile = null;

        reportings.setPageView('dipendente');
    }

    mapCommesse = (
        joborders: JoborderObject[],
        parent: any = null
    ): iCommesse[] => {
        let results: iCommesse[] = [];
        let subRes: iCommesse[] = [];

        joborders.forEach((item: any) => {
            const lvl: number = typeof item.lvl == 'undefined' ? 0 : item.lvl;
            const char: string = "----".repeat(lvl);
            const name: string = typeof item.name == 'undefined' ? item.jobordername.trim() : item.name.trim();
            const code: string = !parent ? item.code : item.identificationcode ?? '';

            results.push({ id: item.joborderid, value: `${char} ${name}`, code, parent });

            if (typeof item.childs !== 'undefined' && item.childs.length > 0) {
                subRes = this.mapCommesse(item.childs, { id: item.joborderid, value: name });

                subRes.forEach((item: any) => {
                    results.push(item);
                });
            }
        });

        return results;
    }

    async getActivities(): Promise<void> {
        await attivitaService.getAllDipendente().then((activities: any) => {
            this.setState({activities});
        }).catch(() => {
            Swal.fire(
                'Errore',
                'Si è verificato un errore imprevisto durante il caricamento delle attività',
                'error'
            );
        });
    }

    async getInstruments(): Promise<void> {
        await RendicontazioniService.magazzinoStrumenti().then((
            instrumentsList: iStrumento[]
        ) => { 
            this.setState( {
                instrumentsList,
            })
        }).catch((reason) => {
            Swal.fire(
                'Errore',
                'Si è verificato un errore imprevisto durante il caricamento degli stumenti',
                'error'
            );
        });
    }

    async componentDidMount() {
        EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });

        const currentUser = await authService.getCurrentUser();

        await PersonService.getProfile().then((profile: Profilo & {error: any}) => {
            if (typeof profile.error !== 'undefined') {
                Swal.fire(
                    'Errore',
                    'Si è verificato un errore imprevisto durante il caricamento del profilo',
                    'error'
                );

                authService.logout();
            } else {
                this.profile = profile;
            }
        }).catch(() => {
            Swal.fire(
                'Errore',
                'Si è verificato un errore imprevisto durante il caricamento del profilo',
                'error'
            );

            authService.logout();
        });

        this.setState({ currentUser });

        if (currentUser) {
            const personId = currentUser.id;

            await this.getMonth(personId, this.nowYear, this.nowMonth);

            $('#addFile').click(() => {
                $('#templateFile').clone().attr('id', '').removeClass('hide').appendTo('#documents');
                $('.removeAttachment').off('click');
                $('.removeAttachment').click((event: any) => {
                    $(event.target).parent().remove();
                });
            });
        }

        $(".day").on('click', ".editParent", (
            event: JQuery.ClickEvent<HTMLElement, null, HTMLElement, HTMLElement>
        ) => {
            const data: iRendicontazione = typeof event.currentTarget.dataset.edit !== 'undefined'
                && JSON.parse(base64Decode(event.currentTarget.dataset.edit))
            const day = Number($(event.target).parents('.rendicontazione').attr('day'));
            const index = Number($(event.target).parents('.rendicontazione').attr('index'));

            reportings.setSelectedIndex(index);

            const ora_inizio: string[] = data.ora.inizio.split(':');
            const ora_fine: string[] = data.ora.fine.split(':');
            const viaggio: string[] = data.viaggio.tempo !== null
                ? data.viaggio.tempo.split(':')
                : ['0', '00'];
            const pausa_id: string | undefined = typeof data.pausa.id === 'string'
                ? data.pausa.id
                : undefined;
            const pausa_text: string | undefined = typeof data.pausa.text?.trim() === 'string'
                ? data.pausa.text?.trim()
                : undefined;

            const formInitialValues: iReportingInitialFormData = {
                tipologia: data.commessa.id !== null ? 'commessa' : 'causale',
                commessa: data.commessa
                    ? `${data.commessa.id}||${data.commessa.text?.trim()}||${data.commessa.code?.trim()}`
                    : undefined,
                parent: data.parent
                    ? `${data.parent.id}||${data.parent.nome?.trim()}||${data.parent.code?.trim()}`
                    : undefined,
                causale: data.causale
                    ? `${data.causale.id}||${data.causale.text?.trim()}`
                    : undefined,
                strumento: data.dispositivo.id && data.dispositivo.text?.trim()
                    ? `${data.dispositivo.id}||${data.dispositivo.text?.trim()}`
                    : undefined,
                operatore: data.operatore.id && data.operatore.text?.trim()
                    ? `${data.operatore.id}||${data.operatore.text?.trim()}`
                    : undefined,
                trasferta: data.trasferta.id && data.trasferta.text?.trim()
                    ? `${data.trasferta.id}||${data.trasferta.text?.trim()}`
                    : undefined,
                ora_fine_hh: ora_fine[0],
                ora_fine_mm: ora_fine[1] === '30' ? '30' : '00',
                ora_inizio_hh: ora_inizio[0],
                ora_inizio_mm: ora_inizio[1] === '30' ? '30' : '00',
                tempo_viaggio_hh: viaggio[0],
                tempo_viaggio_mm: viaggio[1],
                pausa: pausa_id && pausa_text ? `${pausa_id}||${pausa_text}`: "",
                note: data.note,
                note_cliente: data.note_cliente,
                rit_send: data.ritSend ? data.ritSend : false,
                measures_sent: data.measuresSent ? data.measuresSent : false
            };

            if (data.commessa.id) {
                Promise.all([
                    this.getInstruments(),
                    this.getActivities(),
                ]).then(() => {
                    this.setState( {
                        formSubmit: reportings.editSubmitModal,
                        formInitialValues
                    }, async () => {
                        const jobOrderAll: JoborderObject[] | undefined = 
                            this.profile?.personjoborders.active.concat(
                                this.profile.personjoborders.expired
                            );
                        
                        let jobOrder: any = null;
                        let childJobOrder: any = null
                        
                        jobOrderAll && jobOrderAll.forEach((
                            parent
                        ) => {
                            if (parent.joborderid === data.commessa.id) {
                                jobOrder = parent;
                            } else {
                                parent.childs?.forEach((child) => {
                                    if (child.joborderid === data.commessa.id) {
                                        jobOrder = parent;
                                        childJobOrder = child;
                                    }
                                })
                            }
                        })

                        if (!jobOrder) {
                            return Swal.fire(
                                'Errore',
                                'Non è stato possibile recuperare i dati relativi alla commessa',
                                'error'
                            );
                        }

                        const jobOrderChild = jobOrder.childs.length ? jobOrder.childs.map((
                            item: jobOrderChild
                        ) => {
                            return {
                                id: item.joborderid,
                                name: item.jobordername,
                                code: item.identificationcode
                            }
                        }) : []

                        const editJobOrders: IPlanningJobOrder = {
                            id: jobOrder.joborderid,
                            name: jobOrder.name,
                            code: jobOrder.code,
                            children: jobOrderChild
                        }

                        let editDevices: IPlanningDevice[] | undefined = undefined;

                        if (childJobOrder) {
                            const activity: Activity = this.state.activities.filter((item) => {
                                return item.code === childJobOrder.identificationcode
                            })[0];

                            if (!activity) {
                                return Swal.fire(
                                    'Errore',
                                    'Non è stato possibile recuperare i dati relativi all\'attività',
                                    'error'
                                );
                            }

                            editDevices = [];

                            this.state.instrumentsList.forEach((
                                instrument: iStrumento
                            ) => {
                                activity.instruments.forEach((
                                    item: number
                                ) => {
                                    if (item === instrument.id) {
                                        editDevices?.push({
                                            id: instrument.id,
                                            code: instrument.code,
                                            marca_modello: instrument.marca_modello,
                                            tipologia: instrument.tipologia_nome,
                                            serial_number: instrument.serial_number,
                                        })
                                    }
                                })
                            })
                        }

                        await this.openModalRendicontazione(
                            this.state.year,
                            this.state.month,
                            day,
                            'edit',
                            editJobOrders,
                            editDevices
                        )
                    })
                });
            } else {
                this.setState({
                    formSubmit: reportings.editSubmitModal,
                    formInitialValues
                }, async () => {
                    await this.openModalRendicontazione(
                        this.state.year,
                        this.state.month,
                        day,
                        'edit',
                    )
                });
            }
        });
    }

    async getMonth(personId: number, year: number, month: number) {
        EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });

        const data: moment.Moment = moment(year + '-' + month + '-01');
        const giornoSettimana: number = data.day();
        const giorni1: string[] = this.giorniSettimana.slice(0, giornoSettimana);
        const giorni2: string[] = this.giorniSettimana.slice(giornoSettimana, this.giorniSettimana.length);

        const daysInMonth: number = data.daysInMonth();

        const dettaglio: any = RendicontazioniService.getDipendenteRendicontazione(year, month);
        const permessiStudioEsami: Promise<IPermessiStudioEsami> = RendicontazioniService.getPermessiStudioEsami();
        const workHours: number | false = await RendicontazioniService.getDipendenteWorkHours(
            year,
            month
        ).then((data: {
            workhours: number;
        }) => {
            return data.workhours;
        }).catch(() => {
            return false;
        });

        await Promise.all([
            dettaglio,
            permessiStudioEsami
        ]).then(
            (data: [
                any,
                IPermessiStudioEsami
            ]) => {
                let dettaglio = { ...data[0], permessiStudioEsami: { ...data[1] } }

                if (!Array.isArray(data[0])) {
                    this.setState({
                        year,
                        month,
                        giorniVuoti: Array.from(Array(35 - daysInMonth).keys()),
                        giorniSettimana: [...giorni2, ...giorni1],
                        giorniMese: Array.from(Array(daysInMonth).keys()),
                        oreMese: workHours !== false
                            ? workHours
                            : giorniLavorativi(
                                year,
                                month,
                                (dettaglio.firstday ?? 0),
                                (dettaglio.lastday ?? daysInMonth)
                            ) * 8
                    }, () => {
                        reportings.load(dettaglio, this);

                        if (data[0] && data[0].length === 0) {
                            reportings.setPerson(
                                this.state.currentUser.id,
                                this.state.currentUser.name,
                                '',
                                '',
                                '',
                                'E',
                                data[1]
                            );

                            reportings.setCurrentDate(String(year), String(month));
                        }

                        EventBus.dispatch("hideLoader");
                    });
                } else {
                    this.setState({
                        year,
                        month,
                        giorniVuoti: Array.from(Array(35 - daysInMonth).keys()),
                        giorniSettimana: [...giorni2, ...giorni1],
                        giorniMese: Array.from(Array(daysInMonth).keys()),
                        oreMese: workHours !== false ? workHours : giorniLavorativi(
                            year,
                            month,
                            0,
                            daysInMonth
                        ) * 8
                    }, () => {
                        reportings.load(dettaglio, this);

                        if (data[0] && data[0].length === 0) {
                            reportings.setPerson(
                                this.state.currentUser.id,
                                this.state.currentUser.name,
                                '',
                                '',
                                '',
                                'E',
                                data[1]
                            );

                            reportings.setCurrentDate(String(year), String(month));
                        }

                        EventBus.dispatch("hideLoader");
                        EventBus.dispatch("hideLoader");
                    })
                }
            },
        )
    }

    async previousMonth() {
        const { currentUser, month, year } = this.state

        const personId: number | null = currentUser?.id ?? null;
        let newMonth: number = month - 1;
        let newYear: number = year;
        if (newMonth < 1) {
            newMonth = 12;
            newYear = year - 1;
        }

        if (personId) {
            this.getMonth(personId, newYear, newMonth);
        }
    }

    async nextMonth() {
        const { currentUser, month, year } = this.state

        const personId: number | null = currentUser?.id ?? null;
        let newMonth: number = month + 1;
        let newYear: number = year;
        if (newMonth > 12) {
            newMonth = 1;
            newYear = year + 1;
        }

        if (personId) {
            this.getMonth(personId, newYear, newMonth);
        }
    }

    async downloadDocument(idDocumento: number) {
        EventBus.dispatch("showLoader", { text: 'Caricamento del documento in corso...' });

        const documento: IDocumento = await DocumentiService.downloadDipendente(idDocumento);

        if (typeof documento.body !== 'undefined') {
            openBase64NewTab(documento);
        }

        EventBus.dispatch("hideLoader");
    }

    async deleteDocument(idDocumento: number) {
        EventBus.dispatch("showLoader", { text: 'Cancellazione del documento in corso...' });

        await DocumentiService.deleteDipendente(idDocumento).then(
            async () => {
                const year: number = Number(reportings.getCurrentDate().year);
                const month: number = Number(reportings.getCurrentDate().month);
                const personId: number = Number(reportings.getPersonId());

                await this.getMonth(personId, year, month);

                Swal.fire({
                    title: 'Cancellazione avvenuta con successo',
                    icon: 'success',
                    timer: 3000,
                });
            },
            () => Swal.fire({
                title: 'Si è verificato un errore imprevisto durante la cancellazione del tuo documento',
                icon: 'error',
                timer: 3000,
            })
        );

        EventBus.dispatch("hideLoader");
    }

    async updateRendicontazione(status: string) {
        EventBus.dispatch("showLoader", { text: 'Operazione in corso...' });

        const year: number = Number(reportings.getCurrentDate().year);
        const month: number = Number(reportings.getCurrentDate().month);
        const personId: number = Number(reportings.getPersonId());

        const edit = await reportings.updateRendicontazione(status).then(
            async () => {
                EventBus.dispatch("hideLoader");

                if (status === 'C') reportings.setStato({ code: 'C', name: 'Confermato' });
                $('#documents > input').value = '';
                $('#documents > div') && $('#documents > div').remove();
                await this.getMonth(personId, year, month);
            },
            () => {
                EventBus.dispatch("hideLoader");
                Swal.fire({
                    title: 'Errore',
                    text: 'Si è verificato un errore durante la conferma del mese',
                    icon: 'error',
                    timer: 3000,
                })
            }
        );

        return edit;
    }

    updateCommesseCallBack = async(
        value: string,
        formik: FormikProps<any>,
        type: joborderModalType
    ) => {
        const id: number = Number(value.split('|')[0]);
        const allJobOrders: JoborderObject[] | undefined =
            this.profile?.personjoborders.active.concat(
                this.profile.personjoborders.expired
            );

        let parentJobOrder: any;
        let selectedJobOrder: any;
        
        allJobOrders?.forEach((jobOrder: JoborderObject) => {
            return jobOrder.childs?.forEach((subJobOrder) => {
                if(subJobOrder.id === id) {
                    selectedJobOrder = subJobOrder;

                    parentJobOrder = {
                        id: jobOrder.joborderid,
                        name: jobOrder.name,
                        code: jobOrder.code
                    }
                };
            })
        });

        let filterInstruments: iFormFieldOption[] = this.state.instrumentsList.map((
            item: iStrumento
        ) => {
            return {
                value: `${item.id}||${item.marca_modello} (${(item.code ? item.code : 'n.d.')})`,
                label: `${item.tipologia_nome} - ${item.marca_modello} (${item.serial_number})`
            }
        });

        let activity: Activity | null = null;

        if (selectedJobOrder) {
            activity = this.state.activities.filter((item: Activity) => {
                return item.code === selectedJobOrder.identificationcode
            })[0];
        }

        if (parentJobOrder && selectedJobOrder && activity) {
            await attivitaService.getActivityInstruments(activity.id).then((
                toolIds: number[]
            ) => {
                const jobOrderInstruments: iStrumento[] = [];

                this.state.instrumentsList.forEach((tool) => {
                    return toolIds.forEach((id) => {
                        if (tool.id === id) {
                            jobOrderInstruments.push(tool);
                        }
                    })
                });

                filterInstruments = jobOrderInstruments.map((
                    item: iStrumento
                ) => {
                    return {
                        value: `${item.id}||${item.marca_modello} (${(item.code ? item.code : 'n.d.')})`,
                        label: `${item.tipologia_nome} - ${item.marca_modello} (${item.serial_number})`
                    }
                });

                this.setState({
                    filterInstruments
                }, () => {
                    this.setState({
                        formFields: getModalRendicontazioneFields(
                            this.state.jobOrder,
                            this.state.causal,
                            this.state.filterInstruments,
                            this.state.operators,
                            this.state.transfer,
                            type,
                            this.updateCommesseCallBack
                        ),
                    });
                });
            });
        }

        this.setState({ filterInstruments }, () => {
            this.setState({
                formFields: getModalRendicontazioneFields(
                    this.state.jobOrder,
                    this.state.causal,
                    this.state.filterInstruments,
                    this.state.operators,
                    this.state.transfer,
                    type,
                    this.updateCommesseCallBack
                ),
            });
        });

        if (parentJobOrder) {
            formik.setFieldValue(
                'parent',
                `${parentJobOrder.id}||${parentJobOrder.name}||${parentJobOrder.code}`
            );
        } else {
            formik.setFieldValue('parent', undefined);
        }

        formik.setFieldValue('strumento', '');
    }

    openModalRendicontazione = async (
        year: number,
        month: number,
        day: number,
        type: joborderModalType = 'add',
        jobOrder: IPlanningJobOrder | null = null,
        devices: IPlanningDevice[] | null = null
    ) => {
        EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });

        reportings.setSelectedDay(year, month, day);

        const causaliAll = await CausaliService.getAllDipendente()
            .then((response: iCausale[]) => { return response } )
            .catch((reason: any) => { return null });

        const causali: iFormFieldOption[] = causaliAll
            ? causaliAll.map((item: iCausale) => {
                return {
                    value: `${item.id}||${item.name.trim()}`,
                    label: item.name.trim()
                }
            }) : [];

        let strumenti: iFormFieldOption[] = [];

        if (!devices) {
            strumenti = this.state.instrumentsList.length
                ? this.state.instrumentsList.map((item: iStrumento) => {
                    return {
                        value: `${item.id}||${item.marca_modello} (${(item.code ? item.code : 'n.d.')})`,
                        // label: `${item.marca_modello} (${(item.code ? item.code : 'n.d.')})`
                        label: `${item.tipologia_nome} - ${item.marca_modello} (${item.serial_number})`
                    }
                }) : [];
        } else {
            strumenti = devices.map((item: IPlanningDevice) => {
                return {
                    value: `${item.id}||${item.marca_modello} (${(item.code ? item.code : 'n.d.')})`,
                    // label: `${item.marca_modello} (${(item.code ? item.code : 'n.d.')})`
                    label: `${item.tipologia} - ${item.marca_modello} (${item.serial_number})`
                }
            })
        }

        const operatoriAll: iOperatore[] | null = await RendicontazioniService.dipendenteOperatori()
            .then((response: iOperatore[]) => { return response } )
            .catch((reason) => { return null });

        const operatori: iFormFieldOption[] = operatoriAll
            ? operatoriAll.map((item: iOperatore) => {
                return {
                    value: `${item.id}||${item.name.trim()}`,
                    label: item.name.trim()
                }
            }) : [];

        const trasferteAll: iTrasferta[] | null = await RendicontazioniService.dipendenteTrasferte()
            .then((response: iTrasferta[]) => { return response } )
            .catch((reason) => { return null });

        const trasferte: iFormFieldOption[] = trasferteAll
            ? trasferteAll.map((item: iTrasferta) => {
                return {
                    value: `${item.id}||${item.name.trim()}`, 
                    label: item.name.trim()
                }
            }) : [];

        const date: moment.Moment = moment(
            year.toString() + '/' + month.toString() + '/' + day.toString()
        );

        let  jobordersAll: iCommesse[] = [];

        if (!jobOrder) {
            const jobordersActive = this.profile
                ? this.mapCommesse(this.profile.personjoborders.active)
                : [];

            let jobordersExpiredArray: JoborderObject[] = [];

            this.profile && 
            this.profile.personjoborders.expired.length > 0 &&
            this.profile.personjoborders.expired.forEach((joborder: JoborderObject) => {
                const startDate: moment.Moment = moment(joborder.startdate);
                const endDate: moment.Moment = moment(joborder.enddate);

                if (date.isBetween(startDate, endDate, 'days', "[]")) {
                    jobordersExpiredArray.push(joborder);
                }
            });

            let jobordersExpired: iCommesse[] = this.mapCommesse(jobordersExpiredArray);

            jobordersAll = jobordersActive.concat(jobordersExpired);
        } else {
            jobordersAll.push(
                {
                    id: jobOrder.id,
                    value: jobOrder.name,
                    code: jobOrder.code,
                    parent: jobOrder.children
                }
            );

            (jobOrder.children && jobOrder.children.length) && jobOrder.children.forEach((
                child: IPlanningJobOrderChildren
            ) => {
                jobordersAll.push(
                    {
                        id: child.id,
                        value: '---- ' + child.name,
                        code: child.code
                    }
                );
            });
        }

        const commesse: iFormFieldOption[] = jobordersAll.map((item: iCommesse) => {
            return {
                value: `${item.id}||${item.code.trim()} | ${item.value.trim()}||${item.code.trim()}`,
                label: item.value.trim()
            }
        });

        const days = $('#rendicontazione .day.selected').length;

        const modalId: string = 'modal-form-reporting';
        const modalSave: string = 'Salva';

        let modalTitle: string = 'Rendicontazione (';

        if (type === 'add') {
            modalTitle = 'Crea rendicontazione ('
        } else if (type === 'edit') {
            modalTitle = 'Modifica rendicontazione ('
        } else if (type === 'import') {
            modalTitle = '[2/2] Importa rendicontazione ('
        }

        if (days === 0) {
            modalTitle += ('0' + day).slice(-2) + '-' + ('0' + month).slice(-2) + '-' + year;
        } else if (days === 1) {
            modalTitle += days + ' giorno';
        } else {
            modalTitle += days + ' giorni';
        }

        modalTitle += ')'

        this.setState({
            jobOrder: commesse,
            causal: causali,
            instruments: strumenti,
            filterInstruments: strumenti,
            operators: operatori,
            transfer: trasferte,
        }, () => {
            this.setState({
                formFields: getModalRendicontazioneFields(
                    commesse,
                    causali,
                    this.state.filterInstruments,
                    operatori,
                    trasferte,
                    type,
                    this.updateCommesseCallBack
                ),
            }, () => {
                this.setState({
                    showModal: true,
                    modalId,
                    modalTitle,
                    modalType: type,
                    modalSave,
                });
            });
        });

        EventBus.dispatch("hideLoader");
    }

    openModalImportReporting = async(
        year: number,
        month: number,
        day: number
    ) => {
        EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });

        reportings.setSelectedDay(year, month, day);

        const days: number = $('#rendicontazione .day.selected').length;
        const dayDate: moment.Moment = moment(
            `${year}-${(day > 1 ? month : month - 1)}-${day} 00:00:00`
        );

        const planningAll: IPlanningEventData[]|null = await planningService.getDayDipendente(
            dayDate
        ).then((response: IPlanningEventData[] | null) => {
            return response
        } ).catch((reason) => {
            return null
        });

        if (!planningAll || (
            planningAll &&
            !planningAll.length)
        ) {
            this.setState({formInitialValues: this.initialValues});

            EventBus.dispatch("hideLoader");

            Swal.fire(
                'Nessun elemento trovato',
                'Non sono stati trovati elementi da importare',
                'warning'
            );

            return false;
        }

        const plannings: iFormFieldOption[] = [];
        const causali: iFormFieldOption[] = [];

        planningAll && planningAll.forEach((event: IPlanningEventData) => {
            event.plannings && event.plannings.forEach((item: any) => {
                if (item.type === 'P') {
                    plannings.push({
                        value: item.id,
                        label: item.joborder.name
                    });
                } else if (item.type === 'U') {
                    causali.push({ value: item.id, label: item.causale });
                }
            })
        });

        const modalId: string = 'modal-form-import';
        const modalSave: string = 'Continua';

        let modalTitle: string = '[1/2] Importa rendicontazione (';

        if (days === 0) {
            modalTitle += ('0' + day).slice(-2) + '-' + ('0' + month).slice(-2) + '-' + year;
        } else if (days === 1) {
            modalTitle += days + ' giorno';
        } else {
            modalTitle += days + ' giorni';
        }

        modalTitle += ')'

        this.setState({
            formFields: getModalImportReportingFields(
                year,
                month,
                day,
                plannings,
                causali
            ),
            showModal: true,
            modalId,
            modalTitle,
            modalType: 'import',
            modalSave,
            formSubmit: this.importReportingSubmit,
            formInitialValues: {
                ...this.initialValues,
                tipologia: undefined,
            }
        });

        EventBus.dispatch("hideLoader");
    }

    closeModal() {
        this.setState({
            showModal: false,
            formInitialValues: this.initialValues
        });
    }

    importReportingSubmit = async(
        formData: iReportingImportFormData,
        formik: FormikProps<iReportingImportFormData>
    ) => {
        let devices: IPlanningDevice[] | null = null;
        let jobOrder: IPlanningJobOrder | null = null;
        let formInitialValues: iReportingInitialFormData = {
            ...this.initialValues
        };

        if (formData.tipologia === 'causale') {
            if (formData.causale) {
                const causale = await RendicontazioniService.getDipendentePermessoFerie(formData.causale);
                formInitialValues = {
                    tipologia: 'causale',
                    causale: `${causale.causale.id}||${causale.causale.nome}`,
                    ora_inizio_hh: moment(causale.inizio).hours().toString(),
                    ora_inizio_mm: moment(causale.inizio).minutes() === 30 ? '30' : '00',
                    ora_fine_hh: moment(causale.fine).hours().toString(),
                    ora_fine_mm: moment(causale.fine).minutes() === 30 ? '30' : '00',
                    tempo_viaggio_hh: '0',
                    tempo_viaggio_mm: '0',
                    note: causale.note ?? "",
                    note_cliente: causale.note_cliente ?? ""
                }
            }
        } else {
            if (formData.planning) {
                const event:IPlanningDetails = await ApiPlanningService.getEventDipendente(
                    formData.planning
                );
                const eventjobOrder: IPlanningJobOrder = event.joborder;

                formInitialValues = {
                    tipologia: 'commessa',
                    commessa: `${eventjobOrder.id}||${eventjobOrder.name.trim()}||${eventjobOrder.code.trim()}`,
                    ora_inizio_hh: moment(event.start).hours().toString(),
                    ora_inizio_mm: moment(event.start).minutes() === 30 ? '30' : '00',
                    ora_fine_hh: moment(event.end).hours().toString(),
                    ora_fine_mm: moment(event.end).minutes() === 30 ? '30' : '00',
                    tempo_viaggio_hh: '0',
                    tempo_viaggio_mm: '0',
                    note: event.note,
                    note_cliente: event.note_cliente
                }

                devices = event.devices;
                jobOrder = event.joborder;
            }
        }

        await Promise.all([
            this.getInstruments(),
            this.getActivities(),
        ]).then(() => {
            this.setState({
                formInitialValues: {...formInitialValues},
                formSubmit: reportings.addSubmitModal,
            }, async () => {
                await this.openModalRendicontazione(
                    formData.year,
                    formData.month,
                    formData.day,
                    'import',
                    jobOrder,
                    devices,
                );
            });
        });
    }

    openCloseYears = () => this.setState({ dropdownYears: !this.state.dropdownYears });

    render() {
        const {
            giorniSettimana, giorniMese, giorniVuoti, year, month, oreMese, showModal,
            modalId, modalTitle, modalType, modalSave, formFields, formInitialValues, formSubmit
        } = this.state;
        const statoColor: {[key: string]: { color: string, text: string }} = {
            'O': { color: 'bg-cyan', text: 'Aperto' },
            'C': { color: 'bg-azure', text: 'Confermato' },
            'A': { color: 'bg-green', text: 'Accettato' },
            'R': { color: 'bg-red', text: 'Rifiutato' }
        };

        const statorendicontazione: { color: string, text: string } = {
            color: reportings.getStatoCode()
                ? statoColor[reportings.getStatoCode()].color
                : '',
            text: reportings.getStatoCode()
                ? statoColor[reportings.getStatoCode()].text
                : '',
        }

        const yearsClass: string = `dropdown-menu${this.state.dropdownYears ? " show" : ""}`;
        const years: number[] = [];

        for (let i = this.nowYear; i >= 2016; i--) {
            years.push(i);
        }

        const permessiStudioEsami = reportings.getPersonPermessiStudioEsami();

        return <React.Fragment>
            <div className="card" id="rendicontazione">
                <ModalForm
                    id={modalId}
                    showModal={showModal}
                    title={modalTitle}
                    modalType={modalType}
                    saveButtonName={modalSave}
                    formFields={formFields}
                    initialValues={formInitialValues}
                    apiSubmit={() => {}}
                    submitCallback={formSubmit}
                    closeCallback={this.closeModal.bind(this)}
                />
                {
                    permessiStudioEsami && <div className='card-body d-flex flex-column flex-lg-row align-items-center justify-content-start'>
                        <div className="col-12 col-lg-6 d-flex justify-content-start align-items-center p-2">
                            <strong>Permessi esami degli ultimi 3 anni</strong>{' '}
                            <h3 className={"mb-0 ms-2 " + (permessiStudioEsami.overesame ? 'text-danger' : 'text-success')}>
                                {permessiStudioEsami.oreesame} / {permessiStudioEsami.limiteesame}:00 ore
                            </h3>
                        </div>
                        <div className="col-12 col-lg-6 d-flex justify-content-start align-items-center p-2">
                            <strong>Permessi studio dell'ultimo anno</strong>{' '}
                            <h3 className={"mb-0 ms-2 " + (permessiStudioEsami.overstudio ? 'text-danger' : 'text-success')}>
                                {permessiStudioEsami.orestudio} / {permessiStudioEsami.limitestudio}:00 ore
                            </h3>
                        </div>
                    </div>
                }
                <div className="card-body">
                    <div className="row d-flex justify-content-between align-items-center mb-3">
                        <div className="col-12 col-sm-3 p-2">
                            <span id="stato" className={"badge " + statorendicontazione.color}>
                                {statorendicontazione.text}
                            </span>
                        </div>
                        <div className="col-12 col-sm-7 d-flex justify-content-between align-items-center">
                            <button
                                    className="btn btn-outline-primary me-2"
                                    title="Mese precedente"
                                    onClick={() => this.previousMonth()}
                                >
                                <i style={{ fontSize: "18px" }} className="fa fa-angle-left" aria-hidden="true" />
                            </button>
                            <div className="d-flex flex-column flex-lg-row align-items-center">
                                <div id="yearsDropdown" className="nav-item dropdown" onClick={this.openCloseYears}>
                                    <button
                                        className="d-flex btn-link border0"
                                        type="button"
                                        id="dropdownYears"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    >
                                        <h2 className="mb-0">{getNomeMese(month - 1)} {year}</h2>
                                    </button>
                                    <div className={yearsClass} aria-labelledby="dropdownYears">
                                        {
                                            years.map((value: number, index: number) => {
                                                return <span
                                                    key={index}
                                                    className={"dropdown-item" + (year === value ? " active" : "")}
                                                    onClick={() => {
                                                        value !== year && this.getMonth(
                                                            this.state.currentUser.id,
                                                            value,
                                                            month
                                                        )
                                                    }}
                                                >
                                                    {getNomeMese(month - 1)} {value}
                                                </span>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                            <button
                                className="btn btn-outline-primary ms-2"
                                title="Mese successivo"
                                onClick={() => this.nextMonth()}
                            >
                                <i style={{ fontSize: "18px" }} className="fa fa-angle-right" aria-hidden="true" />
                            </button>
                        </div>
                        <div className="col-12 col-sm-2 d-block text-end">
                            <a
                                href="#current-month"
                                className="text-outline-primary fw-bold"
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.getMonth(this.state.currentUser.id, this.nowYear, this.nowMonth);
                                }}
                            >
                                Vai al mese corrente
                            </a>
                        </div>
                    </div>
                    <div id="mesi" className="row">
                        <div className="col-md-12">
                            <div className="container-fluid">
                                <div className="row">
                                    {getMesiAbbreviati().map((mese, index) => {
                                        return <div key={index} className="col-12 col-sm-2 col-lg-1 text-center">
                                            <button
                                                className={"btn-block radius12" + (month === (index + 1) ? " active" : "")}
                                                onClick={() => month !== (index + 1) && this.getMonth(
                                                    this.state.currentUser.id,
                                                    year,
                                                    (index + 1)
                                                )}
                                            >
                                                {mese}
                                            </button>
                                        </div>
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="container-fluid">
                                <header>
                                    <div className="row d-sm-flex text-white days-week">
                                        {giorniSettimana.map((giorno: string) => {
                                            return <h5 key={'g' + giorno} className="col-sm p-1 text-center">{giorno}</h5>
                                        })}
                                    </div>
                                </header>
                                <div className="row border border-right-0 border-bottom-0 newbg">
                                    {giorniMese.map((index: string) => {
                                        const giorno: number = Number(index + 1);
                                        const festivo: boolean = checkFestivo(year, month, giorno);
                                        const corrente: boolean = year === this.nowYear
                                            && month === this.nowMonth
                                            && giorno === this.nowDay;
                                        const classNameContainer: string[] = [
                                            "position-relative",
                                            "d" + giorno,
                                            (corrente ? 'corrente' : ''),
                                            "day col-sm p-2 border border-left-0 border-top-0",
                                            (festivo ? 'festivo' : '')
                                        ];
                                        const classNameDay: string[] = ["day-number col-3", (festivo ? 'festivo' : '')];
                                        return <React.Fragment key={"d" + giorno}>
                                            <div id={"d" + giorno} className={classNameContainer.join(" ")}>
                                                <div className="row align-items-center">
                                                    {(['O', 'R'].includes(reportings.getStatoCode()) ||
                                                    typeof reportings.getStatoCode() === 'undefined') && <>
                                                        <span className="col-12 col-lg-5 pe-0">
                                                            <i
                                                                onClick={(e: React.MouseEvent) => {
                                                                    this.setState({
                                                                        formSubmit: reportings.addSubmitModal,
                                                                    }, async () => {
                                                                        EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });

                                                                        await Promise.all([
                                                                            this.getInstruments(),
                                                                            this.getActivities(),
                                                                        ]).then(() => {
                                                                            this.setState({
                                                                                formInitialValues: this.initialValues
                                                                            }, () => {
                                                                                this.openModalRendicontazione(
                                                                                    year,
                                                                                    month,
                                                                                    giorno
                                                                                )
                                                                            });
                                                                        }).finally(() => {
                                                                            EventBus.dispatch("hideLoader");
                                                                        });
                                                                    });
                                                                }}
                                                                style={{ fontSize: "18px" }}
                                                                className="fa fa-plus-circle icon text-primary me-2"
                                                                title="Crea una nuova rendicontazione"
                                                                aria-hidden="true"
                                                            />
                                                            <i
                                                                onClick={() => this.openModalImportReporting(
                                                                    year,
                                                                    month,
                                                                    giorno
                                                                )}
                                                                style={{ fontSize: "18px" }}
                                                                className="fa fa-calendar icon text-primary"
                                                                title="Importa da planning o permessi/ferie"
                                                                aria-hidden="true"
                                                            />
                                                        </span>
                                                        <span
                                                            id={"totale-giorno-" + giorno}
                                                            className="totale-ore-giorno col-12 col-lg-7 ps-0"
                                                        ></span>
                                                    </>}
                                                </div>
                                                <div className="row align-items-center">
                                                   
                                                </div>
                                                <div className="row align-items-center my-1">
                                                    <span className="col-12 col-lg-6 pe-0">
                                                        <span className={classNameDay.join(" ")}>{giorno}</span>
                                                        <small className="col d-sm-none text-center text-muted">
                                                            {getGiornoSettimana(year, month, giorno)}
                                                        </small>
                                                    </span>
                                                    <span className="col-12 col-lg-6 pe-0">
                                                        <span
                                                            style={{ fontSize: "18px" }}
                                                            id={"buono-pasto-" + giorno}
                                                            className="fa fa-ticket text-primary meal-voucher-icon"
                                                            title="Buono pasto"
                                                            aria-hidden="true"
                                                            // hidden={ !reportings.isMealVoucherAssignable()[giorno - 1] }
                                                        >
                                                        </span>
                                                    </span>
                                                </div>
                                                <span className="col-1"></span>
                                                <div id={"giorno-" + giorno}></div>
                                            </div>
                                            {giorno % 7 === 0 ? <div className="w-100"></div> : ''}
                                        </React.Fragment>
                                        //return <div>{giorno+1}</div>
                                    })}
                                    {giorniVuoti.map((index: string) => {
                                        return <div
                                            key={"subD" + index}
                                            className="day col-sm p-2 border border-left-0 border-top-0 text-truncate "
                                        >
                                            <h5 className="row align-items-center">
                                                <span className="date col-1"></span>
                                                <small className="col d-sm-none text-center text-muted"></small>
                                                <span className="col-1"></span>
                                            </h5>
                                        </div>
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6">
                            <div className="mt-3 card-body">
                                <label><strong>Documenti Allegati:</strong></label>
                                <div>
                                    {reportings.getAttachements()?.length > 0 ?
                                        reportings.getAttachements().map((item: any, index: number) => {
                                            return <div key={index}>
                                                <i onClick={() => this.downloadDocument(item.id)}
                                                    className="fa fa-download action-icon downloadAttachment"
                                                    aria-hidden="true"
                                                />
                                                {['O', 'R'].includes(reportings.getStatoCode()) && (
                                                    <React.Fragment>
                                                        {' '}<i onClick={(event) => {
                                                            Swal.fire({
                                                                title: 'Sei sicuro di voler cancellare questo documento?',
                                                                icon: 'question',
                                                                showCancelButton: true,
                                                                confirmButtonColor: '#85d630',
                                                                cancelButtonColor: '#d33',
                                                                confirmButtonText: 'Conferma',
                                                                cancelButtonText: 'Annulla'
                                                            }).then(async (result) => {
                                                                if (result.isConfirmed) await this.deleteDocument(item.id)
                                                            })
                                                        }}
                                                        className="fa fa-trash action-icon removeAttachment"
                                                        aria-hidden="true"
                                                    />
                                                    </React.Fragment>
                                                )}
                                                {' '}{item.path}
                                            </div>
                                        })
                                        : <div><strong>Nessuno</strong></div>
                                    }
                                </div>
                                <br />
                                {['O', 'R'].includes(reportings.getStatoCode()) && (
                                    <React.Fragment>
                                        <div id="documents">
                                            <input
                                                type="file"
                                                name="attachment"
                                                className="attachment"
                                            />
                                        </div>
                                        <button
                                            type="button"
                                            className="btn btn-primary mt-2"
                                            id="addFile"
                                        >
                                            Aggiungi Documento
                                        </button>
                                        <div id="templateFile" className="hide">
                                            <input type="file" name="attachment" className="attachment" />
                                            <i
                                                className="fa fa-trash action-icon removeAttachment"
                                                aria-hidden="true"
                                            />
                                        </div>
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                        <div className="col-5 offset-md-1 mt-4">
                            {['O', 'R'].includes(reportings.getStatoCode()) &&
                                <div className="row">
                                    <div className="col-md-6">
                                        <button className="btn btn-block btn-warning my-2" onClick={() => {
                                            if (reportings.checkTotaleOreMaggioreOreMese() !== -1)
                                                Swal.fire({
                                                    title: 'Sei sicuro di voler confermare la rendicontazione?',
                                                    text: reportings.checkTotaleOreStraordinario() !== 0
                                                        ? 'Note: risultano registrate '
                                                        + reportings.checkTotaleOreStraordinario()
                                                        + ' ore di straordinario totali'
                                                        : '',
                                                    icon: 'question',
                                                    showCancelButton: true,
                                                    confirmButtonColor: '#85d630',
                                                    cancelButtonColor: '#d33',
                                                    confirmButtonText: 'Conferma',
                                                    cancelButtonText: 'Annulla'
                                                }).then(async (result) => {
                                                    if (result.isConfirmed)
                                                        this.updateRendicontazione('C').then(
                                                            () => Swal.fire({
                                                                title: 'Mese confermato con successo',
                                                                icon: 'success',
                                                                timer: 3000,
                                                            }),
                                                            () => Swal.fire({
                                                                title: 'Si è verificato un errore imprevisto durante la conferma del mese',
                                                                icon: 'error',
                                                                timer: 3000,
                                                            })
                                                        )
                                                });
                                            else
                                                Swal.fire({
                                                    title: 'Le ore rendicontate non corrispondono alle ore mensili previste!',
                                                    icon: 'error',
                                                    timer: 3000,
                                                })
                                        }}>
                                            Conferma mese
                                        </button>
                                    </div>
                                    <div className="col-md-6">
                                        <button className="btn btn-block btn-success my-2" onClick={() => {
                                            Swal.fire({
                                                title: 'Sei sicuro di voler salvare la rendicontazione?',
                                                icon: 'question',
                                                showCancelButton: true,
                                                confirmButtonColor: '#85d630',
                                                cancelButtonColor: '#d33',
                                                confirmButtonText: 'Salva',
                                                cancelButtonText: 'Annulla'
                                            }).then(async (result) => {
                                                if (result.isConfirmed) {
                                                    this.updateRendicontazione('').then(
                                                        () => Swal.fire({
                                                            title: 'Mese salvato con successo',
                                                            icon: 'success',
                                                            showConfirmButton: false,
                                                            timer: 3000,
                                                        }),
                                                        () => Swal.fire({
                                                            title: 'Si è verificato un errore imprevisto durante il salvataggio del mese',
                                                            icon: 'error',
                                                            showConfirmButton: false,
                                                            timer: 3000,
                                                        })
                                                    )
                                                }
                                            });
                                        }}>
                                            Salva mese
                                        </button>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="row">
                            <div className="col-xs-12 col-md-8 mt-4">
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title totale-ore-title">
                                            Totale ore
                                            <span className="tot-monthly">
                                                <span id="totale-ore">00:00</span>{' / '}
                                                <span className="oreMese">
                                                    {oreMese}:00
                                                </span>
                                            </span>
                                        </h3>
                                    </div>
                                    <div className="card-header">
                                        <h3 className="card-title totale-ore-title">
                                            Totale buoni pasto
                                            {/* <span id="totale-buoni-pasto" className="tot-monthly">{reportings.totaleBuoniPasto()}</span> */}
                                            <span id="totale-buoni-pasto" className="tot-monthly"></span>
                                        </h3>
                                    </div>
                                    <div className="card-body p-0 mt-0">
                                        <div>
                                            <table
                                                id="rendicontazione-totale-ore"
                                                className="table nodefault"
                                            >
                                                <tbody></tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    }
}