import * as Yup from "yup";
import { iFormField } from "../../helpers/interfaces/generic";

export function getRitStatusFormFields(statusList: any): Array<iFormField> {
  return [
    {
        label: 'Stato',
        name: 'stato_id',
        type: 'select',
        values: statusList,
        class: 'form-select',
        validation: Yup.string().required("Campo obbligatorio")
    },
    {
        label: 'Note',
        name: 'note',
        type: 'textarea',
        class: 'form-control',
        // validation: Yup.string().required("Campo obbligatorio")
    }
  ];
}