import React, { Component } from 'react';
import Swal from 'sweetalert2';
import EventBus from "../../../common/EventBus";
import Table from '../../../common/TableComponent';
import ModalForm from '../../../common/ModalForm';
import { getAddLicenzaFormFields } from "./config/formFields/addLicenza";
import { getEditLicenzaFormFields } from "./config/formFields/editLicenza";
import { listLicenzeColumns, getListLicenzeColumnDefs } from "./config/tableColumns/listLicenze";
import LicenzeService from "../../../services/api/magazzino/magazzino-licenze.service";
import { iFormField } from '../../../helpers/interfaces/generic';

type Props = {
  licenze: any,
  personId: number
};

type State = {
  data: Array<any>,
  showModal: boolean,
  modalType: string,
  formFields: Array<iFormField>,
  formInitialValues: { [key: string]: any }
}

const $ = require('jquery');

class LicenzeComponent extends Component<Props, State> {
  buttons: (string | { text: string; className: string; action: (e: any, dt: any, node: any, config: any) => void; })[];
  apiSubmit: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      data: props.licenze,
      showModal: false,
      modalType: 'add',
      formFields: [],
      formInitialValues: { 'person_id': props.personId },
    }
    this.apiSubmit = LicenzeService.personAdd;

    this.buttons = [];
  }

  async componentDidMount() {
    EventBus.dispatch("showLoader", { text: 'Caricamento Licenze in corso...' });
    const licenze_all = await LicenzeService.getAll();
    EventBus.dispatch("hideLoader");
    let licenze: any = licenze_all.map((item: any) => { return { key: item.id, value: item.prodotto_nome + ' (' + item.identificativo + ')' } });

    if (Object.keys(licenze).length > 0) {
      this.setState({ formFields: getAddLicenzaFormFields(licenze, this.props.personId) });
    }

    $('table').on('click', '.edit_btn', async (e: any) => {
      e.preventDefault();
      EventBus.dispatch("showLoader", { text: 'Caricamento Licenza in corso...' });
      const idLicenza = $(e.currentTarget).data('id');
      const licenza = await LicenzeService.personGet(idLicenza);
      if (licenza) {
        this.setState({ formFields: getEditLicenzaFormFields(), formInitialValues: licenza }, () => {
          this.apiSubmit = LicenzeService.personPut;
          this.setState({ showModal: true, modalType: 'edit' });
        });
      }
      EventBus.dispatch("hideLoader");
    });

    $('table').on('click', '.delete_btn', async (e: any) => {
      e.preventDefault();
      Swal.fire({
        title: "Vuoi confermare l'eliminazione?",
        //text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Conferma',
        cancelButtonText: 'Annulla'
      }).then(async (result: any) => {
        if (result.isConfirmed) {
          const idLicenza = $(e.currentTarget).data('id');
          const licenza: any = await LicenzeService.personDelete(idLicenza);

          if (licenza && typeof licenza.error !== 'undefined') {
            Swal.fire(
              licenza.error,
              '',
              'error'
            );
          } else {
            this.handleSuccess();
          }
        }
      });
    });
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  handleSuccess() {
    window.location.hash = "#licenze";
    window.location.reload();
  }

  render() {
    const { showModal, formFields, formInitialValues, modalType, data } = this.state;
    return <div className="row p-2">
      <div className="d-flex justify-content-between align-items-center">
        <h3 className="m-0">Licenze</h3>
        <button id="add_btn" type="button" className="btn btn-outline-primary" disabled={formFields.length === 0} onClick={() => this.setState({ showModal: true, modalType: 'add' })}>
          <span>Assegna licenza</span>
        </button>
      </div>
      <ModalForm
        showModal={showModal}
        title={'Nuova licenza'}
        modalType={modalType}
        formFields={formFields}
        initialValues={formInitialValues}
        apiSubmit={this.apiSubmit}
        closeCallback={this.closeModal.bind(this)}
        successAction={this.handleSuccess.bind(this)}
      />
      {
        data.length > 0 ? <React.Fragment>
          <Table id="table_licenze" columns={listLicenzeColumns} columnDefs={getListLicenzeColumnDefs()} datas={this.state.data} buttons={this.buttons} />
        </React.Fragment> : <p className="mt-3">Non sono presenti licenze</p>
      }
    </div>
  }
}
export default LicenzeComponent;