export const listDocumentiColumns = [
    {
        title: 'Azioni',
        data: 'id'
    },
    {
        title: 'Tipo',
        width: 'auto',
        data: 'type'
    },
    {
        title: 'Nome',
        width: 'auto',
        data: 'nome'
    },
    {
        title: 'File',
        width: 'auto',
        data: 'path'
    }, {
        title: 'Note',
        width: 'auto',
        data: 'note'
    }, {
        title: 'Scadenza',
        width: 'auto',
        data: 'expire_date'
    }
];

export function getListDocumentiColumnDefs() {
    return [
        {
            target: 0,
            //data: null,
            render: function (data: any, type: any, row: any) {
                const download = `<a class="download_btn action-icon custom-icon btn btn-outline-secondary rounded-circle me-1"  data-id="${data}">
                    <i class="fa fa-download" aria-hidden="true"></i>
                </a>`;

                const trash = `<a class="delete_btn action-icon custom-icon btn btn-outline-danger rounded-circle me-1"  data-id="${data}">
                    <i class="fa fa-trash" aria-hidden="true"></i>
                </a>`;

                const edit = `<a class="edit_btn action-icon custom-icon btn btn-outline-primary rounded-circle" data-id="${data}">
                    <i style="font-size: 18px" class="fa fa-pencil" aria-hidden="true"></i>
                </a>`;

                return '<div class="d-flex">' + trash + download + edit + '</div>';
            },
        },
    ];
}