import { generatePath } from "react-router";
import { Person } from "../../../helpers/interfaces/person";
import { ConfigColumnDefs, ConfigColumns } from "datatables.net";

export const listPersonaleColumns: ConfigColumns[] = [
  { title: "Azioni", data: "id" },
  { title: "Nominativo", data: "nominativo" },
  { title: "Email", data: "registrationemail" },
  { title: "Cell.", data: "anagrafica.telefono", defaultContent: "-" },
  { title: "Contratti", data: "contratti" },
  { title: "Sede", data: "contratto_attivo[0].sede_contrattuale" },
  { title: "Data Ass.", data: "contratto_attivo[0].data_assunzione" },
  { title: "Data Scad.", data: "contratto_attivo[0].data_fine_rapporto" },
  { title: "Proroghe", data: "contratto_attivo[0].numero_proroghe" },
  { title: "Business unit", data: "businessunit.logourl" },
];

export function getListPersonaleColumnDefs(): ConfigColumnDefs[] {
  return [
    {
      target: 0,
      render: function (data: any, type: any, row: any) {
        const url = generatePath("/organico/dettaglio-personale/:id", {
          id: data
        });
        return `<a class="custom-icon btn btn-outline-primary rounded-circle" href="${url}">
              <i style="font-size: 18px" class="fa fa-user" aria-hidden="true"></i>
            </a>`;
      },
    },
    {
      target: 4,
      render: (data: any, type: any, row: Person) => {
        return row.contratto_attivo ? '<div class="d-flex flex-column">' + row.contratto_attivo.map(
          contratto => contratto.tipocontratto_nome
        ).join(', ') + '</div>' : '';
      },
    },
    {
      target: listPersonaleColumns.length - 1,
      render: (data: any, type: any, row: any) => {
        const logo = `<img class="img-fluid" src="${data}" width="125px" />`;
        return '<div class="d-flex">' + logo + '</div>';
      },
    },
  ];
}