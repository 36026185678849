import React, { Component } from 'react';
import Swal from "sweetalert2";
import RitAmministratoreService from "../../services/api/rit-amministratore.service";
import { RitList, RitDetail, RitStatus } from "../../helpers/interfaces/admin";
import Table from "../../common/TableComponent";
import EventBus from "../../common/EventBus";
import getRitListColumnDefs, { ritListColumns } from "../../config/tableColumns/admin/admin-rit";
import { generatePath } from "react-router-dom";
import { getRitStatusFormFields } from "../../config/formFields/adminRitStatusModal";
import { iFormField, iFormFieldValue } from "../../helpers/interfaces/generic";
import ModalForm from "../../common/ModalForm";
import { openBase64NewTab } from '../../common/Base64';

const $ = require('jquery');

type State = {
    formFields: Array<iFormField>,
    formInitialValues: { [key: string]: any },
    showModal: boolean,
    modalType: string,
    modalTitle: string,
    ritList: RitList[]
}

export default class RitAmministratore extends Component<{}, State> {

    apiSubmit: any;

    constructor(props: {}) {
        super(props)

        this.state = {
            formFields: [],
            formInitialValues: {},
            showModal: false,
            modalType: 'add',
            modalTitle: '',
            ritList: []
        }
    }

    async componentDidMount() {
        EventBus.dispatch("showLoader", { text: 'Caricamento dell\'elenco in corso...' });

        await RitAmministratoreService.getRitList()
        .then((response: RitList[]) => {
            EventBus.dispatch("hideLoader");

            if (response) {
                this.setState({ ritList: response });
            } else {
                Swal.fire({
                title: 'Errore',
                text: 'Si è verificato un errore nel caricamento dei dati.',
                icon: 'error',
                })
            };
        })
        .catch((error: string) => {
            EventBus.dispatch("hideLoader");

            Swal.fire({
            title: 'Errore',
            text: error,
            icon: 'error',
            });
        });

        $('#table-rit-list.table').on('click', '.status_btn', async (e: any) => {
            e.preventDefault();
            EventBus.dispatch("showLoader", { text: 'Caricamento in corso...' });
            const idRit = $(e.currentTarget).data('id');

            const rapportino: RitDetail = await RitAmministratoreService.getOneRit(idRit);

            const _statusList: RitStatus[] = await RitAmministratoreService.getStatusList();
            const statusList: iFormFieldValue[] = _statusList.map((item: RitStatus) => { return { key: item.id, value: item.name } });
      
            if (rapportino) {
              this.setState({
                  formFields: getRitStatusFormFields(statusList),
                  formInitialValues: rapportino
              }, () => {
                  this.apiSubmit = RitAmministratoreService.putStatus;
                  this.setState({ showModal: true, modalType: 'edit', modalTitle: 'Modifica lo stato del Rapportino di Intervento Tecnico (RIT)' });
              });
            }
            EventBus.dispatch("hideLoader");
        });

        $('#table-rit-list.table').on('click', '.edit_btn', async (e: any) => {
            e.preventDefault();
            const ritId = $(e.currentTarget).data('id');
            const url = generatePath("/commerciale/rit-amministratore/rit-edit-amministratore/:id", { id: ritId });
            
            // apre il RIT nella stessa tab del browser dell'app
            // let link = document.createElement('a');
            // link.href = url;
            // link.click();

            // apre il RIT in una nuova tab del browser
            window.open(url, "_blank", "noreferrer noopener");
        });
        
        $('#table-rit-list.table').on('click', '.pdf_btn', async (e: any) => {
            e.preventDefault();
            const ritId = $(e.currentTarget).data('id');

            EventBus.dispatch("showLoader", { text: 'Generazione del RIT in corso...' });
            await RitAmministratoreService.downloadPdfRit(ritId)
            .then((response: any) => {
                EventBus.dispatch("hideLoader");

                if (response && response.body !== undefined) {
                    openBase64NewTab(response);
                } else if (response && response.error !== undefined) {
                    Swal.fire({
                        title: 'Errore',
                        text: response.error,
                        icon: 'error',
                    });
                } else {
                    Swal.fire({
                        title: 'Errore',
                        text: 'Si è verificato un errore durante la generazione del report.',
                        icon: 'error',
                    });
                }
            })
            .catch((error: string) => {
                EventBus.dispatch("hideLoader");
    
                Swal.fire({
                title: 'Errore',
                text: "Si è verificato un errore imprevisto.",
                icon: 'error',
                });
            })
        });

        $('#table-rit-list.table').on('click', '.delete_btn', async (e: any) => {
            e.preventDefault();
            Swal.fire({
                title: 'Vuoi eliminare il RIT?',
                //text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Conferma',
                cancelButtonText: 'Annulla'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const idDocument = $(e.currentTarget).data('id');
                    const response: any = await RitAmministratoreService.deleteRit(idDocument);
                    if (response && typeof response.error !== 'undefined') {
                        Swal.fire(
                            response.error,
                            '',
                            'error'
                        );
                    } else {
                        window.location.reload();
                    }
                }
            });
        });
    }

    closeModal() {
      this.setState({ showModal: false });
    }

    render() {
        const { showModal, modalTitle, modalType, formFields, formInitialValues, ritList } = this.state;
        const buttons = ['excel', 'pdf', 'print']

        return (
            <React.Fragment>
                <div className='custom-container'>
                    <ModalForm
                        showModal={showModal}
                        title={modalTitle}
                        modalType={modalType}
                        formFields={formFields}
                        initialValues={formInitialValues}
                        apiSubmit={this.apiSubmit}
                        closeCallback={this.closeModal.bind(this)}
                    />

                    <div className="card">
                        <div className="card-body">
                            <div className="form-group mb-3 d-flex justify-content-between align-items-center">
                                <h2 className="card-title">Rapportini di intervento tecnico - elenco</h2>
                                {/* <a href="/magazzino/prodotti/nuovo" id="add_btn" className="btn btn-outline-primary">
                                    <span>Aggiungi prodotto</span>
                                </a> */}
                            </div>
                            {
                                ritList.length > 0 &&
                                <Table id={'table-rit-list'} columns={ritListColumns} columnDefs={getRitListColumnDefs()} datas={ritList} buttons={buttons} />
                            }
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

}