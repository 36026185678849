import axios from '../api';
import { AxiosResponse } from 'axios';
import { Profilo, SuccessResponse, JoborderPayload, RitInformations, RitData, RitDocument, IRitObj } from '../../helpers/interfaces/user';
import { IDocumento } from '../../helpers/interfaces/documento';

const API_URL = process.env.REACT_APP_API_URL;

class UserService {

  getProfile(): Promise<Profilo> {
    return axios.get(API_URL + 'dipendente/profilo')
      .then((response: AxiosResponse<any>) => {
        if (response.data.token) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  getRitList(): Promise<IRitObj[]> {
    return axios.get(API_URL + 'dipendente/axist')
      .then((response: AxiosResponse<IRitObj[]>) => { return response.data })
      .catch((error) => { return Promise.reject(error); });
  }

  editRitData(id: number, body: RitInformations): Promise<any> {
    const payload: RitInformations = {
      ritId: id,
      contatto: body.contatto,
      startdate: body.startdate,
      enddate: body.enddate
    }
    return axios.put<RitInformations>(API_URL + 'dipendente/axist/update-send-data', payload)
        .then((response: AxiosResponse<any>) => { return response.data; })
        .catch((error) => { return Promise.reject(error); });
  }

  getRitDocument(id: number): Promise<RitData> {
    return axios.get(API_URL + 'dipendente/axist/' + id)
        .then((response: AxiosResponse<RitData>) => { return response.data })
        .catch((error) => { return Promise.reject(error); });
  }

  postRitData(id: number, body: JoborderPayload): Promise<SuccessResponse> {
    const payload: JoborderPayload = {
      contatto: Number(body.contatto),
      commessa: id,
      startdate: body.startdate,
      enddate: body.enddate
    }
    return axios.post<any>(API_URL + 'dipendente/axist', payload)
        .then((response: AxiosResponse<SuccessResponse>) => { return response.data; })
        .catch((error) => { return Promise.reject(error); });
  }

  putRitData(id: number, body: RitDocument): Promise<any> {
    return axios.put<any>(API_URL + 'dipendente/axist/' + id, body)
        .then((response: AxiosResponse<any>) => { return response.data; })
        .catch((error) => { return Promise.reject(error); });
  }

  saveRitDraft(id: number, body: RitDocument): Promise<any> {
    return axios.put<any>(API_URL + 'dipendente/axist/' + id + '/bozza', body)
        .then((response: AxiosResponse<any>) => { return response.data; })
        .catch((error) => { return Promise.reject(error); });
  }

  deleteRit(id: number): Promise<any> {
      return axios.delete(API_URL + 'dipendente/axist/' + id)
          .then((response: AxiosResponse<any>) => { return response.data })
          .catch((error) => { return Promise.reject(error); });
  }

  downloadPdfRit(id: number): Promise<IDocumento> {
    return axios.get(API_URL + 'dipendente/axist/pdf/' + id)
        .then((response: AxiosResponse<IDocumento>) => { return response.data; })
        .catch((error) => {return Promise.reject(error); });
  }
}

export default new UserService();
