import axios, { AxiosResponse } from "axios"
import { IVeicoloManutenzioneForm } from "../../../config/formFields/magazzino/magazzino-veicoli-manutenzione";
import { IMagazzinoVeicoloManutenzione } from "../../../helpers/interfaces/magazzino/magazzino-veicoli";

const API_URL = process.env.REACT_APP_API_URL;

class MagazzinoVeicoliManutenzioneService {

    async getAll(): Promise<IMagazzinoVeicoloManutenzione[]> {
        try {
            const response = await axios.get<IMagazzinoVeicoloManutenzione[]>(API_URL + "datore/vehicle/maintenance");
            return Array.isArray(response.data) ? response.data : [];
        } catch (error) {
            return await Promise.reject();
        }
    }

    async getOne(id: string): Promise<IMagazzinoVeicoloManutenzione> {
        try {
            const response = await axios.get<IMagazzinoVeicoloManutenzione>(API_URL + 'datore/vehicle/maintenance/' + id);
            return response.data;
        } catch (error) {
            return await Promise.reject();
        }
    }

    async add(body: IVeicoloManutenzioneForm): Promise<IMagazzinoVeicoloManutenzione> {
        try {
            const response = await axios.post<IVeicoloManutenzioneForm, AxiosResponse<IMagazzinoVeicoloManutenzione>>(API_URL + 'datore/vehicle/maintenance', body);
            return response.data;
        } catch (error) {
            return await Promise.reject();
        }
    }

    async edit(id: string, body: IVeicoloManutenzioneForm): Promise<IMagazzinoVeicoloManutenzione> {
        try {
            const response = await axios.put<IVeicoloManutenzioneForm, AxiosResponse<IMagazzinoVeicoloManutenzione>>(API_URL + 'datore/vehicle/maintenance/' + id, body);
            return response.data;
        } catch (error) {
            return await Promise.reject();
        }
    }

}

export default new MagazzinoVeicoliManutenzioneService()