import { ConfigColumnDefs, ConfigColumns } from "datatables.net";

export const listPlanningNotificheColumns: ConfigColumns[] = [
    { title: "Seleziona", data: "id" },
    { title: "Event type", data: "eventtype" },
    { title: "Email", data: "email" },
    // { title: "Data", data: "created_at" },
    // { title: "Letta?", data: "readed" }
];

export function getPlanningNotificheColumnDefs(): ConfigColumnDefs[] {
    return [
        {
            target: 0,
            //data: null,
            render: function (data: any, type: any, row: any) {
                const trash = `<a class="delete_btn custom-icon btn btn-outline-danger rounded-circle me-1" data-id="${data}">
                    <i style="font-size: 18px" class="fa fa-trash" aria-hidden="true"></i>
                </a>`;

                const edit = `<a class="edit_btn custom-icon btn btn-outline-primary rounded-circle" data-id="${data}">
                    <i style="font-size: 18px" class="fa fa-pencil" aria-hidden="true"></i>
                </a>`;

                return '<div class="d-flex">' + trash + edit + '</div>';
            },
        },
    ];
}