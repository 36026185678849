import { generatePath } from "react-router-dom";
import { ConfigColumnDefs, ConfigColumns } from "datatables.net";
import { IMagazzinoLicenza } from "../../../helpers/interfaces/magazzino/magazzino-licenze";

// table columns definitions
export const listLicenzeColumns: ConfigColumns[] = [
    { title: "Azioni", data: "id" },
    { title: "Tipologia", data: "tipologia" },
    { title: "prodotto", data: "prodotto_nome" },
    { title: "Identificativo", data: "identificativo" },
    { title: "Data inizio", data: "data_inizio" },
    { title: "Data fine", data: "data_fine" },
    { title: "Assegnatario", data: "assegnatario" },
    { title: "Note", data: "note" }

];

// function: setting columns defs (in this case, setting the edit button at the first column)
export default function getLicenzeColumnDefs(): ConfigColumnDefs[] {
    return [
        {
            target: 0,
            render: (id: number, type: string, row: IMagazzinoLicenza) => {
                const url = generatePath("/magazzino/licenze/dettaglio-licenza/:id", { id })

                const edit = `<a class="custom-icon btn btn-outline-primary rounded-circle" href="${url}" data-id="'+${id}+'"> 
                    <i style="font-size: 18px" class="fa fa-pencil" aria-hidden="true"></i>
                </a>`;

                return '<div class="d-flex">' + edit + '</div>';
            },
        },
    ];
}