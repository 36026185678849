import { ErrorMessage, Field, Form, Formik } from "formik";
import { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import ModalForm from "../common/ModalForm";
import { iFormField } from "../helpers/interfaces/generic";
import { LoginRequest } from "../helpers/interfaces/user";
import { resetButtonsStyle } from "../helpers/settings/buttons-icons-styles";
import AuthService from "../services/auth.service";

interface RouterProps {
  history: string;
}

type Props = RouteComponentProps<RouterProps>;

type State = {
  email: string,
  password: string,
  loading: boolean,
  message: string,
  formFields: iFormField[]
  showModal: boolean,
  modalType: string,
  formInitialValues: { email: string }
};
export default class Login extends Component<Props, State> {

  apiSubmit: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      loading: false,
      message: "",
      formFields: [],
      showModal: false,
      modalType: 'add',
      formInitialValues: { email: "" }
    };

    this.handleLogin = this.handleLogin.bind(this);
    this.openResetModale = this.openResetModale.bind(this);
  }

  async componentDidMount(): Promise<void> {
    const currentUser = await AuthService.getCurrentUser();
    if (currentUser) {
      this.props.history.push("/");
    }
  }

  validationSchema() {
    return Yup.object().shape({
      email: Yup.string().email().required("Campo obbligatorio"),
      password: Yup.string().required("Campo obbligatorio"),
    });
  }

  async handleLogin(formValue: LoginRequest) {

    const { email, password } = formValue;

    this.setState({
      message: "",
      loading: true
    });

    await AuthService.login(email, password).then(
      () => {
        // history.push('/');
        window.location.reload();
      },
      (error: any) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.error) ||
          error.error ||
          error.toString();
        this.setState({
          loading: false,
          message: resMessage
        });
      }
    ).catch(() => {
      this.setState({ loading: false });
      Swal.fire('Errore', 'Siamo spiacenti, si è verificato un errore imprevisto', 'error');
    });
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  openResetModale() {
    this.apiSubmit = AuthService.recoverPassword;

    this.setState({
      formFields: [
        {
          label: 'Email',
          name: 'registrationemail',
          type: 'text',
          class: 'form-control',
          validation: Yup.string().email().required("Campo obbligatorio")
        }
      ],
      showModal: true,
      modalType: 'add'
    });
  }

  render() {
    const { loading, message, showModal, formFields, formInitialValues, modalType } = this.state;

    const initialValues = {
      email: "",
      password: "",
    };

    return (
      <div className="page page-center">
        <div className="container-tight py-4">
          <div className="card card-md">
            <div className="user-select-none text-center py-2">
              <img src="/assets/ritz-logo.png" height="96px" className="pt-3" alt="ritz-logo" />
            </div>
            <div className="card-body px-4 pt-2 pb-3">
              <Formik
                initialValues={initialValues}
                validationSchema={this.validationSchema}
                onSubmit={this.handleLogin}
              >
                <Form>
                  <h2 className="card-title text-center mb-3">Accedi al tuo account</h2>
                  <div className="mb-3">
                    <label className="form-label">Email</label>
                    <Field name="email" type="email" className="form-control" />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="alert alert-danger"
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">
                      Password
                    </label>
                    <Field name="password" type="password" className="form-control" />
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="alert alert-danger"
                    />
                  </div>

                  <div className="w-100 d-flex justify-content-center mb-3">
                    <button type="submit" className="btn btn-primary" disabled={loading}>
                      {
                        loading && (
                          <span className="spinner-border spinner-border-sm me-1"></span>
                        )
                      }
                      <span>Accedi</span>
                    </button>
                  </div>
                  {
                    message && (
                      <div className="form-group">
                        <div className="alert alert-danger" role="alert">
                          {message}
                        </div>
                      </div>
                    )
                  }
                </Form>
              </Formik>
              <div className="w-100 d-flex justify-content-center">
                <button style={resetButtonsStyle} type="button" id="add_btn" className="nav-link" onClick={() => this.openResetModale()}>
                  Hai dimenticato la password?
                </button>
                <ModalForm showModal={showModal} title={'Resetta la password'} modalType={modalType} formFields={formFields} initialValues={formInitialValues} apiSubmit={this.apiSubmit} closeCallback={this.closeModal.bind(this)} />
              </div>
            </div>
            <p className="d-flex justify-content-center align-items-center">
              <span className="me-1">Powered by</span>
              <a href="https://www.cgmconsulting.it" target="_blank" rel="noreferrer">
                <img src="/assets/CGM-logo.png" height="36px" alt="logo" />
              </a>
            </p>
          </div>
        </div>
      </div>
    )
  }
}