import { AxiosResponse } from 'axios';
import { ILicenzaBody, IMagazzinoLicenza } from '../../../helpers/interfaces/magazzino/magazzino-licenze';
import axios from '../../api';

const API_URL = process.env.REACT_APP_API_URL;

class LicenzeService {

  getAll(): Promise<IMagazzinoLicenza[]> {
    return axios.get<IMagazzinoLicenza[]>(API_URL + 'magazzino/licenze')
      .then(response => { return Array.isArray(response.data) ? response.data : []; })
      .catch((error) => { return Promise.reject(); });
  }

  getOne(id: string): Promise<IMagazzinoLicenza> {
    return axios.get<IMagazzinoLicenza>(API_URL + 'magazzino/licenza/' + id)
      .then(response => { return response.data; })
      .catch((error) => { return Promise.reject(); });
  }

  add(body: ILicenzaBody): Promise<IMagazzinoLicenza> {
    return axios.post<ILicenzaBody, AxiosResponse<IMagazzinoLicenza>>(API_URL + 'magazzino/licenza', body)
      .then((response: AxiosResponse<IMagazzinoLicenza>) => { return response.data; })
      .catch((error) => { return Promise.reject(); });
  }

  edit(id: string, body: ILicenzaBody): Promise<IMagazzinoLicenza> {
    return axios.put<ILicenzaBody, AxiosResponse<IMagazzinoLicenza>>(API_URL + 'magazzino/licenza/' + id, body)
      .then((response: AxiosResponse<IMagazzinoLicenza>) => { return response.data; })
      .catch((error) => { return Promise.reject(); });
  }

  personAdd(data: any) {
    return axios.post(API_URL + 'magazzino/person/licenze', {
      ...data
    })
      .then(response => { return response.data; })
      .catch((error) => { return []; });
  }

  personDelete(id: any) {
    return axios.delete(API_URL + 'magazzino/person/licenze/' + id)
      .then(response => { return response.data; })
      .catch((error) => { return error.response.data; });
  }

  personGet(id: any) {
    return axios.get(API_URL + 'magazzino/person/licenze/' + id)
      .then(response => { return typeof response.data == 'object' ? response.data : null; })
      .catch((error) => { return Promise.reject(); });;
  }

  personPut(id: any, data: any) {
    return axios.put(API_URL + 'magazzino/person/licenze/' + id, {
      ...data
    })
      .then(response => { return response.data; })
      .catch((error) => { return []; });
  }

}
export default new LicenzeService();