import React, { Component } from "react";
import Table from '../../common/TableComponent';
import { listPersonaleColumns, getListPersonaleColumnDefs } from "../../config/tableColumns/organico/personale";
import StatsEmployeesComponent from '../../components/StatsEmployeesComponent';
import EventBus from "../../common/EventBus";
import personService from "../../services/api/person.service";
import { Persons, Person } from "../../helpers/interfaces/person";
import { Tab, TabProp } from "../../common/TabComponent";
import businessunitService from "../../services/api/database/businessunit.service";
import { IBusinessUnit } from "../../helpers/interfaces/businessunit";

type Props = {};

type State = {
  personale: Person[],
  filteredPersonale: Person[],
  tabsKeys: string[],
  tabsValues: string[],
  businessUnits: IBusinessUnit[]
  switchValues: boolean[]
}
export default class ElencoPersonale extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      personale: [],
      filteredPersonale: [],
      tabsKeys: [],
      tabsValues: [],
      businessUnits: [],
      switchValues: []
    }
  }

  async componentDidMount() {
    EventBus.dispatch("showLoader", { text: 'Caricamento personale in corso...' });

    const tabsPromise = personService.getAllOrganicoTabs();
    const businessUnits = businessunitService.getAllDatore();

    await Promise.all([tabsPromise, businessUnits])
      .then(
        response => {
          if (response) {
            const tabsKeys: any = Object.keys(response[0]);
            const tabsValues: any = Object.values(response[0]);

            // let reorderTabs = {
            //   [tabsKeys[1]]: tabsValues[1],
            //   [tabsKeys[2]]: tabsValues[2],
            //   [tabsKeys[3]]: tabsValues[3],
            //   [tabsKeys[4]]: tabsValues[4],
            //   [tabsKeys[0]]: tabsValues[0]
            // }

            let reorderTabs: any = {};
            for (let i = 1; i <= tabsKeys.length -1; i++) {
              reorderTabs[tabsKeys[i]] = tabsValues[i];
            }
            reorderTabs[tabsKeys[0]] = tabsValues[0];

            let reorderValues: string[] = []
            let reorderKeys: string[] = []
            Object.values(reorderTabs).forEach(
              (value: any, index: number) => {
                if (value !== "0") {
                  reorderValues.push(value)
                  reorderKeys.push(Object.keys(reorderTabs)[index])
                }
              }
            );
            let businessUnitConfig = localStorage.getItem('personale_business_unit_filters')
            let readSwitchValue = businessUnitConfig ? JSON.parse(businessUnitConfig) as boolean[] : new Array(response[1].length).fill(true)
            this.setState(
              {
                tabsKeys: reorderKeys,
                tabsValues: reorderValues,
                businessUnits: response[1],
                switchValues: readSwitchValue
              },
              () => this.callPersonale(0)
            )
          }
        },
        () => EventBus.dispatch("hideLoader")
      )
  }

  async callPersonale(currentTab: number) {
    const { tabsKeys, switchValues, businessUnits } = this.state
    EventBus.dispatch("showLoader", { text: 'Caricamento personale in corso...' });
    
    let personalePromise = personService.getAll(tabsKeys[currentTab]);
    await personalePromise
      .then(
        (response: Persons) => this.setState(
          {
            personale: response.data,
            filteredPersonale: response.data
          },
          () => {
            let totalPersonale: Person[] = []
            switchValues.forEach(
              (value, index) => {
                if (value === true) {
                  let selectedPersonale = response.data.filter(person => person.businessunit.id === businessUnits[index].id)
                  totalPersonale = totalPersonale.concat(selectedPersonale)
                }
              }
            )
            this.setState({ filteredPersonale: totalPersonale.sort((p1, p2) => p1.lastname.localeCompare(p2.lastname)) }, () => EventBus.dispatch("hideLoader"))
          }
        ),
        () => EventBus.dispatch("hideLoader")
      )
  }

  onSwitchChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { personale, switchValues, businessUnits, filteredPersonale } = this.state

    // change the switch flags to reflect the selection
    const index = Number(e.target.id.split('-')[1])
    let newSwitchValues = switchValues
    newSwitchValues.splice(index, 1, e.target.checked)

    // render again the table with the new data
    this.setState({ switchValues: newSwitchValues }, () => {
      localStorage.setItem('personale_business_unit_filters', JSON.stringify(switchValues))
      let totalPersonale: Person[] = filteredPersonale
      let selectedPersonale: Person[] = []
      selectedPersonale = personale.filter(person => person.businessunit.id === businessUnits[index].id)
      if (e.target.checked) {
        totalPersonale = totalPersonale.concat(selectedPersonale)
      } else {
        totalPersonale = totalPersonale.filter((el) => !selectedPersonale.includes(el));
      }
      this.setState({ filteredPersonale: totalPersonale.sort((p1, p2) => p1.lastname.localeCompare(p2.lastname)) })
    })
  }

  renderTabs(personale: Person[]): TabProp[] {
    const { tabsKeys, tabsValues, businessUnits, switchValues } = this.state
    let renderTabs: TabProp[] = []

    tabsKeys.forEach(
      (tab, index) => {
        const badge = parseInt(tabsValues[index].toString())
        renderTabs.push({
          id: 'tab_personale_' + tab,
          label: tab,
          hasBadge: true,
          badgeValue: badge,
          content: <React.Fragment>
            <h4 className="mb-3">Filtra per business unit</h4>
            <div className="d-flex">
              {
                businessUnits.map((businessUnit, index) => {
                  return <div key={businessUnit.id} className="form-check form-switch mx-2">
                    <input className="form-check-input" type="checkbox" role="switch" id={"switch-" + index} onChange={(e) => this.onSwitchChange(e)} defaultChecked={switchValues ? switchValues[index] : true} />
                    <label className="form-check-label" htmlFor={"switch-" + index}>{businessUnit.nome}</label>
                  </div>
                })
              }
            </div>
            <Table id={"table_personale_" + tab.toLowerCase()} columns={listPersonaleColumns} columnDefs={getListPersonaleColumnDefs()} datas={personale} buttons={null} />
          </React.Fragment >,
          isActive: false,
        })
      }
    )

    if (renderTabs.length > 0)
      renderTabs[0].isActive = true

    return renderTabs
  }

  render() {
    const { filteredPersonale } = this.state;
    return (<React.Fragment>
      <div className="custom-container">
        <div className="card">
          <div className="card-body">
            <h2 className="card-title">Elenco personale</h2>
            <StatsEmployeesComponent />
            <div className='mt-4'>
              <Tab useState={true} tabs={this.renderTabs(filteredPersonale)} tabClickHandler={async (index) => await this.callPersonale(index)} />
            </div>
          </div>
        </div>
      </div>

    </React.Fragment>
    );
  }
}