import { Component } from 'react';
import Swal from 'sweetalert2';
import EventBus from "../../../common/EventBus";
import RuoliService from "../../../services/api/database/ruoli.service";

import { getAddRuoloFormFields } from "./config/formFields/addRuolo";
import ModalForm from '../../../common/ModalForm';
import { iFormField } from '../../../helpers/interfaces/generic';

type Props = {
  ruoli: any,
  personId: number
};

type State = {
  data: Array<any>,
  showModal: boolean,
  modalType: string,
  formFields: Array<iFormField>,
  formInitialValues: { [key: string]: any },
  loading: boolean
}

const $ = require('jquery');
class RuoliComponent extends Component<Props, State> {
  formInitialValues: { [key: string]: any };
  apiSubmit: (data: any) => Promise<any>;


  constructor(props: Props) {
    super(props);
    this.state = {
      data: props.ruoli,
      showModal: false,
      modalType: 'add',
      formFields: [],
      formInitialValues: [],
      loading: false
    }

    //this.formFields = getAddAdempimentoFormFields();
    this.formInitialValues = {
      'person_id': props.personId
    };
    this.apiSubmit = RuoliService.personAdd;
  }

  async componentDidMount() {
    EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });
    const ruoli_all = await RuoliService.getAllDatore();
    EventBus.dispatch("hideLoader");
    let ruoli: any = ruoli_all.map((item: any) => { return { key: item.id, value: item.nome } });

    if (Object.keys(ruoli).length > 0) {
      this.setState({ formFields: getAddRuoloFormFields(ruoli, this.props.personId) });
    }

    $('.delete_btn').on('click', async (e: any) => {
      e.preventDefault();
      Swal.fire({
        title: "Vuoi confermare la cancellazione?",
        //text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Conferma',
        cancelButtonText: 'Annulla'
      }).then(async (result) => {
        if (result.isConfirmed) {
          const idRuolo = $(e.currentTarget).data('id');
          const ruolo: any = await RuoliService.personDelete(idRuolo);

          if (ruolo && typeof ruolo.error !== 'undefined') {
            Swal.fire(
              ruolo.error,
              '',
              'error'
            );
          } else {
            this.handleSuccess();
          }
        }
      });
    });
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  handleSuccess() {
    window.location.hash = "#ruoli";
    window.location.reload();
  }

  render() {
    const { data, showModal, modalType, formFields } = this.state;

    return data ? <div className="row p-2">
      <div className="d-flex align-items-center justify-content-between">
        <h3 className="m-0">Ruoli</h3>
        <button id="add_btn" type="button" className="btn btn-outline-primary" disabled={formFields.length === 0} onClick={() => this.setState({ showModal: true, modalType: 'add' })}>
          <span>Assegna ruolo</span>
        </button>
      </div>
      <ModalForm
        showModal={showModal}
        title={'Assegna ruolo'}
        modalType={modalType}
        formFields={formFields}
        initialValues={this.formInitialValues}
        apiSubmit={this.apiSubmit}
        closeCallback={this.closeModal.bind(this)}
        successAction={this.handleSuccess.bind(this)}
      />
      <div className='mt-3'>
        <ul className="list-group">
          {
            data.length > 0 ? data.map((value: any, index: number) => {
              return <li key={index} className="list-group-item">
                <div className="row align-items-center">
                  <div className="col-md-2">
                    <div className="delete_btn action-icon custom-icon btn btn-outline-danger rounded-circle" data-id={value.assegnazione_id}>
                      <i className="fa fa-trash" aria-hidden="true"></i>
                    </div>
                  </div>
                  <div className="col-md-10">{value.nome}</div>
                </div>
              </li>
            }) : <p>Non sono presenti ruoli</p>
          }
        </ul>
      </div>
    </div> : <div>Caricamento ruoli in corso..</div>
  }
}
export default RuoliComponent;