import { Component } from "react";

type Props = {
    parentCallback: any,
    clearBox: boolean
};

let inputValue = "";

export default class SearchBar extends Component<Props> {

    handleChange = (e: any) => {
        e.preventDefault();

        inputValue = e.target.value;

        this.props.parentCallback(
            e.target.value.toLowerCase()
        );
    };

    render() {
        return <div className='custom-container'>
            <input
                type="search"
                placeholder=""
                value={this.props.clearBox ? '' : inputValue}
                onChange={this.handleChange}
                className="search-bar col-12"
            />
            {/* <button className="btn fa fa-search"></button> */}
        </div>
    }
};