import * as Yup from "yup";
import { iFormField } from "../../helpers/interfaces/generic";

export function getNewGroupFormFields(): Array<iFormField> {
  return [
    {
      label: 'Nome',
      name: 'nome',
      type: 'text',
      class: 'form-control',
      validation: Yup.string().required("Campo obbligatorio")
    },
  ];
}