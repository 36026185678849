import React, { Component } from "react";
import Swal from 'sweetalert2';
import EventBus from "../../../common/EventBus";
import {
    checkFestivo, getNomeMese, getGiornoSettimana, giorniLavorativi
} from './../../../helpers/calendar';
import RendicontazioniService from "../../../services/api/rendicontazioni.service";
import PersonService from "../../../services/api/person.service";
import CausaliService from "../../../services/api/database/causali.service";
import reportings from './helper/reportings';
import DocumentiService from "../../../services/api/documenti.service";
import { base64Decode, openBase64NewTab } from '../../../common/Base64';
import ModalForm from '../../../common/ModalForm';
import {
    getModalRendicontazioneFields, joborderModalType
} from "../../../config/formFields/rendicontazione";
import { iconsStyle, resetButtonsStyle } from "../../../helpers/settings/buttons-icons-styles";
import { iFormField, iFormFieldOption } from "../../../helpers/interfaces/generic";
import { Person, PersonJoborder } from "../../../helpers/interfaces/person";
import CopyToClipboard from "react-copy-to-clipboard";
import { statusColors } from "../../../helpers/settings/status-settings";
import {
    iCausale, iOperatore, IPermessiStudioEsami, iStrumento, iTrasferta
} from "../../../helpers/interfaces/rendicontazione";
import { jobOrderChild } from "../../../helpers/interfaces/user";
import { iRendicontazione, iReportingInitialFormData } from "./helper/reportings";
import { FormikProps } from "formik";
import { IPlanningDevice } from "../../../helpers/interfaces/planning";
import { ToastContainer, toast } from 'react-toastify';
import attivitaService from "../../../services/api/database/attivita.service";
import { Activity } from "../../../helpers/interfaces/joborders";
import moment from "moment";
import 'moment/locale/it';
import 'react-toastify/dist/ReactToastify.css';
import './style.css';

const $ = require('jquery');

type Props = {
    match: any
};

type State = {
    year: number,
    month: number,
    giorniSettimana: any,
    giorniMese: any,
    giorniVuoti: any,
    oreMese: number,
    showModal: boolean,
    modalTitle: string;
    modalType: string,
    formFields: Array<iFormField>,
    formInitialValues: iReportingInitialFormData,
    formSubmit: (
        formData: any,
        formik: FormikProps<any>
    ) => any;
    errorMessage: string | null;
    copied: boolean;
    person?: Person,
    activities: Activity[],
    instruments: iFormFieldOption[],
    jobOrders: iFormFieldOption[],
    causals: iFormFieldOption[],
    instrumentsList: iStrumento[],
    filterInstruments: iFormFieldOption[],
    operators: iFormFieldOption[],
    transfer: iFormFieldOption[],
}

type iCommesse = {
    id: number,
    value: string,
    code: string,
    parent: Array<any> | null
}

export default class DettaglioRendicontazione extends Component<Props, State> {
    giorniSettimana: string[];
    initialValues: iReportingInitialFormData;

    constructor(props: Props) {
        super(props);

        this.giorniSettimana = [
            'Dom',
            'Lun',
            'Mar',
            'Mer',
            'Gio',
            'Ven',
            'Sab'
        ];

        this.initialValues = {
            ora_fine_hh: '0',
            ora_fine_mm: '00',
            ora_inizio_hh: '0',
            ora_inizio_mm: '00',
            tempo_viaggio_hh: '0',
            tempo_viaggio_mm: '0',
        }

        this.state = {
            year: 0,
            month: 0,
            giorniVuoti: [],
            giorniSettimana: [],
            giorniMese: [],
            oreMese: 0,
            showModal: false,
            modalTitle: 'Rendicontazione',
            modalType: 'add',
            formFields: [],
            formInitialValues: this.initialValues,
            errorMessage: null,
            copied: false,
            formSubmit: reportings.addSubmitModal.bind(this),
            person: undefined,
            activities: [],
            instruments: [],
            jobOrders: [],
            causals: [],
            instrumentsList: [],
            filterInstruments: [],
            operators: [],
            transfer: [],
        }
    }

    mapCommesse = (jobOrders: PersonJoborder[], parent: any = null): any[] => {
        let results: iCommesse[] = [];
        let subRes: iCommesse[] = [];

        jobOrders.forEach((item: any) => {
            const lvl: number = typeof item.lvl === 'undefined'? 0 : item.lvl;
            const char: string = "----".repeat(lvl);
            const name: string = typeof item.name === 'undefined'
                ? item.jobordername
                : item.name;
            const code: string = !parent ? item.code : item.identificationcode ?? '';

            results.push({ id: item.id, value: `${char} ${name}`, code, parent });

            if (typeof item.childs !== 'undefined' && item.childs.length > 0) {
                subRes = this.mapCommesse(item.childs, {
                    id: item.id,
                    value: name
                });

                subRes.forEach((item: any) => {
                    results.push(item);
                });
            }
        });

        return results;
    }

    async getPerson(): Promise<void> {
        const personId: number = parseInt(reportings.getPersonId());

        const person: any = await PersonService.getEmployee(personId);

        if (typeof person.error !== 'undefined') {
            Swal.fire(
                'errore generico',
                '',
                'error'
            );
        } else {
            this.setState({ person });
        }
    }

    async getActivities(): Promise<void> {
        await attivitaService.getAllDatore().then((activities: any) => {
            this.setState({activities});
        }).catch(() => {
            Swal.fire(
                'Errore',
                'Si è verificato un errore imprevisto durante il caricamento delle attività',
                'error'
            );
        });
    }

    async getInstruments(): Promise<void> {
        await RendicontazioniService.magazzinoStrumenti().then((
            instrumentsList: iStrumento[]
        ) => { 
            this.setState( {
                instrumentsList,
            })
        }).catch((reason) => {
            Swal.fire(
                'Errore',
                'Si è verificato un errore imprevisto durante il caricamento degli stumenti',
                'error'
            );
        });
    }

    async componentDidMount() {
        const rendicontazione = this.props.match.params.rendicontazione.split('-');
        if (rendicontazione.length === 3) {
            const personId = rendicontazione[0];
            const year = rendicontazione[1];
            const month = rendicontazione[2];

            const data = moment(year + '-' + month + '-01');
            const giorno_settimana = data.day();
            const giorni_1 = this.giorniSettimana.slice(
                0, giorno_settimana
            );
            const giorni_2 = this.giorniSettimana.slice(
                giorno_settimana,
                this.giorniSettimana.length
            );

            const daysInMonth = data.daysInMonth();

            EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });
            const workHours: number | false = await RendicontazioniService.getDatoreWorkHours(
                personId,
                year,
                month
            ).then((data: {
                workhours: number;
            }) => {
                return data.workhours;
            }).catch(() => {
                return false;
            });

            const permessiStudioEsami: Promise<IPermessiStudioEsami> = RendicontazioniService.getPersonPermessiStudioEsami(personId);
            const dettaglioRendicontazione: any = RendicontazioniService.getRendicontazione(personId, year, month);
            
            // dettaglio rendicontazione + permessi studio/esami
            await Promise.all([dettaglioRendicontazione, permessiStudioEsami])
                .then((data: any) => {
                    let dettaglio = { ...data[0], permessiStudioEsami: { ...data[1] } }

                    this.setState({
                        year,
                        month,
                        giorniVuoti: Array.from(Array(35 - daysInMonth).keys()),
                        giorniSettimana: [...giorni_2, ...giorni_1],
                        giorniMese: Array.from(Array(daysInMonth).keys()),
                        oreMese: workHours !== false ? workHours : giorniLavorativi(
                            year,
                            month,
                            dettaglio.firstday,
                            dettaglio.lastday
                        ) * 8
                    }, () => {
                        reportings.load(dettaglio, this);
                        EventBus.dispatch("hideLoader");
                    });
                },
                () => this.setState({
                    errorMessage: 'Mese non ancora rendicontato.',
                    year,
                    month,
                    giorniVuoti: Array.from(Array(35 - daysInMonth).keys()),
                    giorniSettimana: [...giorni_2, ...giorni_1],
                    giorniMese: Array.from(Array(daysInMonth).keys()),
                    oreMese: workHours !== false ? workHours : giorniLavorativi(
                        year,
                        month,
                        0,
                        daysInMonth
                    ) * 8
                }, () => {
                    reportings.load({}, this);
                    EventBus.dispatch("hideLoader");
                })
            )
            .catch(() => this.setState({
                    errorMessage: 'Mese non ancora rendicontato.',
                    year,
                    month,
                    giorniVuoti: Array.from(Array(35 - daysInMonth).keys()),
                    giorniSettimana: [...giorni_2, ...giorni_1],
                    giorniMese: Array.from(Array(daysInMonth).keys()),
                    oreMese: workHours !== false ? workHours : giorniLavorativi(
                        year,
                        month,
                        0,
                        daysInMonth
                    ) * 8
                }, () => {
                    EventBus.dispatch("hideLoader");
                })
            );

            $(".day").on('click', ".editParent", (
                event: JQuery.ClickEvent<HTMLElement, null, HTMLElement, HTMLElement>
            ) => {
                const data: iRendicontazione = typeof event.currentTarget.dataset.edit !== 'undefined'
                    && JSON.parse(base64Decode(event.currentTarget.dataset.edit))
                const day: number = Number($(event.target).parents('.rendicontazione').attr('day'));
                const index: number = Number($(event.target).parents('.rendicontazione').attr('index'));
    
                reportings.setSelectedIndex(index);
    
                const ora_inizio: string[] = data.ora.inizio.split(':');
                const ora_fine: string[] = data.ora.fine.split(':');
                const viaggio: string[] = data.viaggio.tempo !== null
                    ? data.viaggio.tempo.split(':') : ['0', '00'];
                const pausa_id: string | undefined = typeof data.pausa.id === 'string'
                    ? data.pausa.id
                    : undefined;
                const pausa_text: string | undefined = typeof data.pausa.text?.trim() === 'string'
                    ? data.pausa.text?.trim()
                    : undefined;
    
                const formInitialValues: iReportingInitialFormData = {
                    tipologia: data.commessa.id !== null ? 'commessa' : 'causale',
                    commessa: data.commessa ? `${data.commessa.id}||${data.commessa.text?.trim()}||${data.commessa.code?.trim()}` : undefined,
                    causale: data.causale ? `${data.causale.id}||${data.causale.text?.trim()}` : undefined,
                    strumento: data.dispositivo.id && data.dispositivo.text?.trim()
                        ? `${data.dispositivo.id}||${data.dispositivo.text?.trim()}` : undefined,
                    operatore: data.operatore.id && data.operatore.text?.trim()
                        ? `${data.operatore.id}||${data.operatore.text?.trim()}` : undefined,
                    trasferta: data.trasferta.id && data.trasferta.text?.trim()
                        ? `${data.trasferta.id}||${data.trasferta.text?.trim()}` : undefined,
                    ora_fine_hh: ora_fine[0],
                    ora_fine_mm: ora_fine[1] === '30' ? '30' : '00',
                    ora_inizio_hh: ora_inizio[0],
                    ora_inizio_mm: ora_inizio[1] === '30' ? '30' : '00',
                    tempo_viaggio_hh: viaggio[0],
                    tempo_viaggio_mm: viaggio[1],
                    pausa: pausa_id && pausa_text ? `${pausa_id}||${pausa_text}`: "",
                    note: data.note,
                    rit_send: data.ritSend ? data.ritSend : false,
                    measures_sent: data.measuresSent ? data.measuresSent : false
                };

                if (data.commessa.id) {
                    Promise.all([
                        this.getInstruments(),
                        this.getActivities(),
                        this.getPerson()
                    ]).then(() => {
                        this.setState( {
                            formSubmit: reportings.editSubmitModal,
                            formInitialValues
                        }, () => {
                            let jobOrderAll: PersonJoborder[] = [];

                            if (typeof this.state.person !== 'undefined') {
                                jobOrderAll = this.state.person.personjoborders.active.concat(
                                    this.state.person.personjoborders.expired
                                )
                            }

                            let jobOrder: any = null;
                            let childJobOrder: any = null

                            jobOrderAll && jobOrderAll.forEach((
                                parent
                            ) => {
                                if (parent.id === data.commessa.id) {
                                    jobOrder = parent;
                                } else {
                                    parent.childs?.forEach((child) => {
                                        if (child.id === data.commessa.id) {
                                            jobOrder = parent;
                                            childJobOrder = child;
                                        }
                                    })
                                }
                            });
        
                            if (!jobOrder) {
                                return Swal.fire(
                                    'Errore',
                                    'Non è stato possibile recuperare i dati relativi alla commessa',
                                    'error'
                                );
                            }
        
                            let editDevices: IPlanningDevice[] | undefined = undefined;
        
                            if (childJobOrder) {
                                const activity: Activity = this.state.activities.filter((item) => {
                                    return item.code === childJobOrder.identificationcode
                                })[0];
        
                                if (!activity) {
                                    return Swal.fire(
                                        'Errore',
                                        'Non è stato possibile recuperare i dati relativi all\'attività',
                                        'error'
                                    );
                                }
        
                                editDevices = [];
        
                                this.state.instrumentsList.forEach((
                                    instrument: iStrumento
                                ) => {
                                    activity.instruments.forEach((
                                        item: number
                                    ) => {
                                        if (item === instrument.id) {
                                            editDevices?.push({
                                                id: instrument.id,
                                                code: instrument.code,
                                                marca_modello: instrument.marca_modello,
                                                tipologia: instrument.tipologia_nome,
                                                serial_number: instrument.serial_number,
                                            })
                                        }
                                    })
                                })
                            }
        
                            this.openModalRendicontazione(
                                this.state.year,
                                this.state.month,
                                day,
                                'edit',
                                editDevices
                            )
                        })
                    });
                } else {
                    this.setState({
                        formSubmit: reportings.editSubmitModal,
                        formInitialValues
                    }, async () => {
                        await this.openModalRendicontazione(
                            this.state.year,
                            this.state.month,
                            day,
                            'edit',
                        )
                    });
                }
            });
        }
    }

    async downloadDocument(id: number) {
        EventBus.dispatch("showLoader", { text: 'Caricamento Documento in corso...' });

        const idDocumento = id;
        const documento: any = await DocumentiService.download(idDocumento);

        if (typeof documento.body !== 'undefined') {
            openBase64NewTab(documento);
        }

        EventBus.dispatch("hideLoader");
    }

    async modifyMonthRendicontazione(action: string) {
        EventBus.dispatch("showLoader", { text: 'Operazione in corso...' });
        await reportings.updateRendicontazione(action);
        EventBus.dispatch("hideLoader");
    }

    async updateRendicontazione(status: string) {
        EventBus.dispatch("showLoader", { text: 'Operazione in corso...' });
        await RendicontazioniService.amministrazioneUpdateRendicontazione(reportings.getRendicontazioneId(), { status });
        EventBus.dispatch("hideLoader");
        window.location.reload();
    }

    updateCommesseCallBack = async(
        value: string,
        formik: FormikProps<any>,
        type: joborderModalType
    ) => {
        const id: number = Number(value.split('|')[0]);

        const allJobOrders: any[] = this.state.person
            ? this.state.person?.personjoborders.active.concat(
                this.state.person.personjoborders.expired
            )
            : [];

        let parentJobOrder: any;
        let selectedJobOrder: any;
        
        allJobOrders?.forEach((jobOrder: PersonJoborder) => {
            return jobOrder.childs?.forEach((
                subJobOrder: jobOrderChild
            ) => {
                if(subJobOrder.id === id) {
                    selectedJobOrder = subJobOrder;

                    parentJobOrder = {
                        id: jobOrder.id,
                        name: jobOrder.name,
                        code: jobOrder.code
                    }
                };
            })
        });

        let filterInstruments: iFormFieldOption[] = this.state.instrumentsList.map((
            item: iStrumento
        ) => {
            return {
                value: `${item.id}||${item.marca_modello} (${(item.code ? item.code : 'n.d.')})`,
                label: `${item.tipologia_nome} - ${item.marca_modello} (${item.serial_number})`
            }
        });

        let activity: Activity | null = null;

        if (selectedJobOrder) {
            activity = this.state.activities.filter((item: Activity) => {
                return item.code === selectedJobOrder.identificationcode
            })[0];
        }

        if (parentJobOrder && selectedJobOrder && activity) {
            await attivitaService.getActivityInstruments(activity.id).then((
                toolIds: number[]
            ) => {
                const jobOrderInstruments: iStrumento[] = [];

                this.state.instrumentsList.forEach((tool) => {
                    return toolIds.forEach((id) => {
                        if (tool.id === id) {
                            jobOrderInstruments.push(tool);
                        }
                    })
                });

                filterInstruments = jobOrderInstruments.map((
                    item: iStrumento
                ) => {
                    return {
                        value: `${item.id}||${item.marca_modello} (${(item.code ? item.code : 'n.d.')})`,
                        label: `${item.tipologia_nome} - ${item.marca_modello} (${item.serial_number})`
                    }
                });

                this.setState({
                    filterInstruments
                }, () => {
                    this.setState({
                        formFields: getModalRendicontazioneFields(
                            this.state.jobOrders,
                            this.state.causals,
                            this.state.filterInstruments,
                            this.state.operators,
                            this.state.transfer,
                            type,
                            this.updateCommesseCallBack
                        ),
                    });
                });
            });
        }

        this.setState({ filterInstruments }, () => {
            this.setState({
                formFields: getModalRendicontazioneFields(
                    this.state.jobOrders,
                    this.state.causals,
                    this.state.filterInstruments,
                    this.state.operators,
                    this.state.transfer,
                    type,
                    this.updateCommesseCallBack
                ),
            });
        });

        if (parentJobOrder) {
            formik.setFieldValue(
                'parent',
                `${parentJobOrder.id}||${parentJobOrder.name}||${parentJobOrder.code}`
            );
        } else {
            formik.setFieldValue('parent', undefined);
        }

        formik.setFieldValue('strumento', '');
    }

    openModalRendicontazione = async (
        year: number,
        month: number,
        day: number,
        type: joborderModalType = 'add',
        devices: IPlanningDevice[] | null = null
    ) => {
        EventBus.dispatch("showLoader", {
            text: 'Caricamento dati in corso...'
        });

        reportings.setSelectedDay(year, month, day);

        await this.getPerson();

        const causaliAll: iCausale[] | null = await CausaliService.getAllDatore()
            .then(( response: iCausale[] ) => { return response } )
            .catch((reason) => { return null });

        const causali: iFormFieldOption[] = causaliAll
            ? causaliAll.map((item: iCausale) => {
                return {
                    value: `${item.id}||${item.name.trim()}`,
                    label: item.name.trim()
                }
            })
            : [];

        let strumenti: iFormFieldOption[] = [];

        if (!devices) {
            strumenti = this.state.instrumentsList
                ? this.state.instrumentsList.map((item: iStrumento) => {
                    return {
                        value: `${item.id}||${item.marca_modello} (${(item.code ? item.code : 'n.d.')})`,
                        // label: `${item.marca_modello} (${(item.code ? item.code : 'n.d.')})`
                        label: `${item.tipologia_nome} - ${item.marca_modello} (${item.serial_number})`
                    }
                }) : [];
        } else {
            strumenti = devices.map((item: IPlanningDevice) => {
                return {
                    value: `${item.id}||${item.marca_modello} (${(item.code ? item.code : 'n.d.')})`,
                    label: `${item.marca_modello} (${(item.code ? item.code : 'n.d.')})`
                }
            })
        }

        const operatoriAll: iOperatore[] | null = await RendicontazioniService.getOperatori()
            .then((response: iOperatore[]) => { return response } )
            .catch((reason) => { return null });

        const operatori: iFormFieldOption[] = operatoriAll
            ? operatoriAll.map((item: iOperatore) => { return {
                value: `${item.id}||${item.name.trim()}`,
                label: item.name.trim()
            }})
            : [];

        const trasferteAll: iTrasferta[] | null = await RendicontazioniService.getTrasferte()
            .then((response: iTrasferta[]) => { return response } )
            .catch((reason) => { return null });

        const trasferte: iFormFieldOption[] = trasferteAll
            ? trasferteAll.map((item: iTrasferta) => { return {
                value: `${item.id}||${item.name.trim()}`,
                label: item.name.trim()
            }})
            : [];

        const date: moment.Moment = moment(
            year.toString() + '/' + month.toString() + '/' + day.toString()
        );

        const jobordersActive: any[] = this.mapCommesse(
            this.state.person
            ? this.state.person.personjoborders.active
            : []
        );

        let jobordersExpiredArray: PersonJoborder[] = [];

        this.state.person &&
        this.state.person.personjoborders.expired.length > 0 &&
        this.state.person.personjoborders.expired.forEach((
            joborder: PersonJoborder
        ) => {
            const startDate: moment.Moment = moment(joborder.startdate);
            const endDate: moment.Moment = moment(joborder.enddate);

            if (date.isBetween(startDate, endDate, 'days', "[]")) {
                jobordersExpiredArray.push(joborder);
            }
        });

        let jobordersExpired: any[] = this.mapCommesse(jobordersExpiredArray);

        const jobordersAll: iCommesse[] = jobordersActive.concat(jobordersExpired);
        const jobOrders: iFormFieldOption[] = jobordersAll.map((item: iCommesse) => {
            return {
                value: `${item.id}||${item.code.trim()} | ${item.value.trim()}||${item.code.trim()}`,
                label: item.value.trim()
            }
        });

        const days: number = $('#rendicontazione .day.selected').length;

        let modalTitle: string = 'Rendicontazione (';
        if (days === 0) {
            modalTitle += ('0' + day).slice(-2) + '-' + ('0' + month).slice(-2) + '-' + year;
        } else if (days === 1) {
            modalTitle += days + ' giorno';
        } else {
            modalTitle += days + ' giorni';
        }
        modalTitle += ')'

        this.setState({
            jobOrders,
            causals: causali,
            instruments: strumenti,
            filterInstruments: strumenti,
            operators: operatori,
            transfer: trasferte,
        }, () => {
            this.setState({
                formFields: getModalRendicontazioneFields(
                    jobOrders,
                    causali,
                    strumenti,
                    operatori,
                    trasferte,
                    type,
                    this.updateCommesseCallBack
                ),
            }, () => {
                this.setState({
                    showModal: true,
                    modalTitle,
                    modalType: type,
                });
            });
        });

        EventBus.dispatch("hideLoader");
    }

    closeModal() {
        this.setState({
            showModal: false,
            formInitialValues: this.initialValues
        });
    }

    render() {
        const { giorniSettimana, giorniMese, giorniVuoti, year, month, oreMese, showModal,
            modalTitle, modalType, formFields, formInitialValues, errorMessage, formSubmit
        } = this.state;
        const statoColor: any = {
            'O': { color: 'bg-cyan', text: 'Aperto' },
            'C': { color: 'bg-azure', text: 'Confermato' },
            'A': { color: 'bg-green', text: 'Accettato' },
            'R': { color: 'bg-red', text: 'Rifiutato' }
        };
        const permessiStudioEsami = reportings.getPersonPermessiStudioEsami()
        const personName = reportings.getPersonName()
        const personCompanyEmail = reportings.getPersonCompanyEmail()
        const personPersonalEmail = reportings.getPersonPersonalEmail()
        const personPhone = reportings.getPersonPhone()
        const personFirstDay = reportings.getFirstDay()
        const personLastDay = reportings.getLastDay()

        return <React.Fragment>
            <div className="card" id="rendicontazione">
                {
                    personName && <div
                        className='card-body d-flex flex-column flex-lg-row align-items-center justify-content-start'
                    >
                        <h3 className="col-12 d-flex align-items-center justify-content-start p-2 col-lg-3 m-0 px-lg-2">
                            {
                                reportings.getPersonStatus() === 'E' || reportings.getPersonStatus() === 'N' ?
                                    <div style={{
                                        width: '16px',
                                        height: '16px',
                                        backgroundColor: statusColors[3].color
                                    }} className="rounded-circle"></div>
                                    : (
                                        reportings.getPersonStatus() === 'S' ?
                                            <div style={{
                                                width: '16px',
                                                height: '16px',
                                                backgroundColor: statusColors[1].color
                                            }} className="rounded-circle"></div>
                                            : <div style={{
                                                width: '16px',
                                                height: '16px',
                                                backgroundColor: statusColors[2].color
                                            }} className="rounded-circle"></div>
                                    )
                            }
                            <span className="ms-2">
                                {reportings.getPersonName()}
                            </span>
                        </h3>
                        {
                            personPersonalEmail && <CopyToClipboard
                                text={personPersonalEmail}
                                onCopy={() => {
                                    this.setState({ copied: true },
                                    () => {
                                        toast.success(
                                            'Email personale copiata!',
                                            { className: "bg-dark" }
                                        )
                                    })
                                }}>
                                    <div
                                        className="col-12 col-lg-3 d-flex justify-content-start align-items-center px-2"
                                    >
                                        Email personale{' '}
                                        <button style={resetButtonsStyle} className='nav-link m-0'>
                                            {personPersonalEmail}
                                        </button>
                                    </div>
                            </CopyToClipboard>
                        }
                        {
                            personCompanyEmail && <CopyToClipboard
                                text={personCompanyEmail}
                                onCopy={() => {
                                    this.setState(
                                        { copied: true },
                                        () => {
                                            toast.success('Email aziendale copiata!',
                                            { className: "bg-dark" })
                                        }
                                    )
                                }}>
                                <div
                                    className="col-12 col-lg-3 d-flex justify-content-start align-items-center px-2"
                                >
                                    Email aziendale{' '}
                                    <button style={resetButtonsStyle} className='nav-link m-0'>
                                        {personCompanyEmail}
                                    </button>
                                </div>
                            </CopyToClipboard>
                        }
                        {
                            personPhone && <CopyToClipboard
                                text={personPhone}
                                onCopy={() => {
                                    this.setState(
                                        { copied: true },
                                        () => {
                                            toast.success('Numero copiato!', { className: "bg-dark" })
                                        })
                                }}>
                                <div
                                    className="col-12 col-lg-3 d-flex justify-content-start align-items-center px-2"
                                >
                                    Numero di telefono{' '}
                                    <button style={resetButtonsStyle} className='nav-link m-0'>
                                        {personPhone}
                                    </button>
                                </div>
                            </CopyToClipboard>
                        }
                    </div>
                }
                {
                    permessiStudioEsami && <div className='card-body d-flex flex-column flex-lg-row align-items-center justify-content-start'>
                        <div className="col-12 col-lg-6 d-flex justify-content-start align-items-center p-2">
                            <strong>Permessi esami degli ultimi 3 anni</strong>{' '}
                            <h3 className={"mb-0 ms-2 " + (permessiStudioEsami.overesame ? 'text-danger' : 'text-success')}>
                                {permessiStudioEsami.oreesame} / {permessiStudioEsami.limiteesame}:00 ore
                            </h3>
                        </div>
                        <div className="col-12 col-lg-6 d-flex justify-content-start align-items-center p-2">
                            <strong>Permessi studio dell'ultimo anno</strong>{' '}
                            <h3 className={"mb-0 ms-2 " + (permessiStudioEsami.overstudio ? 'text-danger' : 'text-success')}>
                                {permessiStudioEsami.orestudio} / {permessiStudioEsami.limitestudio}:00 ore
                            </h3>
                        </div>
                    </div>
                }
                <ModalForm
                    showModal={showModal}
                    title={modalTitle}
                    modalType={modalType}
                    formFields={formFields}
                    initialValues={formInitialValues}
                    apiSubmit={() => {}}
                    submitCallback={formSubmit}
                    closeCallback={this.closeModal.bind(this)}
                />
                {errorMessage && <div className="d-flex align-items-center justify-content-center bg-danger p-2">
                    <h3 className="m-0 me-2">Attenzione!</h3> {errorMessage}
                </div>}
                <div className="card-body">
                    <div className="card-title d-flex align-items-center justify-content-between mb-3 px-2">
                        <h2 className="mb-0 d-flex">
                            Rendicontazione - {getNomeMese(month - 1)} {year}
                            {
                                personLastDay !== 0 && personFirstDay !== 0 &&
                                <p className="mb-0 ms-2">(dal {personFirstDay}/{month} al {personLastDay}/{month})</p>
                            }
                            {
                                personLastDay !== 0 && personFirstDay === 0 &&
                                <p className="mb-0 ms-2">(dal 1/{month} al {personLastDay}/{month})</p>
                            }
                            {
                                personLastDay === 0 && personFirstDay !== 0 &&
                                <p className="mb-0 ms-2">(dal {personFirstDay}/{month} all'ultimo del mese)</p>
                            }
                        </h2>
                        <span className={"badge " + statoColor[reportings.getStatoCode()].color}>
                            {statoColor[reportings.getStatoCode()].text}
                        </span>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="container-fluid">
                                <header>
                                    <div className="row d-sm-flex text-white days-week">
                                        {giorniSettimana.map((giorno: string) => {
                                            return <h5 key={'g' + giorno} className="col-sm p-1 text-center">{giorno}</h5>
                                        })}
                                    </div>
                                </header>
                                <div className="row border border-right-0 border-bottom-0 newbg">
                                    {giorniMese.map((index: string) => {
                                        const giorno = Number(index + 1);
                                        const festivo = checkFestivo(year, month, giorno);
                                        const classNameContainer = [
                                            "position-relative",
                                            "d" + giorno,
                                            "day col-sm p-2 border border-left-0 border-top-0",
                                            (festivo ? 'festivo' : '')
                                        ];
                                        const classNameDay = [
                                            "day-number col-2",
                                            (festivo ? 'festivo' : '')
                                        ];

                                        return <React.Fragment key={"d" + giorno}>
                                            <div id={"d" + giorno} className={classNameContainer.join(" ")}>
                                                <span id={"totale-giorno-" + giorno} className="totale-ore-giorno"></span>
                                                <h5 className="row align-items-center">
                                                    {['C', 'A'].includes(reportings.getStatoCode()) &&
                                                        <div onClick={() => {
                                                            this.setState({
                                                                formSubmit: reportings.addSubmitModal,
                                                            }, async () => {
                                                                await Promise.all([
                                                                    this.getInstruments(),
                                                                    this.getActivities(),
                                                                ]).then(() => {
                                                                    this.openModalRendicontazione(
                                                                        year,
                                                                        month,
                                                                        giorno
                                                                    )
                                                                });
                                                            });
                                                        }}>
                                                            <i
                                                                style={{ fontSize: "18px" }}
                                                                className="fa fa-plus-circle icon text-primary"
                                                                aria-hidden="true"
                                                            ></i>
                                                        </div>
                                                    }
                                                    <div className="row align-items-center p-r-0">
                                                        <span className="col-12 col-lg-6 pe-0">
                                                            <span className={classNameDay.join(" ")}>{giorno}</span>
                                                            <small className="col d-sm-none text-center text-muted">
                                                                {getGiornoSettimana(year, month, giorno)}
                                                            </small>
                                                        </span>
                                                        <span className="col-12 col-lg-6 pe-0">
                                                            <span
                                                                style={{ fontSize: "18px" }}
                                                                id={"buono-pasto-" + giorno}
                                                                className="fa fa-ticket text-primary meal-voucher-icon-amm"
                                                                title="Buono pasto"
                                                                aria-hidden="true"
                                                                // hidden={ !reportings.isMealVoucherAssignable()[giorno - 1] }
                                                            >
                                                            </span>
                                                        </span>
                                                    </div>  
                                                    <span className="col-1"></span>
                                                </h5>
                                                <div id={"giorno-" + giorno}></div>
                                            </div>
                                            {giorno % 7 === 0 ? <div className="w-100"></div> : ''}
                                        </React.Fragment>
                                        //return <div>{giorno+1}</div>
                                    })}
                                    {giorniVuoti.map((index: string) => {
                                        return <div
                                            key={"subD" + index}
                                            className="day col-sm p-2 border border-left-0 border-top-0 text-truncate "
                                        >
                                            <h5 className="row align-items-center">
                                                <span className="date col-1"></span>
                                                <small className="col d-sm-none text-center text-muted"></small>
                                                <span className="col-1"></span>
                                            </h5>
                                        </div>
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4">
                            <div className="mt-4 card-body">
                                <label>Documenti Allegati:</label>
                                <div>
                                    {reportings.getAttachements()?.length > 0 ?
                                        reportings.getAttachements().map((item: any, index: number) => {
                                            return <div key={index}>
                                                {item.path}
                                                <div onClick={() => this.downloadDocument(item.id)}>
                                                    <i
                                                        style={iconsStyle}
                                                        className="fa fa-download"
                                                        aria-hidden="true"
                                                    ></i>
                                                </div>
                                            </div>
                                        })
                                        : <div>nessuno</div>
                                    }
                                </div>
                            </div>
                            <div className="mt-4">
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title totale-ore-title">
                                            Totale ore
                                            <span className="tot-monthly">
                                                <span id="totale-ore">00:00</span>
                                                {' '}/{' '}
                                                <span className="oreMese">{oreMese}:00</span>
                                            </span>
                                        </h3>
                                    </div>
                                    <div className="card-header">
                                        <h3 className="card-title totale-ore-title">
                                            Totale buoni pasto
                                            {/* <span id="totale-buoni-pasto" className="tot-monthly">{reportings.totaleBuoniPasto()}</span> */}
                                            <span id="totale-buoni-pasto" className="tot-monthly"></span>
                                        </h3>
                                    </div>
                                    <div className="card-body p-0 mt-0">
                                        <div>
                                            <table
                                                id="rendicontazione-totale-ore"
                                                className="table nodefault"
                                            >
                                                <tbody>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 offset-md-2 mt-4">
                            {['C', 'A'].includes(reportings.getStatoCode()) &&
                                <div className="row justify-content-end px-2">
                                    <div className="col-md-4">
                                        <button
                                            value="R"
                                            className="btn btn-block btn-warning"
                                            onClick={() => {
                                                this.updateRendicontazione('R')
                                            }}
                                        >
                                            Rifiuta Mese
                                        </button>
                                    </div>
                                    {reportings.getStatoCode() === 'C' &&
                                        <div className="col-md-4">
                                            <button
                                                value="A"
                                                className="btn btn-block btn-success"
                                                onClick={() => {
                                                    this.updateRendicontazione('A')
                                                }}
                                            >
                                                Accetta Mese
                                            </button>
                                        </div>
                                    }
                                    <div className="col-md-4">
                                        <button
                                            className="btn btn-block btn-danger"
                                            onClick={() => {
                                                this.modifyMonthRendicontazione('M')
                                            }}
                                        >
                                            Modifica Mese
                                        </button>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            {
                this.state.copied ? <ToastContainer
                    position="bottom-center"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    rtl={false}
                    pauseOnFocusLoss
                    pauseOnHover
                    theme="dark"
                /> : null
            }
        </React.Fragment>
    }
}