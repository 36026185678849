import { ErrorMessage, Field, Form, Formik } from "formik";
import { Component } from "react";
import { Redirect } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from 'yup';
import EventBus from "../common/EventBus";
import { IChangePassword } from "../helpers/interfaces/user";
import { buttonsStyle, iconsStyle } from "../helpers/settings/buttons-icons-styles";
import { default as AuthService, default as authService } from "../services/auth.service";
import IUser from "../types/user.type";

type Props = {};

type State = {
  redirect: string | null,
  userReady: boolean,
  currentUser: IUser,
  loading: boolean,
  disabledForm: boolean,
  message: string,
  initialValues: IChangePassword
}
export default class Profile extends Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = {
      redirect: null,
      userReady: false,
      currentUser: {} as IUser,
      loading: false,
      disabledForm: true,
      message: "",
      initialValues: {} as IChangePassword
    };

    this.handleChangePassword = this.handleChangePassword.bind(this)
  }

  async componentDidMount() {
    const currentUser = await AuthService.getCurrentUser();
    if (currentUser) this.setState({ currentUser, userReady: true, initialValues: { passwordold: "", password1: "", password2: "" } })
  }

  validationSchema() {
    return Yup.object().shape({
      passwordold: Yup.string().required("Campo obbligatorio"),
      password1: Yup.string().required("Campo obbligatorio").min(8, 'La password deve contenere minimo 8 caratteri'),
      password2: Yup.string().required("Campo obbligatorio").oneOf([Yup.ref('password1'), null], 'Le password devono coincidere')
    });
  }

  async handleChangePassword(values: IChangePassword) {
    EventBus.dispatch("showLoader", { text: 'Eliminazione in corso...' });

    this.setState({
      loading: true
    });

    await authService.changePassword(values).then(
      () => {
        EventBus.dispatch("hideLoader");

        Swal.fire(
          'Operazione eseguita.',
          '',
          'success'
        );

        this.setState({
          loading: false
        })
      }, error => {
        EventBus.dispatch("hideLoader");

        Swal.fire(
          'Errore',
          '',
          'error'
        );

        this.setState({
          loading: false
        });
      }
    )
  }

  render() {
    const { currentUser, userReady, initialValues, loading, message, disabledForm, redirect } = this.state;

    if (redirect) {
      return <Redirect to={redirect} />
    }

    return (
      <div className="custom-container">
        {
          userReady ?
            <div className="d-flex flex-column flex-md-row align-items-center align-items-md-stretch">
              <div className="col-12 col-sm-8 col-md-4 col-lg-3">
                <div className="card h-100">
                  <div className="card-body h-100 d-flex flex-column align-items-center justify-content-center">
                    <div className="avatar avatar-xl mb-3 avatar-rounded">
                      <i className="fa fa-user-circle-o fa-lg text-secondary" aria-hidden="true"></i>
                    </div>
                    <h3 className="m-0 mb-1">{currentUser.name} {currentUser.lastname}</h3>
                    <div className="mt-3 d-flex">
                      {
                        currentUser.roles &&
                        currentUser.roles.map(
                          (role, index) => (
                            <span className="badge badge-pill bg-primary mx-1" key={index + '_' + Date.now()}>{role.name}</span>
                          )
                        )
                      }
                    </div>
                    <div className="mt-3">
                      <span className="badge bg-purple-lt">{currentUser.registrationemail}</span>
                    </div>
                    <div className='d-flex justify-content-center mt-3'>
                      <img className="logo" width="116px" src={currentUser.businessunit.logourl} alt="businessunit-profile-logo" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-8 col-md-8 col-lg-9">
                <div className="card ms-0 ms-md-2 mt-2 mt-md-0 h-100">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={this.validationSchema}
                    onSubmit={this.handleChangePassword}
                  >
                    <Form>
                      <div className="card-body">
                        <div className='d-flex justify-content-between align-items-center'>
                          <h3>Modifica la tua password</h3>
                          <button style={buttonsStyle} className='btn rounded-circle' type='button' onClick={() => this.setState({ disabledForm: !disabledForm })} >
                            {
                              disabledForm ?
                                <i style={iconsStyle} className="fa fa-lock text-danger" aria-hidden="true"></i> :
                                <i style={iconsStyle} className="fa fa-unlock-alt text-success" aria-hidden="true"></i>
                            }
                          </button>
                        </div>
                        <div className="my-3">
                          <label className="form-label">Vecchia password</label>
                          <Field name="passwordold" type="password" className="form-control" disabled={disabledForm} />
                          <ErrorMessage
                            name="passwordold"
                            component="div"
                            className="alert alert-danger"
                          />
                        </div>
                        <div className="mb-3">
                          <label className="form-label">
                            Nuova password
                          </label>
                          <Field name="password1" type="password" className="form-control" disabled={disabledForm} />
                          <ErrorMessage
                            name="password1"
                            component="div"
                            className="alert alert-danger"
                          />
                        </div>
                        <div className="mb-3">
                          <label className="form-label">
                            Ripeti password
                          </label>
                          <Field name="password2" type="password" className="form-control" disabled={disabledForm} />
                          <ErrorMessage
                            name="password2"
                            component="div"
                            className="alert alert-danger"
                          />
                        </div>
                        <div className="d-flex justify-content-end">
                          <button type="submit" className="btn btn-primary" disabled={loading || disabledForm}>
                            {loading && (
                              <span className="spinner-border spinner-border-sm me-1"></span>
                            )}
                            <span>Salva</span>
                          </button>
                        </div>
                        {message && (
                          <div className="form-group">
                            <div className="alert alert-danger" role="alert">
                              {message}
                            </div>
                          </div>
                        )}
                      </div>

                    </Form>
                  </Formik>
                </div>
              </div>
            </div>
            : null
        }
      </div>
    );
  }
}