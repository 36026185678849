import axios from '../../api';

const API_URL = process.env.REACT_APP_API_URL;

class ComuniService {
  async getAll() {
    try {
      const response = await axios.get(API_URL + 'database/comune');
      return Array.isArray(response.data) ? response.data : [];
    } catch (error) {
      return await Promise.reject();
    }
  }

  async getAllPatrono(): Promise<{ id: number, nome: string }[]> {
    try {
      const response = await axios.get<{ id: number; nome: string; }[]>(API_URL + 'datore/comune/patrono');
      return Array.isArray(response.data) ? response.data : [];
    } catch (error) {
      return await Promise.reject();
    }
  }

  async get(id: any) {
    try {
      const response = await axios.get(API_URL + 'database/comune/get/' + id);
      return typeof response.data == 'object' ? response.data : null;
    } catch (error) {
      return await Promise.reject();
    };
  }

  async put(id: any, data: any) {
    try {
      const response = await axios.put(API_URL + 'database/comune/' + id, {
        ...data
      });
      return response.data;
    } catch (error) {
      return [];
    }
  }

  async add(data: any) {
    try {
      const response = await axios.post(API_URL + 'database/comune', {
        ...data
      });
      return response.data;
    } catch (error) {
      return [];
    }
  }

}

export default new ComuniService();