import * as Yup from "yup";
import { iFormField, iFormFieldOption } from "../../helpers/interfaces/generic";

export function getOreSettimanaliFormFields(
  persons: iFormFieldOption[],
  type: 'add' | 'edit' = 'add'
): Array<iFormField> {
  return [
    {
      label: 'Persona',
      name: 'person',
      type: type === 'add' ? 'search-select' : 'unset',
      options: persons,
      class: 'form-control',
      validation: Yup.number().required("Campo obbligatorio"),
    },
    {
      label: 'Domenica',
      name: 'domenica',
      type: 'number',
      class: 'form-control',
      min: 0,
      max: 24,
      validation: Yup.number().required("Campo obbligatorio")
    },
    {
      label: 'Lunedì',
      name: 'lunedi',
      type: 'number',
      class: 'form-control',
      min: 0,
      max: 24,
      validation: Yup.number().required("Campo obbligatorio")
    },
    {
      label: 'Martedì',
      name: 'martedi',
      type: 'number',
      class: 'form-control',
      min: 0,
      max: 24,
      validation: Yup.number().required("Campo obbligatorio")
    },
    {
      label: 'Mercoledì',
      name: 'mercoledi',
      type: 'number',
      class: 'form-control',
      min: 0,
      max: 24,
      validation: Yup.number().required("Campo obbligatorio")
    },
    {
      label: 'Giovedì',
      name: 'giovedi',
      type: 'number',
      class: 'form-control',
      min: 0,
      max: 24,
      validation: Yup.number().required("Campo obbligatorio")
    },
    {
      label: 'Venerdì',
      name: 'venerdi',
      type: 'number',
      class: 'form-control',
      min: 0,
      max: 24,
      validation: Yup.number().required("Campo obbligatorio")
    },
    {
      label: 'Sabato',
      name: 'sabato',
      type: 'number',
      class: 'form-control',
      min: 0,
      max: 24,
      validation: Yup.number().required("Campo obbligatorio")
    },
  ];
}