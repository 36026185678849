import * as Yup from "yup";
import { iFormField } from "../../../../../helpers/interfaces/generic";

export function getAddDispositivoFormFields(proprietadispositivo: any, dispositivi: any, tipologiadispositivi: any, personId: any): Array<iFormField> {
  return [
    {
      'label': 'Proprietario dispositivo',
      'name': 'tipologia',
      'type': 'select',
      'class': 'form-control',
      'values': proprietadispositivo
    },
    {
      'label': 'Dispositivo',
      'name': 'dispositivi_id',
      'type': 'search',
      'class': 'form-control',
      'values': dispositivi,
      'placeholder': 'Cerca un dispositivo...',
      'showFromFieldName': 'tipologia',
      'showFromFieldValue': [0]
    },
    {
      'label': 'Data Consegna',
      'name': 'data_consegna',
      'type': 'date',
      'class': 'form-control',
      'showFromFieldName': 'tipologia',
      'showFromFieldValue': [0],
      'validation': Yup.date().required("Campo obbligatorio")
    },
    {
      'label': 'Data Restituzione',
      'name': 'data_restituzione',
      'type': 'date',
      'class': 'form-control',
      'showFromFieldName': 'tipologia',
      'showFromFieldValue': [0]
    },
    {
      'label': 'Rimuovi dal magazzino ?',
      'name': 'rimuovi_magazzino',
      'type': 'select',
      'values': [{ key: 0, value: 'NO' }, { key: 1, value: 'SI' }],
      'class': 'form-select',
      'showFromFieldName': 'tipologia',
      'showFromFieldValue': [0]
    },
    {
      'label': 'Note Assegnazione',
      'name': 'note',
      'type': 'text',
      'class': 'form-control',
      'showFromFieldName': 'tipologia',
      'showFromFieldValue': [0]
    },
    {
      'label': 'Tipologia',
      'name': 'note',
      'type': 'text',
      'class': 'form-control',
      'showFromFieldName': 'tipologia',
      'showFromFieldValue': [1, 2]
    },
    {
      'label': '',
      'name': 'person_id',
      'type': 'hidden',
      'value': personId,
      'class': 'form-control',
      'validation': Yup.number().positive().integer().required("Campo obbligatorio")
    },
  ];
}

export function getEditDispositivoFormFields(statodispositivi: any, personId: number, dispositivoId: any): Array<iFormField> {
  return [
    {
      'label': 'Data Consegna',
      'name': 'data_consegna',
      'type': 'date',
      'class': 'form-control',
      'validation': Yup.date().required("Campo obbligatorio")
    },
    {
      'label': 'Data Restituzione',
      'name': 'data_restituzione',
      'type': 'date',
      'class': 'form-control',
      'validation': Yup.date().notRequired()
    },
    {
      'label': 'Note',
      'name': 'note',
      'type': 'text',
      'class': 'form-control',
      'validation': Yup.string().notRequired()
    },
    /*{
      'label': 'Gestisci magazzino ?',
      'name': 'gestione_magazzino',
      'type': 'radio',
      'values': ['No', 'Si'],
      'class': 'form-control'
    },*/
    {
      'label': 'Premi per gestire lo stato nel magazzino',
      'name': 'gestione_magazzino',
      'type': 'checkbox',
      'class': 'form-check-input'
    },
    {
      'label': 'Il dispositivo è nel magazzino ?',
      'name': 'in_magazzino',
      'type': 'select',
      'values': [{ key: 0, value: 'NO' }, { key: 1, value: 'SI' }],
      'class': 'form-select',
      'showFromFieldName': 'gestione_magazzino',
      'showFromFieldValue': [1]
    },
    {
      'label': 'Stato',
      'name': 'stato_dispositivo',
      'type': 'select',
      'values': statodispositivi,
      'class': 'form-select',
      'validation': Yup.string(),
      'showFromFieldName': 'gestione_magazzino',
      'showFromFieldValue': [1]
    },
    {
      'label': '',
      'name': 'person_id',
      'type': 'hidden',
      'value': personId,
      'class': 'form-control',
      'validation': Yup.number().positive().integer().required("Campo obbligatorio")
    },
    {
      'label': '',
      'name': 'dispositivi_id',
      'type': 'hidden',
      'value': dispositivoId,
      'class': 'form-control',
      'validation': Yup.number().positive().integer().required("Campo obbligatorio")
    },
  ];
}

export function getConsegnaDispositivoFormFields(id: number): Array<iFormField> {
  return [
    {
      'label': 'Spedito con confezione originale',
      'name': 'confezioneOriginale',
      'type': 'checkbox',
      'class': 'form-check-input'
    },
    {
      'label': '',
      'name': 'id',
      'type': 'hidden',
      'value': id,
      'class': 'form-control',
      'validation': Yup.number().positive().integer().required("Campo obbligatorio")
    },
  ]
}