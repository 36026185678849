import * as Yup from "yup";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { iFormField } from "../../helpers/interfaces/generic";

export function getEditRouteFormFields(): Array<iFormField> {
  return [
    {
      'label': 'Descrizione',
      'name': 'description',
      'type': 'text',
      'class': 'form-control',
      'validation': Yup.string().notRequired()
    }
  ];
}
