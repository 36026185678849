import { generatePath } from "react-router-dom";
import { ConfigColumnDefs, ConfigColumns } from "datatables.net";
import { IMagazzinoTipologiaDispositivo } from "../../../helpers/interfaces/magazzino/magazzino-tipologia-dispositivi";

// table columns definitions
export const listTipologiaDispositiviColumns: ConfigColumns[] = [
    { title: "Azioni", data: "id" },
    { title: "Nome", data: "nome" },
];

// function: setting columns defs (in this case, setting the edit button at the first column)
export default function getTipologiaDispositiviColumnDefs(): ConfigColumnDefs[] {
    return [
        {
            target: 0,
            render: (id: number, type: string, row: IMagazzinoTipologiaDispositivo) => {
                const url = generatePath("/magazzino/tipologia-dispositivi/dettaglio-tipologia-dispositivo/:id", { id })

                const edit = `<a class="custom-icon btn btn-outline-primary rounded-circle" href="${url}" data-id="'+${id}+'"> 
                    <i style="font-size: 18px" class="fa fa-pencil" aria-hidden="true"></i>
                </a>`;

                return '<div class="d-flex">' + edit + '</div>';
            },
        },
    ];
}