import React, { Component } from "react";
import EventBus from "../../../common/EventBus";
import ModalForm from '../../../common/ModalForm';
import AttivitaService from "../../../services/api/database/attivita.service";
import { listAttivitaColumns, getListAttivitaColumnDefs, listActivityInstrumentsColumns, getActivityInstrumentsColumnDef } from "../../../config/tableColumns/attivita";
import { getEditAttivitaFormFields, getAddAttivitaFormFields } from "../../../config/formFields/attivita";
import Table from '../../../common/TableComponent';
import { iFormField } from "../../../helpers/interfaces/generic";
import Modal from "../../../common/Modal";
import Swal from "sweetalert2";
import magazzinoStrumentiService from "../../../services/api/magazzino/magazzino-strumenti.service";
import { IMagazzinoStrumento } from "../../../helpers/interfaces/magazzino/magazzino-strumenti";
import attivitaService from "../../../services/api/database/attivita.service";

const $ = require('jquery');

type Props = {
    history: any
};

type State = {
    formFields: Array<iFormField>,
    loading: boolean,
    message: string,
    showModal: boolean,
    showFormModal: boolean,
    modalFormType: string,
    attivita: any,
    formInitialValues: { [key: string]: any },
    activityId: number,
    instruments: IMagazzinoStrumento[],
    activityInstruments: number[],
    tableData: dataType[],
    modalData: any[]
    changes: any[],
}

type dataType = {
    isChecked: boolean,
    id?: number,
    marca_modello?: string,
    code?: string,
    status?: string,
    category?: string,
}

export default class Attivita extends Component<Props, State> {
    innerRefs: any;
    apiSubmit: any;
    buttons: string[] | null;

    constructor(props: Props) {
        super(props);

        this.state = {
            formFields: [],
            attivita: [],
            loading: false,
            message: "",
            showModal: false,
            showFormModal: false,
            modalFormType: 'add',
            formInitialValues: {},
            activityId: 0,
            instruments: [],
            activityInstruments: [],
            tableData: [],
            modalData: [],
            changes: []
        };

        this.innerRefs = [];
        this.apiSubmit = null; // LicenzeService.personAdd;
        this.buttons = null;
    }

    async componentDidMount() {
        EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });

        const attivita = await AttivitaService.getAll();

        if (attivita.length > 0) {
            this.setState({ attivita });
        }

        EventBus.dispatch("hideLoader");

        $('#table_attivita.table').on('click', '.edit_btn', async (e: any) => {
            e.preventDefault();
            EventBus.dispatch("showLoader", { text: 'Caricamento attivita in corso...' });

            const idAttivita = $(e.currentTarget).data('id');
            
            AttivitaService.get(idAttivita).then((attivita) => {
                if (attivita) {
                    this.setState({
                        formFields: getEditAttivitaFormFields(),
                        formInitialValues: attivita
                    }, () => {
                        this.apiSubmit = AttivitaService.put;
                        this.setState({
                            showFormModal: true,
                            modalFormType: 'edit'
                        });
                    });
                }

                EventBus.dispatch("hideLoader");
            }).catch((e: any) => {
                Swal.fire(
                    'Errore',
                    'Si è verificato un errore imprevisto durante il caricamento delle attività',
                    'error'
                );

                EventBus.dispatch("hideLoader");
            });
        });

        $('#table_attivita.table').on('click', '.instruments_btn', async (e: any) => {
            e.preventDefault();
            EventBus.dispatch("showLoader", { text: 'Caricamento in corso...' });

            const activityId = $(e.currentTarget).data('id');

            Promise.all([
                magazzinoStrumentiService.getAll(),
                AttivitaService.getActivityInstruments(activityId)
            ]).then(
                ([instruments, activityInstruments]: [
                    IMagazzinoStrumento[],
                    number[]
                ]) => {
                    if (instruments) {
                        this.setState({instruments});
                    }

                    if (activityInstruments) {
                        this.setState({
                            activityInstruments
                        }, () => {
                            this.setState({
                                showModal: true,
                            });
                        });
                    }

                    let modalData: number[] = [];
                    const tableData: dataType[] = instruments.map((tool) => {
                        let isChecked = false;
                        activityInstruments.forEach((item) => {
                            if (item === tool.id) {
                                isChecked = true;
                            }
                        })

                        if (isChecked && tool.id) {
                            modalData.push(tool.id);
                        }

                        return {
                            isChecked,
                            id: tool.id,
                            marca_modello: tool.marca_modello,
                            code: tool.code,
                            status: tool.stato_nome,
                            category: tool.categoria_nome,
                        };
                    });

                    this.setState({
                        activityId,
                        tableData,
                        modalData
                    });
                }
            ).catch((e: any) => {
                Swal.fire(
                    'Errore',
                    'Si è verificato un errore imprevisto durante il caricamento degli strumenti',
                    'error'
                );
            }).finally(() => {
                EventBus.dispatch("hideLoader");
            });
        });

        $('div#activity-container').on('change', 'input[type=checkbox]',
            async (e: any) => {
                e.preventDefault();

                let { modalData, changes } = this.state;
                const id = $(e.currentTarget).data('id');
                const checked = $(e.currentTarget).is(':checked');
        
                if (id) {
                    if (checked) {
                        modalData.push(id);
                    } else if (!checked && modalData) {
                        modalData = modalData.filter((value: number) => {
                            return value !== id
                        });
                    }
            
                    const changeIndex = changes.findIndex((value: any) => {
                        return value === id
                    });
            
                    if (changeIndex === -1) {
                        changes.push(id);
                    } else {
                        changes = changes.filter((value: number, key: number) => {
                            return key !== changeIndex
                        });
                    }
                }

                this.setState({ modalData, changes });
            }
        );
      
        $('div#activity-container').on('click', '.save_btn',
            async (e: any) => {
                e.preventDefault();
                EventBus.dispatch("showLoader", { text: 'Caricamento in corso...' });
        
                const { activityId, modalData } = this.state;

                if (activityId === 0) {
                    Swal.fire(
                        'Errore',
                        'Si è verificato un errore imprevisto',
                        'error'
                    );
                }

                attivitaService.putActivityInstruments(
                    activityId,
                    modalData
                ).then(((response: {[key: string]: any}) => {
                    if (typeof response.error === 'undefined') {
                        this.closeModal();
                        Swal.fire('Salvataggio riuscito', '', 'success');
                    } else {
                        Swal.fire(
                            'Errore',
                            'Si è verificato un errore durante il salvataggio dei dati',
                            'error'
                        );
                    }
                })).catch((error) => {
                    Swal.fire(
                        'Errore', 
                        error ? error : 'Si è verificato un errore durante il salvataggio dei dati',
                        'error'
                    )
                }).finally(() => {
                    EventBus.dispatch("hideLoader");
                });
            }
        );
    }

    closeModal() {
        this.setState({ showModal: false });
    }

    closeFormModal() {
        this.setState({ showFormModal: false });
    }

    openAddModale() {
        this.apiSubmit = AttivitaService.add;

        this.setState({ 
            formFields: getAddAttivitaFormFields(),
            formInitialValues: {},
            showFormModal: true,
            modalFormType: 'add'
        });
    }

    render() {
        const {
            formFields, showModal, showFormModal, modalFormType,
            attivita, formInitialValues, tableData
        } = this.state;

        return <React.Fragment>
            <div id="activity-container" className="custom-container">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <h2 className="card-title">Attivita</h2>
                            <button
                                id="add_btn"
                                type="button"
                                className="btn btn-outline-primary"
                                onClick={() => this.openAddModale()}
                            >
                                <span>Aggiungi attivita</span>
                            </button>
                        </div>
                        <Modal
                            id="activity-instruments"
                            showModal={showModal}
                            title="Strumenti"
                            type="view"
                            content={<div id="activity-instruments-container">
                                <Table
                                    id="table-activity-instruments"
                                    datas={tableData}
                                    columns={listActivityInstrumentsColumns}
                                    columnDefs={getActivityInstrumentsColumnDef()}
                                    buttons={[]}
                                />
                                <button className='save_btn btn btn-info'>
                                    Salva
                                </button>
                            </div>}
                            closeCallback={this.closeModal.bind(this)}
                        />
                        <ModalForm
                            showModal={showFormModal}
                            title={'Attivita'}
                            modalType={modalFormType}
                            formFields={formFields}
                            initialValues={formInitialValues}
                            apiSubmit={this.apiSubmit}
                            closeCallback={this.closeFormModal.bind(this)}
                        />
                        {attivita.length > 0 && <Table
                            id="table_attivita"
                            columns={listAttivitaColumns}
                            columnDefs={getListAttivitaColumnDefs()}
                            datas={attivita}
                            buttons={this.buttons}
                        />}
                    </div>
                </div>
            </div>
        </React.Fragment>
    }
}