import React, { Component } from "react";
import EventBus from "../../common/EventBus";
import ModalForm from '../../common/ModalForm';

import PlanningNotificheService from '../../services/api/planning-notifiche.service'
import { getPlanningNotificheColumnDefs, listPlanningNotificheColumns } from '../../config/tableColumns/planning-notifiche'
import { getEditPlanningNotificheFormFields, getAddPlanningNotificheFormFields } from "../../config/formFields/planning-notifiche";
import Table from '../../common/TableComponent';
import { iFormField } from "../../helpers/interfaces/generic";
import { IPlanningNotifica } from '../../helpers/interfaces/planning-notifica'
import Swal from 'sweetalert2';

const $ = require('jquery');

type Props = {
    history: any
};
type State = {
    formFields: Array<iFormField>,
    loading: boolean,
    message: string,
    showModal: boolean,
    modalType: string,
    eventi: any,
    formInitialValues: { [key: string]: any }
}
export default class PlanningNotifiche extends Component<Props, State> {
    innerRefs: any;
    apiSubmit: any;
    buttons: string[];

    constructor(props: Props) {
        super(props);
        this.state = {
            formFields: [],
            eventi: [],
            loading: false,
            message: "",
            showModal: false,
            modalType: 'add',
            formInitialValues: {},
        }
        this.innerRefs = [];
        this.apiSubmit = null;
        this.buttons = [
            //"copy",
            //"csv",
            "excel",
            "pdf",
            "print",
            //"colvis"
        ];
    }

    async componentDidMount() {
        EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });

        const eventi = await PlanningNotificheService.getAll();
        if (eventi.length > 0) {
            this.setState({ eventi });
        }

        EventBus.dispatch("hideLoader");

        $('#table_eventi.table').on('click', '.edit_btn', async (e: any) => {
            e.preventDefault();
            EventBus.dispatch("showLoader", { text: 'Caricamento Notifica in corso...' });

            const idEvento = $(e.currentTarget).data('id');
            const evento: IPlanningNotifica = await PlanningNotificheService.getOne(idEvento);
            if (evento) {
                const _eventTypeList: string[] = await PlanningNotificheService.getEventType();
                const eventTypeList: any[] = _eventTypeList.map((item: any) => { return { key: item.id, value: item.type } });

                const selectedEvent = eventTypeList.filter(eventType => eventType.value === evento.eventtype);
                    
                this.setState({ formFields: getEditPlanningNotificheFormFields(eventTypeList), formInitialValues: { ...evento, eventtype: selectedEvent[0].key} }, () => {
                    this.apiSubmit = PlanningNotificheService.edit;
                    this.setState({ showModal: true, modalType: 'edit' });
                });
            }
            EventBus.dispatch("hideLoader");
        });

        $('#table_eventi.table').on('click', '.delete_btn', async (e: any) => {
            e.preventDefault();
            Swal.fire({
                title: 'Vuoi eliminare questa notifica ?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Conferma',
                cancelButtonText: 'Annulla'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const idEvento = $(e.currentTarget).data('id');
                    const evento: any = await PlanningNotificheService.delete(idEvento);
                    if (evento && typeof evento.error !== 'undefined') {
                        Swal.fire(
                            evento.error,
                            '',
                            'error'
                        );
                    } else {
                        window.location.reload();
                    }
                }
            });
        });
    }

    closeModal() {
        this.setState({ showModal: false });
    }

    async openAddModale() {
        const _eventTypeList: string[] = await PlanningNotificheService.getEventType();
        const eventTypeList: any[] = _eventTypeList.map((item: any) => { return { key: item.id, value: item.type } });

        this.apiSubmit = PlanningNotificheService.add;
        this.setState({ formFields: getAddPlanningNotificheFormFields(eventTypeList), formInitialValues: {}, showModal: true, modalType: 'add' });
    }

    render() {
        const { formFields, showModal, modalType, eventi, formInitialValues } = this.state;
        return <React.Fragment>
            <div className="custom-container">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex align-items-center justify-content-between">
                            <h3 className="card-title">Notifiche</h3>
                            <button id="add_btn" type="button" className="btn btn-outline-primary" onClick={() => this.openAddModale()}>
                                <span>Aggiungi evento</span>
                            </button>
                        </div>
                        <ModalForm showModal={showModal} title={'Evento'} modalType={modalType} formFields={formFields} initialValues={formInitialValues} apiSubmit={this.apiSubmit} closeCallback={this.closeModal.bind(this)} />
                        {eventi.length > 0 && <Table id="table_eventi" columns={listPlanningNotificheColumns} columnDefs={getPlanningNotificheColumnDefs()} datas={eventi} buttons={this.buttons} />}
                    </div>
                </div>
            </div>
        </React.Fragment>
    }
}