import * as Yup from "yup";
import { iFormField } from "../../helpers/interfaces/generic";

export function getRitFormFields(contatti: any): Array<iFormField> {
  return [
    {
      'label': 'Contatto cliente',
      'name': 'contatto',
      'type': 'select',
      'values': contatti,
      'class': 'form-select',
      'validation': Yup.string().required("Campo obbligatorio")
    },
    {
      'label': 'Data inizio',
      'name': 'startdate',
      'type': 'date',
      'class': 'form-control',
      'validation': Yup.date().required("Campo obbligatorio")
    },
    {
      'label': 'Data fine',
      'name': 'enddate',
      'type': 'date',
      'class': 'form-control',
      'validation': Yup.date()
        .when(
          'startdate',
          (startdate, schema) => (
            startdate && schema.min(startdate, "La data di fine deve essere maggiore o uguale a quella di inizio")
          )
        )
        .when(
          'startdate',
          (startdate, schema) => (
            startdate && schema.max(new Date(new Date(startdate).setDate(new Date(startdate).getDate() + 90)), "Il massimo intervallo consentito è di 90 giorni")
          )
        )
        .required("Campo obbligatorio")

      // 'validation': Yup.date().when(['startdate', 'enddate'], {
      //     is: (value: any, value2: any) => { 
      //         console.log("VALUE", value);
      //         console.log("VALUE2", value2);
      //         const s = new Date(value)
      //         const e = new Date(value2)
      //         const start = s.setDate(s.getDate() + 90);
      //         const end = e.setDate(e.getDate());
      //         return end < start 
      //     },
      //     then: Yup.date().required("Massimo intervallo consentito: 90 giorni")
      // })
    }
  ];
}

export function editRitFormFields(contatti: any): Array<iFormField> {
  return [
    {
      'label': 'Contatto cliente',
      'name': 'contatto',
      'type': 'select',
      'values': contatti,
      'class': 'form-select',
      'validation': Yup.string().required("Campo obbligatorio")
    },
    // {
    //   'label': 'Stato',
    //   'name': 'stato',
    //   'type': 'select',
    //   'values': [{ key: 1, value: 'GESTITO' }, { key: 2, value: 'RIFIUTATO' }],
    //   'class': 'form-select',
    //   'validation': Yup.string().required("Campo obbligatorio")
    // },
    {
      'label': 'Data inizio',
      'name': 'startdate',
      'type': 'date',
      'class': 'form-control',
      'validation': Yup.date().required("Campo obbligatorio")
    },
    {
      'label': 'Data fine',
      'name': 'enddate',
      'type': 'date',
      'class': 'form-control',
      'validation': Yup.date()
        .when(
          'startdate',
          (startdate, schema) => (
            startdate && schema.min(startdate, "La data di fine deve essere maggiore o uguale a quella di inizio")
          )
        )
        .when(
          'startdate',
          (startdate, schema) => (
            startdate && schema.max(new Date(new Date(startdate).setDate(new Date(startdate).getDate() + 90)), "Il massimo intervallo consentito è di 90 giorni")
          )
        )
        .required("Campo obbligatorio")
    }
  ];
}