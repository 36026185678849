import React, { Component } from 'react'
import Swal from 'sweetalert2';
import EventBus from '../../../common/EventBus';
import { categoriaStrumentiFormFields } from '../../../config/formFields/magazzino/magazzino-categoria-strumenti';
import { iFormField } from '../../../helpers/interfaces/generic';
import { IMagazzinoCategoriaStrumento } from '../../../helpers/interfaces/magazzino/magazzino-categoria-strumenti';
import categoriastrumentiService from '../../../services/api/magazzino/magazzino-categoria-strumenti.service';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { buttonsStyle, iconsStyle } from '../../../helpers/settings/buttons-icons-styles';

type Props = {
    history: {
        push(url: string): void;
    }
    match: {
        params: {
            id: string
        };
    }
};

type State = {
    categoria: IMagazzinoCategoriaStrumento,
    formFields: iFormField[],
    loading: boolean,
    disabledForm: boolean,
    formInitialValues: { nome: string }
}

export default class MagazzinoDettaglioCategoriaStrumenti extends Component<Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            categoria: {} as IMagazzinoCategoriaStrumento,
            formFields: [],
            loading: false,
            disabledForm: true,
            formInitialValues: { nome: "" }
        }

        this.setValidations = this.setValidations.bind(this)
        this.handleUpdateCategoria = this.handleUpdateCategoria.bind(this);
    }

    // method: initializing the form values
    initFormValues(): { nome: string } {
        return {
            nome: this.state.categoria.nome
        }
    }

    async componentDidMount(): Promise<void> {
        EventBus.dispatch("showLoader", { text: 'Caricamento categoria strumento in corso...' });
        await categoriastrumentiService.getOne(this.props.match.params.id)
            .then(
                categoria =>
                    this.setState(
                        {
                            categoria
                        },
                        () => {
                            EventBus.dispatch("hideLoader")
                            this.setState({
                                formFields: categoriaStrumentiFormFields(),
                                formInitialValues: this.initFormValues()
                            })
                        }
                    ),
                error => EventBus.dispatch("hideLoader")
            )
    }

    // method: handling edit type actions
    async handleUpdateCategoria(body: { nome: string }) {

        EventBus.dispatch("showLoader", { text: 'Salvataggio in corso...' });

        this.setState({
            loading: true
        });

        // handle edit call
        await categoriastrumentiService.edit(this.props.match.params.id, body).then(
            response => {
                this.setState(
                    {
                        categoria: response,
                        formInitialValues: this.initFormValues()
                    },
                    () => {
                        EventBus.dispatch("hideLoader");

                        Swal.fire(
                            'Operazione eseguita.',
                            '',
                            'success'
                        );
                        this.setState({
                            loading: false
                        });
                    }
                )
            },
            error => {
                EventBus.dispatch("hideLoader");

                Swal.fire(
                    'Errore.',
                    '',
                    'error'
                );

                this.setState({
                    loading: false
                });
            }
        )
    }

    // method: setting the form validations using Yup
    setValidations() {
        let validations: any = {};
        this.state.formFields.forEach(value => (validations[value.name] = value.validation));
        return Yup.object().shape(validations);
    }

    render() {
        const { loading, disabledForm, formFields, formInitialValues } = this.state;

        // assigning values to the form fields
        formFields.map(field => field.value = formInitialValues[field.name as keyof { nome: string }])

        return <div className="container py-3">
            {
                formFields.length > 0 &&
                <React.Fragment>
                    <Formik
                        initialValues={formInitialValues}
                        validationSchema={this.setValidations}
                        onSubmit={this.handleUpdateCategoria}
                    >
                        <Form className="card">
                            <div className="card-body">
                                <div className='col-12 pb-2 d-flex justify-content-between align-items-center'>
                                    <h3>Dettaglio categoria</h3>
                                    <button style={buttonsStyle} className='btn rounded-circle' type='button' onClick={() => this.setState({ disabledForm: !disabledForm })} >
                                        {
                                            disabledForm ?
                                                <i style={iconsStyle} className="fa fa-lock text-danger" aria-hidden="true"></i> :
                                                <i style={iconsStyle} className="fa fa-unlock-alt text-success" aria-hidden="true"></i>
                                        }
                                    </button>
                                </div>

                                {formFields.map(
                                    (field: iFormField, key) => {
                                        return <div className="mb-3" key={key}>
                                            <div className='col-12'>
                                                <label className="form-label">{field.label}</label>
                                                <Field name={field.name} type={field.type} className={field.class} disabled={disabledForm} />
                                                <ErrorMessage
                                                    name={field.name}
                                                    component="div"
                                                    className="alert alert-danger"
                                                />
                                            </div>
                                        </div>
                                    }
                                )}

                                <div className="col-12 d-flex justify-content-end">
                                    <button type="submit" className="btn btn-primary" disabled={loading || disabledForm}>
                                        {
                                            loading && <span className="spinner-border spinner-border-sm me-1"></span>
                                        }
                                        <span>Salva</span>
                                    </button>
                                </div>
                            </div>
                        </Form>
                    </Formik>
                </React.Fragment>
            }
        </div>
    }
}