import { AxiosResponse } from 'axios';
import { iPlanningUnavailableObject } from '../../helpers/interfaces/planning';
import { IPermessiStudioEsami } from '../../helpers/interfaces/rendicontazione';
import axios from '../api';
const API_URL = process.env.REACT_APP_API_URL;
class RendicontazioniService {
  async getAll(year: number, month: number) {
    try {
      const response = await axios.get(API_URL + 'datore/rendicontazioni/' + year + '/' + month);
      return response.data;
    } catch (error) {
      return await Promise.reject();
    }
  }

  async sollecito(personId: number, data: any) {
    const { year, month } = data;
    try {
      const response = await axios.post(API_URL + 'datore/rendicontazioni/' + personId + '/' + year + '/' + month + '/sollecito', {
        ...data
      });
      return response.data;
    } catch (error) {
      return [];
    }
  }

  async getPermessiStudioEsami(): Promise<IPermessiStudioEsami> {
    try {
      const response = await axios.get<IPermessiStudioEsami>(API_URL + 'dipendente/studio');
      return response.data;
    } catch (error) {
      return await Promise.reject();
    }
  }

  async getPersonPermessiStudioEsami(id: number): Promise<IPermessiStudioEsami> {
    try {
      const response = await axios.get<IPermessiStudioEsami>(API_URL + 'datore/studio/' + id);
      return response.data;
    } catch (error) {
      return await Promise.reject();
    }
  }

  async getRendicontazione(personId: number, year: number, month: number) {
    try {
      const response = await axios.get(API_URL + 'datore/rendicontazioni/' + personId + '/' + year + '/' + month);
      return response.data;
    } catch (error) {
      return await Promise.reject();
    }
  }

  async amministrazioneEditRendicontazione(rendicontazioneId: string, formData: any) {
    try {
      const response = await axios.put(API_URL + 'datore/rendicontazioni/edit/' + rendicontazioneId, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      return response.data;
    } catch (error) {
      return [];
    }
  }

  async amministrazioneUpdateRendicontazione(rendicontazioneId: string, data: any) {
    try {
      const response = await axios.put(API_URL + 'datore/rendicontazioni/' + rendicontazioneId, {
        ...data
      });
      return response.data;
    } catch (error) {
      return [];
    }
  }

  async getDipendenteRendicontazione(year: number, month: number) {
    try {
      const response = await axios.get(API_URL + 'dipendente/rendicontazione/' + year + '/' + month);
      return response.data;
    } catch (error) {
      return [];
    }
  }

  async dipendenteEditRendicontazione(year: number, month: number, formData: any) {
    try {
      const response = await axios.put(API_URL + 'dipendente/rendicontazione/' + year + '/' + month, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      return response.data;
    } catch (error) {
      return [];
    }
  }

  async getOperatori() {
    try {
      const response = await axios.get<Array<any>>(API_URL + 'datore/operatori');
      return response.data;
    } catch (error) {
      return await Promise.reject();
    }
  }

  async getTrasferte() {
    try {
      const response = await axios.get<Array<any>>(API_URL + 'datore/trasferte');
      return response.data;
    } catch (error) {
      return await Promise.reject();
    }
  }

  async dipendenteOperatori() {
    try {
      const response = await axios.get<Array<any>>(API_URL + 'dipendente/operatori');
      return response.data;
    } catch (error) {
      return await Promise.reject();
    }
  }

  async dipendenteTrasferte() {
    try {
      const response = await axios.get<Array<any>>(API_URL + 'dipendente/trasferte');
      return response.data;
    } catch (error) {
      return await Promise.reject();
    }
  }

  async magazzinoStrumenti() {
    try {
      const response = await axios.get<Array<any>>(API_URL + 'dipendente/strumenti');
      return response.data;
    } catch (error) {
      return await Promise.reject();
    }
  }

  async getDipendentePermessoFerie(id: number): Promise<iPlanningUnavailableObject> {
    try {
      const response = await axios.get(API_URL + 'dipendente/permessi-ferie/' + id);
      return response.data;
    } catch (error) {
      return await Promise.reject();
    }
  }

  async getDatoreWorkHours(id: number, year: number, month: number): Promise<{workhours: number}> {
    try {
      const response: AxiosResponse<{workhours: number}> = await axios.get(
        API_URL + 'datore/workhours/' + id + '/' + month + '/' + year
      );
      return response.data;
    } catch (error) {
      return await Promise.reject();
    }
  }

  async getDipendenteWorkHours(year: number, month: number): Promise<{workhours: number}> {
    try {
      const response: AxiosResponse<{workhours: number}> = await axios.get(
        API_URL + 'dipendente/workhours/' + month + '/' + year
      );
      return response.data;
    } catch (error) {
      return await Promise.reject();
    }
  }
}
export default new RendicontazioniService();