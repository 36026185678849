
import moment from 'moment';
import 'moment/locale/it';

import { Component } from 'react'
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'

export type ReactDataPickerState = {
    value: string;
    forSelected: Date | null;
    toSend: string;
    selectValue: Date | null;
}

type Props = {
    name: string;
    className: string;
    readValue?: string;
    disabled?: boolean;
    onChange: (value: ReactDataPickerState) => void;
    dataFormat?: string | string[];
    showTimeSelect?: boolean;
    timeIntervals?: number;
    timeFormat?: string;
    valueFormat?: string;
    shouldCloseOnSelect?: boolean;
}

type State = ReactDataPickerState;

export default class DatePickerForm extends Component<Props, State> {

    constructor(props: Props) {
        super(props)

        this.state = {
            value: "",
            forSelected: null,
            toSend: "",
            selectValue: null
        }

        this.handleChange = this.handleChange.bind(this)
    }

    componentDidMount(): void {
        if (this.props.readValue) {
            this.setState({
                value: this.props.readValue,
                forSelected: new Date(this.props.readValue),
                toSend: this.props.readValue
            })
        }

    }

    handleChange(value: Date | null) {
        if (value) {
            this.setState({
                forSelected: value,
                value: this.props.valueFormat
                    ? moment(value).format(this.props.valueFormat)
                    : (value?.toLocaleDateString() ?? ""),
                toSend: new Date(value.getTime() - (value.getTimezoneOffset() * 60000)).toISOString(),
            })
        }
    }

    render() {
        return (
            <DatePicker
                name={this.props.name}
                selected={this.state.forSelected || null}
                onSelect={(value: any) => this.handleChange(value)}
                onCalendarClose={() => {
                    this.props.onChange(this.state);
                }}
                onChange={(value: Date | null, event?: React.SyntheticEvent<any, Event>) => {
                    event?.preventDefault();
                    this.handleChange(value);
                }}
                dateFormat={typeof this.props.dataFormat !== 'undefined' ? this.props.dataFormat : ["dd/MM/yyyy", "dd-MM-yyyy"]}
                showTimeSelect={this.props.showTimeSelect}
                timeIntervals={this.props.timeIntervals}
                timeFormat={this.props.timeFormat}
                className={this.props.className}
                disabled={this.props.disabled}
                showWeekNumbers
                shouldCloseOnSelect={this.props.shouldCloseOnSelect}
            />
        )
    }

}