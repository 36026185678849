import axios from '../api';
import { AxiosResponse } from 'axios';
import { IDocumento } from '../../helpers/interfaces/documento';
const API_URL = process.env.REACT_APP_API_URL;

class DocumentiService {

  // AMMINISTRAZIONE
  add(formData: any) {
    return axios.post(API_URL + 'datore/documenti', formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then(response => { return response.data; })
      .catch((error) => { return Promise.reject(); });
  }

  download(id: any) {
    return axios.get(API_URL + 'datore/documenti/' + id + '/download')
      .then(response => { return response.data; })
      .catch((error) => { return Promise.reject(error.response.data); });
  }

  delete(id: number | string) {
    return axios.delete(API_URL + 'datore/documenti/' + id)
      .then(response => { return response.data; })
      .catch((error) => { return error.response.data; });
  }

  get(id: any) {
    return axios.get(API_URL + 'datore/documenti/' + id)
      .then(response => { return typeof response.data == 'object' ? response.data : null; })
      .catch((error) => { return Promise.reject(); });;
  }
  put(id: any, data: any) {
    return axios.put(API_URL + 'datore/documenti/' + id, {
      ...data
    })
      .then(response => { return response.data; })
      .catch((error) => { return Promise.reject(); });
  }

  // DIPENDENTE
  downloadDipendente(id: number | string): Promise<IDocumento> {
    return axios.get<IDocumento>(API_URL + 'dipendente/documenti/' + id + '/download')
      .then((response: AxiosResponse<IDocumento>) => { return response.data; })
      .catch((error) => { return Promise.reject(error.response.data); });
  }

  deleteDipendente(id: any) {
    return axios.delete(API_URL + 'dipendente/documenti/' + id + '/rendicontazione')
      .then(response => { return response.data; })
      .catch((error) => { return error.response.data; });
  }
}
export default new DocumentiService();