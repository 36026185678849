import React, { Component, MouseEvent } from 'react'
import EventBus from '../../../common/EventBus';
import Table from '../../../common/TableComponent';
import getVeicoliManutenzioneColumnDefs, { listVeicoliManutenzioneColumns } from '../../../config/tableColumns/magazzino/magazzino-veicoli-manutenzione';
import { IMagazzinoVeicolo, IMagazzinoVeicoloManutenzione } from '../../../helpers/interfaces/magazzino/magazzino-veicoli';
import magazzinoVeicoliManutenzioneService from '../../../services/api/magazzino/magazzino-veicoli-manutenzione.service';
import ModalForm from '../../../common/ModalForm';
import { iFormField, iFormFieldOption } from '../../../helpers/interfaces/generic';
import { veicoliManutenzioneFormFields } from '../../../config/formFields/magazzino/magazzino-veicoli-manutenzione';
import magazzinoVeicoliService from '../../../services/api/magazzino/magazzino-veicoli.service';
import $ from 'jquery';

type State = {
    veicoliManutenzione: IMagazzinoVeicoloManutenzione[],
    veicoli: IMagazzinoVeicolo[],
    veicoliOptions: iFormFieldOption[],
    showModal: boolean,
    modalType: string,
    modalTitle: string,
    formFields: iFormField[],
    formInitialValues: IMagazzinoVeicoloManutenzione | {},
    loading: boolean,
}

export default class MagazzinoVeicoliManutenzione extends Component<{}, State>
{
    apiSubmit: any;

    constructor(props: {}) {
        super(props)

        this.state = {
            veicoliManutenzione: [],
            veicoli: [],
            veicoliOptions: [],
            showModal: false,
            modalType: '',
            modalTitle: '',
            formFields: [],
            formInitialValues: {},
            loading: false,
        }

        this.apiSubmit = null;
    }

    async componentDidMount() {
        EventBus.dispatch("showLoader", { text: 'Caricamento delle manutenzioni veicoli in corso...' });

        const veicoliManutenzione = await magazzinoVeicoliManutenzioneService.getAll().then(
            async (veicoliManutenzione: IMagazzinoVeicoloManutenzione[]) => { return veicoliManutenzione; }
        ).catch(
            (error: any) => { return []; }
        );

        this.setState({
            veicoliManutenzione,
        });

        EventBus.dispatch("hideLoader");

        $('#table_magazzino_veicoli.table').on('click', '.edit_btn', async (e: JQuery.ClickEvent) => {
            e.preventDefault();

            EventBus.dispatch("showLoader", { text: 'Caricamento dei dati in corso...' });

            const idManutenzioneVeicolo = $(e.currentTarget).data('id');

            await magazzinoVeicoliManutenzioneService.getOne(idManutenzioneVeicolo).then(
                async (veicoliManutenzione: IMagazzinoVeicoloManutenzione) => {
                    await this.editHandler(String(veicoliManutenzione.id));
                },
                (error: any) => { return null }
            );

            EventBus.dispatch("hideLoader");
        });
    }

    async loadHandler(
        formInitialValues: IMagazzinoVeicoloManutenzione | {},
        apiSubmit: any,
        modalType: 'add' | 'edit',
        modalTitle: string
    ) {
        const veicoliOptions: iFormFieldOption[] = [];

        await magazzinoVeicoliService.getAll().then(
            async (veicoli: IMagazzinoVeicolo[]) => {
                veicoli.forEach((veicolo: IMagazzinoVeicolo) => {
                    veicoliOptions.push({value: String(veicolo.id), label: `${veicolo.plate} (${veicolo.brand} ${veicolo.model})` })
                });

                this.apiSubmit = apiSubmit;

                this.setState({
                    veicoli,
                    veicoliOptions,
                    formFields: veicoliManutenzioneFormFields(veicoliOptions),
                    formInitialValues,
                    modalType,
                    modalTitle
                }, async() => {
                    this.setState({showModal: true});
                });
            }
        ).catch((error: any) => { return [] });
    }

    async addHandler() {
        EventBus.dispatch("showLoader", { text: 'Caricamento dei dati in corso...' });

        await this.loadHandler(
            {},
            magazzinoVeicoliManutenzioneService.add,
            'add',
            "Crea nuova manutenzione veicolo"
        );

        EventBus.dispatch("hideLoader")
    }

    async editHandler(id: string) {
        const veicoloManutenzione: IMagazzinoVeicoloManutenzione | undefined = await magazzinoVeicoliManutenzioneService.getOne(id).then(
            (data: IMagazzinoVeicoloManutenzione) => { return data; },
            (error: any) => { return undefined }
        );

        await this.loadHandler(
            {
                ...veicoloManutenzione,
                vehicle: veicoloManutenzione ? {
                    value: String(veicoloManutenzione.vehicle.id),
                    label: `${veicoloManutenzione.vehicle.plate} (${veicoloManutenzione.vehicle.brand} ${veicoloManutenzione.vehicle.model}`
                } : undefined
            },
            magazzinoVeicoliManutenzioneService.edit,
            'edit',
            "Modifica manutenzione veicolo"
        );
    }

    closeModal() {
      this.setState({ showModal: false });
    }
  
    handleSuccess() {
      window.location.hash = "#ruoli";
      window.location.reload();
    }

    render() {
        const { veicoliManutenzione, showModal, modalType, modalTitle, formFields, formInitialValues } = this.state;

        const buttons = ['excel', 'pdf', 'print']
        return (
            <React.Fragment>
                <ModalForm
                    showModal={showModal}
                    title={modalTitle}
                    modalType={modalType}
                    formFields={formFields}
                    initialValues={formInitialValues}
                    apiSubmit={this.apiSubmit}
                    closeCallback={this.closeModal.bind(this)}
                    successAction={this.handleSuccess.bind(this)}
                />
                <div className='custom-container'>
                    <div className="card">
                        <div className="card-body">
                            <div className="form-group mb-3 d-flex justify-content-between align-items-center">
                                <h2 className="card-title">Manutenzione veicoli</h2>
                                <a
                                    id="add_btn"
                                    href="#add"
                                    className="btn btn-outline-primary"
                                    onClick={(e: MouseEvent) => {
                                        e.preventDefault();
                                        this.addHandler();
                                    }}
                                >
                                    <span>Aggiungi manutenzione veicolo</span>
                                </a>
                            </div>
                            {
                                veicoliManutenzione.length > 0 &&
                                <Table
                                    id={'table_magazzino_veicoli'}
                                    columns={listVeicoliManutenzioneColumns}
                                    columnDefs={getVeicoliManutenzioneColumnDefs()}
                                    datas={veicoliManutenzione}
                                    buttons={buttons}
                                />
                            }
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}