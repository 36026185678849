import { AxiosResponse } from 'axios';
import { DeviceConsegna, DeviceDeleteEmail, DeviceDownload, DeviceRiconsegna, DeviceValidate } from '../../helpers/interfaces/device';
import axios from '../api';
const API_URL = process.env.REACT_APP_API_URL;

class DispositiviService {
  async getAll(): Promise<any> {
    return await axios.get(API_URL + 'magazzino/dispositivi')
      .then(response => { return Array.isArray(response.data) ? response.data : []; })
      .catch((error) => { return Promise.reject(); });
  }

  async personAdd(data: any): Promise<any> {
    return await axios.post(API_URL + 'magazzino/person/dispositivi', {
      ...data
    })
      .then(response => { return response.data; })
      .catch((error) => { return []; });
  }

  async personDelete(id: number): Promise<any> {
    return await axios.delete(API_URL + 'magazzino/person/dispositivi/' + id)
      .then(response => { return response.data; })
      .catch((error) => { return error.response.data; });
  }

  async personGet(id: number): Promise<any> {
    return await axios.get(API_URL + 'magazzino/person/dispositivi/' + id)
      .then(response => { return typeof response.data == 'object' ? response.data : null; })
      .catch((error) => { return Promise.reject(); });;
  }

  async personPut(id: number, data: any): Promise<any> {
    return await axios.put(API_URL + 'magazzino/person/dispositivi/' + id, {
      ...data
    })
      .then(response => { return response.data; })
      .catch((error) => { return []; });
  }

  async personConsegna(id: number, data: { confezioneOriginale: string }): Promise<DeviceConsegna> {
    if (id) {
      return await axios.get(API_URL + 'magazzino/dispositivi/assegnazione/consegna/' + id + '/' + (data.confezioneOriginale ? 'Y' : 'N'))
        .then((response: AxiosResponse<any>) => { return response.data; })
        .catch((error) => { return Promise.reject();; });
    } else {
      return Promise.reject();
    }
  }

  async personRiconsegna(id: number): Promise<DeviceRiconsegna> {
    if (id) {
      return await axios.get(API_URL + 'magazzino/dispositivi/assegnazione/riconsegna/' + id)
        .then((response: AxiosResponse<any>) => { return response.data; })
        .catch((error) => { return Promise.reject();; });
    } else {
      return Promise.reject();
    }
  }

  async personDeleteEmail(emailId: number): Promise<DeviceDeleteEmail> {
    if (emailId) {
      return await axios.put(API_URL + 'magazzino/dispositivi/assegnazione/reject/' + emailId)
        .then((response: AxiosResponse<any>) => { return response.data; })
        .catch((error) => { return Promise.reject(); });
    } else {
      return Promise.reject();
    }
  }

  async personDownloadEmail(documentoId: number): Promise<DeviceDownload> {
    if (documentoId) {
      return await axios.get(API_URL + 'datore/documenti/' + documentoId + '/download')
        .then((response: AxiosResponse<any>) => { return response.data; })
        .catch((error) => { return Promise.reject(); });
    } else {
      return Promise.reject();
    }
  }

  async personValidateEmail(emailId: number): Promise<DeviceValidate> {
    if (emailId) {
      return await axios.put(API_URL + 'magazzino/dispositivi/assegnazione/' + emailId, {
        stato: 'A',
      })
        .then((response: AxiosResponse<any>) => { return response.data; })
        .catch((error) => { return Promise.reject(); });
    } else {
      return Promise.reject();
    }
  }
}

export default new DispositiviService();
