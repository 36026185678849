import React, { Component } from 'react'
import EventBus from '../../common/EventBus'
import Table from '../../common/TableComponent'
import getRuoliColumnDefs, { listRuoliColumns } from '../../config/tableColumns/gestione-ruoli'
import GestioneRuoliService from '../../services/api/gestione-ruoli.service'
import { ITypeRuolo } from '../../helpers/interfaces/gestione-ruoli';
import ModalForm from '../../common/ModalForm';
import * as Yup from "yup";
import { iFormField } from "../../helpers/interfaces/generic";
import { addRuoliFormFields, editRuoliFormFields } from '../../config/formFields/gestione-ruoli';
import Swal from "sweetalert2";

const $ = require('jquery');

type State = {
    formFields: Array<iFormField>,
    formInitialValues: { [key: string]: any },
    showModal: boolean,
    modalType: string,
    modalTitle: string,
    ruoli: ITypeRuolo[]
}

export class GestioneRuoliComponent extends Component<{}, State> {

    apiSubmit: any;

    constructor(props: {}) {
        super(props)

        this.state = {
            formFields: [],
            formInitialValues: {},
            showModal: false,
            modalType: '',
            modalTitle: '',
            ruoli: []
        }
    }

    async componentDidMount() {
        EventBus.dispatch("showLoader", { text: 'Caricamento ruoli in corso...' });

        await GestioneRuoliService.getRoles()
        .then(
            (response: ITypeRuolo[]) => {
                EventBus.dispatch("hideLoader");
                this.setState({ ruoli: response });
            }
        )
        .catch(() => {
            EventBus.dispatch("hideLoader");

            Swal.fire({
                title: 'Errore',
                text: "Si è verificato un errore.",
                icon: 'error',
                });
        });

        $('#table_ruoli').on('click', '.edit_btn', async (e: any) => {
            e.preventDefault();

            const idItem = $(e.currentTarget).data('id');

            const selectedRole = this.state.ruoli.filter((item: ITypeRuolo) => item.id === idItem);

            this.setState({
                formFields: editRuoliFormFields(),
                formInitialValues: selectedRole[0]
            }, () => {
                this.apiSubmit = GestioneRuoliService.editRole;
                this.setState({ showModal: true, modalTitle: 'Modifica ruolo', modalType: 'edit' });
            });
        });
    }

    async openAddModale() {
        this.setState({
            formFields: addRuoliFormFields(),
            formInitialValues: {}
        }, () => {
            this.apiSubmit = GestioneRuoliService.addRole;
            this.setState({ showModal: true, modalTitle: 'Aggiungi un nuovo ruolo', modalType: 'add' });
        });
    }

    closeModal() {
        this.setState({ showModal: false });
    }

    validationSchema() {
        let validations: any = {};
        this.state.formFields.forEach((value: any, key: any) => (validations[value.name] = value.validation));

        return Yup.object().shape(validations);
    }

    render() {
        const { formFields, formInitialValues, showModal, modalTitle, modalType, ruoli } = this.state;

        const buttons = ['excel', 'pdf', 'print']
        return (
            <React.Fragment>
                <div className='custom-container'>
                    <div className="card">
                        <div className="card-body">
                            <div className="form-group mb-3 d-flex justify-content-between align-items-center">
                                <h2 className="card-title">Gestione ruoli</h2>

                                <button id="add_btn" type="button" className="btn btn-outline-primary" onClick={async () => this.openAddModale()}>
                                    <span>Aggiungi ruolo</span>
                                </button>
                            </div>
                            {
                                ruoli.length > 0 &&
                                <Table id={'table_ruoli'} columns={listRuoliColumns} columnDefs={getRuoliColumnDefs()} datas={ruoli} buttons={buttons} />
                            }

                            <ModalForm
                                showModal={showModal}
                                title={modalTitle}
                                modalType={modalType}
                                formFields={formFields}
                                initialValues={formInitialValues}
                                apiSubmit={this.apiSubmit}
                                closeCallback={this.closeModal.bind(this)}
                            />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default GestioneRuoliComponent