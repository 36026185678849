import * as Yup from "yup";
import { iFormField } from "../../../../../helpers/interfaces/generic";

export function getAddServizioFormFields(servizi: any, personId: number): Array<iFormField> {
  return [
    {
      'label': 'Servizio',
      'name': 'servizi_id',
      'type': 'select',
      'values': servizi,
      'class': 'form-select',
      'validation': Yup.string()
    },
    {
      'label': '',
      'name': 'person_id',
      'type': 'hidden',
      'value': personId,
      'class': 'form-control',
      'validation': Yup.number().positive().integer().required("Campo obbligatorio")
    },
  ];
}