import * as Yup from 'yup'
import { iFormField, iFormFieldOption } from '../../../helpers/interfaces/generic';

export interface IVeicoloManutenzioneForm {
    type: string;
    date: string;
    vehicle: number;
}

const requiredMessage = 'Campo obbligatorio'

// function: setting form fields for devices' add/edit page
export function veicoliManutenzioneFormFields(
    veicoli: iFormFieldOption[]
): iFormField[] {
    return [
        {
            label: 'Tipologia',
            name: 'type',
            type: 'text',
            class: 'form-control',
            validation: Yup.string().required(requiredMessage)
        },
        {
            label: 'Data',
            name: 'date',
            type: 'date',
            class: 'form-control',
            validation: Yup.string().required(requiredMessage)
        },
        {
            label: 'Veicolo',
            name: 'vehicle',
            type: 'search-select',
            options: veicoli,
            class: 'form-control',
            validation: Yup.string().required(requiredMessage)
        }
    ];
}