import React, { Component } from 'react';
import Swal from 'sweetalert2';
import EventBus from "../../../common/EventBus";
import Table from '../../../common/TableComponent';
import ModalForm from '../../../common/ModalForm';
import { getAddCommesseFormFields } from "./config/formFields/addCommesse";
import { getEditCommesseFormFields } from "./config/formFields/editCommesse";
import { listCommesseColumns } from "./config/tableColumns/listCommesse";
import { getListAttestatiColumnDefs } from "./config/tableColumns/listAttestati";
import CommesseService from "../../../services/api/commesse.service";
import { iFormField } from '../../../helpers/interfaces/generic';

type Props = {
  commesse: any,
  personId: number
};

type State = {
  data: any,
  showModal: boolean,
  modalType: string,
  formFields: Array<iFormField>,
  formInitialValues: { [key: string]: any },
  currentJobrder: string
}

const $ = require('jquery');

class CommesseComponent extends Component<Props, State> {
  buttons: (string | { text: string; className: string; action: (e: any, dt: any, node: any, config: any) => void; })[];
  apiSubmit: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      data: props.commesse,
      showModal: false,
      modalType: 'add',
      formFields: [],
      formInitialValues: { 'person_id': props.personId },
      currentJobrder: 'active'
    }
    this.apiSubmit = CommesseService.personAdd;

    this.buttons = [];
  }

  componentDidMount() {
    $('table').on('click', '.edit_btn', async (e: any) => {
      e.preventDefault();
      EventBus.dispatch("showLoader", { text: 'Caricamento Commessa in corso...' });
      const idCommessa = $(e.currentTarget).data('id');
      const commessa: any = await CommesseService.personGet(idCommessa);
      if (commessa) {
        this.setState({
          formFields: getEditCommesseFormFields(), formInitialValues: {
            ...commessa,
            'id': commessa.personjoborderid,
            'personid': this.props.personId,
            'joborderid': commessa.joborder.joborderid
          }
        }, () => {
          this.apiSubmit = CommesseService.personPut;
          this.setState({ showModal: true, modalType: 'edit' });
        });
      }
      EventBus.dispatch("hideLoader");
    });

    $('table').on('click', '.delete_btn', async (e: any) => {
      e.preventDefault();
      Swal.fire({
        title: "Vuoi confermare l'operazione?",
        //text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Conferma',
        cancelButtonText: 'Annulla'
      }).then(async (result) => {
        if (result.isConfirmed) {
          const idCommessa = $(e.currentTarget).data('id');
          const commessa: any = await CommesseService.personDelete(idCommessa);

          if (commessa && typeof commessa.error !== 'undefined') {
            Swal.fire(
              commessa.error,
              '',
              'error'
            );
          } else {
            this.handleSuccess();
          }
        }
      });
    });
  }

  async openAddModale() {
    EventBus.dispatch("showLoader", { text: 'Caricamento Commesse in corso...' });
    await CommesseService.getAll().then(
      (commesse_all: { active: Array<object>, expired: Array<object>, error?: string }) => {
        if (commesse_all) {
          let commesse: any = commesse_all.active.map(
            (item: any) => { return { key: item.id, value: item.name + ' (scadenza ' + item.expired + ')' } }
          ).sort((commessaA, commessaB) => commessaA.value.localeCompare(commessaB.value));

          if (Object.keys(commesse).length > 0) {
            this.setState({ formFields: getAddCommesseFormFields(commesse), formInitialValues: { 'personid': this.props.personId }, showModal: true, modalType: 'add' });
          }

          EventBus.dispatch("hideLoader");
        } else {
          EventBus.dispatch("hideLoader");
          Swal.fire('Errore', "Si è verificato un errore imprevisto durante il caricamento dell'elenco delle commesse", 'error');
        }
      },
      () => {
        EventBus.dispatch("hideLoader");
        Swal.fire('Errore', "Siamo spiacenti, non è stato possibile caricare l'elenco delle commesse, per favore riprova più tardi", 'error')
      }
    );
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  handleSuccess() {
    window.location.hash = "#commesse";
    window.location.reload();
  }

  render() {
    const { showModal, formFields, formInitialValues, modalType, data, currentJobrder } = this.state;
    const jobOrders = data[currentJobrder];

    return <div className="row p-2">
      <React.Fragment>
        <ModalForm
          showModal={showModal}
          title={'Nuova Commessa'}
          modalType={modalType}
          formFields={formFields}
          initialValues={formInitialValues}
          apiSubmit={this.apiSubmit}
          closeCallback={this.closeModal.bind(this)}
          successAction={this.handleSuccess.bind(this)}
        />
        <div className="d-flex justify-content-between align-items-center">
          <h3 className="m-0">Commesse</h3>
          <button id="add_btn" type="button" className="btn btn-outline-primary" onClick={() => this.openAddModale()}>
            <span>Assegna commessa</span>
          </button>
        </div>
        <div className="mt-3">
          <div>
            <ul className="nav nav-tabs" data-bs-toggle="tabs">
              <li className="nav-item">
                <a href='#attive' className="nav-link active" data-bs-toggle="tab" onClick={() => this.setState({ currentJobrder: 'active' })}>Attive</a>
              </li>
              <li className="nav-item">
                <a href='#scadute' className="nav-link" data-bs-toggle="tab" onClick={() => this.setState({ currentJobrder: 'expired' })}>Scadute</a>
              </li>
            </ul>
          </div>
          <div className="tab-content">
            <div className="tab-pane active show" id="attive">
              <Table id="table_commesse_attive" columns={listCommesseColumns} columnDefs={getListAttestatiColumnDefs()} datas={jobOrders} buttons={this.buttons} />
            </div>
            <div className="tab-pane" id="attive">
              <Table id="table_commesse_scadute" columns={listCommesseColumns} columnDefs={getListAttestatiColumnDefs()} datas={jobOrders} buttons={this.buttons} />
            </div>
          </div>
        </div>
      </React.Fragment>
    </div>
  }
}
export default CommesseComponent;