import React, { Component } from "react";
import EventBus from "../../common/EventBus";
import ModalForm from '../../common/ModalForm';

import ClientiService from "../../services/api/clienti.service";
import { listClientiColumns, getListClientiColumnDefs } from "../../config/tableColumns/clienti";
import { getEditClienteFormFields, getAddClienteFormFields } from "../../config/formFields/clienti";
import Table from '../../common/TableComponent';
import { iFormField } from "../../helpers/interfaces/generic";

const $ = require('jquery');

type Props = {
    history: any
};
type State = {
    formFields: Array<iFormField>,
    loading: boolean,
    message: string,
    showModal: boolean,
    modalType: string,
    clienti: any,
    formInitialValues: { [key: string]: any }
}
export default class Clienti extends Component<Props, State> {
    innerRefs: any;
    apiSubmit: any;
    buttons: string[] | null;

    constructor(props: Props) {
        super(props);
        this.state = {
            formFields: [],
            clienti: [],
            loading: false,
            message: "",
            showModal: false,
            modalType: 'add',
            formInitialValues: {},
        }
        this.innerRefs = [];
        this.apiSubmit = null;// LicenzeService.personAdd;
        this.buttons = null;
    }

    async componentDidMount() {
        EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });
        const clienti = await ClientiService.getAll();
        if (clienti.length > 0) {
            this.setState({ clienti });
        }
        EventBus.dispatch("hideLoader");

        $('#table_clienti.table').on('click', '.edit_btn', async (e: any) => {
            e.preventDefault();
            EventBus.dispatch("showLoader", { text: 'Caricamento cliente in corso...' });
            const idCliente = $(e.currentTarget).data('id');
            const cliente = await ClientiService.get(idCliente);
            if (cliente) {
                this.setState({ formFields: getEditClienteFormFields(), formInitialValues: cliente }, () => {
                    this.apiSubmit = ClientiService.put;
                    this.setState({ showModal: true, modalType: 'edit' });
                });
            }
            EventBus.dispatch("hideLoader");
        });
    }

    closeModal() {
        this.setState({ showModal: false });
    }

    openAddModale() {
        this.apiSubmit = ClientiService.add;
        this.setState({ formFields: getAddClienteFormFields(), formInitialValues: {}, showModal: true, modalType: 'add' });
    }

    render() {
        const { formFields, showModal, modalType, clienti, formInitialValues } = this.state;
        return <React.Fragment>
            <div className="custom-container">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <h2 className="card-title">Clienti</h2>
                            <button id="add_btn" type="button" className="btn btn-outline-primary" onClick={() => this.openAddModale()}>
                                <span>Aggiungi cliente</span>
                            </button>
                        </div>
                        <ModalForm showModal={showModal} title={'Cliente'} modalType={modalType} formFields={formFields} initialValues={formInitialValues} apiSubmit={this.apiSubmit} closeCallback={this.closeModal.bind(this)} />
                        {clienti.length > 0 && <Table id="table_clienti" columns={listClientiColumns} columnDefs={getListClientiColumnDefs()} datas={clienti} buttons={this.buttons} />}
                    </div>
                </div>
            </div>
        </React.Fragment>
    }
}