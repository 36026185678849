import { iFormField } from "../../helpers/interfaces/generic";
import * as Yup from 'yup';

export function getBusinessunitFormFields(): Array<iFormField> {
    return [
        {
            'label': 'Nome',
            'name': 'nome',
            'type': 'text',
            'class': 'form-control',
            'validation': Yup.string().required()
        },
        {
            'label': 'Url del logo',
            'name': 'logourl',
            'type': 'text',
            'class': 'form-control',
            'validation': Yup.string().required()
        },
        {
            'label': 'Dominio',
            'name': 'domain',
            'type': 'text',
            'class': 'form-control',
            'validation': Yup.string().required()
        },
        {
            'label': 'Footer per email (inserire il codice HTML)',
            'name': 'mailfooter',
            'type': 'html',
            'class': 'form-control',
            'validation': Yup.string().required()
        },
        {
            'label': 'Footer per documenti (inserire il codice HTML)',
            'name': 'documentfooter',
            'type': 'html',
            'class': 'form-control',
            'validation': Yup.string().required()
        },
    ];
}