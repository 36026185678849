import * as Yup from "yup";
import { iFormField } from "../../helpers/interfaces/generic";

export function getEditEstablishmentFormFields(clienti: any): Array<iFormField> {
  return [
    {
      'label': 'Nome',
      'name': 'name',
      'type': 'text',
      'class': 'form-control',
      'validation': Yup.string().required("Campo obbligatorio")
    },
    {
      'label': 'Indirizzo',
      'name': 'address',
      'type': 'text',
      'class': 'form-control',
      'validation': Yup.string().required("Campo obbligatorio")
    },
    {
      'label': 'Distanza',
      'name': 'distance',
      'type': 'number',
      'class': 'form-control',
      'min': 0
    },
    {
      'label': 'Cliente',
      'name': 'clientId',
      'type': 'select',
      'values': clienti,
      'class': 'form-select',
      'validation': Yup.string().required("Campo obbligatorio")
    }
  ];
}