import * as Yup from 'yup';
import { iFormField, iFormFieldOption } from '../../helpers/interfaces/generic';
import $ from 'jquery';
import { FormikProps } from 'formik';
import { iJobOrderFormFieldUpdateCallback } from '../../helpers/interfaces/rendicontazione';

const requiredMessage = 'Campo obbligatorio'

export function getSollecitoRendicontazioneFields(): Array<iFormField> {
  return [
    {
      label: 'Nota: (facoltativa)',
      name: 'note',
      type: 'text',
      class: 'form-control',
      validation: Yup.string().notRequired()
    },
    {
      label: '',
      name: 'id',
      type: 'hidden',
      class: 'form-control',
      validation: Yup.string().required(requiredMessage)
    },
    {
      label: '',
      name: 'year',
      type: 'hidden',
      class: 'form-control',
      validation: Yup.string().required(requiredMessage)
    },
    {
      label: '',
      name: 'month',
      type: 'hidden',
      class: 'form-control',
      validation: Yup.string().required(requiredMessage)
    }
  ];
}

export type joborderModalType = 'add' | 'edit' | 'import';

export function getModalRendicontazioneFields(
  joborders: iFormFieldOption[],
  causali: iFormFieldOption[],
  strumenti: iFormFieldOption[],
  operatori: iFormFieldOption[],
  trasferte: iFormFieldOption[],
  formType: joborderModalType = 'add',
  updateCommesseCallBack?: iJobOrderFormFieldUpdateCallback
): Array<iFormField> {
  return [
    {
      label: 'Tipologia',
      name: 'tipologia',
      type: 'select',
      values: [
        { key: 'commessa', value: 'Commessa' },
        { key: 'causale', value: 'Causale' }
      ],
      class: 'form-control',
      placeholder: '--seleziona una tipologia--',
      validation: Yup.string().required('Questo campo è obbligatorio'),
      disabled: formType === 'import'
    },
    {
      label: 'Commessa',
      name: 'commessa',
      type: 'search-select',
      options: joborders,
      class: 'form-select',
      placeholder: '--seleziona una commessa--',
      showFromFieldName: 'tipologia',
      showFromFieldValue: ['commessa'],
      validation: Yup.string().when('tipologia', {
        is: (value: string) => value === "commessa",
        then: Yup.string().required(requiredMessage)
      }),
      updateField: 'commessa',
      updateCallback: async (
        value: any,
        formik: FormikProps<any>
      ) => {
        updateCommesseCallBack && await updateCommesseCallBack(
          value,
          formik,
          formType
        );
      }
    },
    {
      label: 'Commessa padre',
      name: 'parent',
      type: 'unset',
      class: '',
    },
    {
      label: 'Causale',
      name: 'causale',
      type: 'search-select',
      options: causali,
      class: 'form-select',
      placeholder: '--seleziona una causale--',
      showFromFieldName: 'tipologia',
      showFromFieldValue: ['causale'],
      validation: Yup.string().when('tipologia', {
        is: (value: string) => value === "causale",
        then: Yup.string().required(requiredMessage)
      }),
      disabled: formType === 'import'
    },
    {
      label: 'Strumento',
      name: 'strumento',
      type: 'search-select',
      options: [{value: '', label: '--'}, ...strumenti],
      class: 'form-control',
      placeholder: '--seleziona uno strumento--',
      showFromFieldName: 'tipologia',
      showFromFieldValue: ['commessa'],
    },
    {
      label: 'Operatore',
      name: 'operatore',
      type: 'search-select',
      options: [{value: '', label: '--'}, ...operatori],
      class: 'form-control',
      placeholder: '--seleziona un operatore--',
      showFromFieldName: 'tipologia',
      showFromFieldValue: ['commessa'],
    },
    {
      label: 'Trasferta',
      name: 'trasferta',
      type: 'search-select',
      options: [{value: '', label: '--'}, ...trasferte],
      class: 'form-control',
      placeholder: '--seleziona una trasferta--',
      showFromFieldName: 'tipologia',
      showFromFieldValue: ['commessa'],
    },
    {
      label: 'Ora di inizio',
      name: 'ora_inizio',
      type: 'time',
      class: 'form-control',
      minuteType: 'select',
      values: [
        {key: '00', value: '00'},
        {key: '30', value: '30'}
      ],
    },
    {
      label: 'Ora di fine',
      name: 'ora_fine',
      type: 'time',
      class: 'form-control',
      minuteType: 'select',
      values: [
        {key: '00', value: '00'},
        {key: '30', value: '30'}
      ],
    },
    {
      label: 'Pausa',
      name: 'pausa',
      type: 'search-select',
      options: [
        { value: '', label: '--'},
        { value: '00:30||30 minuti', label: '30 minuti' },
        { value: '01:00||1 ora', label: '1 ora' }
      ],
      class: 'form-control',
      placeholder: '--seleziona un tempo--',
      showFromFieldName: 'tipologia',
      showFromFieldValue: ['commessa'],
    },
    {
      label: 'Viaggio',
      name: 'tempo_viaggio',
      type: 'time',
      class: 'form-control',
      // showFromFieldName: 'tipologia',
      // showFromFieldValue: ['commessa'],
    },
    {
      label: 'Nota',
      name: 'note',
      type: 'text',
      class: 'form-control'
    },
    {
      label: 'Nota Cliente',
      name: 'note_cliente',
      type: 'text',
      class: 'form-control'
    },
    {
      label: 'RIT inviato',
      name: 'rit_send',
      type: 'checkbox',
      class: 'form-check-input',
      value: false,
      showFromFieldName: 'tipologia',
      showFromFieldValue: ['commessa'],
    },
    {
      label: 'Report di misura inviati',
      name: 'measures_sent',
      type: 'checkbox',
      class: 'form-check-input',
      value: false,
      showFromFieldName: 'tipologia',
      showFromFieldValue: ['commessa'],
    },
    {
      label: 'Applica a tutti i giorni selezionati',
      name: 'all-days',
      type: 'checkbox',
      class: $('#rendicontazione .day.selected').length <= 1
        ? 'form-check-input invisible'
        : 'form-check-input',
      value: true,
      labelClass: $('#rendicontazione .day.selected').length <= 1
        ? 'invisible'
        : 'pt-3',
    },
  ]
}

export function getModalImportReportingFields(
  year: number,
  month: number,
  day: number,
  plannings: iFormFieldOption[],
  causali: iFormFieldOption[],
  stumenti?: iFormFieldOption[]
): Array<iFormField> {
  return [
    {
      label: 'Tipologia',
      name: 'tipologia',
      type: 'select',
      values: [{
          key: 'planning',
          value: `Planning (${plannings.length})`,
          disabled: plannings.length === 0
        }, {
          key: 'causale',
          value: `Causale (${causali.length})`,
          disabled: causali.length === 0
        }
      ],
      class: 'form-control',
      placeholder: '--seleziona una tipologia--',
      validation: Yup.string().required('Questo campo è obbligatorio')
    },
    {
      label: 'Planning',
      name: 'planning',
      type: 'search-select',
      options: plannings,
      class: 'form-select',
      placeholder: '--seleziona una planning--',
      showFromFieldName: 'tipologia',
      showFromFieldValue: ['planning'],
      'validation': Yup.string().when("tipologia", {
        is: 'planning',
        then: Yup.string().required("Campo obbligatorio")
      }),
    },
    {
      label: 'Causale',
      name: 'causale',
      type: 'search-select',
      options: causali,
      class: 'form-select',
      placeholder: '--seleziona una causale--',
      showFromFieldName: 'tipologia',
      showFromFieldValue: ['causale'],
      'validation': Yup.number().when("tipologia", {
        is: 'causale',
        then: Yup.number().required("Campo obbligatorio")
      }),
    },
    {
      label: '',
      name: 'year',
      type: 'hidden',
      class: '',
      value: year
    },
    {
      label: '',
      name: 'month',
      type: 'hidden',
      class: '',
      value: month
    },
    {
      label: '',
      name: 'day',
      type: 'hidden',
      class: '',
      value: day
    }
  ]
}
