import { iFormField } from "../../../helpers/interfaces/generic";
import * as Yup from 'yup';

export interface IServizioForm {
    nome: string,
    code: string
}

const requiredMessage = 'Campo obbligatorio'

// function: setting form fields for devices' add/edit page
export function serviziFormFields(): iFormField[] {
    return [
        {
            label: 'Nome',
            name: 'nome',
            type: 'text',
            class: 'form-control',
            validation: Yup.string().required(requiredMessage)
        },
        {
            label: 'Codice',
            name: 'code',
            type: 'text',
            class: 'form-control',
            validation: Yup.string().required(requiredMessage)
        },
    ]
}