import { AxiosResponse } from 'axios';
import { IMagazzinoStatoDispositivo, IStatoDispositivoBody } from '../../../helpers/interfaces/magazzino/magazzino-stato-dispositivo';
import axios from '../../api';

const API_URL = process.env.REACT_APP_API_URL;

class StatoDispositiviService {

  controller: AbortController;

  constructor() {
    this.controller = new AbortController();
  }

  async getAll(): Promise<IMagazzinoStatoDispositivo[]> {
    this.controller = new AbortController();
    try {
      const response = await axios.get<IMagazzinoStatoDispositivo[]>(API_URL + 'magazzino/dispositivi/stato', {
        signal: this.controller.signal
      });
      return Array.isArray(response.data) ? response.data : [];
    } catch (error) {
      return await Promise.reject();
    }
  }

  async getOne(id: string): Promise<IMagazzinoStatoDispositivo> {
    try {
      const response = await axios.get<IMagazzinoStatoDispositivo>(API_URL + 'magazzino/dispositivi/stato/' + id);
      return response.data;
    } catch (error) {
      return await Promise.reject();
    }
  }

  async add(body: IStatoDispositivoBody): Promise<IMagazzinoStatoDispositivo> {
    try {
      const response = await axios.post<IStatoDispositivoBody, AxiosResponse<IMagazzinoStatoDispositivo>>(API_URL + 'magazzino/dispositivi/stato', body);
      return response.data;
    } catch (error) {
      return await Promise.reject();
    }
  }

  async edit(id: string, body: IStatoDispositivoBody): Promise<IMagazzinoStatoDispositivo> {
    try {
      const response = await axios.put<IStatoDispositivoBody, AxiosResponse<IMagazzinoStatoDispositivo>>(API_URL + 'magazzino/dispositivi/stato/' + id, body);
      return response.data;
    } catch (error) {
      return await Promise.reject();
    }
  }

  abort() {
    this.controller.abort();
  }

  async getAllDipendente(): Promise<IMagazzinoStatoDispositivo[]> {
    this.controller = new AbortController();
    try {
      const response = await axios.get<IMagazzinoStatoDispositivo[]>(API_URL + 'dipendente/dispositivi/stato', {
        signal: this.controller.signal
      });
      return Array.isArray(response.data) ? response.data : [];
    } catch (error) {
      return await Promise.reject();
    }
  }

  async getOneDipendente(id: string): Promise<IMagazzinoStatoDispositivo> {
    try {
      const response = await axios.get<IMagazzinoStatoDispositivo>(API_URL + 'dipendente/dispositivi/stato/' + id);
      return response.data;
    } catch (error) {
      return await Promise.reject();
    }
  }

}

export default new StatoDispositiviService();