import { AxiosResponse } from 'axios';
import { Activity } from '../../../helpers/interfaces/joborders';
import axios from '../../api';

const API_URL = process.env.REACT_APP_API_URL;

class AttivitaService {
  async getAll(): Promise<Activity[]> {
    try {
      const response = await axios.get<Activity[]>(
        API_URL + 'database/attivita'
      );

      return Array.isArray(response.data) ? response.data : [];
    } catch (error) {
      return await Promise.reject();
    }
  }

  async getAllDipendente(): Promise<Activity[]> {
    try {
      const response: AxiosResponse<Activity[]> = await axios.get(
        API_URL + 'dipendente/attivita'
      );

      return Array.isArray(response.data) ? response.data : [];
    } catch (error) {
      return await Promise.reject();
    }
  }

  async getAllDatore(): Promise<Activity[]> {
    try {
      const response: AxiosResponse<Activity[]> = await axios.get(
        API_URL + 'datore/attivita'
      );

      return Array.isArray(response.data) ? response.data : [];
    } catch (error) {
      return await Promise.reject();
    }
  }

  async get(id:any): Promise<Activity | null> {
    try {
      const response = await axios.get<Activity>(
        API_URL + 'database/attivita/' + id
      );

      return typeof response.data == 'object' ? response.data : null;
    } catch (error) {
      return await Promise.reject();
    };
  }

  async put(id:any, data:any): Promise<Activity> {
    try {
      const response = await axios.put<Activity>(
        API_URL + 'database/attivita/' + id,
        { ...data }
      );

      return response.data;
    } catch (error) {
      return Promise.reject();
    }
  }

  async add(data:any): Promise<Activity> {
    try {
      const response = await axios.post<Activity>(
        API_URL + 'database/attivita',
        { ...data }
      );

      return response.data;
    } catch (error) {
      return Promise.reject();
    }
  }

  async getActivityInstruments(
    id:any
  ): Promise<number[]> {
    try {
      const response: AxiosResponse<number[] & { error?: string }> = await axios.get<
      number[] & { error?: string }
    >(
        API_URL + 'database/attivita/' + id + '/strumenti'
      );

      if (response.data?.error) {
        return [];
      }

      return typeof response.data == 'object' ? response.data : [];
    } catch (error) {
      return await Promise.reject();
    };
  }

  async putActivityInstruments(
    id: any,
    data: any
  ): Promise<any> {
    try {
      const response = await axios.put(
          API_URL + 'database/attivita/' + id + '/strumenti', {
            instruments: data
        }
      );

      return response.data;
    } catch (error) {
      return await Promise.reject();
    }
  }
}

export default new AttivitaService();