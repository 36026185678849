import React, { Component } from "react";
import EventBus from "../../../common/EventBus";

import RendicontazioniService from "../../../services/api/rendicontazioni.service";
import { listRendicontazioniColumns, getListRendicontazioniNonRendicontatoColumnDefs, getListRendicontazioniGenericColumnDefs } from "../../../config/tableColumns/rendicontazioni";
import { getSollecitoRendicontazioneFields } from "../../../config/formFields/rendicontazione";
import Table from '../../../common/TableComponent';
import ModalForm from '../../../common/ModalForm';

import moment from "moment";
import 'moment/locale/it';
import { iFormField } from "../../../helpers/interfaces/generic";
import { ConfigColumnDefs } from "datatables.net";
import Select from "react-select";
import personService from "../../../services/api/person.service";
import Swal from "sweetalert2";
import { getListRendicontazioniRifiutatoApertoColumnDefs } from "../../../config/tableColumns/rendicontazioni";

const $ = require('jquery');

type Props = {
  history: any
};

type State = {
  loading: boolean,
  message: string,
  rendicontazioni: any,
  currentTab: string,
  employees: Array<Option>;
  showModal: boolean,
  modalType: string,
  modalTitle: string,
  formFields: Array<iFormField>,
  formInitialValues: { [key: string]: any },
  search_year: number;
  search_month: number;
  search_employee: Option;
  search_year_joborders: number;
  search_month_joborders: number;
}

type Option = {
  value: any,
  label: string
};

export default class ElencoRendicontazioni extends Component<Props, State> {
  buttons: string[] | null;
  apiSubmit: any;
  currentYear: number;
  currentMonth: number;
  years: Array<Option>;
  months: Array<Option>;

  constructor(props: Props) {
    super(props);

    this.currentYear = Number(moment.utc().format("YYYY"));
    this.currentMonth = Number(moment.utc().format("MM"));

    this.state = {
      rendicontazioni: null,
      employees: [],
      loading: false,
      message: "",
      currentTab: '',
      showModal: false,
      modalType: 'add',
      modalTitle: 'Rendicontazione',
      formFields: [],
      formInitialValues: {},
      search_year: this.currentYear,
      search_month: this.currentMonth,
      search_employee: { value: null, label: 'Seleziona dipendente' },
      search_year_joborders: this.currentYear,
      search_month_joborders: this.currentMonth,
    }

    this.apiSubmit = null;
    this.buttons = null;
    this.years = [];
    this.months = [];
  }

  async componentDidMount() {
    for (let y = this.currentYear; y >= 2016; y--) {
      this.years.push({ value: y, label: y.toString() });
    }

    for (let m = 1; m <= 12; m++) {
      this.months.push({ value: m, label: m.toString() });
    }

    this.load(this.currentYear, this.currentMonth);

    $('.joborders-container').on('click', '.sollecito_btn', async (e: any) => {
      e.preventDefault();
      const splitId = $(e.currentTarget).data('id').split('-');
      if (splitId.length === 3) {
        this.setState({
          formFields: getSollecitoRendicontazioneFields(), formInitialValues: {
            id: splitId[0],
            year: splitId[1],
            month: splitId[2]
          }
        }, () => {
          this.apiSubmit = RendicontazioniService.sollecito;
          this.setState({ modalTitle: 'Sollecito rendicontazione', showModal: true, modalType: 'edit' });
        });
      } else {
        Swal.fire(
          'Errore',
          'Si è verificato un errore imprevisto durante il caricamento dei dati necessari per l\'invio del sollecito.',
          'error'
        );
      }
    });
  }

  async load(year: number, month: number) {
    EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });

    const rendicontazioni = await RendicontazioniService.getAll(year, month);
    const firstTab = Object.keys(rendicontazioni)[0];

    if (rendicontazioni) {
      this.setState({ rendicontazioni, currentTab: firstTab });
    }

    await personService.getAllForSearch()
      .then(
        result => {
          let employees: Array<Option> = [];
          result.data.forEach((employee => {
            if (employee.idpersontype !== 'Datore' && employee.status !== 'D') {
              employees.push({ value: employee.id, label: employee.nominativo });
            }
          }));

          this.setState({ employees, search_employee: { value: employees[0].value, label: employees[0].label } })
        },
        () => Swal.fire('Non è stato possibile caricare l\'elenco dei dipendenti', '', 'error')
      ).catch(() => Swal.fire('A causa di un errore imprevisto non è stato possibile caricare l\'elenco dei dipendenti', '', 'error'));

    EventBus.dispatch("hideLoader");
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  render() {
    const {
      rendicontazioni,
      showModal,
      modalType,
      modalTitle,
      formFields,
      formInitialValues,
      employees,
      search_year,
      search_month,
      search_employee,
      search_year_joborders,
      search_month_joborders,
    } = this.state;

    return <React.Fragment>
      <ModalForm
        showModal={showModal}
        title={modalTitle}
        modalType={modalType}
        formFields={formFields}
        initialValues={formInitialValues}
        apiSubmit={this.apiSubmit}
        closeCallback={this.closeModal.bind(this)}
      />
      <div className="row  mb-2">
        <div className="col-md-12">
          <div className="card">
            <div className="card-header">
              <strong className="card-title">Cerca mese dipendente</strong>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-sm-3" data-select2-id="21">
                  <div className="form-group" data-select2-id="20">
                    <label className="form-label">Anno</label>
                    <Select
                      options={this.years}
                      value={{ value: search_year, label: search_year.toString() }}
                      onChange={(option) => option && this.setState({ search_year: option.value })}
                    />
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    <label className="form-label">Mese</label>
                    <Select
                      options={this.months}
                      value={{ value: search_month, label: search_month.toString() }}
                      onChange={(option) => option && this.setState({ search_month: option.value })}
                    />
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    <label className="form-label">Dipendente</label>
                    <Select
                      options={employees}
                      value={search_employee && { value: search_employee.value, label: search_employee.label }}
                      onChange={(option) => option && this.setState({ search_employee: option })}
                    />
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    <label className="form-label">&nbsp;</label>
                    <button className="btn btn-primary" type="button"
                      onClick={() => {
                        if (search_employee && search_year && search_month) {
                          const subData = search_employee.value + '-' + search_year + '-' + search_month;
                          this.props.history.push('/organico/rendicontazioni/dettaglio/' + subData);
                        } else {
                          Swal.fire('Compila tutti i campi obbligatori', '', 'warning');
                        }
                      }}
                    >Vai al mese</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-md-12">
          <div className="card">
            <div className="card-header">
              <strong className="card-title">Cerca mese</strong>
            </div>
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col-sm-3" data-select2-id="21">
                  <div className="form-group" data-select2-id="20">
                    <label className="form-label">Anno</label>
                    <Select
                      options={this.years}
                      value={{ value: search_year_joborders, label: search_year_joborders.toString() }}
                      onChange={(option) => option && this.setState({ search_year_joborders: option.value })}
                    />
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    <label className="form-label">Mese</label>
                    <Select
                      options={this.months}
                      value={{ value: search_month_joborders, label: search_month_joborders.toString() }}
                      onChange={(option) => option && this.setState({ search_month_joborders: option.value })}
                    />
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    <label className="form-label">&nbsp;</label>
                    <button className="btn btn-primary" type="button" onClick={() => this.load(search_year_joborders, search_month_joborders)}>
                      Cerca rendicontazioni
                    </button>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group">
                    &nbsp;
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="joborders-container">
        <div className="card">
          <div className="card-body">
            <h3 className="pb-3">Rendicontazioni</h3>
            <ul className="nav nav-tabs mb-3" data-bs-toggle="tabs">
              {
                rendicontazioni && Object.keys(rendicontazioni).map((key: string, index: number) => {
                  const type = key.toLocaleLowerCase().replace(' ', '_');
                  return <li key={'type_' + index} className="nav-item">
                    <a href={"#" + type} className={"nav-link " + (index === 0 ? 'active' : '')} data-bs-toggle="tab" >{key} ({rendicontazioni[key].length})</a>
                  </li>
                })
              }
            </ul>
            <div className="tab-content">
              {
                rendicontazioni && Object.keys(rendicontazioni).map((key: string, index: number) => {
                  let columnDefs: ConfigColumnDefs[] = [];
                  const type = key.toLocaleLowerCase().replace(' ', '_');
                  if (['rifiutato', 'aperto'].includes(type)) {
                    columnDefs = getListRendicontazioniRifiutatoApertoColumnDefs();
                  } else if (['non_rendicontato'].includes(type)) {
                    columnDefs = getListRendicontazioniNonRendicontatoColumnDefs();
                  } else {
                    columnDefs = getListRendicontazioniGenericColumnDefs();
                  }
                  return <div key={'tab_' + index} id={type} className={"tab-pane " + (index === 0 ? 'active show' : '')} >
                    {rendicontazioni[key].length > 0 ? <Table id={"table_" + key} columns={listRendicontazioniColumns} columnDefs={columnDefs} datas={rendicontazioni[key]} buttons={this.buttons} />
                      : <p>Non sono presenti rendicontazioni</p>}
                  </div>;
                })
              }
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  }
}