import { AxiosResponse } from 'axios';
import { IMagazzinoStatoStrumento, IStatoStrumentoBody } from '../../../helpers/interfaces/magazzino/magazzino-stato-strumenti';
import axios from '../../api';

const API_URL = process.env.REACT_APP_API_URL;

class StatoStrumentiService {

  controller: AbortController;

  constructor() {
    this.controller = new AbortController();
  }

  getAll(): Promise<IMagazzinoStatoStrumento[]> {
    this.controller = new AbortController();
    return axios.get<IMagazzinoStatoStrumento[]>(API_URL + 'magazzino/strumenti/stato', {
      signal: this.controller.signal
    })
      .then(response => { return Array.isArray(response.data) ? response.data : []; })
      .catch((error) => { return Promise.reject(); });
  }

  getOne(id: string): Promise<IMagazzinoStatoStrumento> {
    return axios.get<IMagazzinoStatoStrumento>(API_URL + 'magazzino/strumenti/stato/' + id)
      .then((response: AxiosResponse<IMagazzinoStatoStrumento>) => { return response.data; })
      .catch((error) => { return Promise.reject(); });
  }

  add(body: IStatoStrumentoBody): Promise<IMagazzinoStatoStrumento> {
    return axios.post<IStatoStrumentoBody, AxiosResponse<IMagazzinoStatoStrumento>>(API_URL + 'magazzino/strumenti/stato', body)
      .then((response: AxiosResponse<IMagazzinoStatoStrumento>) => { return response.data; })
      .catch((error) => { return Promise.reject(); });
  }

  edit(id: string, body: IStatoStrumentoBody): Promise<IMagazzinoStatoStrumento> {
    return axios.put<IStatoStrumentoBody, AxiosResponse<IMagazzinoStatoStrumento>>(API_URL + 'magazzino/strumenti/stato/' + id, body)
      .then((response: AxiosResponse<IMagazzinoStatoStrumento>) => { return response.data; })
      .catch((error) => { return Promise.reject(); });
  }

  abort() {
    this.controller.abort();
  }

}

export default new StatoStrumentiService();