
import React, { Component } from 'react'
import EventBus from '../../../common/EventBus';
import { TabProp, Tab } from '../../../common/TabComponent';
import getDispositiviColumnDefs, { listAssegnatariColumns, listDispositiviColumns } from '../../../config/tableColumns/magazzino/magazzino-dispositivi';
import { IAssegnatario, IDispositivo } from '../../../helpers/interfaces/magazzino/magazzino-dispositivi';
import tipologiaDispositiviService from '../../../services/api/magazzino/magazzino-tipologia-dispositivi.service';
import magazzinoDispositiviService from '../../../services/api/magazzino/magazzino-dispositivi.service';
import Table from '../../../common/TableComponent';
import { IMagazzinoTipologiaDispositivo } from '../../../helpers/interfaces/magazzino/magazzino-tipologia-dispositivi';
import Modal from '../../../common/Modal';

const $ = require('jquery')

type State = {
    tipologie: IMagazzinoTipologiaDispositivo[],
    dispositivi: IDispositivo[],
    dispositiviTabs: TabProp[],
    storicoModal: boolean,
    assegnatari: IAssegnatario[]
}

export default class MagazzinoDispositivi extends Component<{}, State> {

    constructor(props: {}) {
        super(props)

        this.state = {
            tipologie: [],
            dispositivi: [],
            dispositiviTabs: [],
            storicoModal: false,
            assegnatari: []
        }
    }

    async componentDidMount() {

        EventBus.dispatch("showLoader", { text: 'Caricamento dei dispositivi in corso...' });

        const tipologiePromise = tipologiaDispositiviService.getAll();
        const dispositiviPromise = magazzinoDispositiviService.getAll();

        // loading the devices (for tables) and the types (for tabs)
        await Promise.all([dispositiviPromise, tipologiePromise])
            .then(
                values => this.setState(
                    {
                        dispositivi: values[0],
                        tipologie: values[1]
                    },
                    () => {
                        let dispositiviTabs: TabProp[] = this.state.tipologie.map(tipologia => {
                            let dispositiviInTable = []

                            let searchPanes = {
                                viewTotal: true,
                                initCollapsed: true,
                                columns: [1, 4, 6, 8, 12],
                                layout: "columns-2"
                            }

                            dispositiviInTable = [...values[0]].filter(dispositivo => dispositivo.tipologia_nome === tipologia.nome)

                            const dom = "P<'d-flex justify-content-between align-items-center my-3'Bf>t<'d-flex justify-content-between align-items-center my-3'l<'p-0'i>p>"

                            return {
                                id: 'tab_magazzino_dispositivi_' + tipologia?.nome.toLowerCase().replace(/\s/g, "_"),
                                label: tipologia ? tipologia.nome : '',
                                content: (<Table id={'table_magazzino_dispositivi_' + tipologia?.nome.toLowerCase().replace(/\s/g, "_")} columns={listDispositiviColumns} columnDefs={getDispositiviColumnDefs()} datas={dispositiviInTable} dom={dom} searchPanes={searchPanes} buttons={['excel']} />),
                                isActive: false
                            }
                        })

                        if (dispositiviTabs.length !== 0)
                            dispositiviTabs[0].isActive = true

                        this.setState({ dispositiviTabs }, () => EventBus.dispatch("hideLoader"))
                    }
                ),
                error => EventBus.dispatch("hideLoader")
            );

        $('.table').on('click', '.storico_btn', async (e: any) => {
            e.preventDefault();
            EventBus.dispatch("showLoader", { text: 'Caricamento storico in corso...' });

            const dispositivoId = $(e.currentTarget).data('id');
            magazzinoDispositiviService.getOne(dispositivoId)
                .then(
                    dispositivo => this.setState(
                        {
                            assegnatari: dispositivo.storico_assegnatari,
                            storicoModal: true
                        },
                        () => EventBus.dispatch("hideLoader")
                    ),
                    () => EventBus.dispatch("hideLoader")
                )

        });
    }

    closeModal() {
        this.setState({ storicoModal: false })
    }

    render() {
        const { dispositivi, dispositiviTabs, storicoModal, assegnatari } = this.state;

        return (
            <React.Fragment>
                <div className='custom-container'>
                    <div className="card">
                        <div className="card-body">
                            <div className="mb-3 d-flex justify-content-between align-items-center">
                                <h2 className="card-title">Dispositivi</h2>
                                <a href="/magazzino/dispositivi/nuovo" id="add_btn" className="btn btn-outline-primary">
                                    <span>Aggiungi dispositivo</span>
                                </a>
                            </div>
                            <Modal id="assegnatari" showModal={storicoModal} title={'Elenco assegnatari'} closeCallback={this.closeModal.bind(this)} type={''} closeButton content={
                                <Table id="table_storico_assegnatari" columns={listAssegnatariColumns} columnDefs={null} datas={assegnatari} buttons={null} lengthChange={false} lengthMenu={[10]} />}
                            />
                            {
                                dispositivi.length > 0 &&
                                <React.Fragment>
                                    <Tab tabs={dispositiviTabs} />
                                </React.Fragment>
                            }
                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}