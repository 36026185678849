import { AxiosResponse } from 'axios';
import axios from '../api';
import { ITypeNote, INoteSpese } from '../../helpers/interfaces/note-spese';
import { IDocumento } from '../../helpers/interfaces/documento';

const API_URL = process.env.REACT_APP_API_URL;

class NoteSpeseService {

    getType(): Promise<ITypeNote[]> {
        return axios.get<ITypeNote[]>(API_URL + 'dipendente/expenses/tipologia')
            .then((response: AxiosResponse<ITypeNote[]>) => { return response.data })
            .catch((error) => { return Promise.reject(error); });
    }

    // DIPENDENTE

    getAll(month: any, year: any): Promise<INoteSpese[]> {
        return axios.get<INoteSpese[]>(API_URL + 'dipendente/expenses/personal/monthyear/' + month + '/' + year)
            // .then((response: AxiosResponse<INoteSpese[]>) => { return Array.isArray(response.data) ? response.data : []; })
            .then((response: AxiosResponse<INoteSpese[]>) => { return response.data })
            .catch((error) => { return Promise.reject(error); });
    }

    getOne(id: string): Promise<INoteSpese> {
        return axios.get<INoteSpese>(API_URL + 'dipendente/expenses/' + id)
            .then((response: AxiosResponse<INoteSpese>) => { return response.data })
            .catch((error) => { return Promise.reject(error); });
    }

    add(body: FormData): Promise<INoteSpese> {
        return axios.post<FormData, AxiosResponse<INoteSpese>>(API_URL + 'dipendente/expenses', body, { headers: { "Content-Type": "multipart/form-data" } })
            .then((response: AxiosResponse<INoteSpese>) => { return response.data; })
            .catch((error) => { return Promise.reject(error); });
    }

    edit(id: string, body: FormData): Promise<any> {
        // usiamo post anziche' put perche' la put con formdata in SLIM (BE) non funziona
        return axios.post<FormData, AxiosResponse<any>>(API_URL + 'dipendente/expenses/' + id, body, { headers: { "Content-Type": "multipart/form-data" } })
            .then((response: AxiosResponse<any>) => { return response.data; })
            .catch((error) => { return Promise.reject(error); });
    }

    delete(id: string): Promise<any> {
        return axios.delete<any>(API_URL + 'dipendente/expenses/' + id)
            .then((response: AxiosResponse<any>) => { return response.data })
            .catch((error) => { return Promise.reject(error); });
    }
    
    downloadAttachment(id: number | string): Promise<IDocumento> {
        return axios.get<IDocumento>(API_URL + 'dipendente/expenses/download/' + id)
            .then(response => { return response.data; })
            .catch((error) => { return Promise.reject(); });
    }

    // AMMINISTRAZIONE

    getAllNotes(id: number, month: any, year: any): Promise<INoteSpese[]> {
        return axios.get<INoteSpese[]>(API_URL + 'amministrazione/expenses/user/monthyear/' + id + '/' + month + '/' + year)
            // .then((response: AxiosResponse<INoteSpese[]>) => { return Array.isArray(response.data) ? response.data : []; })
            .then((response: AxiosResponse<INoteSpese[]>) => { return response.data })
            .catch((error) => { return Promise.reject(error); });
    }

    getAllNotesAllPersons(month: any, year: any): Promise<INoteSpese[]> {
        return axios.get<INoteSpese[]>(API_URL + 'amministrazione/expenses/all/monthyear/' + month + '/' + year)
            // .then((response: AxiosResponse<INoteSpese[]>) => { return Array.isArray(response.data) ? response.data : []; })
            .then((response: AxiosResponse<INoteSpese[]>) => { return response.data })
            .catch((error) => { return Promise.reject(error); });
    }

    getOneNote(id: string): Promise<INoteSpese> {
        return axios.get<INoteSpese>(API_URL + 'amministrazione/expenses/' + id)
            .then((response: AxiosResponse<INoteSpese>) => { return response.data })
            .catch((error) => { return Promise.reject(error); });
    }

    addNote(body: FormData): Promise<INoteSpese> {
        return axios.post<FormData, AxiosResponse<INoteSpese>>(API_URL + 'amministrazione/expenses', body, { headers: { "Content-Type": "multipart/form-data" } })
            .then((response: AxiosResponse<INoteSpese>) => { return response.data; })
            .catch((error) => { return Promise.reject(error); });
    }

    editNote(id: string, body: FormData): Promise<any> {
        // usiamo post anziche' put perche' la put con formdata in SLIM (BE) non funziona
        return axios.post<FormData, AxiosResponse<any>>(API_URL + 'amministrazione/expenses/' + id, body, { headers: { "Content-Type": "multipart/form-data" } })
            .then((response: AxiosResponse<any>) => { return response.data; })
            .catch((error) => { return Promise.reject(error); });
    }

    deleteNote(id: string): Promise<any> {
        return axios.delete<any>(API_URL + 'amministrazione/expenses/' + id)
            .then((response: AxiosResponse<any>) => { return response.data })
            .catch((error) => { return Promise.reject(error); });
    }
    
    downloadAttachmentNote(id: number | string): Promise<IDocumento> {
        return axios.get<IDocumento>(API_URL + 'amministrazione/expenses/download/' + id)
            .then(response => { return response.data; })
            .catch((error) => { return Promise.reject(); });
    }

}

export default new NoteSpeseService();