import { Component } from 'react';
import EventBus from '../../common/EventBus';
import magazzinoDispositiviService from '../../services/api/magazzino/magazzino-dispositivi.service';
import { IDispositivo } from '../../helpers/interfaces/magazzino/magazzino-dispositivi';
import magazzinoStrumentiService from '../../services/api/magazzino/magazzino-strumenti.service';
import { IMagazzinoStrumento } from '../../helpers/interfaces/magazzino/magazzino-strumenti';
import magazzinoLicenzeService from '../../services/api/magazzino/magazzino-licenze.service';
import { IMagazzinoLicenza } from '../../helpers/interfaces/magazzino/magazzino-licenze';
import magazzinoVeicoliService from '../../services/api/magazzino/magazzino-veicoli.service';
import { IMagazzinoVeicolo } from "../../helpers/interfaces/magazzino/magazzino-veicoli";
import { IServizioDaDisabilitare, ILicenzaDaDisabilitare } from '../../helpers/interfaces/magazzino/magazzino-home'
import magazzinoHomeService from '../../services/api/magazzino/magazzino-home.service';
import Table from '../../common/TableComponent';
import { getLicenzeDaDisabilitareColumnDefs, getServiziDaDisabilitareColumnDefs, listLicenzeDaDisabilitareColumns, listServiziDaDisabilitareColumns } from '../../config/tableColumns/magazzino/magazzino-home';

type State = {
    dispositivi: IDispositivo[],
    tipologieDispositivi: string[],
    dispositiviDisponibili: IDispositivo[],
    dispositiviDaResettare: number,
    strumenti: IMagazzinoStrumento[],
    tipologieStrumenti: string[],
    strumentiDisponibili: IMagazzinoStrumento[],
    licenze: IMagazzinoLicenza[],
    tipologieLicenze: string[],
    licenzeDisponibili: IMagazzinoLicenza[],
    veicoli: IMagazzinoVeicolo[],
    serviziDaDisabilitare: IServizioDaDisabilitare[],
    licenzeDaDisabilitare: ILicenzaDaDisabilitare[]
}

export class MagazzinoHome extends Component<{}, State> {

    constructor(props: {}) {
        super(props)

        this.state = {
            dispositivi: [],
            tipologieDispositivi: [],
            dispositiviDisponibili: [],
            dispositiviDaResettare: 0,
            strumenti: [],
            tipologieStrumenti: [],
            strumentiDisponibili: [],
            licenze: [],
            tipologieLicenze: [],
            licenzeDisponibili: [],
            veicoli: [],
            serviziDaDisabilitare: [],
            licenzeDaDisabilitare: []
        }
    }

    async componentDidMount(): Promise<void> {
        EventBus.dispatch("showLoader", { text: 'Caricamento dei dati in corso...' });

        const dispositiviPromise = magazzinoDispositiviService.getAll();
        const strumentiPromise = magazzinoStrumentiService.getAll();
        const licenzePromise = magazzinoLicenzeService.getAll();
        const veicoliPromise = magazzinoVeicoliService.getAll();
        const serviziDaDisabilitarePromise = magazzinoHomeService.getAllServiceToDisable();
        const licenzeDaDisabilitarePromise = magazzinoHomeService.getAllLicencesToDisable();

        await Promise.all([dispositiviPromise, strumentiPromise, licenzePromise, veicoliPromise, serviziDaDisabilitarePromise, licenzeDaDisabilitarePromise]).then(
            (values) => {
                const dispositiviDisponibili = values[0].filter(d => d.censimento === true);
                const dispositiviDaResettare = values[0].filter(d => d.stato_nome === "da resettare").length;
                let tipologieDispositivi = values[0].map(d => d.tipologia_nome)
                tipologieDispositivi = tipologieDispositivi.filter((item, pos) => {
                    return tipologieDispositivi.indexOf(item) === pos;
                });

                const strumentiDisponibili = values[1].filter(item => item.disponibile === true);
                let tipologieStrumenti: any[] = values[1].map((item: IMagazzinoStrumento) => item.tipologia_nome);
                tipologieStrumenti = tipologieStrumenti.filter((item, pos) => {
                    return tipologieStrumenti.indexOf(item) === pos;
                });

                const licenzeDisponibili = values[2].filter(item => item.disponibile === true);
                let tipologieLicenze: any[] = values[2].map((item: IMagazzinoLicenza) => item.prodotto_nome);
                tipologieLicenze = tipologieLicenze.filter((item, pos) => {
                    return tipologieLicenze.indexOf(item) === pos;
                });

                this.setState(
                    {
                        dispositivi: values[0],
                        tipologieDispositivi,
                        dispositiviDisponibili,
                        dispositiviDaResettare,
                        strumenti: values[1],
                        tipologieStrumenti,
                        strumentiDisponibili,
                        licenze: values[2],
                        tipologieLicenze,
                        licenzeDisponibili,
                        veicoli: values[3],
                        serviziDaDisabilitare: values[4],
                        licenzeDaDisabilitare: values[5]
                    },
                    () => EventBus.dispatch("hideLoader")
                )
            },
            error => EventBus.dispatch("hideLoader")
        );
    }

    render() {
        const {
            dispositivi,
            dispositiviDisponibili,
            tipologieDispositivi,
            strumenti,
            strumentiDisponibili,
            tipologieStrumenti,
            licenze,
            licenzeDisponibili,
            tipologieLicenze,
            veicoli,
            serviziDaDisabilitare,
            licenzeDaDisabilitare
        } = this.state

        const buttons = ['excel', 'pdf', 'print']

        return (
            <div className='custom-container'>
                <div className='card'>
                    <div className='card-body'>
                        <h3 className='pb-3'>Materiale disponibile</h3>
                        <div className="d-flex flex-wrap">
                            {
                                dispositiviDisponibili.length > 0 ? tipologieDispositivi.map(tipologia => {
                                    return (
                                        <div className='col-lg-3 col-md-6 col-12 pe-md-2 mb-2' key={tipologia.replace(' ', '-').toLowerCase()}>
                                            <div className='card'>
                                                <div className='card-body d-flex justify-content-between align-items-center'>
                                                    <h4 className='m-0'>{tipologia}</h4>
                                                    <p>{dispositiviDisponibili.filter(d => d.tipologia_nome === tipologia).length}/{dispositivi.filter(d => d.tipologia_nome === tipologia).length}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }) : <p>Non sono presenti dispositivi</p>
                            }
                        </div>
                    </div>
                </div>

                <div className='card mt-3'>
                    <div className='card-body'>
                        <h3 className='pb-3'>Strumenti disponibili</h3>
                        <div className="d-flex flex-wrap">
                            {
                                strumentiDisponibili.length > 0 ? tipologieStrumenti.map(tipologia => {
                                    return (
                                        <div className='col-lg-3 col-md-6 col-12 pe-md-2 mb-2' key={tipologia.replace(' ', '-').toLowerCase()}>
                                            <div className='card'>
                                                <div className='card-body d-flex justify-content-between align-items-center'>
                                                    <h4 className='m-0'>{tipologia}</h4>
                                                    <p>{strumentiDisponibili.filter(s => s.tipologia_nome === tipologia).length}/{strumenti.filter(s => s.tipologia_nome === tipologia).length}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }) : <p>Non sono presenti strumenti</p>
                            }
                        </div>
                    </div>
                </div>

                <div className='card mt-3'>
                    <div className='card-body'>
                        <h3 className='pb-3'>Licenze disponibili</h3>
                        <div className="d-flex flex-wrap">
                            {
                                licenzeDisponibili.length > 0 ? tipologieLicenze.map(tipologia => {
                                    return (
                                        <div className='col-lg-3 col-md-6 col-12 pe-md-2 mb-2' key={tipologia.replace(' ', '-').toLowerCase()}>
                                            <div className='card'>
                                                <div className='card-body d-flex justify-content-between align-items-center'>
                                                    <h4 className='m-0'>{tipologia}</h4>
                                                    <p>{licenzeDisponibili.filter(l => l.prodotto_nome === tipologia).length}/{licenze.filter(l => l.prodotto_nome === tipologia).length}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }) : <p>Non sono presenti licenze</p>
                            }
                        </div>
                    </div>
                </div>

                <div className='card mt-3'>
                    <div className='card-body'>
                        <h3 className='pb-3'>Veicoli disponibili</h3>
                        <div className="d-flex flex-wrap">
                            {
                                veicoli.length > 0 ? 
                                        <div className='col-lg-3 col-md-6 col-12 pe-md-2 mb-2'>
                                            <div className='card'>
                                                <div className='card-body d-flex justify-content-between align-items-center'>
                                                    <h4 className='m-0'>Veicoli</h4>
                                                    <p>{veicoli.length}</p>
                                                </div>
                                            </div>
                                        </div>
                                : <p>Non sono presenti veicoli</p>
                            }
                        </div>
                    </div>
                </div>
                <div className='col-12 d-flex flex-column flex-md-row justify-content-around mt-3'>
                    {
                        serviziDaDisabilitare.length !== 0 && <div className='col-12 col-md-6 py-2 py-md-0 pe-md-1'>
                            <div className='card'>
                                <div className='card-body'>
                                    <h3>Servizi da disabilitare</h3>
                                    <Table id={'table_magazzino_servizi_da_disabilitare'} columns={listServiziDaDisabilitareColumns} columnDefs={getServiziDaDisabilitareColumnDefs()} datas={serviziDaDisabilitare} buttons={buttons} />
                                </div>
                            </div>
                        </div>
                    }
                    {
                        licenzeDaDisabilitare.length !== 0 && <div className='col-12 col-md-6 py-2 py-md-0 ps-md-1'>
                            <div className='card'>
                                <div className='card-body'>
                                    <h3>Licenze da disabilitare</h3>
                                    <Table id={'table_magazzino_servizi_da_disabilitare'} columns={listLicenzeDaDisabilitareColumns} columnDefs={getLicenzeDaDisabilitareColumnDefs()} datas={licenzeDaDisabilitare} buttons={buttons} />
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default MagazzinoHome