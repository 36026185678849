import { Component } from "react";
import { Profilo, JoborderObject, Contatto, SuccessResponse, IRitObj } from "../../helpers/interfaces/user";
import UserService from "../../services/api/user.service";
import moment from "moment";
import 'moment/locale/it';
import { Tab, TabProp } from "../../common/TabComponent";
import Table from "../../common/TableComponent";
import getlistJobordersColumnsDefs, { listJobordersColumns, listDevicesColumns, listServicesColumns, listLicencesColumns, listPermissionsColumns } from "../../config/tableColumns/dipendente";
import getRitListColumnDefs, { ritListColumns } from "../../config/tableColumns/dipendente-rit";
import getDocsListColumnDefs, { docsListColumns } from "../../config/tableColumns/dipendente-docs";
import eventBus from "../../common/EventBus";
import permessiferieService from "../../services/api/permessiferie.service";
import Select from "react-select";
import { getRitFormFields, editRitFormFields } from "../../config/formFields/employeeRitModal";
import { iFormField, iFormFieldValue } from "../../helpers/interfaces/generic";
import ModalForm from '../../common/ModalForm';
import { generatePath } from "react-router-dom";
import { openBase64NewTab } from '../../common/Base64';
import Swal from "sweetalert2";
import DocumentiService from "../../services/api/documenti.service";
import { IDocumento } from '../../helpers/interfaces/documento';

const $ = require('jquery');

type Props = {};

type State = {
  formFields: Array<iFormField>,
  formInitialValues: { [key: string]: any },
  showModal: boolean,
  modalType: string,
  modalTitle: string,
  profile: Profilo | null;
  causali: Array<Option>;
  selectCausale: Option;
  ritList: IRitObj[]
};

type Option = {
  value: any,
  label: string
};

export default class HomeDipendente extends Component<Props, State> {
  apiSubmit: any;
  buttons: string[] | null;
  reloadPage: boolean = false;

  constructor(props: Props) {
    super(props);

    this.state = {
      formFields: [],
      formInitialValues: {},
      showModal: false,
      modalType: 'add',
      modalTitle: '',
      profile: null,
      causali: [],
      selectCausale: {value: null, label: 'TUTTE LE CAUSALI'},
      ritList: []
    };

    this.apiSubmit = null;
    this.buttons = null;
  }

  async componentDidMount() {
    eventBus.dispatch("showLoader", { text: 'Caricamento della home page del dipendente in corso...' });

    await UserService.getProfile()
      .then(
        (profile) => {
          if (profile) {
            this.setState({ profile });
          }
        }
      );

    await UserService.getRitList()
      .then(
        (ritList: IRitObj[]) => {
          if (ritList) {
            this.setState({ ritList });
          }
        }
      )  

    await permessiferieService.getCausalDipendente()
      .then(
        (causaliDipendente) => {
          if (causaliDipendente) {
            let causali: Array<Option> = [{value: null, label: 'TUTTE LE CAUSALI'}];
            causaliDipendente.forEach(causale => {
              causali.push({value: causale.name, label: causale.name})
            })
            this.setState({ causali });
          }
        },
      );

    eventBus.dispatch("hideLoader");

    $('#table_active_joborder.table').on('click', '.addrit_btn', async (e: any) => {
      e.preventDefault();
      eventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });
      const idCommessa = $(e.currentTarget).data('id');
      
      const commessa = this.state.profile?.personjoborders.active.filter((item: JoborderObject) => item.id === idCommessa);
      const contattiCommessa: Contatto[] = [];
      if (commessa && commessa[0].contatti && commessa[0].contatti.length > 0) {
        commessa[0].contatti.map((item: Contatto) => contattiCommessa.push(item));
      }
      const contatti: iFormFieldValue[] = contattiCommessa.map((item: Contatto) => { return { key: item.id, value: item.name + ' '   + item.lastName } });

      if (commessa) {
        const _commessa = {...commessa[0]};
      
        this.setState({
            formFields: getRitFormFields(contatti),
            formInitialValues: { ..._commessa, id: _commessa.joborderid }
        }, () => {
            this.apiSubmit = UserService.postRitData;
            this.setState({ showModal: true, modalType: 'edit', modalTitle: 'Crea un nuovo Rapportino di Intervento Tecnico (RIT)' });
        });
      }
      eventBus.dispatch("hideLoader");
    });

    $('#table_expired_joborder.table').on('click', '.addrit_btn', async (e: any) => {
      e.preventDefault();
      eventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });
      const idCommessa = $(e.currentTarget).data('id');
      
      const commessa = this.state.profile?.personjoborders.expired.filter((item: JoborderObject) => item.id === idCommessa);
      const contattiCommessa: Contatto[] = [];
      if (commessa && commessa[0].contatti && commessa[0].contatti.length > 0) {
        commessa[0].contatti.map((item: Contatto) => contattiCommessa.push(item));
      }
      const contatti: iFormFieldValue[] = contattiCommessa.map((item: Contatto) => { return { key: item.id, value: item.name + ' '   + item.lastName } });

      if (commessa) {
        const _commessa = {...commessa[0]};
      
        this.setState({
            formFields: getRitFormFields(contatti),
            formInitialValues: { ..._commessa, id: _commessa.joborderid }
        }, () => {
            this.apiSubmit = UserService.postRitData;
            this.setState({ showModal: true, modalType: 'edit', modalTitle: 'Crea un nuovo Rapportino di Intervento Tecnico (RIT)' });
        });
      }
      eventBus.dispatch("hideLoader");
    });

    $('#table-rit-list.table').on('click', '.editRit_btn', async (e: any) => {
      e.preventDefault();
      const ritId = $(e.currentTarget).data('id');
      const url = generatePath("/dipendente/rit-dipendente/:id", { id: ritId });
      
      // apre il RIT nella stessa tab del browser dell'app
      // let link = document.createElement('a');
      // link.href = url;
      // link.click();

      // apre il RIT in una nuova tab del browser
      window.open(url, "_blank", "noreferrer noopener");
    });

    $('#table-rit-list.table').on('click', '.edit_btn', async (e: any) => {
      e.preventDefault();
      eventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });
      this.reloadPage = true;

      const idRit = $(e.currentTarget).data('id');
      const { ritList } = this.state;
      const rit: any = ritList.filter((item: IRitObj) => item.id === idRit);
      
      const contattiCommessa: Contatto[] = [];
      const commessa = this.state.profile?.personjoborders.active.filter((item: JoborderObject) => item.joborderid === rit[0].commessa.id);
      if (commessa && commessa[0] && commessa[0].contatti && commessa[0].contatti.length > 0) {
        commessa[0].contatti.map((item: Contatto) => contattiCommessa.push(item));
      }
      const contacts: iFormFieldValue[] = contattiCommessa.map((item: Contatto) => { return { key: item.id, value: item.name + ' '   + item.lastName } });

      if (rit && commessa && commessa.length > 0) {
        this.setState({
            formFields: editRitFormFields(contacts),
            formInitialValues: { ...rit[0], contatto: rit[0].contatto.id }
        }, () => {
            this.apiSubmit = UserService.editRitData;
            this.setState({ showModal: true, modalType: 'edit', modalTitle: 'Modifica i dati del Rapportino di Intervento Tecnico (RIT)' });
        });
      }
      eventBus.dispatch("hideLoader");
    });
    
    $('#table-rit-list.table').on('click', '.pdf_btn', async (e: any) => {
        e.preventDefault();
        const ritId = $(e.currentTarget).data('id');

        eventBus.dispatch("showLoader", { text: 'Generazione del RIT in corso...' });
        await UserService.downloadPdfRit(ritId)
        .then((response: any) => {
            eventBus.dispatch("hideLoader");

            if (response && response.body !== undefined) {
                openBase64NewTab(response);
            } else if (response && response.error !== undefined) {
                Swal.fire({
                    title: 'Errore',
                    text: response.error,
                    icon: 'error',
                });
            } else {
                Swal.fire({
                    title: 'Errore',
                    text: 'Si è verificato un errore durante la generazione del report.',
                    icon: 'error',
                });
            }
        })
        .catch((error: string) => {
            eventBus.dispatch("hideLoader");

            Swal.fire({
            title: 'Errore',
            text: "Si è verificato un errore imprevisto.",
            icon: 'error',
            });
        })
    });

    $('#table-rit-list.table').on('click', '.delete_btn', async (e: any) => {
        e.preventDefault();

        Swal.fire({
            title: 'Vuoi eliminare il RIT?',
            //text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Conferma',
            cancelButtonText: 'Annulla'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const idDocument = $(e.currentTarget).data('id');
                const response: any = await UserService.deleteRit(idDocument);
                if (response && typeof response.error !== 'undefined') {
                    Swal.fire(
                        response.error,
                        '',
                        'error'
                    );
                } else {
                    window.location.reload();
                }
            }
        });
    });

    $('#table-doc-list.table').on('click', '.download_btn', async (e: any) => {
      e.preventDefault();
      eventBus.dispatch("showLoader", { text: 'Caricamento Documento in corso...' });

      const idDocumento = $(e.currentTarget).data('id');

      await DocumentiService.downloadDipendente(idDocumento)
      .then((documento: IDocumento) => {
        eventBus.dispatch("hideLoader");

        if (typeof documento.body !== 'undefined') {
          openBase64NewTab(documento);
        } else {
          Swal.fire({
            title: 'Errore',
            text: "Non è stato possibile scaricare il documento",
            icon: 'error',
          });
        }
      })
      .catch((error) => {
        eventBus.dispatch("hideLoader");
        if (error.error.toLowerCase() === "not data") {
          Swal.fire({
            title: 'Errore',
            text: 'Documento non presente in archivio.',
            icon: 'error'
          });
        } else {
          Swal.fire({
            title: 'Errore',
            text: "Si è verificato un errore imprevisto.",
            icon: 'error',
          });
        }
      })
    });
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  async openRIT(ritObj: SuccessResponse) {
    const ritId: number = ritObj.id;
    const url = generatePath("/dipendente/rit-dipendente/:id", { id: ritId });

    // apre il RIT nella stessa tab del browser dell'app
    // let link = document.createElement('a');
    // link.href = url;
    // link.click();

    // apre il RIT in una nuova tab del browser
    // window.open(url, "_blank", "noreferrer noopener");

    if (!this.reloadPage) window.open(url, "_blank", "noreferrer noopener");
    this.reloadPage = false;
    window.location.reload();
  }

  joborders(): JSX.Element {
    const { profile } = this.state;
    let joborderTabs: Array<TabProp> = [];

    if (profile?.personjoborders.active) {
      joborderTabs.push({
        id: 'active-joborder',
        label: 'Attive',
        content: <>
          {profile?.personjoborders.active.length ? (
            <div className="tab-content">
              <div className="tab-pane active show" id="commesse">
                <Table id="table_active_joborder" columns={listJobordersColumns} columnDefs={getlistJobordersColumnsDefs()} datas={profile?.personjoborders.active ?? []} buttons={null} />
              </div>
            </div>
          ) : (
            <div>Nessuna commessa attiva trovata</div>
          )}
        </>,
        isActive: true
      })
    }

    if (profile?.personjoborders.expired) {
      joborderTabs.push({
        id: 'expired-joborder',
        label: 'Scadute',
        content: <>
          {profile?.personjoborders.expired.length ? (
            <div className="tab-content">
              <div className="tab-pane active show" id="commesse-scadute">
                <Table id="table_expired_joborder" columns={listJobordersColumns} columnDefs={getlistJobordersColumnsDefs()} datas={profile?.personjoborders.expired ?? []} buttons={null} />
              </div>
            </div>
          ) : (
            <div>Nessuna commessa scaduta trovata</div>
          )}
        </>
      })
    }

    return joborderTabs.length ? (<Tab tabs={joborderTabs} />) : (<div>Nessuna commessa trovata</div>)
  }

  devices(): JSX.Element {
    const { profile } = this.state;
    return (
      <>
        {profile?.dispositivi?.length ? (
          <Table id="table_devices" columns={listDevicesColumns} columnDefs={[]} datas={profile?.dispositivi ?? []} buttons={null} />
        ) : (
          <div>Nessun dispositivo trovato</div>
        )}
      </>
    );
  }

  licences(): JSX.Element {
    const { profile } = this.state;
    return (
      <>
        {profile?.licenze?.length ? (
          <Table id="table_licences" columns={listLicencesColumns} columnDefs={[]} datas={profile?.licenze ?? []} buttons={null} />
        ) : (
          <div>Nessuna licenza trovata</div>
        )}
      </>
    );
  }

  services(): JSX.Element {
    const { profile } = this.state;
    return (
      <>
        {profile?.servizi?.length ? (
          <Table id="table_services" columns={listServicesColumns} columnDefs={[]} datas={profile?.servizi ?? []} buttons={null} />
        ) : (
          <div>Nessun servizio trovato</div>
        )}
      </>
    );
  }

  permissions(): JSX.Element {
    const { profile, selectCausale } = this.state;
    let permissionTabs: Array<TabProp & {isActive: boolean}> = [];
    let defaultTab: 'nuova' | 'confermata' | 'rifiutata' = 'nuova';

    if (profile && !profile.permessiferie.nuova) {
      defaultTab = 'confermata';
    } else if (profile && !profile.permessiferie.nuova && !profile.permessiferie.confermata) {
      defaultTab = 'rifiutata';
    }

    if (profile?.permessiferie?.nuova) {
      let nuova: (object | null)[] = [];
      profile?.permessiferie.nuova?.forEach(value => {
        (!selectCausale.value || value.causale === selectCausale.value) && nuova.push(value);
      });

      permissionTabs.push({
        id: 'new-permission',
        label: 'Da confermare',
        content: <>
          {nuova.length ? (
            <Table id="table_active_permission" columns={listPermissionsColumns} columnDefs={[]} datas={nuova} buttons={null} />
          ) : (
            <div>Nessuna nuova richiesta trovata{selectCausale.value && ' con il filtro "'+ selectCausale.label +'"'}.</div>
          )}
        </>,
        isActive: defaultTab === 'nuova',
      })
    }

    if (profile?.permessiferie?.confermata) {
      let confermata: (object | null)[] = [];
      profile?.permessiferie.confermata?.forEach(value => {
        (!selectCausale.value || value.causale === selectCausale.value) && confermata.push(value);
      });

      permissionTabs.push({
        id: 'confirmed-permission',
        label: 'Confermate',
        content: <>
          {confermata.length ? (
            <Table id="table_expired_permission" columns={listPermissionsColumns} columnDefs={[]} datas={confermata} buttons={null} />
          ) : (
            <div>Nessuna richiesta confermata trovata{selectCausale.value && ' con il filtro "'+ selectCausale.label +'"'}.</div>
          )}
        </>,
        isActive: defaultTab === 'confermata',
      })
    }

    if (profile?.permessiferie?.rifiutata) {
      let rifiutata: (object | null)[] = [];
      profile?.permessiferie.rifiutata?.forEach(value => {
        (!selectCausale.value || value.causale === selectCausale.value) && rifiutata.push(value);
      });

      permissionTabs.push({
        id: 'rejected-permission',
        label: 'Rifiutate',
        content: <>
          {rifiutata.length ? (
            <Table id="table_expired_permission" columns={listPermissionsColumns} columnDefs={[]} datas={rifiutata} buttons={null} />
          ) : (
            <div>Nessuna richiesta rifiutata trovata{selectCausale.value && ' con il filtro "'+ selectCausale.label +'"'}.</div>
          )}
        </>,
        isActive: defaultTab === 'rifiutata',
      })
    }

    return permissionTabs.length ? (
      <Tab useState={true} tabs={permissionTabs} />
    ) : (
      <div>Nessuna richiesta di permesso/ferie trovata</div>
    )
  }

  rit(): JSX.Element {
    const { ritList } = this.state;
    return (
      <>
        {ritList.length ? (
          <Table id="table-rit-list" columns={ritListColumns} columnDefs={getRitListColumnDefs()} datas={ritList} buttons={null} />
        ) : (
          <div>Nessun documento trovato</div>
        )}
      </>
    );
  }
  
  documents(): JSX.Element {
    const { profile } = this.state;
    return (
      <>
        {profile?.documenti.length ? (
          <Table id="table-doc-list" columns={docsListColumns} columnDefs={getDocsListColumnDefs()} datas={profile?.documenti ?? []} buttons={null} />
        ) : (
          <div>Nessun documento trovato</div>
        )}
      </>
    );
  }

  render() {
    const { profile, formFields, formInitialValues, showModal, modalTitle, modalType, causali, selectCausale } = this.state;

    return (
      <div className="custom-container">
        <div className='card'>
          <div className='card-body'>
            <h3 className='pb-3'>Home dipendente</h3>
            {profile?.santoPatrono && (
              <>
                <div className="alert alert-warning">
                  <h4>Festività</h4>
                  <span>
                    Il tuo Santo Patrono è quello di <strong>{profile.santoPatrono.comune}</strong>{' '}
                    il <strong>{moment(profile.santoPatrono.data).format('DD/MM')}</strong>
                  </span>
                </div>
              </>
            )}
            <Tab tabs={[
              { id: "commesse", label: "Commesse", content: this.joborders(), isActive: true },
              { id: "dispositivi", label: "Dispositivi", content: this.devices() },
              { id: "licenze", label: "Licenze", content: this.licences() },
              { id: "servizi", label: "Servizi", content: this.services() },
              { id: "permessi-ferie", label: "Permessi/Ferie", content: 
                <>
                  <div className="col-3"><strong>Filtra per causale:</strong></div>
                  <Select 
                    options={causali}
                    value={selectCausale}
                    className="col-3 mb-3"
                    onChange={(option) => {this.setState({selectCausale: {value: option?.value, label: option?.label ?? option?.value}})}}
                  />
                  {this.permissions()}
                </>
              },
              { id: "rit", label: "RIT", content: this.rit() },
              { id: "docs", label: "Documenti", content: this.documents() }
            ]} />

            <ModalForm
                showModal={showModal}
                title={modalTitle}
                modalType={modalType}
                formFields={formFields}
                initialValues={formInitialValues}
                apiSubmit={this.apiSubmit}
                closeCallback={this.closeModal.bind(this)}
                successAction={this.openRIT.bind(this)}
              />
          </div>
        </div>
      </div>
    );
  }
}