import { iFormField } from "../../helpers/interfaces/generic";
import * as Yup from 'yup';

export function getAddTipoUtenteFormFields(): iFormField[] {
    return [
        {
            label: 'Nome',
            name: 'type',
            type: 'text',
            class: 'form-control',
            validation: Yup.string().required('Campo obbligatorio')
        },
        {
            label: 'Visibilità',
            name: 'visible',
            type: 'select',
            values: [{ key: 0, value: "No" }, { key: 1, value: "Sì" }],
            class: 'form-select',
            validation: Yup.string().required('Campo obbligatorio')
        }
    ]
}

export function getEditTipoUtenteFormFields(): iFormField[] {
    return [
        {
            label: 'Nome',
            name: 'type',
            type: 'text',
            class: 'form-control',
            validation: Yup.string().required('Campo obbligatorio')
        },
        {
            label: 'Visibilità',
            name: 'visible',
            type: 'select',
            values: [{ key: 0, value: "No" }, { key: 1, value: "Sì" }],
            class: 'form-select',
            validation: Yup.string().required('Campo obbligatorio')
        }
    ]
}