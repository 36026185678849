import React, { Component } from "react";
import { Formik, Form, ErrorMessage, FormikProps } from "formik";
import * as Yup from "yup";

import EventBus from "../../common/EventBus";
import ReportsService from "../../services/api/reports.service";
import { getRapportiniPresenzeFormFields } from "../../config/formFields/reports";
import { openBase64NewTab } from '../../common/Base64';
import Swal from "sweetalert2";
import { iFormField, iFormFieldValue } from "../../helpers/interfaces/generic";

type Props = {
    renderField: (item: iFormField, _checkboxDisabled: boolean, key: number, formik: FormikProps<any>) => false | JSX.Element,
    persons: iFormFieldValue[],
    checkboxDisabled: boolean
};

type State = {
    loading: boolean,
    formFields: Array<iFormField>,
    disabledForm: boolean,
    formInitialValues: { [key: string]: any }
};

export default class RapportiniPresenze extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: false,
            formFields: [],
            disabledForm: true,
            formInitialValues: {},
        }

    }

    componentDidMount(): void {
        this.setState({ formFields: getRapportiniPresenzeFormFields(this.props.persons) });
    }

    componentDidUpdate(prevProps: any) {
        if (prevProps.persons !== this.props.persons) {
            this.setState({ formFields: getRapportiniPresenzeFormFields(this.props.persons) });
        }
    }

    validationSchema() {
        let validations: any = {};
        this.state.formFields.forEach((value: any, key: any) => (validations[value.name] = value.validation));

        return Yup.object().shape(validations);
    }

    async handleUpdate(formValue: any) {
        const split_date = formValue.date.split('-');
        if (split_date.length === 2) {
            formValue.anno = split_date[0];
            formValue.mese = split_date[1];
        }

        EventBus.dispatch("showLoader", { text: 'Generazione report in corso...' });

        ReportsService.presences_monthly(formValue)
            .then(
                response => {
                    this.setState({
                        loading: false,
                    }, () => {
                        if (response && response.body !== undefined) {
                            openBase64NewTab(response);
                        } else if (response && response.error !== undefined) {
                            Swal.fire({
                                title: 'Errore',
                                text: response.error,
                                icon: 'error',
                            });
                        } else {
                            Swal.fire({
                                title: 'Errore',
                                text: 'Si è verificato un errore durante la generazione del report.',
                                icon: 'error',
                            });
                        }
                        EventBus.dispatch("hideLoader");
                    });

                }
            );
    }

    render() {
        const { loading, formFields } = this.state;
        let initialValues: any = {};
        initialValues['date'] = '';
        initialValues['perconsulente'] = false;

        return <React.Fragment>
            <Formik
                initialValues={initialValues}
                validationSchema={this.validationSchema.bind(this)}
                onSubmit={this.handleUpdate.bind(this)}
            >
                {(formik: FormikProps<any>) => {
                    return <Form className="px-3 pt-3">
                        {formFields && formFields.map((item: iFormField, key: number) => {
                            return <div className="form-group mb-3 row" key={key}>

                                {this.props.renderField(item, this.props.checkboxDisabled, key, formik)}

                                <ErrorMessage
                                    name={item.name}
                                    component="div"
                                    className="alert alert-danger"
                                />
                            </div>
                        })}
                        <div className="d-flex justify-content-end">
                            <button type="submit" className="btn btn-primary" disabled={loading}>
                                {loading && (
                                    <span className="spinner-border spinner-border-sm me-1"></span>
                                )}
                                <span>Genera</span>
                            </button>
                        </div>
                    </Form>
                }}

            </Formik>
        </React.Fragment>
    }
}