import * as Yup from "yup";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { iFormField } from "../../helpers/interfaces/generic";

export function getEditTutorFormFields(tutors: any): Array<iFormField> {
  return [
    {
      'label': 'Tutor',
      'name': 'personid',
      'type': 'select',
      'values': tutors,
      'class': 'form-select',
      'validation': Yup.number().required("Campo obbligatorio")
    }
  ];
}

export function getAddTutorFormFields(persons: any): Array<iFormField> {
  return [
    {
      'label': 'Personale',
      'name': 'personid',
      'type': 'select',
      'values': persons,
      'class': 'form-select',
      'validation': Yup.number().required("Campo obbligatorio")
    }
  ];
}