import { ConfigColumnDefs, ConfigColumns } from "datatables.net";

export const listRateiColumns: ConfigColumns[] = [
    // { title: "Azioni", data: "id" },
    // {
    //     "title": "Dipendente", "data": null, render: (data: any, type: any, row: any) => {
    //         return row.person.fullname
    //     }
    // },
    {
        "title": "Data", "data": null, render: (data: any, type: any, row: any) => {
            return row.mese + ' ' + row.anno
        }
    },
    { title: "Tipo", data: "tipo" },
    { title: "Ferie_perm_Qta", data: "Ferie_perm_Qta" },
    // { title: "Ferie_perm_importo", data: "Ferie_perm_importo" },
    { title: "Ban_Rol_Fes_Fles_Qta", data: "Ban_Rol_Fes_Fles_Qta" },
    // { title: "Ban_Rol_Fes_Fles_imp", data: "Ban_Rol_Fes_Fles_imp" },
    { title: "Tredicesima_Qta", data: "Tredicesima_Qta" },
    // { title: "Tredicesima_importo", data: "Tredicesima_importo" },
    { title: "Quattordicesima_Qta", data: "Quattordicesima_Qta" },
    // { title: "Quattordicesima_imp", data: "Quattordicesima_imp" },
    { title: "Premi_Qta", data: "Premi_Qta" },
    // { title: "Premi_importo", data: "Premi_importo" },
    // { title: "TFR_con_ratei", data: "TFR_con_ratei" }
];

export default function listRateiColumnDefs(): ConfigColumnDefs[] {
    return [];
}
