import { ConfigColumnDefs, ConfigColumns } from "datatables.net";

export const listRuoliColumns: ConfigColumns[] = [
    { title: "Azioni", data: "id" },
    { title: "Ruolo", data: "role" },
    { title: "Codice", data: "code" }
];

export default function getRuoliColumnDefs(): ConfigColumnDefs[] {
    return [
        {
            target: 0,
            render: function (data: any, type: any, row: any) {

                const edit = `<a class="edit_btn custom-icon btn btn-outline-primary rounded-circle me-1" data-id="${data}"><i style="font-size: 18px" class="fa fa-pencil" aria-hidden="true"></i></a>`;

                return '<div class="d-flex">' + edit + '</div>';
            },
        }
    ];
}
