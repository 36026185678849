import itLocale from '@fullcalendar/core/locales/it';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid'; // a plugin!
import React, { Component, createRef } from "react";
import Swal from "sweetalert2";
import EventBus from "../../../../common/EventBus";
import ModalForm from "../../../../common/ModalForm";
import { iFormField } from "../../../../helpers/interfaces/generic";
import { IPermessiFeriePerMese } from '../../../../helpers/interfaces/permessiferie';
import causaliService from "../../../../services/api/database/causali.service";
import permessiferieService from "../../../../services/api/permessiferie.service";
import statipermessiferieService from "../../../../services/api/statipermessiferie.service";

// daterangepicker
import 'daterangepicker/daterangepicker.css';
import 'daterangepicker/daterangepicker.js';
import $ from 'jquery';
import moment from "moment";
import 'moment/locale/it';

type Props = {
    data: any[],
    personId: number
};

type State = {
    formFields: Array<iFormField>,
    loading: boolean,
    message: string,
    showModal: boolean,
    modalType: string,
    richieste: any,
    formInitialValues: { [key: string]: any },
    stati: any,
    startDate: string,
    endDate: string,
    causali_id: string,
}

const dateFormat = 'YYYY/MM/DD HH:mm';
const startDate = moment().format('YYYY/MM/DD 09:00:00');
const endDate = moment().format('YYYY/MM/DD 18:00:00');

export default class CalendarioComponent extends Component<Props, State> {
    apiSubmit: any;
    buttons: string[];
    calendarRef = createRef<FullCalendar>()
    causal: any;

    constructor(props: Props) {
        super(props);

        this.state = {
            formFields: [],
            richieste: [],
            loading: false,
            message: "",
            showModal: false,
            modalType: 'add',
            formInitialValues: {},
            stati: [],
            startDate,
            endDate,
            causali_id: '1',
        }

        this.apiSubmit = null;
        this.buttons = [
            //"copy",
            //"csv",
            "excel",
            "pdf",
            "print",
            //"colvis"
        ];
        this.causal = null;
    }

    async componentDidMount() {
        EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });

        $(function () {
            let inputGroup = document.querySelector(".input-group")
            let card = document.querySelector(".permessi-card")
            let width = $(window).width();
            $('input#ferieperiodo').daterangepicker({
                timePicker: true,
                timePicker24Hour: true,
                startDate,
                endDate,
                locale: {
                    customRangeLabel: dateFormat,
                    format: dateFormat,
                    cancelLabel: 'Annulla',
                    applyLabel: 'Applica'
                },
                minDate: '2016-01-01',
                minYear: 2016,
                opens: (width && width <= 1400) ? "center" : "right",
                parentEl: (width && width <= 567 && inputGroup) ? inputGroup : (width && width > 567 && card) ? card : undefined,
            },
                async function (startDate: moment.Moment, endDate: moment.Moment) {
                    const value = startDate.format(dateFormat) + ' - ' + endDate.format(dateFormat);
                    $('input#ferieperiodo').attr('value', value);
                });
        })

        await Promise.all([
            statipermessiferieService.getAll(),
            permessiferieService.getAllForDipendente(this.props.personId),
            causaliService.getAllDatore()
        ]).then(
            (res: [{
                id: number;
                nome: string;
                code: string;
            }[], IPermessiFeriePerMese[], never[]]) => {
                if (res[0].length > 0 && res[1].length > 0) {
                    res[0].forEach((item: any) => {
                        if (item.code === "new") item.nome = "da confermare";
                    });

                    const richieste = res[1].map((item) => {
                        return {
                            'title': item.dipendente + ' ' + item.causale.nome,
                            'start': item.inizio,
                            'end': item.fine,
                            'backgroundColor': item.stato.colore
                        }
                    });
                    this.setState({ stati: res[0], richieste });
                }

                if (res[2].length) {
                    this.causal = res[2]
                }

                EventBus.dispatch("hideLoader");
            }
        )

        $('div.form-group').on('change', 'input#ferieperiodo', (e) => {
            const dates = e.target.value.split(' - ');
            this.setState({ startDate: dates[0], endDate: dates[1] });
        });

        $(window).on("resize", () => {
            let inputGroup = document.querySelector(".input-group")
            let card = document.querySelector(".permessi-card")
            let width = $(window).width();
            let dateRangePickerOptions: daterangepicker.Options = {
                timePicker: true,
                timePicker24Hour: true,
                startDate,
                endDate,
                locale: {
                    customRangeLabel: dateFormat,
                    format: dateFormat,
                    cancelLabel: 'Annulla',
                    applyLabel: 'Applica'
                },
                minDate: '2016-01-01',
                minYear: 2016,
                opens: (width && width <= 1400) ? "center" : "right",
                parentEl: (width && width <= 567 && inputGroup) ? inputGroup : (width && width > 567 && card) ? card : undefined
            }
            $('input#ferieperiodo').daterangepicker(dateRangePickerOptions)
        });

        $('div.card-body').on('submit', 'form#permissionRequest', (e) => {
            e.preventDefault();

            Swal.fire({
                title: "Aggiungi permesso o ferie?",
                text: "Sei sicuro/a di voler aggiungere questo permesso o ferie?",
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Conferma',
                cancelButtonText: 'Annulla'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const result: any & { error: Array<string> } = await permessiferieService.createPermessiFerie(
                        this.state.startDate,
                        this.state.endDate,
                        this.state.causali_id,
                        this.props.personId
                    );

                    if (result && typeof result.error !== 'undefined') {
                        Swal.fire(
                            'Si è verificato un errore',
                            'SI è verificato un errore imprevisto durante l\'elaborazione della sua richiesta',
                            'error'
                        );
                    } else {
                        Swal.fire({
                            title: "Permesso o ferie aggiunto",
                            text: "Il permesso o ferie è stato aggiunto con successo",
                            icon: 'success',
                            showCancelButton: false,
                            confirmButtonColor: '#3085d6',
                            confirmButtonText: 'OK',
                        }).then(async (result) => {
                            if (result.isConfirmed) {
                                window.location.reload();
                            }
                        });
                    }
                }
            });
        });
    }

    closeModal() {
        this.setState({ showModal: false });
    }

    handleSelectChange(selectValue: string) {
        const causali_id = selectValue;
        this.setState({ causali_id });
    }

    render() {
        const { formFields, showModal, modalType, richieste, stati, formInitialValues, startDate, endDate } = this.state;
        return <>
            <div id="permessi-ferie-calendario" className="row mt-2">
                <ModalForm showModal={showModal} title={'Rifiuta'} modalType={modalType} formFields={formFields} initialValues={formInitialValues} apiSubmit={this.apiSubmit} closeCallback={this.closeModal.bind(this)} />
                <div className="card permessi-card mb-4">
                    <div className="card-body">
                        <h4 className='mb-3'>Aggiungi nuovo permesso o ferie:</h4>
                        <form id="permissionRequest" className="d-flex flex-column flex-xl-row w-100 align-items-center" action="/dipendente/permessi-ferie" method="post">
                            <div className="form-group w-100 mb-3 mb-xl-0 me-xl-2">
                                <label className="mb-1">Tipologia</label>
                                <select className="form-control" id="causali_id" name="causali_id" tabIndex={-1} aria-hidden="true"
                                    onChange={e => this.handleSelectChange(e.target.value)}>
                                    {this.causal ? this.causal.map((element: any, index: number) => {
                                        if (element.code !== 'IN' && element.code !== 'ML' && element.code !== 'VI') {
                                            return <option key={index} value={element.id}>{element.name}</option>
                                        } else {
                                            return null;
                                        }
                                    }) : <></>}
                                </select>
                                <span className="select2 select2-container select2-container--default" dir="ltr" data-select2-id="5" style={{ width: '384.75px' }}>
                                    <span className="selection">
                                        <span className="select2-selection select2-selection--single" role="combobox" aria-controls="" aria-haspopup="true" aria-expanded="false" tabIndex={0} aria-disabled="false" aria-labelledby="select2-causali_id-no-container">
                                            {/* <span className="select2-selection__rendered" id="select2-causali_id-no-container" role="textbox" aria-readonly="true" title="PERMESSI">PERMESSI</span> */}
                                            <span className="select2-selection__arrow" role="presentation">
                                                <b role="presentation">{' '}</b>
                                            </span>
                                        </span>
                                    </span>
                                    <span className="dropdown-wrapper" aria-hidden="true"></span>
                                </span>
                            </div>
                            <div className="form-group w-100 me-xl-2 mb-3 mb-xl-0">
                                <label className="w-100 text-truncate">Periodo (Inizio - Fine) [formato.. Anno/Mese/giorno ora:minuti]</label>
                                <div className="input-group position-relative">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" style={{ padding: 10 }}><i className="fa fa-clock-o"></i></span>
                                    </div>
                                    <input type="text" className="form-control" name="ferieperiodo" id="ferieperiodo" value={startDate + ' - ' + endDate} onChange={(e) => { }} />
                                </div>
                            </div>
                            <button className="btn btn-primary mt-0 mt-xl-4 mb-xl-2" type="submit">
                                Aggiungi
                            </button>
                        </form>
                    </div>
                </div>
                <FullCalendar
                    ref={this.calendarRef}
                    plugins={[dayGridPlugin, timeGridPlugin]}
                    headerToolbar={{
                        left: 'prev,next today',
                        center: 'title',
                        right: 'dayGridMonth,timeGridWeek,timeGridDay'
                    }}
                    initialView="dayGridMonth"
                    events={richieste}
                    locale={itLocale}
                />

                <div className="d-flex justify-content-center align-items-center my-2">
                    <h4 className="mb-0 me-3 me-md-1">Stato richieste</h4>
                    <div className='d-flex flex-column flex-md-row'>
                        {
                            stati.map((stato: any, index: number) => {
                                return <div key={index} className="badge badge-pill me-1" style={{ "color": "#fff", "margin": "5px 0px", "backgroundColor": stato.colore }}>{stato.nome}</div>
                            })
                        }
                    </div>

                </div>
            </div>
        </>
    }
}