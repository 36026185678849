import { Component } from 'react';
import Swal from 'sweetalert2';
import EventBus from "../../../common/EventBus";
import { Field, Form, Formik } from 'formik';
import AdempimentiService from "../../../services/api/database/adempimenti.service";

import { getAddAdempimentoFormFields } from "./config/formFields/addAdempimento";
import ModalForm from '../../../common/ModalForm';
import { iFormField } from '../../../helpers/interfaces/generic';

type Props = {
  adempimenti: any,
  personId: number
};
type State = {
  data: Array<any>,
  showModal: boolean,
  modalType: string,
  formFields: Array<iFormField>,
  formInitialValues: { [key: string]: any },
  loading: boolean
}
const $ = require('jquery');

class AdempimentiComponent extends Component<Props, State> {
  color_state: any;
  formInitialValues: { [key: string]: any };
  apiSubmit: (data: any) => Promise<any>;


  constructor(props: Props) {
    super(props);
    this.state = {
      data: props.adempimenti,
      showModal: false,
      modalType: 'add',
      formFields: [],
      formInitialValues: [],
      loading: false
    }
    this.color_state = [];
    this.color_state[1] = { 'color': 'rgb(158, 158, 158)', 'label': 'Non applicabile' };
    this.color_state[2] = { 'color': 'rgb(211, 47, 47)', 'label': 'Assente' };
    this.color_state[3] = { 'color': 'rgb(251, 192, 45)', 'label': 'In attesa' };
    this.color_state[4] = { 'color': 'rgb(56, 142, 60)', 'label': 'Ok' };

    //this.formFields = getAddAdempimentoFormFields();
    this.formInitialValues = {
      'person_id': props.personId
    };
    this.apiSubmit = AdempimentiService.personAdd;
  }

  async componentDidMount() {
    EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });
    const adempimenti_all = await AdempimentiService.getAllDatore();
    EventBus.dispatch("hideLoader");
    let adempimenti: any = adempimenti_all.map((item: any) => { return { key: item.id, value: item.nome } });

    let status: any = this.color_state.map((item: any, key: any) => { return { key, value: item.label } });

    if (Object.keys(adempimenti).length > 0 && Object.keys(status).length) {
      this.setState({ formFields: getAddAdempimentoFormFields(adempimenti, status, this.props.personId) });
    }

    $('.delete_btn').on('click', async (e: any) => {
      e.preventDefault();
      Swal.fire({
        title: "Vuoi confermare la cancellazione?",
        //text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Conferma',
        cancelButtonText: 'Annulla'
      }).then(async (result: any) => {
        if (result.isConfirmed) {
          const idAdempimento = $(e.currentTarget).data('id');
          const adempimento: any = await AdempimentiService.personDelete(idAdempimento);

          if (adempimento && typeof adempimento.error !== 'undefined') {
            Swal.fire(
              adempimento.error,
              '',
              'error'
            );
          } else {
            window.location.reload();
          }
        }
      });
    });
  }

  handleSubmit(formValue: any) {
    this.setState({
      loading: true
    });

    AdempimentiService.updateAssegnazione(formValue)
      .then(
        () => {
          this.setState({
            loading: false
          }, () => {
            Swal.fire({
              title: 'Operazione eseguita.',
              text: 'Contratto salvato.',
              icon: 'success'
            }).then((result: any) => {
              if (result.isConfirmed || result.isDismissed) {
                $("div.loader-default").addClass('is-active');
                this.handleSuccess()
              }
            });
          });
        },
        (error: any) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.setState({
            loading: false
          }, () => {
            Swal.fire({
              title: 'Operazione non eseguita.',
              text: resMessage,
              icon: 'error'
            });
          });
        }
      );
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  handleSuccess() {
    window.location.hash = "#adempimenti";
    window.location.reload();
  }

  render() {
    const { data, showModal, modalType, formFields, loading } = this.state;
    return data ? <div className="row p-2">
      <div className="d-flex align-items-center justify-content-between">
        <h3 className="m-0">Adempimenti</h3>
        <button id="add_btn" type="button" className="btn btn-outline-primary" disabled={formFields.length === 0} onClick={() => this.setState({ showModal: true, modalType: 'add' })}>
          <span>Assegna adempimento</span>
        </button>
      </div>

      <ModalForm
        showModal={showModal}
        title={'Assegna adempimento'}
        modalType={modalType}
        formFields={formFields}
        initialValues={this.formInitialValues}
        apiSubmit={this.apiSubmit}
        closeCallback={this.closeModal.bind(this)}
        successAction={this.handleSuccess.bind(this)}
      />

      <Formik
        initialValues={{}}
        onSubmit={this.handleSubmit.bind(this)}>
        <Form>
          <ul className="list-group my-3">
            {
              data.length > 0 ?
                data.map((value: any, index: number) => {
                  return <li key={index} className="list-group-item">
                    <div className="row align-items-center">
                      <div className="col-md-2">
                        <div className="delete_btn action-icon custom-icon btn btn-outline-danger rounded-circle" data-id={value.id}>
                          <i className="fa fa-trash" aria-hidden="true"></i>
                        </div>
                      </div>
                      <div className="col-md-6">{value.adempimento}</div>
                      <div className="col-md-4">
                        <Field as="select" className="form-control" name={value.id} style={{ "width": "90%" }}>
                          <option value="">--seleziona una voce--</option>
                          {
                            this.color_state.map((color: any, keyColor: any) => {
                              return <option key={'option' + keyColor} value={keyColor} selected={keyColor === value.status}>{color.label}</option>
                            })
                          }
                        </Field>
                      </div>
                    </div>
                  </li>
                }) : <p>Non sono presenti adempimenti</p>
            }
          </ul>
          {
            data.length > 0 && <div className="d-flex justify-content-end">
              <button type="submit" className="btn btn-primary" disabled={loading}>
                {
                  loading && (
                    <span className="spinner-border spinner-border-sm me-1"></span>
                  )
                }
                <span>Salva</span>
              </button>
            </div>
          }
        </Form>
      </Formik>
    </div> : <div>Caricamento adempimenti in corso..</div>
  }
}
export default AdempimentiComponent;