import { AxiosResponse } from 'axios';
import axios from '../api';
import { ITypeNote, ITypeNoteBody } from '../../helpers/interfaces/note-spese';

const API_URL = process.env.REACT_APP_API_URL;

class NoteSpeseTipologiaService {

    getAll(): Promise<ITypeNote[]> {
        return axios.get<ITypeNote[]>(API_URL + 'database/expenses/tipologia')
            .then((response: AxiosResponse<ITypeNote[]>) => { return response.data })
            .catch((error: any) => { return Promise.reject(error); });
    }

    getOne(id: string): Promise<ITypeNote> {
        return axios.get<ITypeNote>(API_URL + 'database/expenses/tipologia/' + id)
            .then((response: AxiosResponse<ITypeNote>) => { return response.data })
            .catch((error) => { return Promise.reject(error); });
    }

    add(body: ITypeNoteBody): Promise<ITypeNote> {
        return axios.post<ITypeNoteBody, AxiosResponse<ITypeNote>>(API_URL + 'database/expenses/tipologia', body )
            .then((response: AxiosResponse<ITypeNote>) => { return response.data; })
            .catch((error) => { return Promise.reject(error); });
    }

    edit(id: string, body: ITypeNoteBody): Promise<ITypeNote> {
        const payload: ITypeNoteBody = {
            nome: body.nome,
            conto: body.conto
        }
        return axios.put<ITypeNoteBody, AxiosResponse<ITypeNote>>(API_URL + 'database/expenses/tipologia/' + id, payload )
            .then((response: AxiosResponse<ITypeNote>) => { return response.data; })
            .catch((error) => { return Promise.reject(error); });
    }

}

export default new NoteSpeseTipologiaService();