import { AxiosResponse } from 'axios';
import axios from '../api';
import { IEstablishmentGetObj, IEstablishmentAddEditObj, EstablishmentsBody } from '../../helpers/interfaces/stabilimenti';

const API_URL = process.env.REACT_APP_API_URL;

class EstablishmentsService {

    getAll(): Promise<IEstablishmentGetObj[]> {
        return axios.get<IEstablishmentGetObj[]>(API_URL + 'v2/datore/establishments')
            .then((response: AxiosResponse<IEstablishmentGetObj[]>) => { return response.data })
            .catch((error: any) => { return Promise.reject(error); });
    }

    getOne(id: string): Promise<IEstablishmentGetObj> {
        return axios.get<IEstablishmentGetObj>(API_URL + 'v2/datore/establishments/' + id)
            .then((response: AxiosResponse<IEstablishmentGetObj>) => { return response.data })
            .catch((error) => { return Promise.reject(error); });
    }

    add(body: any): Promise<IEstablishmentAddEditObj> {
        const payload:EstablishmentsBody = {
            name: body.name,
            address: body.address,
            distance:Math.round(body.distance),
            clientiId: Number(body.clientId)
        }
        
        return axios.post<EstablishmentsBody, AxiosResponse<IEstablishmentAddEditObj>>(API_URL + 'v2/datore/establishments', payload )
            .then((response: AxiosResponse<IEstablishmentAddEditObj>) => { return response.data; })
            .catch((error) => { return Promise.reject(error); });
    }

    edit(id: string, body: any): Promise<IEstablishmentAddEditObj> {
        const payload:EstablishmentsBody = {
            name: body.name,
            address: body.address,
            distance: Math.round(body.distance),
            clientiId: Number(body.clientId)
        }
        return axios.put<EstablishmentsBody, AxiosResponse<IEstablishmentAddEditObj>>(API_URL + 'v2/datore/establishments/' + id, payload )
            .then((response: AxiosResponse<IEstablishmentAddEditObj>) => { return response.data; })
            .catch((error) => { return Promise.reject(error); });
    }

}

export default new EstablishmentsService();