import React, { Component } from 'react';
import EventBus from "../../common/EventBus";
import GestioneRateiService from "../../services/api/gestione-ratei.service";
import { IDipendenteRatei } from '../../helpers/interfaces/user';
import { listRateiColumns } from '../../config/tableColumns/gestione-ratei';
import Table from '../../common/TableComponent';
import Swal from "sweetalert2";

type Props = {
    match: {
        params: {
            id: string
        };
    }
};

type State = {
    rateiData: IDipendenteRatei[],
    dipendente: string,
    noDataMsg: string | null
}

export default class DettaglioRateiComponent extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            rateiData: [],
            dipendente: '',
            noDataMsg: null
        }
    }

    async componentDidMount() {
        EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });

        GestioneRateiService.getOne(this.props.match.params.id)
            .then(
                (response: any) => {
                    EventBus.dispatch("hideLoader");
                    if (!response || response.error) {
                        // Swal.fire(
                        //     'Attenzione',
                        //     'Dati non trovati',
                        //     'warning'
                        // );

                        this.setState({noDataMsg: "Dati non trovati."})
                    } else {
                        this.setState({ rateiData: response });
                        this.setState({ dipendente: response[0].person.fullname });
                    }
                }
            )
            .catch(
                (error) => {
                    EventBus.dispatch("hideLoader");

                    Swal.fire(
                        'Errore',
                        "Si è verificato un errore imprevisto.",
                        'error'
                    );
                }
            );
    }

    render() {
        const { rateiData, dipendente, noDataMsg } = this.state;
        const buttons = ['excel', 'pdf', 'print'];

        return <React.Fragment>
            <div className="custom-container">
                <div className="card">
                    <div className="card-body">
                        <h2 className="card-title mb-4">
                            Dettaglio ratei di: {dipendente}
                        </h2>

                        {rateiData.length > 0 && <Table id="table_ratei-dipendente" columns={listRateiColumns} columnDefs={[]} datas={rateiData} buttons={buttons} />}

                        {noDataMsg && <div className="d-flex align-items-center justify-content-center bg-danger p-2"><h3 className="m-0 me-2">Attenzione!</h3> {noDataMsg}</div>}
                    </div>
                </div>
            </div>
        </React.Fragment>
    }
}
