import React, { Component } from 'react'
import Swal from 'sweetalert2'
import EventBus from '../common/EventBus'
import { Tab, TabProp } from '../common/TabComponent'
import Table from '../common/TableComponent'
import getNotificheColumnDefs, { listNotificheColumns } from '../config/tableColumns/notifiche'
import { INotifica } from '../helpers/interfaces/notifica'
import notificheService from '../services/api/notifiche.service'

const $ = require('jquery');

type Props = {}

type State = {
    notifiche: { [key: string]: INotifica[] },
    selected: number[]
}

export class Notifiche extends Component<Props, State> {

    constructor(props: Props) {
        super(props)

        this.state = {
            notifiche: {},
            selected: []
        }

        this.handleReadClick = this.handleReadClick.bind(this)
    }

    async componentDidMount(): Promise<void> {
        EventBus.dispatch("showLoader", { text: 'Caricamento delle notifiche in corso...' });

        await notificheService.getAll()
            .then(
                notifiche => this.setState(
                    {
                        notifiche
                    },
                    () => EventBus.dispatch("hideLoader")
                ),
                () => EventBus.dispatch("hideLoader")
            )

        $('.tab-content').on('click', '.check_btn', async (e: any) => {
            const { selected } = this.state
            const notificaId = parseInt($(e.currentTarget).data('id'));
            const checkValue = $(e.target).prop('checked')
            if (checkValue === true)
                this.setState({ selected: [...selected, notificaId] })
            else {
                let findNotifica = selected.find(id => id === notificaId)
                if (findNotifica !== undefined) {
                    selected.splice(selected.indexOf(findNotifica), 1)
                    this.setState({ selected })
                }
            }
        });
    }

    async handleReadClick() {
        const { selected } = this.state
        EventBus.dispatch("showLoader", { text: 'Aggiornamento in corso...' });
        await notificheService.edit({ readed: selected })
            .then(
                response => {
                    EventBus.dispatch("hideLoader")
                    window.location.reload()
                },
                () => {
                    Swal.fire(
                        'Salvataggio non effettuato.',
                        'Non è stato possibile effettuare il salvataggio a causa di un errore imprevisto',
                        'error'
                    );
                    EventBus.dispatch("hideLoader")
                }
            )
    }

    renderTabs(): TabProp[] {
        const { notifiche, selected } = this.state
        const tabsKeys = Object.keys(notifiche).map(label => label.charAt(0).toUpperCase() + label.slice(1))
        const tabsValues = Object.values(notifiche)
        let renderTabs: TabProp[] = []

        tabsKeys.forEach(
            (tab, index) => {
                const badge = parseInt(tabsValues[index].length.toString())
                renderTabs.push({
                    id: 'tab_notifiche_' + tab,
                    label: tab,
                    hasBadge: true,
                    badgeValue: badge,
                    content: (
                        <div className='position-relative'>
                            <div className='position-absolute' style={{ zIndex: 1, top: 0 }}>
                                <button className='btn btn-primary' disabled={selected.length === 0 ? true : false} onClick={this.handleReadClick}>Segna come letto</button>
                            </div>
                            <div className="pt-3 pt-lg-0">
                                <Table id={"table_notifiche_" + tab.toLowerCase()} columns={listNotificheColumns} columnDefs={getNotificheColumnDefs()} datas={tabsValues[index]} buttons={[]} />

                            </div>
                        </div>
                    ),
                    isActive: false,
                })
            }
        )

        if (renderTabs.length > 0)
            renderTabs[0].isActive = true

        return renderTabs
    }

    render() {
        const { notifiche } = this.state
        return <div className="custom-container">
            <div className="card">
                <div className="card-body">
                    <h2 className="card-title">Notifiche</h2>
                    <div className='mt-3'>
                        {
                            Object.keys(notifiche).length !== 0 ? <Tab useState={true} tabs={this.renderTabs()} /> : <span>Nessuna nuova notifica</span>
                        }
                    </div>
                </div>
            </div>
        </div>
    }
}

export default Notifiche