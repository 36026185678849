import React, { Component } from "react";
import ConfigurationService from "../../services/api/configuration.service";
import { IConfiguration } from '../../helpers/interfaces/configuration';
import Swal from 'sweetalert2';
import eventBus from "../../common/EventBus";
import ModalForm from '../../common/ModalForm';
import Table from '../../common/TableComponent';
import { iFormField } from "../../helpers/interfaces/generic";
import { listConfigurationColumns, getListConfigurationColumnDefs } from "../../config/tableColumns/configuration";
import { getAddConfigurationFormFields, getEditConfigurationFormFields } from "../../config/formFields/configuration";

const $ = require('jquery');

type Props = {
};

type State = {
    formFields: Array<iFormField>,
    formInitialValues: { [key: string]: any },
    showModal: boolean,
    modalType: string,
    modalTitle: string,
    configurationData: IConfiguration[],
    noDataMsg: string | null
}

export default class ConfigurationComponent extends Component<Props, State> {
    apiSubmit: any;

    constructor(props: Props) {
        super(props);
        this.state = {
            formFields: [],
            formInitialValues: {},
            showModal: false,
            modalType: '',
            modalTitle: '',
            configurationData: [],
            noDataMsg: null
        }

        this.apiSubmit = null;
    }

    async componentDidMount() {
        eventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });

        await ConfigurationService.getAll()
        .then(
            (response: any) => {
                eventBus.dispatch("hideLoader");
                if (!response || response.error) {
                    this.setState({noDataMsg: "Non ci sono dati disponibili."})
                } else {
                    this.setState({configurationData: response});
                }
            }
        )
        .catch(() => {
                eventBus.dispatch("hideLoader");
                Swal.fire(
                    'Errore',
                    'Si è verificato un errore.',
                    'error'
                );
            }
        )

        $('#table_configuration.table').on('click', '.edit_btn', async (e: any) => {
            e.preventDefault();
            eventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });
            const idItem = $(e.currentTarget).data('id');
            const item = await ConfigurationService.getOne(idItem);
            if (item) {
                this.setState({ formFields: getEditConfigurationFormFields(), formInitialValues: item }, () => {
                    this.apiSubmit = ConfigurationService.edit;
                    this.setState({ showModal: true, modalType: 'edit', modalTitle: 'Modifica voce' });
                });
            }
            eventBus.dispatch("hideLoader");
        });
        
    }

    openAddModal() {
        this.apiSubmit = ConfigurationService.add;

        this.setState({
            formFields: getAddConfigurationFormFields(),
            formInitialValues: {},
            showModal: true,
            modalType: 'add',
            modalTitle: 'Aggiungi una nuova voce'
        });
    }

    closeModal() {
        this.setState({ showModal: false });
    }

    render() {
        const { formFields, formInitialValues, showModal, modalTitle, modalType, configurationData, noDataMsg } = this.state;
        const buttons = ['excel', 'pdf', 'print'];
        
        return <React.Fragment>
            <div className="custom-container">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <h2 className="card-title m-0">Parametri di configurazione</h2>
                            <button id="add_btn" type="button" className="btn btn-outline-primary" onClick={async () => this.openAddModal()}>
                                <span>Aggiungi parametro</span>
                            </button>
                        </div>

                        <div className="tab-pane active show" id="commesse">
                            <ModalForm showModal={showModal} title={modalTitle} modalType={modalType} formFields={formFields} initialValues={formInitialValues} apiSubmit={this.apiSubmit} closeCallback={this.closeModal.bind(this)} />
                            
                            {configurationData.length > 0 && <Table id="table_configuration" columns={listConfigurationColumns} columnDefs={getListConfigurationColumnDefs()} datas={configurationData} buttons={buttons} />}
                            
                            {noDataMsg && <div className="d-flex align-items-center justify-content-center bg-danger p-2"><h3 className="m-0 me-2">Attenzione!</h3> {noDataMsg}</div>}
                        </div>
                    </div>
                </div>
            </div>
            
        </React.Fragment>
    }

}