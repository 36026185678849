import { AxiosResponse } from 'axios';
import axios from '../api';
import { IConfiguration } from '../../helpers/interfaces/configuration';

const API_URL = process.env.REACT_APP_API_URL;

class ConfigurationService {

    getAll(): Promise<IConfiguration[]> {
        return axios.get<IConfiguration[]>(API_URL + 'database/configuration')
            .then((response: AxiosResponse<IConfiguration[]>) => { return response.data })
            .catch((error: any) => { return Promise.reject(error); });
    }

    getOne(id: string): Promise<IConfiguration> {
        return axios.get<IConfiguration>(API_URL + 'database/configuration/' + id)
            .then((response: AxiosResponse<IConfiguration>) => { return response.data })
            .catch((error) => { return Promise.reject(error); });
    }

    add(body: IConfiguration): Promise<IConfiguration> {
        return axios.post<IConfiguration, AxiosResponse<IConfiguration>>(API_URL + 'database/configuration', body )
            .then((response: AxiosResponse<IConfiguration>) => { return response.data; })
            .catch((error) => { return Promise.reject(error); });
    }

    edit(id: string, body: IConfiguration): Promise<IConfiguration> {
        const payload: IConfiguration = {
            value: body.value
        }
        return axios.put<IConfiguration, AxiosResponse<IConfiguration>>(API_URL + 'database/configuration/' + id, payload )
            .then((response: AxiosResponse<IConfiguration>) => { return response.data; })
            .catch((error) => { return Promise.reject(error); });
    }

}

export default new ConfigurationService();