import { ConfigColumnDefs, ConfigColumns } from "datatables.net";

export const listConfigurationColumns: ConfigColumns[] = [
    { "title": "Azioni", "data": "id" },
    { "title": "Nome", "data": "nome" },
    { "title": "Valore", "data": "value" }
];

export function getListConfigurationColumnDefs(): ConfigColumnDefs[] {
    return [
        {
            target: 0,
            render: function (data: any, type: any, row: any) {
                return `<a class="edit_btn custom-icon btn btn-outline-primary rounded-circle" data-id="${data}">
                    <i style="font-size: 18px" class="fa fa-pencil" aria-hidden="true"></i>
                </a>`;
            },
        },
    ];
}