import React, { Component } from 'react';
import Swal from 'sweetalert2';
import Table from '../../../common/TableComponent';
import ModalForm from '../../../common/ModalForm';
import EventBus from "../../../common/EventBus";
import { getAddDocumentoFormFields } from "./config/formFields/addDocumento";
import { getEditDocumentoFormFields } from "./config/formFields/editDocumento";
import { listDocumentiColumns, getListDocumentiColumnDefs } from "./config/tableColumns/listDocumenti";
import DocumentiService from "../../../services/api/documenti.service";

import { openBase64NewTab } from '../../../common/Base64';
import { iFormField } from '../../../helpers/interfaces/generic';

type Props = {
  documenti: any,
  personId: number
};

type State = {
  data: Array<any>,
  showModal: boolean,
  modalType: string,
  formFields: Array<iFormField>,
  formInitialValues: { [key: string]: any }
}

const $ = require('jquery');

class DocumentiComponent extends Component<Props, State> {
  buttons: (string | { text: string; className: string; action: (e: any, dt: any, node: any, config: any) => void; })[];
  apiSubmit: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      data: props.documenti,
      showModal: false,
      modalType: 'add',
      formFields: [],
      formInitialValues: [],
    }
    this.apiSubmit = null;

    this.buttons = [
      //"copy",
      //"csv",
      "excel",
      "pdf",
      "print",
      //"colvis"
    ];
  }

  componentDidMount() {
    //this.setState({ formFields: getEditDocumentoFormFields() }, () => {
    $('table').on('click', '.edit_btn', async (e: any) => {
      e.preventDefault();
      EventBus.dispatch("showLoader", { text: 'Caricamento Documento in corso...' });
      const idDocumento = $(e.currentTarget).data('id');
      const documento = await DocumentiService.get(idDocumento);
      if (documento) {
        this.setState({ formFields: getEditDocumentoFormFields(), formInitialValues: documento }, () => {
          this.apiSubmit = DocumentiService.put;
          this.setState({ showModal: true, modalType: 'edit' });
        });
      }
      EventBus.dispatch("hideLoader");
    });

    $('table').on('click', '.download_btn', async (e: any) => {
      e.preventDefault();
      EventBus.dispatch("showLoader", { text: 'Recupero del documento in corso...' });
      const idDocumento = $(e.currentTarget).data('id');
      // const documento: any = await DocumentiService.download(idDocumento);
      // if (documento && typeof documento.body !== 'undefined') {
      //   openBase64NewTab(documento);
      // }

      await DocumentiService.download(idDocumento)
      .then((documento: any) => {
        EventBus.dispatch("hideLoader");
        if (documento && typeof documento.body !== 'undefined') {
          openBase64NewTab(documento);
        } else {
          Swal.fire({
            title: 'Errore',
            text: 'Si è verificato un errore. Non è stato possibile recuperare il documento.',
            icon: 'error'
          });
        }
      })
      .catch(error => {
        EventBus.dispatch("hideLoader");
        if (error.error.toLowerCase() === "not data") {
          Swal.fire({
            title: 'Errore',
            text: 'Documento non presente in archivio.',
            icon: 'error'
          });
        } else {
          Swal.fire({
            title: 'Errore',
            text: 'Si è verificato un errore.',
            icon: 'error'
          });
        }
      })
    });

    $('table').on('click', '.delete_btn', async (e: any) => {
      e.preventDefault();
      Swal.fire({
        title: "Vuoi eliminare il documento?",
        //text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Conferma',
        cancelButtonText: 'Annulla'
      }).then(async (result) => {
        if (result.isConfirmed) {
          const idDocumento = $(e.currentTarget).data('id');
          const documento: any = await DocumentiService.delete(idDocumento);

          if (typeof documento.error !== 'undefined') {
            Swal.fire(
              documento.error,
              '',
              'error'
            );
          } else {
            this.handleSuccess();
          }
        }
      });
    });
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  async openAddDocumento() {
    this.apiSubmit = DocumentiService.add;
    this.setState({ formFields: getAddDocumentoFormFields(), formInitialValues: { person_id: this.props.personId }, showModal: true, modalType: 'add' }, () => {
      this.setState({ showModal: false });
    });
  }

  handleSuccess() {
    window.location.hash = "#documenti";
    window.location.reload();
  }

  render() {
    const { showModal, formFields, formInitialValues, modalType, data } = this.state;
    return <div className="row p-2">
      <ModalForm
        showModal={showModal}
        title={'Documento'}
        modalType={modalType}
        formFields={formFields}
        initialValues={formInitialValues}
        apiSubmit={this.apiSubmit}
        closeCallback={this.closeModal.bind(this)}
        successAction={this.handleSuccess.bind(this)}
      />
      <div className="d-flex justify-content-between align-items-center">
        <h3 className="m-0">Documenti</h3>
        <button id="add_btn" type="button" className="btn btn-outline-primary" onClick={this.openAddDocumento.bind(this)}>
          <span>Aggiungi documento</span>
        </button>
      </div>
      {
        data.length > 0 ? <React.Fragment>
          <Table id="table_documenti" columns={listDocumentiColumns} columnDefs={getListDocumentiColumnDefs()} datas={this.state.data} buttons={this.buttons} />
        </React.Fragment> : <p className='mt-3'>Non sono presenti documenti</p>
      }
    </div>
  }
}
export default DocumentiComponent;