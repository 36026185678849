import axios from '../../api';

const API_URL = process.env.REACT_APP_API_URL;

class StatoRITService {
  controller: AbortController;

  constructor() {
    this.controller = new AbortController();
  }

  async getAll() {
    this.controller = new AbortController();
    try {
      const response = await axios.get(API_URL + 'database/rit/status', {
        signal: this.controller.signal
      });
      return Array.isArray(response.data) ? response.data : [];
    } catch (error) {
      return await Promise.reject();
    }
  }

  async get(id:any) {
    try {
      const response = await axios.get(API_URL + 'database/rit/status/' + id);
      return typeof response.data == 'object' ? response.data : null;
    } catch (error) {
      return await Promise.reject();
    };
  }

  async put(id:any, data:any) {
    try {
      const response = await axios.put(API_URL + 'database/rit/status/' + id, {
        ...data
      });
      return response.data;
    } catch (error) {
      return [];
    }
  }

  async add(data:any) {
    try {
      const response = await axios.post(API_URL + 'database/rit/status', {
        ...data
      });
      return response.data;
    } catch (error) {
      return [];
    }
  }

  abort() {
    this.controller.abort();
  }
}

export default new StatoRITService();