import { ConfigColumnDefs, ConfigColumns } from "datatables.net";
import { IMagazzinoVeicolo, IMagazzinoVeicoloManutenzione } from "../../../helpers/interfaces/magazzino/magazzino-veicoli";

// table columns definitions
export const listVeicoliManutenzioneColumns: ConfigColumns[] = [
    { title: "Azioni", data: "id" },
    { title: "Tipologia", data: "type" },
    { title: "Data", data: "date" },
    { title: "Veicolo", data: "vehicle" },
];

// function: setting columns defs (in this case, setting the edit button at the first column)
export default function getVeicoliManutenzioneColumnDefs(): ConfigColumnDefs[] {
    return [
        {
            target: 0,
            render: (id: number, type: string, row: IMagazzinoVeicoloManutenzione) => {
                const edit = `<a class="edit_btn custom-icon btn btn-outline-primary rounded-circle" data-id="${id}">
                    <i style="font-size: 18px" class="fa fa-pencil" aria-hidden="true"></i>
                </a>`;

                return `<div class="d-flex">${edit}</div>`;
            },
        },
        {
            target: 3,
            render: (vehicle: IMagazzinoVeicolo, type: string, row: IMagazzinoVeicoloManutenzione) => {
                return `<div class="d-flex">${vehicle.plate} (${vehicle.brand} ${vehicle.model})</div>`;
            },
        },
    ];
}