import * as Yup from "yup";
import { iFormField } from "../../helpers/interfaces/generic";

export function aggiornaFileRateiFormFields(): Array<iFormField> | Array<iFormField> {
    return [
        {
            'label': 'Allega file ratei',
            'name': 'file',
            'type': 'file',
            'class': 'form-control',
            'validation': Yup.string().required("Campo obbligatorio")
        }
    ]
}