import axios from '../api';
import { AxiosResponse } from 'axios';
import { SerialNumber } from '../../helpers/interfaces/person';
const API_URL = process.env.REACT_APP_API_URL;

class AnagraficaService {
  update(personId:Number, data:any) {
    return axios.put(API_URL + 'datore/person/'+personId+'/anagrafica', {
      ...data
    })
    .then(response => { return response.data; })
    .catch((error) => { return Promise.reject(); });
  }

  add(data:any) {
    return axios.post(API_URL + 'datore/person/anagrafica', {
      ...data
    })
    .then(response => { return response.data; })
    .catch((error) => { return Promise.reject(); });
  }

  getSerialNumbers(): Promise<SerialNumber[]> {
    return axios.get(API_URL + 'datore/person/matricole/used')
      .then((response: AxiosResponse<SerialNumber[]>) => { return response.data; })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}
export default new AnagraficaService();