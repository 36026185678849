import React, { Component } from 'react'
import Swal from 'sweetalert2';
import EventBus from '../../../common/EventBus';
import Table from '../../../common/TableComponent';
import { getEditCommessaFormFields } from '../../../config/formFields/commesse';
import { iFormField, iFormFieldValue, iFormFieldOption } from '../../../helpers/interfaces/generic';
import { IJobOrderExpiring } from '../../../helpers/interfaces/home-amministrazione';
import commesseService from '../../../services/api/commesse.service';
import * as homeSettings from '../../../config/tableColumns/home-amministrazione';
import ModalForm from '../../../common/ModalForm';
import { Customer, Activity } from "../../../helpers/interfaces/joborders";
import ClientiService from "../../../services/api/clienti.service";
import CommesseService from "../../../services/api/commesse.service";
import AttivitaService from  "../../../services/api/database/attivita.service";

const $ = require('jquery');

type Props = {};

type State = {
    jobOrdersExpiring: IJobOrderExpiring;
    formFields: iFormField[],
    showModal: boolean,
    modalType: string,
    formInitialValues: iFormField,
    jobOrdersFromClient: any
}

export class HomeCommesseTab extends Component<Props, State> {

    clienti: any[];
    attivita: any[];
    apiSubmit: typeof commesseService.renew | typeof commesseService.stop;

    constructor(props: Props) {
        super(props)

        this.state = {
            jobOrdersExpiring: {
                assignments: [],
                joborders: []
            },
            formFields: [],
            showModal: false,
            modalType: 'add',
            formInitialValues: {} as iFormField,
            jobOrdersFromClient: []
        }

        this.apiSubmit = commesseService.renew;
        this.closeModal = this.closeModal.bind(this);
        this.clienti = [];
        this.attivita = [];
    }

    async callbackEditJoborderFromCustomer(customerId: number, isFirstLevel: boolean) {
        EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });
        const commesse_all: any = await CommesseService.fromCustomer(customerId);
        let commesse: any = Object.keys(commesse_all).map((key: any) => { return { key: commesse_all[key].id, value: commesse_all[key].jobordername } });

        EventBus.dispatch("hideLoader");
        this.setState({ formFields: getEditCommessaFormFields(
            this.clienti,
            this.callbackEditJoborderFromCustomer.bind(this),
            commesse,
            this.attivita,
            isFirstLevel
        )});
    }

    async componentDidMount(): Promise<void> {
        EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });

        await commesseService.getAllExpiring()
            .then(
                jobOrdersExpiring => this.setState(
                    {
                        jobOrdersExpiring
                    },
                    () => {
                        EventBus.dispatch("hideLoader")
                    }
                ),
                error => EventBus.dispatch("hideLoader")
            )

        // listening to the renew button click event in joborders table
        $('#tab_home_amministrazione_joborders').on('click', '.renew_btn', async (e: any) => {
            e.preventDefault();
            EventBus.dispatch("showLoader", { text: 'Caricamento commessa in corso...' });
            const commessaId = $(e.currentTarget).data('id');

            // await commesseService.get(commessaId)
            //     .then(
            //         commessa => {
            //             if (commessa) {
            //                 this.setState(
            //                     {
            //                         formFields: getEditCommessaFormFields(),
            //                         formInitialValues: commessa
            //                     },
            //                     () => {
            //                         this.apiSubmit = commesseService.renew;
            //                         this.setState({ showModal: true, modalType: 'edit' });
            //                         EventBus.dispatch("hideLoader");
            //                     }
            //                 );
            //             }
            //         },
            //         error => EventBus.dispatch("hideLoader")
            //     );

            const commessa: any = await CommesseService.get(commessaId);

            const isFirstLevel = !commessa?.parentJoborder;
            const rateHourly: number = commessa?.rateIsHourly ? 1 : 0;
    
            const _clienti: Customer[] = await ClientiService.getAll();
            const clienti: iFormFieldValue[] = _clienti.map((item: Customer) => { return { key: item.id, value: item.ragionesociale } });

            const _attivita: Activity[] = await AttivitaService.getAllDatore();
            const attivita: iFormFieldOption[] = _attivita.map((item: Activity) => {
                return { value: item.id, label: item.descrizione }
            });

            this.clienti = clienti;
            this.attivita = attivita;

            if (commessa) {
                this.setState({
                        formFields: getEditCommessaFormFields(clienti, this.callbackEditJoborderFromCustomer(commessa.customerid, isFirstLevel), this.state.jobOrdersFromClient, attivita, isFirstLevel), 
                        formInitialValues: {...commessa, rateIsHourly: rateHourly}
                    }, () => {
                        this.apiSubmit = commesseService.renew;
                        this.setState({ showModal: true, modalType: 'edit' });
                    });
            }
            EventBus.dispatch("hideLoader");
        });

        // listening to the stop button click event in joborders table
        $('#tab_home_amministrazione_joborders').on('click', '.stop_btn', async (e: any) => {
            e.preventDefault();
            Swal.fire({
                title: 'Vuoi bloccare la commessa?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Conferma',
                cancelButtonText: 'Annulla',
                customClass: 'custom-alert'
            }).then(async (result: any) => {
                if (result.isConfirmed) {
                    EventBus.dispatch("showLoader", { text: 'Blocco commessa in corso...' });
                    const commessaId = $(e.currentTarget).data('id');
                    await commesseService.stop(commessaId).then(
                        res => {
                            window.location.reload();
                            EventBus.dispatch("hideLoader")
                        },
                        error => {
                            window.location.reload();
                            EventBus.dispatch("hideLoader")
                        }
                    );
                }
            });
        });
    }

    // method: closing modal
    closeModal() {
        this.setState({ showModal: false });
    }

    render() {
        const { jobOrdersExpiring, showModal, modalType, formFields, formInitialValues } = this.state
        const currentDom = "t<'d-flex justify-content-between align-items-center m-2'lip>"

        return (
            <div className='d-flex flex-wrap'>
                {
                    jobOrdersExpiring.assignments.length > 0 && <div className='col-12 py-3 col-md-6 p-md-3'>
                        <h4 className='mb-3'>Assegnazioni in scadenza</h4>
                        <Table id='tab_home_amministrazione_assignments' columns={homeSettings.listAssignmentsColumns} columnDefs={homeSettings.getAssignmentsColumnDefs()} datas={jobOrdersExpiring.assignments} dom={currentDom} buttons={[]} lengthMenu={[5, 10]} />
                    </div>
                }
                {
                    jobOrdersExpiring.joborders.length > 0 && <div className="col-12 py-3 col-md-6 p-md-3">
                        <h4 className='mb-3'>Commesse in scadenza</h4>
                        <ModalForm showModal={showModal} title={'Dati della commessa selezionata'} modalType={modalType} formFields={formFields} initialValues={formInitialValues} apiSubmit={this.apiSubmit} closeCallback={this.closeModal} />
                        <Table id='tab_home_amministrazione_joborders' columns={homeSettings.listJobOrdersColumns} columnDefs={homeSettings.getJobOrdersColumnDefs()} datas={jobOrdersExpiring.joborders} dom={currentDom} buttons={[]} lengthMenu={[5, 10]} />
                    </div>
                }
            </div>
        )
    }
}

export default HomeCommesseTab