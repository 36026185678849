import { AxiosResponse } from 'axios';
import { IMagazzinoCategoriaStrumento } from '../../../helpers/interfaces/magazzino/magazzino-categoria-strumenti';
import axios from '../../api';

const API_URL = process.env.REACT_APP_API_URL;

class CategoriaStrumentiService {

  controller: AbortController;

  constructor() {
    this.controller = new AbortController();
  }

  getAll(): Promise<IMagazzinoCategoriaStrumento[]> {
    this.controller = new AbortController();
    return axios.get<IMagazzinoCategoriaStrumento[]>(API_URL + 'magazzino/strumenti/categoria', {
      signal: this.controller.signal
    })
      .then(response => { return Array.isArray(response.data) ? response.data : []; })
      .catch((error) => { return Promise.reject(); });
  }

  getOne(id: string): Promise<IMagazzinoCategoriaStrumento> {
    return axios.get<IMagazzinoCategoriaStrumento>(API_URL + 'magazzino/strumenti/categoria/' + id)
      .then((response: AxiosResponse<IMagazzinoCategoriaStrumento>) => { return response.data; })
      .catch((error) => { return Promise.reject(); });
  }

  add(body: { nome: string }): Promise<IMagazzinoCategoriaStrumento> {
    return axios.post<{ nome: string }, AxiosResponse<IMagazzinoCategoriaStrumento>>(API_URL + 'magazzino/strumenti/categoria', body)
      .then((response: AxiosResponse<IMagazzinoCategoriaStrumento>) => { return response.data; })
      .catch((error) => { return Promise.reject(); });
  }

  edit(id: string, body: { nome: string }): Promise<IMagazzinoCategoriaStrumento> {
    return axios.put<{ nome: string }, AxiosResponse<IMagazzinoCategoriaStrumento>>(API_URL + 'magazzino/strumenti/categoria/' + id, body)
      .then((response: AxiosResponse<IMagazzinoCategoriaStrumento>) => { return response.data; })
      .catch((error) => { return Promise.reject(); });
  }

  abort() {
    this.controller.abort();
  }

}

export default new CategoriaStrumentiService();