import * as Yup from "yup";
import { iFormField } from "../../../../../helpers/interfaces/generic";

export function getAddLicenzaFormFields(licenze: any, personId: number): Array<iFormField> {
  return [
    {
      'label': 'Licenze',
      'name': 'licenze_id',
      'type': 'search',
      'values': licenze,
      'class': 'form-select',
      'placeholder': 'Cerca licenze...',
      'validation': Yup.string()
    },
    {
      'label': 'Data Consegna',
      'name': 'data_consegna',
      'type': 'date',
      'class': 'form-control',
      'validation': Yup.date().required("Campo obbligatorio")
    },
    {
      'label': 'Data Restituzione',
      'name': 'data_restituzione',
      'type': 'date',
      'class': 'form-control',
      'validation': Yup.date().notRequired()
    },
    {
      'label': 'Note',
      'name': 'note',
      'type': 'text',
      'class': 'form-control',
      'validation': Yup.string().notRequired()
    },
    {
      'label': '',
      'name': 'person_id',
      'type': 'hidden',
      'value': personId,
      'class': 'form-control',
      'validation': Yup.number().positive().integer().required("Campo obbligatorio")
    },
  ];
}