import React, { Component } from "react";
import EventBus from "../../common/EventBus";
import GestioneRateiService from "../../services/api/gestione-ratei.service";
import { IDipendenteRatei } from '../../helpers/interfaces/user';
import Swal from "sweetalert2";
import Table from '../../common/TableComponent';
import { listRateiColumns } from '../../config/tableColumns/ratei';

type Props = {
};

type State = {
    rateiList: IDipendenteRatei[],
    noDataMsg: string | null
}

export default class RateiComponent extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            rateiList: [],
            noDataMsg: null
        }
    }

    async componentDidMount() {
        EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });

        GestioneRateiService.getAllDipendente().then(
            (response: any) => {
                EventBus.dispatch("hideLoader");
                if (!response || response.error) {
                    // Swal.fire(
                    //     'Attenzione.',
                    //     'Non ci sono dati disponibili',
                    //     'warning'
                    // );

                    this.setState({noDataMsg: "Non ci sono dati disponibili."})
                } else {
                    this.setState({rateiList: response});
                }
            }
        ).catch(() => {
            EventBus.dispatch("hideLoader");
            Swal.fire(
                'Errore.',
                'Non è stato possibile recuperare i dati a causa di un errore imprevisto.',
                'error'
            );
        });
    }

    render() {
        const { rateiList, noDataMsg } = this.state;
        const buttons = ['excel', 'pdf', 'print'];

        return <React.Fragment>
            <div className="custom-container">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <h2 className="card-title">Ratei</h2>
                        </div>

                        {rateiList.length > 0 && <Table id="table_ratei" columns={listRateiColumns} columnDefs={[]} datas={rateiList} buttons={buttons} />}

                        {noDataMsg && <div className="d-flex align-items-center justify-content-center bg-danger p-2"><h3 className="m-0 me-2">Attenzione!</h3> {noDataMsg}</div>}
                    </div>
                </div>
            </div>
        </React.Fragment>
    }
}