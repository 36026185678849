import { AxiosResponse } from 'axios';
import { IDispositivo } from '../../../helpers/interfaces/magazzino/magazzino-dispositivi';
import axios from '../../api';

const API_URL = process.env.REACT_APP_API_URL;

class MagazzinoDispositiviService {

    //* se ci sono chiamate che restituiscono 200 ma non hanno dati, metti controllo su error

    async getAll(): Promise<IDispositivo[]> {
        try {
            const response = await axios.get<IDispositivo[]>(API_URL + 'magazzino/dispositivi');
            return Array.isArray(response.data) ? response.data : [];
        } catch (error) {
            return await Promise.reject();
        }
    }

    async getOne(id: string): Promise<IDispositivo> {
        try {
            const response = await axios.get<IDispositivo>(API_URL + 'magazzino/dispositivi/' + id);
            return response.data;
        } catch (error) {
            return await Promise.reject();
        }
    }

    async add(body: FormData): Promise<IDispositivo> {
        try {
            const response = await axios.post<FormData, AxiosResponse<IDispositivo>>(API_URL + 'magazzino/dispositivi', body, { headers: { "Content-Type": "multipart/form-data" } });
            return response.data;
        } catch (error) {
            return await Promise.reject();
        }
    }

    async edit(id: string, body: FormData): Promise<IDispositivo> {
        try {
            const response = await axios.put<FormData, AxiosResponse<IDispositivo>>(API_URL + 'magazzino/dispositivi/' + id, body, { headers: { "Content-Type": "multipart/form-data" } });
            return response.data;
        } catch (error) {
            return await Promise.reject();
        }
    }

    async getAllDipendente(): Promise<IDispositivo[]> {
        try {
            const response = await axios.get<IDispositivo[]>(API_URL + 'dipendente/dispositivi');
            return Array.isArray(response.data) ? response.data : [];
        } catch (error) {
            return await Promise.reject();
        }
    }

    async getOneDipendente(id: string): Promise<IDispositivo> {
        try {
            const response = await axios.get<IDispositivo>(API_URL + 'dipendente/dispositivi/' + id);
            return response.data;
        } catch (error) {
            return await Promise.reject();
        }
    }

}

export default new MagazzinoDispositiviService();