import React, { Component } from 'react'
import EventBus from '../../../common/EventBus';
import { iFormField } from '../../../helpers/interfaces/generic';
import { iconsStyle } from '../../../helpers/settings/buttons-icons-styles';
import contrattiService from '../../../services/api/contratti.service';
import tutorsService from '../../../services/api/tutors.service';
import tipologiacontrattiService from '../../../services/api/database/tipologiacontratti.service';
import finecontrattiService from '../../../services/api/database/finecontratti.service';
import { getContrattoFormFields } from './config/formFields/addContratto';
import ModalForm from '../../../common/ModalForm';
import Swal from 'sweetalert2';

type Props = {
    contrattoAttivo: any,
    history: any
};

type State = {
    showModal: boolean,
    modalType: string,
    modalTitle: string,
    formFields: Array<iFormField>,
    formInitialValues: { [key: string]: any },
}

export class InfoContrattoComponent extends Component<Props, State> {

    apiSubmit: typeof contrattiService.put = contrattiService.put;

    constructor(props: Props) {
        super(props)

        this.state = {
            showModal: false,
            modalType: 'edit',
            modalTitle: 'Modifica',
            formFields: [],
            formInitialValues: {}
        }
    }

    initFormValues(data: any) {
        let initialValues = {}
        Object.keys(data).forEach(
            value => {
                Object.assign(initialValues, {
                    ...initialValues,
                    [value]: typeof data[value] === 'boolean' ? Number(data[value]) : data[value]
                })
            }
        )
        return initialValues
    }

    async openEditModal(id: number) {
        EventBus.dispatch("showLoader", { text: 'Caricamento contratto in corso...' });

        const dettaglioContratto: any = await contrattiService.get(id);
        if (dettaglioContratto) {
            const tipologiacontratti_all = await tipologiacontrattiService.getAllDatore();
            let tipologiacontratti: any = tipologiacontratti_all.map((item: any) => { return { key: item.id, value: item.nome } });

            const tutors_all = await tutorsService.getAll();
            let tutors: any = tutors_all.map((item: any) => { return { key: item.id, value: item.tutor } });

            const finecontratti_all = await finecontrattiService.getAllDatore();
            let finecontratti: any = finecontratti_all.map((item: any) => { return { key: item.id, value: item.nome } });

            let initialValues = this.initFormValues(dettaglioContratto)

            this.setState({
                formFields: getContrattoFormFields(tipologiacontratti, tutors, finecontratti),
                formInitialValues: { ...initialValues },
                showModal: true,
                modalType: 'edit',
                modalTitle: 'Modifica contratto'
            });
        }
        EventBus.dispatch("hideLoader");
    }

    closeModal() {
        this.setState({ showModal: false })
    }

    handleSuccess() {
        window.location.hash = "#contratti";
        window.location.reload();
    }

    handleResponse() {
        EventBus.dispatch("hideLoader");
        Swal.fire({
            title: "Operazione completata con successo",
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK',
        }).then(
            async result => {
                this.handleSuccess();
            }
        );
    }

    handleError() {
        EventBus.dispatch("hideLoader");
        Swal.fire(
            'Operazione fallita',
            'error'
        )
    }

    async submitCallback(data: any, formik: any) {
        EventBus.dispatch("showLoader", { text: 'Operazione in corso...' });

        const formData = data
        // edit contratto
        delete formData.variabile
        let contratto = formData
        await contrattiService.put(contratto.id, contratto).then(
            () => this.handleResponse(),
            () => this.handleError()
        )
    }

    render() {
        const { contrattoAttivo } = this.props
        const { showModal, modalTitle, modalType, formFields, formInitialValues } = this.state

        return (
            contrattoAttivo ? <div className="card mt-2 h-100">
                <ModalForm
                    showModal={showModal}
                    title={modalTitle}
                    modalType={modalType}
                    formFields={formFields}
                    initialValues={formInitialValues}
                    apiSubmit={this.apiSubmit}
                    closeCallback={this.closeModal.bind(this)}
                    successAction={this.handleSuccess.bind(this)}
                    submitCallback={this.submitCallback.bind(this)}
                />

                <div className="card-body">
                    <div className='mb-3 d-flex align-items-center justify-content-between'>
                        <h3 className='mb-0'>
                            Contratto attivo
                        </h3>
                        <button className="action-icon custom-icon btn btn-outline-primary rounded-circle" onClick={() => this.openEditModal(contrattoAttivo.id)}>
                            <i style={iconsStyle} className="fa fa-pencil" aria-hidden="true"></i>
                        </button>
                    </div>
                    {
                        contrattoAttivo.tipocontratto_nome && <div className="mb-2 d-flex justify-content-between">
                            <label><strong>Tipo di contratto</strong></label>
                            <span>{contrattoAttivo.tipocontratto_nome}</span>
                        </div>
                    }
                    {
                        contrattoAttivo.mansione && <div className="mb-2 d-flex justify-content-between">
                            <label><strong>Mansione</strong></label>
                            <span>{contrattoAttivo.mansione}</span>
                        </div>
                    }
                    {
                        contrattoAttivo.data_assunzione && <div className="mb-2 d-flex justify-content-between">
                            <label><strong>Data assunzione</strong></label>
                            <span>{contrattoAttivo.data_assunzione}</span>
                        </div>
                    }
                    {
                        contrattoAttivo.data_fine_prova && <div className="mb-2 d-flex justify-content-between">
                            <label><strong>Data fine prova</strong></label>
                            <span>{contrattoAttivo.data_fine_prova}</span>
                        </div>
                    }
                    {
                        contrattoAttivo.data_fine_rapporto && <div className="mb-2 d-flex justify-content-between">
                            <label><strong>Data fine rapporto</strong></label>
                            <span>{contrattoAttivo.data_fine_rapporto}</span>
                        </div>
                    }
                    {
                        contrattoAttivo.numero_proroghe && <div className="mb-2 d-flex justify-content-between">
                            <label><strong>Proroghe</strong></label>
                            <span>{contrattoAttivo.numero_proroghe}</span>
                        </div>
                    }
                    {
                        contrattoAttivo.sede_operativa && <div className="mb-2 d-flex justify-content-between">
                            <label><strong>Sede operativa</strong></label>
                            <span>{contrattoAttivo.sede_operativa}</span>
                        </div>
                    }
                    <div className="mb-2 d-flex justify-content-between">
                        <label><strong>Sede contrattuale</strong></label>
                        <span>{contrattoAttivo.sede_contrattuale}</span>
                    </div>
                    {
                        contrattoAttivo.ufficio && <div className="mb-2 d-flex justify-content-between">
                            <label><strong>Ufficio</strong></label>
                            <span>{contrattoAttivo.ufficio ? 'Sì' : 'No'}</span>
                        </div>
                    }
                    {
                        contrattoAttivo.tutor && <div className="mb-2 d-flex justify-content-between">
                            <label><strong>Tutor</strong></label>
                            <span>{contrattoAttivo.tutor}</span>
                        </div>
                    }
                    {
                        contrattoAttivo.ccnl && <div className="mb-2 d-flex justify-content-between">
                            <label><strong>CCNL</strong></label>
                            <span>{contrattoAttivo.ccnl}</span>
                        </div>
                    }
                    {
                        contrattoAttivo.protetta && <div className="mb-2 d-flex justify-content-between">
                            <label><strong>Protetta</strong></label>
                            <span>{contrattoAttivo.protetta ? 'Sì' : 'No'}</span>
                        </div>
                    }
                    {
                        contrattoAttivo.ticket && <div className="mb-2 d-flex justify-content-between">
                            <label><strong>Ticket</strong></label>
                            <span>{contrattoAttivo.ticket ? 'Sì' : 'No'}</span>
                        </div>
                    }
                    {
                        contrattoAttivo.ticket_value && <div className="mb-2 d-flex justify-content-between">
                            <label><strong>Valore ticket</strong></label>
                            <span>{contrattoAttivo.ticket_value} €</span>
                        </div>
                    }
                    {
                        contrattoAttivo.fine_contratto_nome && <div className="mb-2 d-flex justify-content-between">
                            <label><strong>Fine contratto</strong></label>
                            <span>{contrattoAttivo.fine_contratto_nome}</span>
                        </div>
                    }
                    {
                        contrattoAttivo.nota && <div className="mb-2 d-flex justify-content-between">
                            <label><strong>Note</strong></label>
                            <span>{contrattoAttivo.nota}</span>
                        </div>
                    }
                </div>
            </div> : null
        )
    }

}

export default InfoContrattoComponent