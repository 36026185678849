import React, { Component } from "react";
import Swal from 'sweetalert2';
import EventBus from "../../common/EventBus";
import ModalForm from '../../common/ModalForm';

import AdempimentiService from "../../services/api/database/adempimenti.service";
import { listAdempimentiColumns, getListAdempimentiColumnDefs } from "../../config/tableColumns/organico/adempimenti";
import { getEditAdempimentoFormFields, getAddAdempimentoFormFields } from "../../config/formFields/adempimenti";
import Table from '../../common/TableComponent';
import { iFormField } from "../../helpers/interfaces/generic";

const $ = require('jquery');

type Props = {
    history: any
};

type State = {
    formFields: Array<iFormField>,
    loading: boolean,
    message: string,
    showModal: boolean,
    modalType: string,
    adempimenti: any,
    formInitialValues: { [key: string]: any }
}
export default class Adempimenti extends Component<Props, State> {
    innerRefs: any;
    apiSubmit: any;
    buttons: string[] | null;

    constructor(props: Props) {
        super(props);
        this.state = {
            formFields: [],
            adempimenti: [],
            loading: false,
            message: "",
            showModal: false,
            modalType: 'add',
            formInitialValues: {},
        }
        this.innerRefs = [];
        this.apiSubmit = null;// LicenzeService.personAdd;
        this.buttons = null;
    }

    async componentDidMount() {
        EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });
        const adempimenti = await AdempimentiService.getAll();
        if (adempimenti.length > 0) {
            this.setState({ adempimenti });
        }
        EventBus.dispatch("hideLoader");

        $('#table_adempimento.table').on('click', '.edit_btn', async (e: any) => {
            e.preventDefault();
            EventBus.dispatch("showLoader", { text: 'Caricamento Adempimento in corso...' });
            const idAdempimento = $(e.currentTarget).data('id');
            const adempimento: any = await AdempimentiService.get(idAdempimento);
            if (adempimento) {
                this.setState({ formFields: getEditAdempimentoFormFields(), formInitialValues: adempimento }, () => {
                    this.apiSubmit = AdempimentiService.put;
                    this.setState({ showModal: true, modalType: 'edit' });
                });
            }
            EventBus.dispatch("hideLoader");
        });

        $('#table_adempimento.table').on('click', '.delete_btn', async (e: any) => {
            e.preventDefault();
            Swal.fire({
                title: "Vuoi confermare l'eliminazione?",
                //text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Conferma',
                cancelButtonText: 'Annulla'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const idAdempimento = $(e.currentTarget).data('id');
                    const adempimento: any = await AdempimentiService.delete(idAdempimento);

                    if (adempimento && typeof adempimento.error !== 'undefined') {
                        Swal.fire(
                            adempimento.error,
                            '',
                            'error'
                        );
                    } else {
                        window.location.reload();
                    }
                }
            });
        });
    }

    closeModal() {
        this.setState({ showModal: false });
    }

    async openAddModale() {
        this.apiSubmit = AdempimentiService.add;
        this.setState({ formFields: getAddAdempimentoFormFields(), formInitialValues: {}, showModal: true, modalType: 'add' });
    }

    render() {
        const { formFields, showModal, modalType, adempimenti, formInitialValues } = this.state;

        return <React.Fragment>
            <div className="custom-container">
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center">
                            <h3>Adempimenti</h3>
                            <button id="add_btn" type="button" className="btn btn-outline-primary" onClick={() => this.openAddModale()}>
                                <span>Aggiungi adempimento</span>
                            </button>
                        </div>
                        <ModalForm showModal={showModal} title={'Adempimento'} modalType={modalType} formFields={formFields} initialValues={formInitialValues} apiSubmit={this.apiSubmit} closeCallback={this.closeModal.bind(this)} />
                        {adempimenti.length > 0 && <Table id="table_adempimento" columns={listAdempimentiColumns} columnDefs={getListAdempimentiColumnDefs()} datas={adempimenti} buttons={this.buttons} />}
                    </div>
                </div>
            </div>

        </React.Fragment>
    }
}