import axios from '../api';
import { RitList, RitDetail, RitStatus, RitDocument, SuccessResponse } from "../../helpers/interfaces/admin";
import { IDocumento } from '../../helpers/interfaces/documento';

const API_URL = process.env.REACT_APP_API_URL;

class RitAmministratoreService {
    async getRitList(): Promise<RitList[]> {
        try {
            const response = await axios.get(API_URL + 'datore/rit');

            return response.data;
        } catch (error) {
            return await Promise.reject(error);
        }
    }

    async getOneRit(id: number): Promise<RitDetail> {
        try {
            const response = await axios.get(
                API_URL + 'datore/rit/' + id
            );

            return response.data;
        } catch (error) {
            return await Promise.reject(error);
        }
    }

    async getStatusList(): Promise<RitStatus[]> {
    try {
            const response = await axios.get(
                API_URL + 'datore/rit/status'
            );

            return response.data;
        } catch (error) {
            return await Promise.reject(error);
        }
    }

    async putStatus(
        id: number,
        body: RitDetail
    ): Promise<any> {
        const payload = {
            stato: body.stato_id,
            note: body.note
        }
        try {
            const response = await axios.put<any>(
                API_URL + 'datore/rit/' + id + '/gestione',
                payload
            );

            return response.data;
        } catch (error) {
            return await Promise.reject(error);
        }
    }

    async putRitData(
        id: number,
        body: RitDocument
    ): Promise<SuccessResponse> {
        try {
            const response = await axios.put<any>(
                API_URL + 'datore/rit/' + id + '/invia',
                body
            );

            return response.data;
        } catch (error) {
            return await Promise.reject(error);
        }
    }

    async saveRitDraft(
        id: number,
        body: RitDocument
    ): Promise<SuccessResponse> {
        try {
            const response = await axios.put<any>(
                API_URL + 'datore/rit/' + id + '/bozza',
                body
            );

            return response.data;
        } catch (error) {
            return await Promise.reject(error);
        }
    }

    async deleteRit(
        id: number
    ): Promise<any> {
        try {
            const response = await axios.delete(
                API_URL + 'datore/rit/' + id
            );

            return response.data;
        } catch (error) {
            return await Promise.reject(error);
        }
    }

    async downloadPdfRit(
        id: number
    ): Promise<IDocumento> {
        try {
            const response = await axios.get(
                API_URL + 'datore/rit/pdf/' + id
            );

            return response.data;
        } catch (error) {
            return await Promise.reject(error);
        }
    }
}

export default new RitAmministratoreService();
