import axios from '../../api';

const API_URL = process.env.REACT_APP_API_URL;

class RuoliService {
  async getAll() {
    try {
      const response = await axios.get(API_URL + 'database/ruolo');
      return Array.isArray(response.data) ? response.data : [];
    } catch (error) {
      return await Promise.reject();
    }
  }

  async getAllDatore() {
    try {
      const response = await axios.get(API_URL + 'datore/ruolo');
      return Array.isArray(response.data) ? response.data : [];
    } catch (error) {
      return await Promise.reject();
    }
  }

  async get(id:any) {
    try {
      const response = await axios.get(API_URL + 'database/ruolo/' + id);
      return typeof response.data == 'object' ? response.data : null;
    } catch (error) {
      return await Promise.reject();
    };
  }

  async put(id:any, data:any) {
    try {
      const response = await axios.put(API_URL + 'database/ruolo/' + id, {
        ...data
      });
      return response.data;
    } catch (error) {
      return [];
    }
  }

  async add(data:any) {
    try {
      const response = await axios.post(API_URL + 'database/ruolo', {
        ...data
      });
      return response.data;
    } catch (error) {
      return [];
    }
  }

  async personAdd(data:any) {
    try {
      const response = await axios.post(API_URL + 'datore/person/ruolo', {
        ...data
      });
      return response.data;
    } catch (error) {
      return [];
    }
  }

  async personDelete(id:any) {
    try {
      const response = await axios.delete(API_URL + 'datore/person/ruolo/' + id);
      return response.data;
    } catch (error: any) {
      return error.response.data;
    }
  }
}

export default new RuoliService();