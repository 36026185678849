import Swal from 'sweetalert2';

const $ = require('jquery');

declare let confirm: any;
declare let location: any;
class Events {
    constructor() {
        this.selectAnno();
        this.selectDay();
        //this.deleteParent();
        this.closeRendicontazione();
    }

    selectAnno = (): void => {
        $('select[name="rendicontazione_select_anno"]').change(function (event: any) {
            var anno = $(event.target).val();
            var mese = $('#mese_corrente').val();
            var url = '/dipendente/rendicontazione/' + anno + '/' + mese;
            $(location).attr('href', url);
            $("div.loader-default").addClass('is-active');
        });
    }

    isMac = (): boolean => {
        return navigator.userAgent.indexOf('Mac OS X') !== -1;
    }

    selectDay = (): void => {
        const isMac = this.isMac();
        $('div').on('click', '.day', (event: any) => {
            if (((isMac && event.metaKey) || (!isMac && event.ctrlKey)) && typeof $(event.target).attr('id') != 'undefined') {
                $(event.target).hasClass('selected') ? $(event.target).removeClass('selected') : $(event.target).addClass('selected');
            }
        });
    }

    changeMonth = (getStatoModificaRendicontazione: () => number): void => {
        $('a.confirm.rendicontazione').off('click');
        $("a.confirm.rendicontazione").click(function (event: any) {
            if (getStatoModificaRendicontazione() === 1) {
                if (!confirm("Ha modificato la rendicontazione senza salvare, vuoi procedere perdendo le modifiche ?")) {
                    event.preventDefault();
                } else {
                    $("div.loader-default").addClass('is-active');
                }
            } else {
                $("div.loader-default").addClass('is-active');
            }
        });
    }

    deleteParent = (callback?: (day: number, reporting: number) => void, setStatoModificaRendicontazione?: (stato: number) => void): void => {
        $('#rendicontazione').on('click', '.deleteParent' , function (event: JQuery.ClickEvent<HTMLElement, null, HTMLElement, HTMLElement>) {
            Swal.fire({
                title: "Sei sicuro di voler confermare l'eliminazione ?",
                //text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Conferma',
                cancelButtonText: 'Annulla'
            }).then((result: any) => {
                if (result.isConfirmed) {
                    $(event.target).parents('.rendicontazione').remove();
                    const day = $(event.target).parents('.rendicontazione').attr('day');
                    const index = $(event.target).parents('.rendicontazione').attr('index');
                    if (typeof callback == 'function') {
                        (day && index) && callback(Number(day), Number(index));
                    }
                    if (typeof setStatoModificaRendicontazione == 'function') {
                        setStatoModificaRendicontazione(1);
                    }
                }
            });
        });
    }

    closeRendicontazione = (): void => {
        $('#modalRendicontazione').on('hidden.bs.modal', function () {
            $('#rendicontazione .day.selected').removeClass('selected');
        });
    }

    openRendicontazione = (callback?: (year: number, month: number, day: number, title: string, personid?: number) => void): void => {
        $('.modalRendicontazione').off('click');
        $('.modalRendicontazione').on('click', function (event: any) {
            const year = $(event.target).parent().data('year');
            const month = $(event.target).parent().data('month');
            const day = $(event.target).parent().data('day');
            const personid = $(event.target).parent().data('personid');
            if (typeof callback == 'function') {
                callback(year, month, day, 'Rendicontazione', personid);
            }
        });
    }

    clickSubmitRendicontazione = (callback?: (status: string) => void): void => {
        $('.submitRendicontazione').off('click');
        $('.submitRendicontazione').on('click', function (event: any) {
            if (typeof callback == 'function') {
                const status = $(event.target).attr('status');
                const parola = status === 'C' ? 'confermare' : 'salvare';
                const confirmText = status === 'C' ? 'Conferma' : 'Salva';
                Swal.fire({
                    title: 'Sei sicuro di voler ' + parola + ' la rendicontazione ?',
                    //text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: confirmText,
                    cancelButtonText: 'Annulla'
                }).then((result: any) => {
                    if (result.isConfirmed) {
                        status && callback(status);
                    }
                });
            }
        });
    }
}
export default Events;