import * as Yup from "yup";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { iFormField } from "../../helpers/interfaces/generic";

export function getEditComuneFormFields(): Array<iFormField> {
  return [
    {
      'label': 'Comune',
      'name': 'nome',
      'type': 'text',
      'class': 'form-control',
      'validation': Yup.string().required("Campo obbligatorio")
    },
    {
      'label': 'Data Patrono',
      'name': 'dataPatrono',
      'type': 'date',
      'class': 'form-control',
      'validation': Yup.string().required("Campo obbligatorio")
    }
  ]
}

export function getAddComuneFormFields(): Array<iFormField> {
  return [
    {
      'label': 'Comune',
      'name': 'nome',
      'type': 'text',
      'class': 'form-control',
      'validation': Yup.string().required("Campo obbligatorio")
    },
    {
      'label': 'Data Patrono',
      'name': 'dataPatrono',
      'type': 'date',
      'class': 'form-control',
      'validation': Yup.string().required("Campo obbligatorio")
    }
  ];
}