import axios from 'axios';
import AuthService from "./auth.service";
import authHeader from './auth-header';

axios.interceptors.request.use(async function (config: any) {
    config.headers = { ...config.headers, ...authHeader() };
    return config;
}, function (error) {
    return Promise.reject(error);
});

axios.interceptors.response.use(undefined, function (error) {
    const statusCode = error.response ? error.response.status : null;

    if (statusCode === 401 || statusCode === 403) {
        if (window.location.pathname !== '/login')
            AuthService.logout();
    }

    return Promise.reject(error);
})

export default axios;