import { ConfigColumnDefs, ConfigColumns } from "datatables.net";

export const listContattiColumns: ConfigColumns[] = [
    { "title": "Azioni", "data": "id" },
    { "title": "Cliente", "data": "clienti.ragionesociale" },
    {
        "title": "E-mail", "data": null, render: (data: any, type: any, row: any) => {
            return row.email1 + '<br>' + row.email2;
        }
    },
    { "title": "Cognome", "data": "lastName" },
    { "title": "Nome", "data": "name" },
    { "title": "Cellulare", "data": "phoneCell" },
    { "title": "Nota", "data": "notes" }
];

export function getListContattiColumnDefs(): ConfigColumnDefs[] {
    return [
        {
            target: 0,
            //data: null,
            render: function (data: any, type: any, row: any) {
                const trash = `<a class="delete_btn custom-icon btn btn-outline-danger rounded-circle me-1" data-id="${data}"><i style="font-size: 18px" class="fa fa-trash" aria-hidden="true"></i></a>`;

                const edit = `<a class="edit_btn custom-icon btn btn-outline-primary rounded-circle" data-id="${data}"><i style="font-size: 18px" class="fa fa-pencil" aria-hidden="true"></i></a>`;

                return '<div class="d-flex">' + trash + edit + '</div>';
            },
        },
    ];
}