import * as Yup from 'yup'
import { iFormField, iFormFieldValue, iFormFieldOption } from '../../helpers/interfaces/generic';
import moment from 'moment';
import { FormikProps } from 'formik';
import { IPlanningFormFields } from '../../helpers/interfaces/planning';

const requiredMessage = 'Campo obbligatorio';

// function: setting form fields for planning's add and edit modals
export function planningFormFields(
    people: iFormFieldOption[],
    devices: iFormFieldOption[],
    jobOrders: iFormFieldOption[],
    activities: iFormFieldOption[],
    vehicles: iFormFieldOption[],
    licenses: iFormFieldOption[],
    statuses: iFormFieldOption[],
    priority: iFormFieldOption[],
    customers: iFormFieldValue[],
    resourcesHandler: (
        date: moment.Moment,
        type: 'start' | 'end',
    ) => Promise<void>,
    joborderHandler: (
        value: number,
        formik?: FormikProps<IPlanningFormFields>,
    ) => Promise<void>,
    activitiesHandler: (
        values: iFormFieldOption[],
        formik: FormikProps<IPlanningFormFields>,
    ) => Promise<void>,
): iFormField[] {
    return [
        {
            label: 'Cliente',
            name: 'customer',
            type: 'select',
            values: customers,
            class: 'form-select'
        },
        {
            label: 'Commessa',
            name: 'joborder',
            type: 'search-select',
            class: 'form-control',
            options: jobOrders,
            validation: Yup.string().required(requiredMessage),
            // possibile modifica:
            // se c'e' cliente, commessa non e' obbligatoria
            // validation: Yup.string().when("customer", {
            //     is: (value: any) => { return value === undefined },
            //     then: Yup.string().required(requiredMessage)
            // })
            updateField: 'joborder',
            updateCallback: async (
                value: number,
                formik: FormikProps<IPlanningFormFields>,
            ) => {
                if (value) {
                    await joborderHandler(value, formik);
                }
            }
        },
        {
            label: 'Attività',
            name: 'activities',
            type: 'multi-select',
            class: 'form-control',
            options: activities,
            validation: Yup.array().nullable(),
            updateField: 'activities',
            updateCallback: async (
                values: iFormFieldOption[],
                formik: FormikProps<IPlanningFormFields>,
            ) => {
                if (values) {
                    await activitiesHandler(values, formik);
                }
            }
        },
        {
            label: 'Data inizio',
            name: 'start',
            type: 'datetime',
            class: 'form-control',
            validation: Yup.string().required(requiredMessage),
            updateField: 'start',
            updateCallback: async (value: any) => {
                if (value) {
                    const startDate = moment(value);
                    await resourcesHandler(startDate, 'start');
                }
            }
        },
        {
            label: 'Data fine',
            name: 'end',
            type: 'datetime',
            class: 'form-control',
            validation: Yup.string().required(requiredMessage),
            updateField: 'end',
            updateCallback: async (value: any) => {
                if (value) {
                    const endDate = moment(value);
                    await resourcesHandler(endDate, 'end');
                }
            }
        },
        {
            label: 'Persone',
            name: 'persons',
            type: 'multi-select',
            class: 'form-control',
            options: people,
            // validation: Yup.array().min(1).required(requiredMessage),
            validation: Yup.array().nullable()
                .when(["devices", "vehicles", "licenses"], {
                    is: (valueD: any, valueV: any, valueL: any) => {
                        return (
                            (valueD === undefined || valueD === null || valueD === 0 || (valueD.length && valueD.length === 0) || (Object.keys(valueD) && Object.keys(valueD).length === 0)) &&
                            (valueV === undefined || valueV === null || valueV === 0 || (valueV.length && valueV.length === 0) || (Object.keys(valueV) && Object.keys(valueV).length === 0)) &&
                            (valueL === undefined || valueL === null || valueL === 0 || (valueL.length && valueL.length === 0) || (Object.keys(valueL) && Object.keys(valueL).length === 0))
                        )
                    },
                    then: Yup.array().nullable().required(requiredMessage)
                }),
        },
        {
            label: 'Strumenti', // 'Dispositivi',
            name: 'devices',
            type: 'multi-select',
            class: 'form-control',
            options: devices,
            validation: Yup.array().nullable(),
        },
        {
            label: 'Veicoli',
            name: 'vehicles',
            type: 'multi-select',
            class: 'form-control',
            options: vehicles,
            validation: Yup.array().nullable(),
        },
        {
            label: 'Licenze',
            name: 'licenses',
            type: 'multi-select',
            class: 'form-control',
            options: licenses,
            validation: Yup.array().nullable(),
        },
        {
            label: 'Stato',
            name: 'stato',
            type: 'search-select',
            class: 'form-control',
            options: statuses,
            validation: Yup.string().required(requiredMessage)
        },
        {
            label: 'Priorità',
            name: 'priority',
            type: 'search-select',
            class: 'form-control',
            options: priority,
            validation: Yup.string().required(requiredMessage)
        },
        {
            label: 'Indirizzo',
            name: 'address',
            type: 'text',
            class: 'form-control',
            validation: Yup.string()
        },
        {
            label: 'Note',
            name: 'note',
            type: 'textarea',
            class: 'form-control',
            validation: Yup.string()
        }
    ];
}

// function: setting form fields for planning's change status modal
export function planningChangeStatusFormFields(
    statuses: iFormFieldOption[],
): iFormField[] {
    return [
        {
            label: 'Stato',
            name: 'stato',
            type: 'search-select',
            class: 'form-control',
            options: statuses,
            validation: Yup.string()
        },
    ];
}

export function planningPerDateUtenteFormFields(): Array<iFormField> {
    return [
        {
            'label': 'Data di inizio',
            'name': 'startDate',
            'type': 'date',
            'class': 'form-control',
            'validation': Yup.date().required("Campo obbligatorio")
        },
        {
            'label': 'Data di fine',
            'name': 'endDate',
            'type': 'date',
            'class': 'form-control',
            'validation': Yup.date().required("Campo obbligatorio")
        }
    ]
}
