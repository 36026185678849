import { IBusinessUnit } from '../../../helpers/interfaces/businessunit';
import axios from '../../api';

const API_URL = process.env.REACT_APP_API_URL;

class BusinessUnitService {
    async getAll() {
        try {
            const response = await axios.get<IBusinessUnit[]>(API_URL + 'database/businessunit');
            return Array.isArray(response.data) ? response.data : [];
        } catch (error) {
            return await Promise.reject();
        }
    }

    async getAllDatore() {
        try {
            const response = await axios.get<IBusinessUnit[]>(API_URL + 'datore/businessunit');
            return Array.isArray(response.data) ? response.data : [];
        } catch (error) {
            return await Promise.reject();
        }
    }

    async get(id: any) {
        try {
            const response = await axios.get<IBusinessUnit>(API_URL + 'database/businessunit/' + id);
            return typeof response.data == 'object' ? response.data : null;
        } catch (error) {
            return await Promise.reject();
        }
    }

    async put(id: any, data: any) {
        try {
            const response = await axios.put(API_URL + 'database/businessunit/' + id, { ...data });
            return response.data;
        } catch (error) {
            return [];
        }
    }

    async add(data: any) {
        try {
            const response = await axios.post(API_URL + 'database/businessunit', { ...data });
            return response.data;
        } catch (error) {
            return [];
        }
    }
}

export default new BusinessUnitService();