import { AxiosResponse } from 'axios';
import { IMagazzinoTipologiaStrumento } from '../../../helpers/interfaces/magazzino/magazzino-tipologia-strumenti';
import axios from '../../api';

const API_URL = process.env.REACT_APP_API_URL;

class TipologiaStrumentiService {

  controller: AbortController;

  constructor() {
    this.controller = new AbortController();
  }

  async getAll(): Promise<IMagazzinoTipologiaStrumento[]> {
    this.controller = new AbortController();
    try {
      const response = await axios.get<IMagazzinoTipologiaStrumento[]>(API_URL + 'magazzino/strumenti/tipologia', {
        signal: this.controller.signal
      });
      return Array.isArray(response.data) ? response.data : [];
    } catch (error) {
      return await Promise.reject();
    }
  }

  async getOne(id: string): Promise<IMagazzinoTipologiaStrumento> {
    try {
      const response = await axios.get<IMagazzinoTipologiaStrumento>(API_URL + 'magazzino/strumenti/tipologia/' + id);
      return response.data;
    } catch (error) {
      return await Promise.reject();
    }
  }

  async add(body: { nome: string }): Promise<IMagazzinoTipologiaStrumento> {
    try {
      const response = await axios.post<{ nome: string; }, AxiosResponse<IMagazzinoTipologiaStrumento>>(API_URL + 'magazzino/strumenti/tipologia', body);
      return response.data;
    } catch (error) {
      return await Promise.reject();
    }
  }

  async edit(id: string, body: { nome: string }): Promise<IMagazzinoTipologiaStrumento> {
    try {
      const response = await axios.put<{ nome: string; }, AxiosResponse<IMagazzinoTipologiaStrumento>>(API_URL + 'magazzino/strumenti/tipologia/' + id, body);
      return response.data;
    } catch (error) {
      return await Promise.reject();
    }
  }

  abort() {
    this.controller.abort();
  }

  async getAllDipendente(): Promise<IMagazzinoTipologiaStrumento[]> {
    this.controller = new AbortController();
    try {
      const response = await axios.get<IMagazzinoTipologiaStrumento[]>(API_URL + 'dipendente/strumenti/tipologia', {
        signal: this.controller.signal
      });
      return Array.isArray(response.data) ? response.data : [];
    } catch (error) {
      return await Promise.reject();
    }
  }

  async getOneDipendente(id: string): Promise<IMagazzinoTipologiaStrumento> {
    try {
      const response = await axios.get<IMagazzinoTipologiaStrumento>(API_URL + 'dipendente/strumenti/tipologia/' + id);
      return response.data;
    } catch (error) {
      return await Promise.reject();
    }
  }

}

export default new TipologiaStrumentiService();