import { ConfigColumnDefs, ConfigColumns } from "datatables.net";

export const ritListColumns: ConfigColumns[] = [
    { "title": "Azioni", "data": "id" },
    { "title": "Stato", "data": "stato" },
    { "title": "Tipo", "data": "rittype" },
    {
        "title": "Commessa", "data": null, render: (data: any, type: any, row: any) => {
            return row.commessa.jobordername + ' (' + row.commessa.identificationcode + ')'
        }
    },
    {
        "title": "TAG", "data": null, render: (data: any, type: any, row: any) => {
            return row.document.tag
        }
    },
    { "title": "Data inizio", "data": "startdate" },
    { "title": "Data fine", "data": "enddate" },
    {
        "title": "Contatto", "data": null, render: (data: any, type: any, row: any) => {
            return row.contatto.lastname + ' ' + row.contatto.name
        }
    },
    {
        "title": "Data invio", "data": null, render: (data: any, type: any, row: any) => {
            return row.document.sendDate
        }
    },
    {
        "title": "Firma", "data": null, render: (data: any, type: any, row: any) => {
            return row.document.signed ? 'Firmato' : 'NON firmato';
        }
    }
];

export default function getRitListColumnDefs(): ConfigColumnDefs[] {
    return [
        {
            target: 0,
            render: function (data: any, type: any, row: any) {
                const edit = `<a class="edit_btn custom-icon btn btn-outline-primary rounded-circle me-1" data-id="${data}"><i style="font-size: 18px" class="fa fa-pencil" aria-hidden="true"></i></a>`;
                const editRit = `<a class="editRit_btn custom-icon btn btn-outline-primary rounded-circle me-1" data-id="${data}"><i style="font-size: 18px" class="fa fa-pencil-square-o" aria-hidden="true"></i></a>`;
                // const pdf = `<a class="pdf_btn custom-icon btn btn-outline-primary rounded-circle me-1" data-id="${data}"><i style="font-size: 18px" class="fa fa-file-text-o" aria-hidden="true"></i></a>`;
                const pdf = `<a class="pdf_btn action-icon custom-icon btn btn-outline-secondary rounded-circle me-1" data-id="${data}"><i class="fa fa-download" aria-hidden="true"></i></a>`;
                const trash = `<a class="delete_btn custom-icon btn btn-outline-danger rounded-circle me-1" data-id="${data}"><i style="font-size: 18px" class="fa fa-trash" aria-hidden="true"></i></a>`;

                const buttons = row.stato === undefined || row.stato === "" ? edit + editRit + pdf + trash : pdf;

                return '<div class="d-flex">' + buttons + '</div>';
            }
        }
    ];
}