import React from 'react';
import { Component } from 'react';
import eventBus from '../../common/EventBus';
import { ITypeNote, INoteSpese } from '../../helpers/interfaces/note-spese';
import { IPersonForSearch } from '../../helpers/interfaces/person';
import { Profilo } from "../../helpers/interfaces/user";
import NoteSpeseService from '../../services/api/note-spese.service';
import personService from "../../services/api/person.service";
import UserService from "../../services/api/user.service";
// import NoteSpeseAmministratore from './note-spese-amministratore';
import NoteSpeseDipendente from './note-spese-dipendente';
// import { notaSpesaFormFields } from '../../config/formFields/note-spese-amministrazione';
import { Field, FormikProps } from "formik";
import { iFormField, iFormFieldValue } from "../../helpers/interfaces/generic";
// import { Tab } from "../../common/TabComponent";
import ModalForm from '../../common/ModalForm';
import Swal from "sweetalert2";
import "moment/locale/it";

type Props = {
}

type State = {
    loading: boolean,
    disabledForm: boolean,
    formFields: Array<iFormField>,
    formInitialValues: { [key: string]: any },
    showModal: boolean,
    modalType: string,
    modalTitle: string,
    tipologieNota: iFormFieldValue[],
    profilo: { id: number, name: string }
    personale: iFormFieldValue[],
    commesse: iFormFieldValue[],
    expenses: INoteSpese[]
}

export default class NoteSpeseAmministrazione extends Component<Props, State> {
    
    apiSubmit: any;
    
    constructor(props: Props) {
        super(props);    

        this.state = {
            loading: false,
            disabledForm: false,
            formFields: [],
            formInitialValues: {},
            showModal: false,
            modalType: '',
            modalTitle: '',
            tipologieNota: [],
            profilo: { id: 1, name: '' },
            personale: [],
            commesse: [],
            expenses: []
        }

        // this.showNoteTable = this.showNoteTable.bind(this);
    }

    async componentDidMount(): Promise<void> {
        eventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });

        await NoteSpeseService.getType()
        .then((_tipologieNota: ITypeNote[]) => {
            if (_tipologieNota && _tipologieNota.length > 0) {
                const tipologieNota = _tipologieNota.map((item: ITypeNote) => { return { key: item.id, value: item.nome } });

                this.setState({tipologieNota});
            }

        });
        
        await UserService.getProfile()
        .then((profile: Profilo) => {
            if (profile && profile.id) {
                const profilo: any = { id: profile.id, name: profile.name + ' ' + profile.lastname };
                this.setState({profilo});

                if (profile.personjoborders && profile.personjoborders.active && profile.personjoborders.active.length > 0) {
                    const commesse = profile.personjoborders.active.map((item: any) => { return { key: item.joborderid, value: item.code + ' - ' + item.name } });
                    this.setState({commesse});
                }
            } else {
                Swal.fire({
                    title: 'Errore',
                    text: 'Si è verificato un errore nel caricamento dei dati.',
                    icon: 'error',
                })
            }
        });

        // await personService.getAllForSearch()
        await personService.getAllForSearchDatore()
        .then((response: any) => {
            if (response && response.data) {
                const __personale = response.data.filter((item: IPersonForSearch) => item.status.toLowerCase() === 'e');
                // const personale = _personale.map((person: IPersonForSearch) => {return { key: person.id, value: person.name + ' ' + person.lastname } });
                const _personale = __personale.map((person: IPersonForSearch) => {return { key: person.id, value: person.nominativo } });

                const personale = _personale.sort(this.SortArray);

                this.setState({personale});
            } else {
                Swal.fire({
                    title: 'Errore',
                    text: 'Si è verificato un errore nel caricamento dei dati.',
                    icon: 'error',
                })
            }
        })

        eventBus.dispatch("hideLoader");
    }

    SortArray(x: any, y: any) {
        return x.value.localeCompare(y.value);
    }

    // showNoteTable(noteData: INoteSpese) {
    //     const attachments: any = [];
    //     noteData.attachments.map((attachment:any) => attachments.push({...attachment}))
    
    //     const notaSpese = {
    //         amount: noteData.amount,
    //         attachments: attachments,
    //         credit: noteData.creditcard,
    //         date: noteData.date,
    //         id: noteData.id,
    //         joborder: {
    //             id: noteData.joborder?.id,
    //             name: noteData.joborder?.name,
    //             code: noteData.joborder?.code,
    //             start: noteData.joborder?.start,
    //             expire: noteData.joborder?.expire,
    //             customer: noteData.joborder?.customer
    //         },
    //         km: noteData.km,
    //         tipologia: {
    //             id: noteData.tipologia?.id,
    //             nome: noteData.tipologia?.nome
    //         },
    //         tragitto: noteData.tragitto,
    //         person: {
    //             id: noteData.user?.id,
    //             fullname: noteData.user?.fullname
    //         }
    //     }
        
    //     const attachmentsList: string[] = [];
    //     notaSpese.attachments.map((item: any) => attachmentsList.push(item.path));

    //     this.setState({
    //         formFields: notaSpesaFormFields(this.state.personale, this.state.tipologieNota, this.state.commesse),
    //         formInitialValues: { ...notaSpese, 
    //                             tipologia: notaSpese.tipologia?.id ?? '',
    //                             credit: notaSpese.credit ? 1 : 0,
    //                             joborder: notaSpese.joborder?.id ?? '',
    //                             person: notaSpese.person?.id,
    //                             attachments: notaSpese.attachments
    //                             // attachments: attachmentsList
    //                         }
    //     }, () => {
    //         this.apiSubmit = NoteSpeseService.editNote;
    //         this.setState({ showModal: true, modalTitle: 'Modifica nota spese', modalType: 'edit' });
    //     });
    // }

    closeModal() {
        this.setState({ showModal: false });
    }

    renderField(item: iFormField, key: number, formik: FormikProps<any>) {
        switch (item.type) {
            case 'select':
                return <React.Fragment>
                    <label className="form-label col-3 col-form-label">{item.label}</label>
                    <div className="col">
                        <Field as={item.type} name={item.name} className={item.class} hidden={item.hidden} onChange={async (event: any) => {

                            formik.setFieldValue(item.name, event.target.value);
                            if (typeof item.updateField !== 'undefined' && typeof item.updateCallback !== 'undefined') {
                                await item.updateCallback(event.target.value, formik);
                            }
                        }}>
                            <option key={''} value={''}>{item.value ?? 'Tutti'}</option>
                            {item.values && item.values.map((option: iFormFieldValue) => {
                                    return <option key={option.key} value={option.key}>{option.value}</option>
                            })}
                        </Field>
                    </div>
                </React.Fragment>
            default:
                return <React.Fragment>
                    <label className="form-label col-3 col-form-label">{item.label}</label>
                    <div className="col">
                        <Field name={item.name} type={item.type} className={item.class} />
                    </div>
                </React.Fragment>
        }
    }

    render() {
        // const { formFields, formInitialValues, showModal, modalTitle, modalType, personale, profilo, tipologieNota, commesse } = this.state;
        const { formFields, formInitialValues, showModal, modalTitle, modalType, personale, tipologieNota, commesse } = this.state;

        return <React.Fragment>
            <div className="custom-container">
                <div className="card">
                    <div className="card-body">
                        <h2 className="card-title">
                            Note spese personale
                        </h2>
                        {/* <Tab useState={true} tabs={[
                            {
                                id: 'note_amministratore',
                                label: 'Sezione amministratore',
                                content: <NoteSpeseAmministratore
                                            persons={[]}
                                            profilo={profilo}
                                            tipologieNota={tipologieNota}
                                            commesse={commesse}
                                            renderField={this.renderField.bind(this)}
                                            parentCallback2={this.showNoteTable} />,
                                isActive: true
                            },
                            {
                                id: 'note_dipendenti',
                                label: 'Sezione dipendenti',
                                content: <NoteSpeseDipendente
                                            persons={personale}
                                            tipologieNota={tipologieNota}
                                            commesse={commesse}
                                            renderField={this.renderField.bind(this)}
                                            parentCallback2={this.showNoteTable} />,
                                isActive: false
                            }
                        ]} /> */}

                        <NoteSpeseDipendente
                                persons={personale}
                                tipologieNota={tipologieNota}
                                commesse={commesse}
                                renderField={this.renderField.bind(this)}
                                // parentCallback2={this.showNoteTable} 
                            />
                    </div>
                </div>

                <ModalForm
                    showModal={showModal}
                    title={modalTitle}
                    modalType={modalType}
                    formFields={formFields}
                    initialValues={formInitialValues}
                    apiSubmit={this.apiSubmit}
                    closeCallback={this.closeModal.bind(this)}
                />
            </div>
        </React.Fragment>
    }
}