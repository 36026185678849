import { Component } from "react";
import authService from "../services/auth.service";
import { History } from 'history';

type Props = {
  history: History;
};

type State = {};

export default class Home extends Component<Props, State>
{
  async componentDidMount(): Promise<void> {
    const currentUser = await authService.getCurrentUser();

    if (currentUser) {
      if (currentUser?.routes.includes('amministrazione-home-cards')) {
        this.props.history.push("/amministrazione");
      } else {
        this.props.history.push("/dipendente/home");
      }
    }
  }

  render() {
    return <></>;
  }
}