import React, { Component } from "react";
import Valutare from './valutare';
import Confermate from './confermate';
import Calendario from './calendario';
import { Tab, TabProp } from "../../../common/TabComponent";

type Props = {};

export default class PermessiFerie extends Component<Props> {

    render() {

        // set tabs config
        let gestioneTabs: TabProp[] = [
            {
                id: 'tab_gestione_da_valutare',
                label: `Da confermare`,
                hasBadge: false,
                content: <Valutare />,
                isActive: false
            },
            {
                id: 'tab_gestione_confermate',
                label: `Confermate`,
                hasBadge: false,
                content: <Confermate />,
                isActive: false
            },
            {
                id: 'tab_gestione_calendario',
                label: `Calendario`,
                hasBadge: false,
                content: <Calendario />,
                isActive: false
            }
        ]

        gestioneTabs[0].isActive = true

        return <div className="custom-container">
            <div className="card">
                <div className="card-body">
                    <h2 className="card-title">Gestione permessi e ferie</h2>
                    <Tab useState={true} tabs={gestioneTabs}></Tab>
                </div>
            </div>
        </div>
    }
}