import React, { Component } from "react";
import { Formik, Form, ErrorMessage, FormikProps } from "formik";
import * as Yup from "yup";

import EventBus from "../../common/EventBus";
import ReportsService from "../../services/api/reports.service";
import { getRapportiniCommesseFormFields } from "../../config/formFields/reports";
import { openBase64NewTab } from '../../common/Base64';
import Swal from "sweetalert2";
import { iFormField, iFormFieldValue } from "../../helpers/interfaces/generic";
import moment from "moment";
import "moment/locale/it";
import { numberRange } from "../../common/NumberRanger";

type Props = {
    renderField: (item: iFormField, _checkboxDisabled: boolean, key: number, formik: FormikProps<any>) => false | JSX.Element,
    persons: iFormFieldValue[],
    joborders: iFormFieldValue[],
    joborders_all: any[]
};

type State = {
    loading: boolean,
    formFields: Array<iFormField>,
    years: iFormFieldValue[],
    months: iFormFieldValue[],
    year: string,
    month: string,
    joborders: iFormFieldValue[],
    joborders_filtered: any[],
    disabledForm: boolean,
    formInitialValues: { [key: string]: any },
};

export default class RapportiniCommesse extends Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: false,
            formFields: [],
            years: [],
            months: [],
            year: '',
            month: '',
            joborders: [],
            joborders_filtered: [],
            disabledForm: true,
            formInitialValues: {},
        }
    }

    componentDidMount(): void {
        const years_all = numberRange(2016, moment().year()).reverse();
        let years: iFormFieldValue[] = years_all.map((item: any) => { return { key: item, value: item } });

        const months_all = numberRange(1, 12);
        let months: iFormFieldValue[] = months_all.map((item: any) => { return { key: item, value: item } });

        this.setState({
            formFields: getRapportiniCommesseFormFields(years, months, this.props.joborders, this.updateYearField.bind(this), this.updateMonthField.bind(this)),
            joborders: this.props.joborders,
            years,
            months
        });
    }

    componentDidUpdate(prevProps: any) {
        if (prevProps.joborders !== this.props.joborders) {
            const {years, months} = this.state;

            this.setState({ formFields: getRapportiniCommesseFormFields(years, months, this.props.joborders, this.updateYearField.bind(this), this.updateMonthField.bind(this)) });
        }
    }

    updateYearField( year: string ): void {
        // const {years, months} = this.state;
        // let joborders_all;

        // if (year) {
        //     const startYear = moment(year + '-01-01').year();
        //     const endYear = moment(year + '-12-31').year();

        //     joborders_all = this.props.joborders_all.filter((joborder, index: number) => {
        //         return (joborder.start && moment(joborder.start).year() === startYear) || 
        //             (joborder.end && moment(joborder.end).year() === endYear) ||
        //             ((joborder.start && moment(joborder.start).year() <= startYear) && (!joborder.end || (joborder.end && moment(joborder.end).year() >= endYear)))
        //     })
        // } else {
        //     joborders_all = this.props.joborders_all;
        // }

        // const joborders = joborders_all.map((item) => { return { key: item.id, value: item.name + ' (scadenza: ' + item.expired + ')' } });
        // this.setState({year, joborders, formFields: getRapportiniCommesseFormFields(years, months, joborders, this.updateYearField.bind(this)) });

        const {years, months} = this.state;
        let joborders_all;

        if (year) {
            const selectedYear = moment(year).year();

            joborders_all = this.props.joborders_all.filter(joborder => {
                if (!joborder.start) {joborder.start = '2016-01-01'};

                return (joborder.start && moment(joborder.start).year() === selectedYear) || 
                    (joborder.expired && moment(joborder.expired).year() === selectedYear) ||
                    ((joborder.start && moment(joborder.start).year() <= selectedYear) && 
                        (!joborder.expired || (joborder.expired && moment(joborder.expired).year() >= selectedYear)))
            });
        } else {
            joborders_all = this.props.joborders_all;
        }

        const joborders = joborders_all.map((item) => { return { key: item.id, value: item.name + ' (scadenza: ' + item.expired + ')' } });
        this.setState({year, joborders, formFields: getRapportiniCommesseFormFields(years, months, joborders, this.updateYearField.bind(this), this.updateMonthField.bind(this)) });
        this.setState({year: year, joborders_filtered: joborders_all});
    }

    updateMonthField( month: string ): void {
        const _month = month.length === 2 ? month : "0" + month;
        const date = this.state.year + "-" + _month + "-01";

        const {years, months} = this.state;
        let joborders_all;

        if (month) {
            const selectedYear = moment(this.state.year).year();
            const selectedMonth = moment(date).month();

            joborders_all = this.state.joborders_filtered.filter(joborder => {
                if (!joborder.start) {joborder.start = '2016-01-01'};

                return (joborder.expired && moment(joborder.expired).year() > selectedYear) || 
                    ((joborder.expired && moment(joborder.expired).year() === selectedYear) && moment(joborder.expired).month() >= selectedMonth)
            });
        } else {
            joborders_all = this.state.joborders_filtered;
        }

        const joborders = joborders_all.map((item) => { return { key: item.id, value: item.name + ' (scadenza: ' + item.expired + ')' } });
        this.setState({month, joborders, formFields: getRapportiniCommesseFormFields(years, months, joborders, this.updateYearField.bind(this), this.updateMonthField.bind(this)) });
    }

    validationSchema() {
        let validations: any = {};
        this.state.formFields.forEach((value: any, key: any) => (validations[value.name] = value.validation));

        return Yup.object().shape(validations);
    }

    async handleUpdate(formValue: any) {
        EventBus.dispatch("showLoader", { text: 'Generazione report in corso...' });
        const report: any = await ReportsService.joborder(formValue.year, formValue.month, formValue.joborder);
        if (typeof report.body !== 'undefined') {
            openBase64NewTab(report);
        } else {
            this.setState({
                loading: false,
            }, () => {
                Swal.fire({
                    title: 'Errore',
                    text: 'Si è verificato un errore durante la generazione del report.',
                    icon: 'error',
                });
            });
        }
        EventBus.dispatch("hideLoader");
    }

    render() {
        const { loading, formFields } = this.state;
        let initialValues: any = {};
        initialValues['year'] = '';

        return <React.Fragment>
            <Formik
                initialValues={initialValues}
                validationSchema={this.validationSchema.bind(this)}
                onSubmit={this.handleUpdate.bind(this)}
            >
                {(formik: FormikProps<any>) => {
                    return <Form className="px-3 pt-3">
                        {formFields && formFields.map((item: iFormField, key: number) => {
                            return <div className="form-group mb-3 row" key={key}>

                                {this.props.renderField(item, true, key, formik)}

                                <ErrorMessage
                                    name={item.name}
                                    component="div"
                                    className="alert alert-danger"
                                />
                            </div>
                        })}
                        <div className="d-flex justify-content-end">
                            <button type="submit" className="btn btn-primary" disabled={loading}>
                                {loading && (
                                    <span className="spinner-border spinner-border-sm me-1"></span>
                                )}
                                <span>Genera</span>
                            </button>
                        </div>
                    </Form>
                }}

            </Formik>
        </React.Fragment>
    }
}