import * as Yup from "yup";
import { iFormField, iFormFieldValue } from "../../helpers/interfaces/generic";
import { FormikProps } from "formik";

export function getEditCommessaFormFields(
  clienti: any,
  joborderFromCustomer: any,
  joborders: any = [],
  attivita: any,
  isFirstLevel: boolean,
  parentJoborderCallback?: (
      value: any,
      type: 'add' | 'edit'
  ) => Promise<number | undefined>,
  activityCallback?: (
      value: any,
      type: 'add' | 'edit'
  ) => Promise<number>,
): Array<iFormField> {
  return [
    {
      'label': 'Tipologia commessa',
      'name': 'rittype',
      'type': 'select',
      'values': getRitTypeFieldValues(),
      'class': 'form-select',
      // 'validation': Yup.string().required("Campo obbligatorio"),
      showFromFieldName: 'parentJoborderId',
      showFromFieldType: false
    },  
    {
      'label': 'Cliente',
      'name': 'customerid',
      'type': 'select',
      'values': clienti,
      'class': 'form-select',
      // 'disabled': true,
      // 'validation': Yup.string().required("Campo obbligatorio"),
      'updateField': 'joborderid',
      'updateCallback': joborderFromCustomer
    },
    {
      'label': 'Commessa padre',
      'name': 'parentJoborderId',
      'type': 'select',
      'values': joborders,
      'class': 'form-select',
      'disabled': true,
      // 'validation': Yup.string().notRequired(),
      updateField: 'parentJoborderId',
      updateCallback: async (
        value: any,
        formik: FormikProps<any>
      ) => {
        if (value) {
          if (parentJoborderCallback) {
            const ritType = await parentJoborderCallback(value, 'edit');
            formik.setFieldValue('jobOrder_rit_type', ritType);
          }
        }
      },
    },
    {
      'label': 'Attività',
      'name': 'activity',
      'type': 'select',
      'values': attivita,
      'class': 'form-select',
      // 'validation': Yup.string().notRequired(),
      showFromFieldName: 'parentJoborderId',
      showFromFieldType: true,
      updateField: 'activity',
      updateCallback: async (
        value: any,
        formik: FormikProps<any>
      ) => {
        if (value) {
          if (activityCallback) {
            const ritType = await activityCallback(value, 'edit');
            formik.setFieldValue('activity_rit_type', ritType);
          }
        }
      },
    },
    {
      'label': 'Nome',
      'name': 'name',
      'type': 'text',
      'class': 'form-control',
      'disabled': !isFirstLevel,
      // 'validation': Yup.string().required("Campo obbligatorio")
    },
    {
      'label': 'Codice',
      'name': 'code',
      'type': 'text',
      'class': 'form-control',
      'disabled': !isFirstLevel,
      // 'validation': Yup.string().required("Campo obbligatorio")
    },
    {
      'label': 'Ordine',
      'name': 'order',
      'type': 'text',
      'class': 'form-control',
      'validation': Yup.string().when("parentJoborderId", {
        is: (value: any) => { return value === undefined },
        then: Yup.string().required("Campo obbligatorio")
      }),
      showFromFieldName: 'parentJoborderId',
      showFromFieldType: false
    },
    {
      'label': 'Giorni',
      'name': 'days',
      'type': 'text',
      'class': 'form-control',
      // 'validation': Yup.string().required("Campo obbligatorio"),
      showFromFieldName: 'parentJoborderId',
      showFromFieldType: false
    },
    {
      'label': 'Data Inizio',
      'name': 'start',
      'type': 'date',
      'class': 'form-control',
      'validation': Yup.date().when("parentJoborderId", {
        is: (value: any) => { return value === undefined },
        then: Yup.date().required("Campo obbligatorio")
      }),
      showFromFieldName: 'parentJoborderId',
      showFromFieldType: false
    },
    {
      'label': 'Data Termine',
      'name': 'expire',
      'type': 'date',
      'class': 'form-control',
      'validation': Yup.date().when("parentJoborderId", {
        is: (value: any) => { return value === undefined },
        then: Yup.date().required("Campo obbligatorio")
      }),
      showFromFieldName: 'parentJoborderId',
      showFromFieldType: false
    },
    {
      'label': 'Tariffa Oraria',
      'name': 'rateIsHourly',
      'type': 'select',
      'values': [{ key: 1, value: 'Si' }, { key: 0, value: 'No' }],
      'class': 'form-select',
      'validation': Yup.string().required("Campo obbligatorio"),
      showFromFieldName: 'parentJoborderId',
      showFromFieldType: false
    },
    {
      'label': 'Tariffa',
      'name': 'rate',
      'type': 'text',
      'class': 'form-control',
      'validation': Yup.number().when("rateIsHourly", {
        // is: (value: any) => { console.log("VALUE: ", value, '-', typeof(value)); return value === '1'},
        is: '1',
        then: Yup.number().required("Campo obbligatorio")
      }),
      showFromFieldName: 'parentJoborderId',
      showFromFieldType: false
    },
    {
      'label': 'Terminare la commessa?',
      'name': 'finished',
      'type': 'select',
      'values': [{ key: 1, value: 'Si' }, { key: 0, value: 'No' }],
      'class': 'form-select',
      // 'validation': Yup.string().required("Campo obbligatorio"),
      showFromFieldName: 'parentJoborderId',
      showFromFieldType: false
    },
    {
      'label': '',
      'name': 'jobOrder_rit_type',
      'type': 'unset',
      'class': 'form-control',
    },
    {
      'label': '',
      'name': 'activity_rit_type',
      'type': 'unset',
      'class': 'form-control',
    },
  ];
}

export function getAddCommessaFormFields(
  clienti: iFormFieldValue[],
  joborderFromCustomer: any,
  joborders: any = [],
  attivita: any[],
  parentJoborderCallback: (
      value: any,
      type: 'add' | 'edit'
  ) => Promise<number | undefined>,
): iFormField[] {
  return [
    {
      'label': 'Tipologia commessa',
      'name': 'rittype',
      'type': 'select',
      'values': getRitTypeFieldValues(),
      'class': 'form-select',
      // 'validation': Yup.string().required("Campo obbligatorio"),
      showFromFieldName: 'joborderid',
      showFromFieldType: false
    },
    {
      'label': 'Cliente',
      'name': 'customerid',
      'type': 'select',
      'values': clienti,
      'class': 'form-select',
      // 'validation': Yup.string().required("Campo obbligatorio"),
      'updateField': 'joborderid',
      'updateCallback': joborderFromCustomer
    },
    {
      'label': 'Commessa padre',
      'name': 'joborderid',
      'type': 'select',
      'values': joborders,
      'class': 'form-select',
      // 'validation': Yup.string().notRequired(),
      updateField: 'parentJoborderId',
      updateCallback: async (
        value: any,
        formik: FormikProps<any>
      ) => {
        if (value) {
          const ritType = await parentJoborderCallback(value, 'add');
          formik.setFieldValue('jobOrder_rit_type', ritType);
        }
      },
    },
    {
      'label': 'Attività',
      'name': 'activities',
      'type': 'multi-select',
      'options': attivita,
      'class': 'form-select',
      // 'validation': Yup.array().notRequired(),
      showFromFieldName: 'joborderid',
      showFromFieldType: true,
    },
    {
      'label': 'Nome',
      'name': 'name',
      'type': 'text',
      'class': 'form-control',
      // 'validation': Yup.string().required("Campo obbligatorio"),
      showFromFieldName: 'joborderid',
      // showFromFieldValue: [ undefined, 0 ]
      showFromFieldType: false
    },
    {
      'label': 'Codice',
      'name': 'code',
      'type': 'text',
      'class': 'form-control',
      // 'validation': Yup.string().required("Campo obbligatorio")
      showFromFieldName: 'joborderid',
      showFromFieldType: false
    },
    {
      'label': 'Ordine',
      'name': 'order',
      'type': 'text',
      'class': 'form-control',
      'required': true,
      'validation': Yup.string().when("joborderid", {
        is: (value: any) => { return value === undefined },
        then: Yup.string().required("Campo obbligatorio")
      }),
      showFromFieldName: 'joborderid',
      showFromFieldType: false
    },
    {
      'label': 'Giorni',
      'name': 'days',
      'type': 'text',
      'class': 'form-control',
      // 'validation': Yup.string().required("Campo obbligatorio"),
      showFromFieldName: 'joborderid',
      showFromFieldType: false
    },
    {
      'label': 'Data Inizio',
      'name': 'start',
      'type': 'date',
      'class': 'form-control',
      'validation': Yup.date().when("joborderid", {
        is: (value: any) => { return value === undefined },
        then: Yup.date().required("Campo obbligatorio")
      }),
      showFromFieldName: 'joborderid',
      showFromFieldType: false
    },
    {
      'label': 'Data Termine',
      'name': 'expire',
      'type': 'date',
      'class': 'form-control',
      'validation': Yup.date().when("joborderid", {
        is: (value: any) => { return value === undefined },
        then: Yup.date().required("Campo obbligatorio")
      }),
      showFromFieldName: 'joborderid',
      showFromFieldType: false
    },
    {
      'label': 'Tariffa Oraria',
      'name': 'rateIsHourly',
      'type': 'select',
      'values': [{ key: 1, value: 'Si' }, { key: 0, value: 'No' }],
      'class': 'form-select',
      'validation': Yup.string().required("Campo obbligatorio")
    },
    {
      'label': 'Tariffa',
      'name': 'rate',
      'type': 'text',
      'class': 'form-control',
      'validation': Yup.number().when("rateIsHourly", {
        is: (value: any) => { return value === '1'},
        then: Yup.number().required("Campo obbligatorio")
      })
    },
    {
      'label': 'Terminare la commessa?',
      'name': 'finished',
      'type': 'select',
      'values': [{ key: 1, value: 'Si' }, { key: 0, value: 'No' }],
      'class': 'form-select',
      // 'validation': Yup.string().required("Campo obbligatorio")
    },
    {
      'label': '',
      'name': 'jobOrder_rit_type',
      'type': 'unset',
      'class': 'form-control',
    },
  ];
}

export function getPersonListCommessaFormFields(personale: any[]) {
  return [
    {
      label: '',
      name: 'joborderid',
      type: 'unset',
      class: 'form-control',
    },
    {
      label: '',
      name: 'startdate',
      type: 'unset',
      class: 'form-control',
    },
    {
      label: '',
      name: 'enddate',
      type: 'unset',
      class: 'form-control',
    },
    {
      label: 'Dipendenti',
      name: 'ids',
      type: 'multi-select',
      isMulti: true,
      options: personale,
      class: 'form-select',
      validation: Yup.array().required("Campo obbligatorio")
    }
  ]
}

function getRitTypeFieldValues(): ({
  key: number,
  value: string
})[] {
  return [
    { key: 1, value: 'CONSUNTIVO' },
    { key: 2, value: 'CORPO' },
    { key: 3, value: 'GIORNATA'},
  ]
}