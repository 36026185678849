import React, { Component } from 'react';
import Swal from 'sweetalert2';
import EventBus from "../../../common/EventBus";
import Table from '../../../common/TableComponent';
import ModalForm from '../../../common/ModalForm';
import { getAddAttestatiFormFields } from "./config/formFields/addAttestati";
import { listAttestatiColumns, getListAttestatiColumnDefs } from "./config/tableColumns/listAttestati";
import AttestatiService from "../../../services/api/attestati.service";
import { iFormField } from '../../../helpers/interfaces/generic';

type Props = {
  attestati: any,
  personId: number
};

type State = {
  data: Array<any>,
  showModal: boolean,
  modalType: string,
  formFields: Array<iFormField>,
  formInitialValues: { [key: string]: any }
}

const $ = require('jquery');

class AttestatiComponent extends Component<Props, State> {
  buttons: (string | { text: string; className: string; action: (e: any, dt: any, node: any, config: any) => void; })[];
  apiSubmit: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      data: props.attestati,
      showModal: false,
      modalType: 'add',
      formFields: [],
      formInitialValues: { 'person_id': props.personId },
    }
    this.apiSubmit = AttestatiService.personAdd;

    this.buttons = [];
  }

  async componentDidMount() {
    EventBus.dispatch("showLoader", { text: 'Caricamento dati in corso...' });
    const attestati_all = await AttestatiService.getAll();
    EventBus.dispatch("hideLoader");
    let attestati: any = attestati_all.map((item: any) => { return { key: item.id, value: item.nome } });

    if (Object.keys(attestati).length > 0) {
      this.setState({ formFields: getAddAttestatiFormFields(attestati, this.props.personId) });
    }

    $('table').on('click', '.edit_btn', async (e: any) => {
      e.preventDefault();
      EventBus.dispatch("showLoader", { text: 'Caricamento Attestato in corso...' });
      const idAttestato = $(e.currentTarget).data('id');
      const attestato = await AttestatiService.personGet(idAttestato);
      if (attestato) {
        this.setState({ formFields: getAddAttestatiFormFields(attestati, this.props.personId), formInitialValues: attestato }, () => {
          this.apiSubmit = AttestatiService.personPut;
          this.setState({ showModal: true, modalType: 'edit' });
        });
      }
      EventBus.dispatch("hideLoader");
    });

    $('table').on('click', '.delete_btn', async (e: any) => {
      e.preventDefault();
      Swal.fire({
        title: "Vuoi eliminare il documento?",
        //text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Conferma',
        cancelButtonText: 'Annulla'
      }).then(async (result) => {
        if (result.isConfirmed) {
          const idAttestato = $(e.currentTarget).data('id');
          const attestato: any = await AttestatiService.personDelete(idAttestato);

          if (attestato && typeof attestato.error !== 'undefined') {
            Swal.fire(
              attestato.error,
              '',
              'error'
            );
          } else {
            this.handleSuccess()
          }
        }
      });
    });
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  handleSuccess() {
    window.location.hash = "#attestati";
    window.location.reload();
  }

  render() {
    const { showModal, formFields, formInitialValues, modalType, data } = this.state;
    return <div className="row p-2">
      <ModalForm
        showModal={showModal}
        title={'Attestato'}
        modalType={modalType}
        formFields={formFields}
        initialValues={formInitialValues}
        apiSubmit={this.apiSubmit}
        closeCallback={this.closeModal.bind(this)}
        successAction={this.handleSuccess.bind(this)}
      />
      <div className="d-flex justify-content-between align-items-center">
        <h3 className="m-0">Attestati</h3>
        <button id="add_btn" type="button" className="btn btn-outline-primary" disabled={formFields.length === 0} onClick={() => this.setState({ showModal: true, modalType: 'add' })}>
          <span>Assegna attestato</span>
        </button>
      </div>
      <div className='mt-3'>
        {
          data.length > 0 ? <React.Fragment>
            <Table id="table_attestati" columns={listAttestatiColumns} columnDefs={getListAttestatiColumnDefs()} datas={this.state.data} buttons={this.buttons} />
          </React.Fragment> : <p>Non sono presenti attestati</p>
        }
      </div>
    </div>
  }
}
export default AttestatiComponent;