import * as Yup from "yup";
import { iFormField } from "../../helpers/interfaces/generic";

export function getEditExpensesFormFields(): Array<iFormField> {
  return [
    {
      'label': 'Nome',
      'name': 'nome',
      'type': 'text',
      'class': 'form-control',
      'disabled': true
    },
    {
      'label': 'Conto',
      'name': 'conto',
      'type': 'text',
      'class': 'form-control',
      'validation': Yup.string().required("Campo obbligatorio")
    }
  ];
}

export function getAddExpensesFormFields(): Array<iFormField> | Array<iFormField> {
  return [
    {
      'label': 'Nome',
      'name': 'nome',
      'type': 'text',
      'class': 'form-control',
      'validation': Yup.string().required("Campo obbligatorio")
    },
    {
      'label': 'Conto',
      'name': 'conto',
      'type': 'text',
      'class': 'form-control',
      'validation': Yup.string().required("Campo obbligatorio")
    }
  ];
}