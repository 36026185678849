import { Component } from 'react';
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import PersonService from "../../../services/api/person.service";
import { buttonsStyle, iconsStyle } from '../../../helpers/settings/buttons-icons-styles';
import businessunitService from '../../../services/api/database/businessunit.service';

type Props = {
  person: any
};
type State = {
  data: any,
  loading: boolean,
  message: string,
  disabledForm: boolean,
  businessunit: { id: number, value: string }[]
};
class InfoAccessoComponent extends Component<Props, State> {
  status: any;
  // State array variable to save and show data
  constructor(props: Props) {
    super(props);
    this.state = {
      data: props.person,
      loading: false,
      message: "",
      disabledForm: true,
      businessunit: [{ id: props.person.businessunit.id, value: props.person.businessunit.nome }]
    }
    this.handleLogin = this.handleLogin.bind(this);
    this.status = { 'E': 'Attivo', 'S': 'Sospeso', 'D': 'Eliminato' };
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    if (prevState.disabledForm !== this.state.disabledForm && this.state.disabledForm === false) {
      businessunitService.getAllDatore().then(
        (response) => {
          this.setState({ businessunit: response.map(unit => { return { id: unit.id, value: unit.nome } }) });
        },
      );
    }
  }

  handleLogin(formValue: { registrationemail: string; lastname: string, name: string, status: string }) {
    this.setState({
      message: "",
      loading: true
    });

    PersonService.updateEmployee(this.state.data.id, formValue).then(
      () => {
        this.setState({
          message: "",
          loading: false
        });
        window.location.reload()
      },
      (error: any) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        this.setState({
          loading: false,
          message: resMessage
        });
      }
    );
  }

  validationSchema() {
    return Yup.object().shape({
      registrationemail: Yup.string().email().required("This field is required!"),
      lastname: Yup.string().required("This field is required!"),
      name: Yup.string().required("This field is required!"),
    });
  }

  render() {
    const { data, loading, message, disabledForm, businessunit } = this.state;
    const initialValues = {
      registrationemail: data.registrationemail,
      lastname: data.lastname,
      name: data.name,
      status: data.status,
      businessunit: data.businessunit.id
    };
    return <Formik
      initialValues={initialValues}
      validationSchema={this.validationSchema}
      onSubmit={this.handleLogin}
    >
      <Form className="card">
        <div className="card-body">
          <div className="d-flex align-items-center justify-content-end">
            <button style={buttonsStyle} className='btn rounded-circle' type='button' onClick={() => this.setState({ disabledForm: !disabledForm })} >
              {
                disabledForm ?
                  <i style={iconsStyle} className="fa fa-lock text-danger" aria-hidden="true"></i> :
                  <i style={iconsStyle} className="fa fa-unlock-alt text-success" aria-hidden="true"></i>
              }
            </button>
          </div>
          <div className="mb-3">
            <label className="form-label">E-mail</label>
            <Field name="registrationemail" type="email" className="form-control" disabled={disabledForm} />
            <ErrorMessage
              name="registrationemail"
              component="div"
              className="alert alert-danger"
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Cognome</label>
            <Field name="lastname" type="text" className="form-control" disabled={disabledForm} />
            <ErrorMessage
              name="lastname"
              component="div"
              className="alert alert-danger"
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Nome</label>
            <Field name="name" type="text" className="form-control" disabled={disabledForm} />
            <ErrorMessage
              name="name"
              component="div"
              className="alert alert-danger"
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Business unit</label>
            <Field as="select" name="businessunit" className="form-select" disabled={disabledForm}>
              {businessunit.map((businessunit: { id: number, value: string }) => {
                return <option key={businessunit.id} value={businessunit.id}>{businessunit.value}</option>
              })}
            </Field>
          </div>
          <div className="mb-3">
            <label className="form-label">Stato</label>
            <Field as="select" name="status" className="form-select" disabled={disabledForm}>
              {Object.keys(this.status).map((key: any) => {
                return <option key={key} value={key}>{this.status[key]}</option>
              })}
            </Field>
          </div>
          <div className="d-flex justify-content-end">
            <button type="submit" className="btn btn-primary" disabled={loading || disabledForm}>
              {
                loading && (
                  <span className="spinner-border spinner-border-sm me-1"></span>
                )
              }
              <span>Salva</span>
            </button>
          </div>
          {
            message && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {message}
                </div>
              </div>
            )
          }
        </div>
      </Form>
    </Formik>
  }
}
export default InfoAccessoComponent;