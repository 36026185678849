import * as Yup from "yup";
import { iFormField } from "../../helpers/interfaces/generic";

export function getEditPlanningNotificheFormFields(eventTypeList: any): Array<iFormField> {
  return [
    {
      'label': 'Email',
      'name': 'email',
      'type': 'text',
      'class': 'form-control',
      'validation': Yup.string().notRequired()
    },
    {
      'label': 'Evento',
      'name': 'eventtype',
      'type': 'select',
      'class': 'form-select',
      'values': eventTypeList,
      'validation': Yup.string().notRequired()
    }
  ];
}

export function getAddPlanningNotificheFormFields(eventTypeList: any[]): Array<iFormField> {
    return [
      {
        'label': 'Email',
        'name': 'email',
        'type': 'text',
        'class': 'form-control',
        'validation': Yup.string().notRequired()
      },
      {
        'label': 'Evento',
        'name': 'eventtype',
        'type': 'select',
        'class': 'form-select',
        'values': eventTypeList,
        'validation': Yup.string().notRequired()
      }
    ];
  }