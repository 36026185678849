import { AxiosResponse } from 'axios';
import axios from '../api';
import { ITypeRuolo, ITypeRuoloBody } from '../../helpers/interfaces/gestione-ruoli';

const API_URL = process.env.REACT_APP_API_URL;

class GestioneRuoliService {

    getRoles(): Promise<ITypeRuolo[]> {
        return axios.get<ITypeRuolo[]>(API_URL + 'admin/personrole')
            .then((response: AxiosResponse<ITypeRuolo[]>) => { return response.data })
            .catch((error) => { return Promise.reject(error); });
    }

    // getRole(id: string): Promise<ITypeRuolo> {
    //     return axios.get<ITypeRuolo>(API_URL + 'admin/personrole/' + id)
    //         .then((response: AxiosResponse<ITypeRuolo>) => { return response.data })
    //         .catch((error) => { return Promise.reject(error); });
    // }

    addRole(body: ITypeRuoloBody): Promise<ITypeRuolo> {
        return axios.post<ITypeRuoloBody, AxiosResponse<ITypeRuolo>>(API_URL + 'admin/personrole', body )
            .then((response: AxiosResponse<ITypeRuolo>) => { return response.data; })
            .catch((error) => { return Promise.reject(error); });
    }

    editRole(id: string, body: ITypeRuoloBody): Promise<any> {
        const role: ITypeRuoloBody = {
            role: body.role,
            code: body.code
        }
        
        return axios.put<ITypeRuoloBody, AxiosResponse<ITypeRuolo>>(API_URL + 'admin/personrole/' + id, role )
            .then((response: AxiosResponse<ITypeRuolo>) => { return response.data; })
            .catch((error) => { return Promise.reject(error); });
    }

}

export default new GestioneRuoliService();