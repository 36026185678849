import * as Yup from 'yup'
import { iFormField } from '../../../helpers/interfaces/generic';

export interface IContrattoForm {
    nome: string;
    codice: string;
    data_fine_noleggio: string;
    data_inizio_noleggio: string;
}

const requiredMessage = 'Campo obbligatorio'

// function: setting form fields for devices' add/edit page
export function contrattiFormFields(): iFormField[] {
    return [
        {
            label: 'Nome',
            name: 'nome',
            type: 'text',
            class: 'form-control',
            validation: Yup.string().required(requiredMessage)
        },
        {
            label: 'Codice',
            name: 'codice',
            type: 'text',
            class: 'form-control',
            validation: Yup.string().required(requiredMessage)
        },
        {
            label: 'Data inizio noleggio',
            name: 'data_inizio_noleggio',
            type: 'date',
            class: 'form-control',
            validation: Yup.string().required(requiredMessage)
        },
        {
            label: 'Data fine noleggio',
            name: 'data_fine_noleggio',
            type: 'date',
            class: 'form-control',
            validation: Yup.string().required(requiredMessage)
        }
    ];
}