import React, { Component } from "react";
import { Formik, Form, ErrorMessage, FormikProps } from "formik";
import * as Yup from "yup";
import eventBus from "../../common/EventBus";
import NoteSpeseService from '../../services/api/note-spese.service';
import { getNoteSpeseFormFields } from '../../config/formFields/note-spese-dipendente';
import Swal from "sweetalert2";
import { iFormField, iFormFieldValue } from "../../helpers/interfaces/generic";
import moment from "moment";
import "moment/locale/it";
import { numberRange } from "../../common/NumberRanger";

type Props = {
    renderField: (item: iFormField, key: number, formik: FormikProps<any>) => false | JSX.Element,
    parentCallback: any
};

type State = {
    loading: boolean,
    formFields: Array<iFormField>,
    formInitialValues: { [key: string]: any }
};

export default class NoteSpeseSelect extends Component<Props, State>{

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: false,
            formFields: [],
            formInitialValues: {},
        };
    }

    componentDidMount(): void {
        const _years = numberRange(2016, moment().year()).reverse();
        const years: iFormFieldValue[] = _years.map((item: any) => { return { key: item, value: item } });

        const _months = numberRange(1, 12);
        const months: iFormFieldValue[] = _months.map((item: any) => { return { key: item, value: item } });

        this.setState({
            formFields: getNoteSpeseFormFields( years, months ),
            formInitialValues: {}
        })
    }

    validationSchema() {
        let validations: any = {};
        this.state.formFields.forEach((value: any, key: any) => (validations[value.name] = value.validation));

        return Yup.object().shape(validations);
    }

    async handleUpdate(formValue: any) {
        eventBus.dispatch("showLoader", { text: 'Caricamento note spese in corso...' });

        NoteSpeseService.getAll(formValue.month, formValue.year)
            .then((response: any) => {
                    eventBus.dispatch("hideLoader");

                    if (response && response.status && response.status.toLowerCase() === 'error') {
                        if (response.message && response.message.toLowerCase() === 'non trovato') {
                            Swal.fire({
                                // title: 'Attenzione',
                                text: 'Non sono state trovate note spese nel periodo indicato.',
                                icon: 'warning',
                            })
                        } else {
                            Swal.fire({
                                title: 'Errore',
                                text: 'Si è verificato un errore.',
                                icon: 'error'
                            })
                        }

                        this.props.parentCallback(
                            []
                        );
                    } else {
                        this.props.parentCallback(
                            response
                        );
                    }
                }
            )
            .catch((error: string) => {
                eventBus.dispatch("hideLoader");

                Swal.fire({
                    title: 'Errore',
                    text: error,
                    icon: 'error',
                    });
            });
    }

    render() {
        const { loading, formFields } = this.state;
        let initialValues: any = {};
        initialValues['year'] = '';
        initialValues['month'] = '';

        return <React.Fragment>
            <Formik
                initialValues={initialValues}
                validationSchema={this.validationSchema.bind(this)}
                onSubmit={this.handleUpdate.bind(this)}
            >
                {(formik: FormikProps<any>) => {
                    return <Form className="px-3 pt-3">
                        {formFields && formFields.map((item: iFormField, key: number) => {
                            return <div className="form-group mb-3 row" key={key}>

                                {this.props.renderField(item, key, formik)}

                                <ErrorMessage
                                    name={item.name}
                                    component="div"
                                    className="alert alert-danger"
                                />
                            </div>
                        })}
                        <div className="d-flex justify-content-end">
                            <button type="submit" className="btn btn-primary" disabled={loading}>
                                {loading && (
                                    <span className="spinner-border spinner-border-sm me-1"></span>
                                )}
                                <span>Carica note spese</span>
                            </button>
                        </div>
                    </Form>
                }}

            </Formik>
        </React.Fragment>
    }
}