import { generatePath } from "react-router";
import { ConfigColumnDefs, ConfigColumns } from "datatables.net";

export const listAttestatiColumns: ConfigColumns[] = [
    { title: "Azioni", data: "id" },
    { title: "Nome", data: "nome" },
    { title: "Dipendenti", data: "dipendentiCount" }
];

export function getListAttestatiColumnDefs(): ConfigColumnDefs[] {
    return [
        {
            target: 0,
            //data: null,
            render: function (data: any, type: any, row: any) {
                return `<a class="edit_btn custom-icon btn btn-outline-primary rounded-circle" data-id="${data}">
                    <i style="font-size: 18px" class="fa fa-pencil" aria-hidden="true"></i>
                </a>`;
            },
        },
    ];
}

export const AttestatiDashboardCompletoColumns: ConfigColumns[] = [
    { title: "Azioni", data: "person_id" },
    {
        title: "Dipendente", data: null, render: (data: any, type: any, row: any) => {
            return row.person_lastname + ' ' + row.person_name;
        }
    },
    { title: "Cell", data: "person_phone" },
    { title: "E-mail", data: "person_email" },
    { title: "Sede contrattuale", data: "sede_contrattuale" },
    { title: "Tipo contratto", data: "tipo_contratto" },
    { title: "Tipo attestato", data: "tipo_attestato" },
    { title: "Data assegnazione", data: "data_assegnazione" },
    { title: "Data scadenza", data: "data_scadenza" }
];

export function AttestatiDashboardCompletoColumnDefs(): ConfigColumnDefs[] {
    return [
        {
            target: 0,
            //data: null,
            render: function (data: any, type: any, row: any) {
                const url = generatePath("/organico/dettaglio-personale/:id", {
                    id: data
                });
                return `<a class="edit_btn custom-icon btn btn-outline-primary rounded-circle" href="${url}">
                    <i style="font-size: 18px" class="fa fa-user" aria-hidden="true"></i>
                </a>`;
            },
        },
    ];
}

export const AttestatiDashboardScadenzaColumns: ConfigColumns[] = [
    { title: "Azioni", data: "id" },
    {
        title: "Dipendente",
        data: null, render: (data: any, type: any, row: any) => {
            return row.lastname + ' ' + row.name;
        }
    },
    { title: "Attestato", data: "attestato" },
    { title: "Data assegnazione", data: "data_assegnazione" },
    { title: "Data scadenza", data: "data_scadenza" }
];

export const AttestatiDashboardMancantiColumns: ConfigColumns[] = [
    { title: "Azioni", data: "person_id", defaultContent: "-" },
    {
        title: "Dipendente",
        data: null, render: (data: any, type: any, row: any) => {
            return row.person_lastname + ' ' + row.person_name;
        },
        defaultContent: "-"
    },
    { title: "E-mail", data: "person_email", defaultContent: "-" },
    { title: "Telefono", data: "anagrafica.telefono", defaultContent: "-" },
    { title: "CF", data: "anagrafica.cf", defaultContent: "-" },
    { title: "Data Nascita", data: "anagrafica.data_nascita", defaultContent: "-" },
    { title: "Luogo Nascita", data: "anagrafica.luogo_nascita", defaultContent: "-" },
    { title: "Provincia Nascita", data: "anagrafica.provincia_nascita", defaultContent: "-" },
    { title: "Codice Catastale", data: "anagrafica.codice_catastale_comune_nascita", defaultContent: "-" },
    { title: "Comune Residenza", data: "anagrafica.comune_residenza", defaultContent: "-" },
    { title: "Provincia Residenza", data: "anagrafica.provincia_residenza", defaultContent: "-" },
    { title: "CAP Residenza", data: "anagrafica.cap_residenza", defaultContent: "-" },
    { title: "Indirizzo Residenza", data: "anagrafica.indirizzo_residenza", defaultContent: "-" },
    { title: "Nazionalità", data: "anagrafica.nazionalita", defaultContent: "-" },
    { title: "Titolo Studio", data: "anagrafica.titolo_studio_nome", defaultContent: "-" },
    { title: "Permesso Soggiorno", data: "anagrafica.permesso_soggiorno", defaultContent: "-" },
    { title: "Comune Domicilio", data: "anagrafica.comune_domicilio", defaultContent: "-" },
    { title: "Provincia Domicilio", data: "anagrafica.provincia_domicilio", defaultContent: "-" },
    { title: "CAP Domicilio", data: "anagrafica.cap_domicilio", defaultContent: "-" },
    { title: "Indirizzo Domicilio", data: "anagrafica.indirizzo_domicilio", defaultContent: "-" }
];