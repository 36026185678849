import { generatePath } from "react-router-dom";
import { ConfigColumnDefs, ConfigColumns } from "datatables.net";
import { IMagazzinoServizio } from "../../../helpers/interfaces/magazzino/magazzino-servizi";

// table columns definitions
export const listServiziColumns: ConfigColumns[] = [
    { title: "Azioni", data: "id" },
    { title: "Nome", data: "nome" },
    { title: "Codice", data: "code" },
    { title: "Assegnatari", data: "assegnatari" }
];

// function: setting columns defs (in this case, setting the edit button at the first column)
export default function getServiziColumnDefs(): ConfigColumnDefs[] {
    return [
        {
            target: 0,
            render: (id: number, type: string, row: IMagazzinoServizio) => {
                const url = generatePath("/magazzino/servizi/dettaglio-servizio/:id", { id })

                const edit = `<a class="custom-icon btn btn-outline-primary rounded-circle" href="${url}" data-id="'+${id}+'"> 
                    <i style="font-size: 18px" class="fa fa-pencil" aria-hidden="true"></i>
                </a>`;

                return '<div class="d-flex">' + edit + '</div>';
            },
        },
        {
            target: 3,
            render: (data: Array<{assegnatario: string}>, type: string, row: IMagazzinoServizio) => {
                let assegnatari = data?.map(a => a.assegnatario)
                return assegnatari && assegnatari.length > 0 ? `<p data-id="'+${data}+'">${assegnatari.join(', ')}</p>` : '';
            },
        }
    ];
}