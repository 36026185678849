import * as Yup from 'yup'
import { iFormField } from '../../../helpers/interfaces/generic';

export interface IVeicoloForm {
    brand: string;
    model: string;
    plate: string;
}

const requiredMessage = 'Campo obbligatorio'

// function: setting form fields for devices' add/edit page
export function veicoliFormFields(): iFormField[] {
    return [
        {
            label: 'Marca',
            name: 'brand',
            type: 'text',
            class: 'form-control',
            validation: Yup.string().required(requiredMessage)
        },
        {
            label: 'Modello',
            name: 'model',
            type: 'text',
            class: 'form-control',
            validation: Yup.string().required(requiredMessage)
        },
        {
            label: 'Targa',
            name: 'plate',
            type: 'text',
            class: 'form-control',
            validation: Yup.string().required(requiredMessage)
        }
    ];
}